/* eslint-disable no-unused-vars */
import Button from '@components/button/button.component'
import { metaDummy2 } from 'common/common.static'
import Table from '@components/table/table.component'
import { Toast } from '@components/toast/toast.component'
import { IUseNewVendorBooking } from '../new-vendor-booking.interface'
import { containersHeaders } from '../new-vendor-booking.static'
import { INewVendorBookingContainerValidation } from 'form-validation/new-vendor-booking'

const Container = ({ service }: { service: IUseNewVendorBooking }) => {
    const additionalButtonPackline = (
        <>
            <Button
                onClick={() => {
                    service.setConfirmationModalProperty({
                        title: 'Delete Container',
                        message:
                            'Deleting this will not be able to undo the changes.',
                        btnProceed: {
                            onClick: () => {
                                const filteredData =
                                    service.containersData.filter((item) => {
                                        return !service.containersSelected.some(
                                            (deleteItem) =>
                                                deleteItem.id === item.id,
                                        )
                                    })
                                service.setContainersData(filteredData)
                                service.setContainersSelected([])
                                Toast({
                                    header: 'Container Removed Succesfully',
                                    type: 'success',
                                })
                            },
                            variant: 'red',
                        },
                    })
                    service.confirmationModal.openModalHandling()
                }}
                label="REMOVE SELECTED"
                variant="brand-v2-inverse"
                icon="ri-delete-bin-5-line text-size-L"
                className="flex items-center"
                isDisabled={service.containersSelected.length ? false : true}
            />
            <Button
                onClick={() => {
                    service.setContainerParams('add')
                    service.setContainerData(
                        service.newVendorBookingContainerInitialValue,
                    )
                    service.formContainerModal.openModalHandling()
                }}
                label="ADD CONTAINER"
                variant="brand-v2-inverse"
                icon="ri-add-line text-size-L"
                className="flex items-center"
            />
        </>
    )

    return (
        <div className="h-[calc(100vh-283px)]">
            <Table<INewVendorBookingContainerValidation>
                headers={containersHeaders}
                data={service.containersData}
                meta={metaDummy2(service.containersData.length)}
                nextHandling={(page) => {
                    console.log('hit Next API -> ' + page)
                }}
                previousHandling={(page) => {
                    console.log('hit Next API -> ' + page)
                }}
                moduleTitle={'Purchase Order'}
                checkboxVisible={true}
                checkboxDataHandling={(checkedData) => {
                    service.setContainersSelected(checkedData)
                }}
                additionalButtonBottom={additionalButtonPackline}
                loading={false}
                onRowClick={(data) => {
                    service.setContainerParams('update')
                    service.setContainerData(data)
                    service.formContainerModal.openModalHandling()
                }}
                disableErrorIllustration
            />
        </div>
    )
}

export default Container

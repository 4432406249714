import {
    IShippingInstructionContainer,
    IShippingInstructionPackLines,
} from './shipping-instruction-details/shipping-instruction-details.interface'
import { IShippingInstructionsSummary } from './shipping-instructions.interface'
import { IRelatedPartiesFormDummy } from './edit-shipping-instructions/edit-shipping-instructions.interface'

export const shippingInstructionsSummaryDummy: IShippingInstructionsSummary[] =
    [
        {
            transportMode: 'Air',
            status: 'to be submitted',
            bookingNo: 'BKG001',
            consignor: 'Company A',
            consignee: 'Company B',
            goodsDescription: 'Electronics',
            origin: 'JFK',
            destination: 'LHR',
            packs: '10',
            totalVolume: '5.2',
            containerMode: '',
            detailsData: {
                bookingNo: 'BKG001',
                containerMode: 'Loose',
                transportMode: 'Air Freight',
                serviceLevel: {
                    code: 'std',
                    description: 'Standard',
                },
                outerPacks: '20',
                outerPacksUnit: {
                    code: 'plt',
                    description: 'Pallet',
                },
                weightMeasure: {
                    qty: '100',
                    unit: 'kg',
                    unitLabel: 'KG',
                },
                volumeMeasure: {
                    unitLabel: 'M3',
                    unit: 'm3',
                    qty: '321',
                },
                woodenPackage: 'Fumigated',
                dangerousGoods: 'With MSDS',
                originPort: 'Melbourne',
                cargoReadyDate: '12 Jun 2024',
                destinationPort: 'Bangkok',
                deliveryRequiredBy: '20 Jun 2024',
                incoTerm: 'Ex Works',
                goodsValue: '154.00',
                goodsCurrency: 'THB',
                additionalTerms: 'Customer will issue agreement',
                insuranceValue: '100.00',
                insuranceCurrency: 'THB',
                chargesApply: 'Show Collect Charges',
                marksAndNumbers:
                    'Each package is clearly marked with a unique identifier, consisting of a combination of letters and numbers, for easy tracking and identification. Labels include the following marks and numbers: ABC1234567, XYZ8901234, LMN5678901, and QRS2345678. These markings ensure accurate handling and efficient processing throughout the shipping process.',
                goodsDescription:
                    'This shipment includes a variety of high-quality items. Fresh apples, picked at peak ripeness, ensure crisp and juicy fruit ready for consumption. Stylish leather jackets, crafted from premium leather, offer both durability and a fashionable look for all seasons. Soft and comfortable cotton T-shirts, available in various sizes and colors, provide everyday wear versatility. Beautifully crafted wooden furniture, including tables and chairs, features intricate designs and sturdy construction, perfect for home or office use. Each item is meticulously packed to ensure safe transit and delivery, guaranteeing customer satisfaction and product integrity upon arrival.',
                shipperPickupAddress: {
                    companyName: 'OneByOne Logistical Melbourne',
                    address1: '567 Collins Street',
                    address2: '',
                    city: 'Melborne',
                    state: 'VIC',
                    postCode: '3000',
                    country: {
                        code: 'AU',
                        name: 'Australia',
                    },
                    contactName: 'Raven Hudson',
                    phoneNumber: '+61 2 1234 5678',
                    mmobileNumber: '+61 4 1234 5678',
                    emailAddress: 'raven.hudson@gmail.com',
                },
                shipper: {
                    companyName: 'OneByOne Logistical Melbourne',
                    address1: '567 Collins Street',
                    address2: '',
                    city: 'Melborne',
                    state: 'VIC',
                    postCode: '3000',
                    country: {
                        code: 'AU',
                        name: 'Australia',
                    },
                    contactName: 'Raven Hudson',
                    phoneNumber: '+61 2 1234 5678',
                    mmobileNumber: '+61 4 1234 5678',
                    emailAddress: 'raven.hudson@gmail.com',
                },
                consigneeDeliveryAddress: {
                    companyName: 'OneByOne Logistical Bangkok',
                    address1: '123 Sukhumvit Road',
                    address2: '',
                    city: 'Bangkok',
                    state: 'Khlong Toei',
                    postCode: '10110',
                    country: {
                        code: 'TH',
                        name: 'Thailand',
                    },
                    contactName: 'Jason Lorde',
                    phoneNumber: '+66 2 123 4567',
                    mmobileNumber: '+66 8 1234 5678',
                    emailAddress: 'jason.lorde@gmail.com',
                },
                consignee: {
                    companyName: 'OneByOne Logistical Bangkok',
                    address1: '123 Sukhumvit Road',
                    address2: '',
                    city: 'Bangkok',
                    state: 'Khlong Toei',
                    postCode: '10110',
                    country: {
                        code: 'TH',
                        name: 'Thailand',
                    },
                    contactName: 'Jason Lorde',
                    phoneNumber: '+66 2 123 4567',
                    mmobileNumber: '+66 8 1234 5678',
                    emailAddress: 'jason.lorde@gmail.com',
                },
            },
            packLines: [
                {
                    pieces: '1',
                    packType: 'Box',
                    length: '10.00',
                    height: '10.00',
                    width: '22.00',
                    weightMeasure: {
                        unitLabel: 'KG',
                        unit: 'kg',
                        qty: '200.00',
                    },
                    volumeMeasure: {
                        unitLabel: 'M3',
                        unit: 'm3',
                        qty: '321',
                    },
                    description: 'Fresh Apples',
                    marksAndNumbers: 'ABC1234567',
                    linePrice: '$100.00',
                    tariffNumber: '0808.10',
                    hsCode: '841810',
                    id: 1,
                    unitDimension: 'm',
                    priceCurrency: '',
                },
                {
                    pieces: '1',
                    packType: 'Box',
                    length: '10.00 M',
                    width: '22.00 M',
                    weightMeasure: {
                        unitLabel: 'KG',
                        unit: 'kg',
                        qty: '200.00',
                    },
                    volumeMeasure: {
                        unitLabel: 'M3',
                        unit: 'm3',
                        qty: '321',
                    },
                    description: 'Fresh Apples',
                    marksAndNumbers: 'ABC1234567',
                    linePrice: '$100.00',
                    tariffNumber: '0808.10',
                    hsCode: '841810',
                    height: '10.00 M',
                    id: 2,
                    unitDimension: 'm',
                    priceCurrency: '',
                },
                {
                    pieces: '1',
                    packType: 'Box',
                    length: '10.00 M',
                    width: '22.00 M',
                    weightMeasure: {
                        unitLabel: 'KG',
                        unit: 'kg',
                        qty: '200.00',
                    },
                    volumeMeasure: {
                        unitLabel: 'M3',
                        unit: 'm3',
                        qty: '321',
                    },
                    description: 'Fresh Apples',
                    marksAndNumbers: 'ABC1234567',
                    linePrice: '$100.00',
                    tariffNumber: '0808.10',
                    hsCode: '841810',
                    height: '10.00 M',
                    id: 3,
                    unitDimension: 'm',
                    priceCurrency: '',
                },
                {
                    pieces: '1',
                    packType: 'Box',
                    length: '10.00 M',
                    width: '22.00 M',
                    weightMeasure: {
                        unitLabel: 'KG',
                        unit: 'kg',
                        qty: '200.00',
                    },
                    volumeMeasure: {
                        unitLabel: 'M3',
                        unit: 'm3',
                        qty: '321',
                    },
                    description: 'Fresh Apples',
                    marksAndNumbers: 'ABC1234567',
                    linePrice: '$100.00',
                    tariffNumber: '0808.10',
                    hsCode: '841810',
                    height: '10.00 M',
                    id: 4,
                    unitDimension: 'm',
                    priceCurrency: '',
                },
                {
                    pieces: '1',
                    packType: 'Box',
                    length: '10.00 M',
                    width: '22.00 M',
                    weightMeasure: {
                        unitLabel: 'KG',
                        unit: 'kg',
                        qty: '200.00',
                    },
                    volumeMeasure: {
                        unitLabel: 'M3',
                        unit: 'm3',
                        qty: '321',
                    },
                    description: 'Fresh Apples',
                    marksAndNumbers: 'ABC1234567',
                    linePrice: '$100.00',
                    tariffNumber: '0808.10',
                    hsCode: '841810',
                    height: '10.00 M',
                    id: 5,
                    unitDimension: 'm',
                    priceCurrency: 'AUD',
                },
            ],
            containers: [],
        },
        {
            transportMode: 'Sea',
            status: 'submitted',
            bookingNo: 'BKG002',
            consignor: 'Company C',
            consignee: 'Company D',
            goodsDescription: 'Furniture',
            origin: 'HKG',
            destination: 'SIN',
            packs: '20',
            totalVolume: '15.7',
            containerMode: '',
            detailsData: {
                bookingNo: 'BKG002',
                containerMode: 'fcl',
                transportMode: 'Sea Freight',
                serviceLevel: {
                    code: 'std',
                    description: 'Standard',
                },
                outerPacks: '20',
                outerPacksUnit: {
                    code: 'plt',
                    description: 'Pallet',
                },
                weightMeasure: {
                    qty: '100',
                    unit: 'kg',
                    unitLabel: 'KG',
                },
                volumeMeasure: {
                    unitLabel: 'M3',
                    unit: 'm3',
                    qty: '321',
                },
                woodenPackage: 'Fumigated',
                dangerousGoods: 'With MSDS',
                originPort: 'Melbourne',
                cargoReadyDate: '12 Jun 2024',
                destinationPort: 'Bangkok',
                deliveryRequiredBy: '20 Jun 2024',
                incoTerm: 'Ex Works',
                goodsValue: '154.00',
                goodsCurrency: 'THB',
                additionalTerms: 'Customer will issue agreement',
                insuranceValue: '100.00',
                insuranceCurrency: 'THB',
                chargesApply: 'Show Collect Charges',
                marksAndNumbers:
                    'Each package is clearly marked with a unique identifier, consisting of a combination of letters and numbers, for easy tracking and identification. Labels include the following marks and numbers: ABC1234567, XYZ8901234, LMN5678901, and QRS2345678. These markings ensure accurate handling and efficient processing throughout the shipping process.',
                goodsDescription:
                    'This shipment includes a variety of high-quality items. Fresh apples, picked at peak ripeness, ensure crisp and juicy fruit ready for consumption. Stylish leather jackets, crafted from premium leather, offer both durability and a fashionable look for all seasons. Soft and comfortable cotton T-shirts, available in various sizes and colors, provide everyday wear versatility. Beautifully crafted wooden furniture, including tables and chairs, features intricate designs and sturdy construction, perfect for home or office use. Each item is meticulously packed to ensure safe transit and delivery, guaranteeing customer satisfaction and product integrity upon arrival.',
                shipperPickupAddress: {
                    companyName: 'OneByOne Logistical Melbourne',
                    address1: '567 Collins Street',
                    address2: '',
                    city: 'Melborne',
                    state: 'VIC',
                    postCode: '3000',
                    country: {
                        code: 'AU',
                        name: 'Australia',
                    },
                    contactName: 'Raven Hudson',
                    phoneNumber: '+61 2 1234 5678',
                    mmobileNumber: '+61 4 1234 5678',
                    emailAddress: 'raven.hudson@gmail.com',
                },
                shipper: {
                    companyName: 'OneByOne Logistical Melbourne',
                    address1: '567 Collins Street',
                    address2: '',
                    city: 'Melborne',
                    state: 'VIC',
                    postCode: '3000',
                    country: {
                        code: 'AU',
                        name: 'Australia',
                    },
                    contactName: 'Raven Hudson',
                    phoneNumber: '+61 2 1234 5678',
                    mmobileNumber: '+61 4 1234 5678',
                    emailAddress: 'raven.hudson@gmail.com',
                },
                consigneeDeliveryAddress: {
                    companyName: 'OneByOne Logistical Bangkok',
                    address1: '123 Sukhumvit Road',
                    address2: '',
                    city: 'Bangkok',
                    state: 'Khlong Toei',
                    postCode: '10110',
                    country: {
                        code: 'TH',
                        name: 'Thailand',
                    },
                    contactName: 'Jason Lorde',
                    phoneNumber: '+66 2 123 4567',
                    mmobileNumber: '+66 8 1234 5678',
                    emailAddress: 'jason.lorde@gmail.com',
                },
                consignee: {
                    companyName: 'OneByOne Logistical Bangkok',
                    address1: '123 Sukhumvit Road',
                    address2: '',
                    city: 'Bangkok',
                    state: 'Khlong Toei',
                    postCode: '10110',
                    country: {
                        code: 'TH',
                        name: 'Thailand',
                    },
                    contactName: 'Jason Lorde',
                    phoneNumber: '+66 2 123 4567',
                    mmobileNumber: '+66 8 1234 5678',
                    emailAddress: 'jason.lorde@gmail.com',
                },
            },
            packLines: [
                {
                    pieces: '1',
                    packType: 'Box',
                    length: '10.00',
                    height: '10.00',
                    width: '22.00',
                    weightMeasure: {
                        unitLabel: 'KG',
                        unit: 'kg',
                        qty: '200.00',
                    },
                    volumeMeasure: {
                        unitLabel: 'M3',
                        unit: 'm3',
                        qty: '321',
                    },
                    description: 'Fresh Apples',
                    marksAndNumbers: 'ABC1234567',
                    linePrice: '$100.00',
                    tariffNumber: '0808.10',
                    hsCode: '841810',
                    id: 1,
                    unitDimension: 'm',
                    priceCurrency: '',
                },
                {
                    pieces: '1',
                    packType: 'Box',
                    length: '10.00 M',
                    width: '22.00 M',
                    weightMeasure: {
                        unitLabel: 'KG',
                        unit: 'kg',
                        qty: '200.00',
                    },
                    volumeMeasure: {
                        unitLabel: 'M3',
                        unit: 'm3',
                        qty: '321',
                    },
                    description: 'Fresh Apples',
                    marksAndNumbers: 'ABC1234567',
                    linePrice: '$100.00',
                    tariffNumber: '0808.10',
                    hsCode: '841810',
                    height: '10.00 M',
                    id: 2,
                    unitDimension: 'm',
                    priceCurrency: '',
                },
                {
                    pieces: '1',
                    packType: 'Box',
                    length: '10.00 M',
                    width: '22.00 M',
                    weightMeasure: {
                        unitLabel: 'KG',
                        unit: 'kg',
                        qty: '200.00',
                    },
                    volumeMeasure: {
                        unitLabel: 'M3',
                        unit: 'm3',
                        qty: '321',
                    },
                    description: 'Fresh Apples',
                    marksAndNumbers: 'ABC1234567',
                    linePrice: '$100.00',
                    tariffNumber: '0808.10',
                    hsCode: '841810',
                    height: '10.00 M',
                    id: 3,
                    unitDimension: 'm',
                    priceCurrency: '',
                },
                {
                    pieces: '1',
                    packType: 'Box',
                    length: '10.00 M',
                    width: '22.00 M',
                    weightMeasure: {
                        unitLabel: 'KG',
                        unit: 'kg',
                        qty: '200.00',
                    },
                    volumeMeasure: {
                        unitLabel: 'M3',
                        unit: 'm3',
                        qty: '321',
                    },
                    description: 'Fresh Apples',
                    marksAndNumbers: 'ABC1234567',
                    linePrice: '$100.00',
                    tariffNumber: '0808.10',
                    hsCode: '841810',
                    height: '10.00 M',
                    id: 4,
                    unitDimension: 'm',
                    priceCurrency: '',
                },
                {
                    pieces: '1',
                    packType: 'Box',
                    length: '10.00 M',
                    width: '22.00 M',
                    weightMeasure: {
                        unitLabel: 'KG',
                        unit: 'kg',
                        qty: '200.00',
                    },
                    volumeMeasure: {
                        unitLabel: 'M3',
                        unit: 'm3',
                        qty: '321',
                    },
                    description: 'Fresh Apples',
                    marksAndNumbers: 'ABC1234567',
                    linePrice: '$100.00',
                    tariffNumber: '0808.10',
                    hsCode: '841810',
                    height: '10.00 M',
                    id: 5,
                    unitDimension: 'm',
                    priceCurrency: 'AUD',
                },
            ],
            containers: [
                {
                    containerNo: 'ABCD12345678',
                    type: '40 HC',
                    count: '1',
                    sealNo: 'SL2658981',
                    id: 1,
                },
                {
                    containerNo: 'ABCD12345678',
                    type: '40 HC',
                    count: '1',
                    sealNo: 'SL2658981',
                    id: 2,
                },
                {
                    containerNo: 'ABCD12345678',
                    type: '40 HC',
                    count: '1',
                    sealNo: 'SL2658981',
                    id: 3,
                },
                {
                    containerNo: 'ABCD12345678',
                    type: '40 HC',
                    count: '1',
                    sealNo: 'SL2658981',
                    id: 4,
                },
            ],
        },
        {
            transportMode: 'Road',
            status: 'to be submitted',
            bookingNo: 'BKG003',
            consignor: 'Company E',
            consignee: 'Company F',
            goodsDescription: 'Clothing',
            origin: 'LAX',
            destination: 'ORD',
            packs: '15',
            totalVolume: '8.4',
            containerMode: '',
            detailsData: {
                bookingNo: 'BKG003',
                containerMode: 'fcl',
                transportMode: 'Sea Freight',
                serviceLevel: {
                    code: 'std',
                    description: 'Standard',
                },
                outerPacks: '20',
                outerPacksUnit: {
                    code: 'plt',
                    description: 'Pallet',
                },
                weightMeasure: {
                    qty: '100',
                    unit: 'kg',
                    unitLabel: 'KG',
                },
                volumeMeasure: {
                    unitLabel: 'M3',
                    unit: 'm3',
                    qty: '321',
                },
                woodenPackage: 'Fumigated',
                dangerousGoods: 'With MSDS',
                originPort: 'Melbourne',
                cargoReadyDate: '12 Jun 2024',
                destinationPort: 'Bangkok',
                deliveryRequiredBy: '20 Jun 2024',
                incoTerm: 'Ex Works',
                goodsValue: '154.00',
                goodsCurrency: 'THB',
                additionalTerms: 'Customer will issue agreement',
                insuranceValue: '100.00',
                insuranceCurrency: 'THB',
                chargesApply: 'Show Collect Charges',
                marksAndNumbers:
                    'Each package is clearly marked with a unique identifier, consisting of a combination of letters and numbers, for easy tracking and identification. Labels include the following marks and numbers: ABC1234567, XYZ8901234, LMN5678901, and QRS2345678. These markings ensure accurate handling and efficient processing throughout the shipping process.',
                goodsDescription:
                    'This shipment includes a variety of high-quality items. Fresh apples, picked at peak ripeness, ensure crisp and juicy fruit ready for consumption. Stylish leather jackets, crafted from premium leather, offer both durability and a fashionable look for all seasons. Soft and comfortable cotton T-shirts, available in various sizes and colors, provide everyday wear versatility. Beautifully crafted wooden furniture, including tables and chairs, features intricate designs and sturdy construction, perfect for home or office use. Each item is meticulously packed to ensure safe transit and delivery, guaranteeing customer satisfaction and product integrity upon arrival.',
                shipperPickupAddress: {
                    companyName: 'OneByOne Logistical Melbourne',
                    address1: '567 Collins Street',
                    address2: '',
                    city: 'Melborne',
                    state: 'VIC',
                    postCode: '3000',
                    country: {
                        code: 'AU',
                        name: 'Australia',
                    },
                    contactName: 'Raven Hudson',
                    phoneNumber: '+61 2 1234 5678',
                    mmobileNumber: '+61 4 1234 5678',
                    emailAddress: 'raven.hudson@gmail.com',
                },
                shipper: {
                    companyName: 'OneByOne Logistical Melbourne',
                    address1: '567 Collins Street',
                    address2: '',
                    city: 'Melborne',
                    state: 'VIC',
                    postCode: '3000',
                    country: {
                        code: 'AU',
                        name: 'Australia',
                    },
                    contactName: 'Raven Hudson',
                    phoneNumber: '+61 2 1234 5678',
                    mmobileNumber: '+61 4 1234 5678',
                    emailAddress: 'raven.hudson@gmail.com',
                },
                consigneeDeliveryAddress: {
                    companyName: 'OneByOne Logistical Bangkok',
                    address1: '123 Sukhumvit Road',
                    address2: '',
                    city: 'Bangkok',
                    state: 'Khlong Toei',
                    postCode: '10110',
                    country: {
                        code: 'TH',
                        name: 'Thailand',
                    },
                    contactName: 'Jason Lorde',
                    phoneNumber: '+66 2 123 4567',
                    mmobileNumber: '+66 8 1234 5678',
                    emailAddress: 'jason.lorde@gmail.com',
                },
                consignee: {
                    companyName: 'OneByOne Logistical Bangkok',
                    address1: '123 Sukhumvit Road',
                    address2: '',
                    city: 'Bangkok',
                    state: 'Khlong Toei',
                    postCode: '10110',
                    country: {
                        code: 'TH',
                        name: 'Thailand',
                    },
                    contactName: 'Jason Lorde',
                    phoneNumber: '+66 2 123 4567',
                    mmobileNumber: '+66 8 1234 5678',
                    emailAddress: 'jason.lorde@gmail.com',
                },
            },
            packLines: [
                {
                    pieces: '1',
                    packType: 'Box',
                    length: '10.00',
                    height: '10.00',
                    width: '22.00',
                    weightMeasure: {
                        unitLabel: 'KG',
                        unit: 'kg',
                        qty: '200.00',
                    },
                    volumeMeasure: {
                        unitLabel: 'M3',
                        unit: 'm3',
                        qty: '321',
                    },
                    description: 'Fresh Apples',
                    marksAndNumbers: 'ABC1234567',
                    linePrice: '$100.00',
                    tariffNumber: '0808.10',
                    hsCode: '841810',
                    id: 1,
                    unitDimension: 'm',
                    priceCurrency: '',
                },
                {
                    pieces: '1',
                    packType: 'Box',
                    length: '10.00 M',
                    width: '22.00 M',
                    weightMeasure: {
                        unitLabel: 'KG',
                        unit: 'kg',
                        qty: '200.00',
                    },
                    volumeMeasure: {
                        unitLabel: 'M3',
                        unit: 'm3',
                        qty: '321',
                    },
                    description: 'Fresh Apples',
                    marksAndNumbers: 'ABC1234567',
                    linePrice: '$100.00',
                    tariffNumber: '0808.10',
                    hsCode: '841810',
                    height: '10.00 M',
                    id: 2,
                    unitDimension: 'm',
                    priceCurrency: '',
                },
                {
                    pieces: '1',
                    packType: 'Box',
                    length: '10.00 M',
                    width: '22.00 M',
                    weightMeasure: {
                        unitLabel: 'KG',
                        unit: 'kg',
                        qty: '200.00',
                    },
                    volumeMeasure: {
                        unitLabel: 'M3',
                        unit: 'm3',
                        qty: '321',
                    },
                    description: 'Fresh Apples',
                    marksAndNumbers: 'ABC1234567',
                    linePrice: '$100.00',
                    tariffNumber: '0808.10',
                    hsCode: '841810',
                    height: '10.00 M',
                    id: 3,
                    unitDimension: 'm',
                    priceCurrency: '',
                },
                {
                    pieces: '1',
                    packType: 'Box',
                    length: '10.00 M',
                    width: '22.00 M',
                    weightMeasure: {
                        unitLabel: 'KG',
                        unit: 'kg',
                        qty: '200.00',
                    },
                    volumeMeasure: {
                        unitLabel: 'M3',
                        unit: 'm3',
                        qty: '321',
                    },
                    description: 'Fresh Apples',
                    marksAndNumbers: 'ABC1234567',
                    linePrice: '$100.00',
                    tariffNumber: '0808.10',
                    hsCode: '841810',
                    height: '10.00 M',
                    id: 4,
                    unitDimension: 'm',
                    priceCurrency: '',
                },
                {
                    pieces: '1',
                    packType: 'Box',
                    length: '10.00 M',
                    width: '22.00 M',
                    weightMeasure: {
                        unitLabel: 'KG',
                        unit: 'kg',
                        qty: '200.00',
                    },
                    volumeMeasure: {
                        unitLabel: 'M3',
                        unit: 'm3',
                        qty: '321',
                    },
                    description: 'Fresh Apples',
                    marksAndNumbers: 'ABC1234567',
                    linePrice: '$100.00',
                    tariffNumber: '0808.10',
                    hsCode: '841810',
                    height: '10.00 M',
                    id: 5,
                    unitDimension: 'm',
                    priceCurrency: 'AUD',
                },
            ],
            containers: [
                {
                    containerNo: 'ABCD12345678',
                    type: '40 HC',
                    count: '1',
                    sealNo: 'SL2658981',
                    id: 1,
                },
                {
                    containerNo: 'ABCD12345678',
                    type: '40 HC',
                    count: '1',
                    sealNo: 'SL2658981',
                    id: 2,
                },
                {
                    containerNo: 'ABCD12345678',
                    type: '40 HC',
                    count: '1',
                    sealNo: 'SL2658981',
                    id: 3,
                },
                {
                    containerNo: 'ABCD12345678',
                    type: '40 HC',
                    count: '1',
                    sealNo: 'SL2658981',
                    id: 4,
                },
            ],
        },
        {
            transportMode: 'Air',
            status: 'to be submitted',
            bookingNo: 'BKG004',
            consignor: 'Company G',
            consignee: 'Company H',
            goodsDescription: 'Medical Supplies',
            origin: 'CDG',
            destination: 'FRA',
            packs: '5',
            totalVolume: '3.1',
            containerMode: '',
            detailsData: {
                bookingNo: 'BKG004',
                containerMode: 'fcl',
                transportMode: 'Air Freight',
                serviceLevel: {
                    code: 'std',
                    description: 'Standard',
                },
                outerPacks: '20',
                outerPacksUnit: {
                    code: 'plt',
                    description: 'Pallet',
                },
                weightMeasure: {
                    qty: '100',
                    unit: 'kg',
                    unitLabel: 'KG',
                },
                volumeMeasure: {
                    unitLabel: 'M3',
                    unit: 'm3',
                    qty: '321',
                },
                woodenPackage: 'Fumigated',
                dangerousGoods: 'With MSDS',
                originPort: 'Melbourne',
                cargoReadyDate: '12 Jun 2024',
                destinationPort: 'Bangkok',
                deliveryRequiredBy: '20 Jun 2024',
                incoTerm: 'Ex Works',
                goodsValue: '154.00',
                goodsCurrency: 'THB',
                additionalTerms: 'Customer will issue agreement',
                insuranceValue: '100.00',
                insuranceCurrency: 'THB',
                chargesApply: 'Show Collect Charges',
                marksAndNumbers:
                    'Each package is clearly marked with a unique identifier, consisting of a combination of letters and numbers, for easy tracking and identification. Labels include the following marks and numbers: ABC1234567, XYZ8901234, LMN5678901, and QRS2345678. These markings ensure accurate handling and efficient processing throughout the shipping process.',
                goodsDescription:
                    'This shipment includes a variety of high-quality items. Fresh apples, picked at peak ripeness, ensure crisp and juicy fruit ready for consumption. Stylish leather jackets, crafted from premium leather, offer both durability and a fashionable look for all seasons. Soft and comfortable cotton T-shirts, available in various sizes and colors, provide everyday wear versatility. Beautifully crafted wooden furniture, including tables and chairs, features intricate designs and sturdy construction, perfect for home or office use. Each item is meticulously packed to ensure safe transit and delivery, guaranteeing customer satisfaction and product integrity upon arrival.',
                shipperPickupAddress: {
                    companyName: 'OneByOne Logistical Melbourne',
                    address1: '567 Collins Street',
                    address2: '',
                    city: 'Melborne',
                    state: 'VIC',
                    postCode: '3000',
                    country: {
                        code: 'AU',
                        name: 'Australia',
                    },
                    contactName: 'Raven Hudson',
                    phoneNumber: '+61 2 1234 5678',
                    mmobileNumber: '+61 4 1234 5678',
                    emailAddress: 'raven.hudson@gmail.com',
                },
                shipper: {
                    companyName: 'OneByOne Logistical Melbourne',
                    address1: '567 Collins Street',
                    address2: '',
                    city: 'Melborne',
                    state: 'VIC',
                    postCode: '3000',
                    country: {
                        code: 'AU',
                        name: 'Australia',
                    },
                    contactName: 'Raven Hudson',
                    phoneNumber: '+61 2 1234 5678',
                    mmobileNumber: '+61 4 1234 5678',
                    emailAddress: 'raven.hudson@gmail.com',
                },
                consigneeDeliveryAddress: {
                    companyName: 'OneByOne Logistical Bangkok',
                    address1: '123 Sukhumvit Road',
                    address2: '',
                    city: 'Bangkok',
                    state: 'Khlong Toei',
                    postCode: '10110',
                    country: {
                        code: 'TH',
                        name: 'Thailand',
                    },
                    contactName: 'Jason Lorde',
                    phoneNumber: '+66 2 123 4567',
                    mmobileNumber: '+66 8 1234 5678',
                    emailAddress: 'jason.lorde@gmail.com',
                },
                consignee: {
                    companyName: 'OneByOne Logistical Bangkok',
                    address1: '123 Sukhumvit Road',
                    address2: '',
                    city: 'Bangkok',
                    state: 'Khlong Toei',
                    postCode: '10110',
                    country: {
                        code: 'TH',
                        name: 'Thailand',
                    },
                    contactName: 'Jason Lorde',
                    phoneNumber: '+66 2 123 4567',
                    mmobileNumber: '+66 8 1234 5678',
                    emailAddress: 'jason.lorde@gmail.com',
                },
            },
            packLines: [
                {
                    pieces: '1',
                    packType: 'Box',
                    length: '10.00',
                    height: '10.00',
                    width: '22.00',
                    weightMeasure: {
                        unitLabel: 'KG',
                        unit: 'kg',
                        qty: '200.00',
                    },
                    volumeMeasure: {
                        unitLabel: 'M3',
                        unit: 'm3',
                        qty: '321',
                    },
                    description: 'Fresh Apples',
                    marksAndNumbers: 'ABC1234567',
                    linePrice: '$100.00',
                    tariffNumber: '0808.10',
                    hsCode: '841810',
                    id: 1,
                    unitDimension: 'm',
                    priceCurrency: '',
                },
                {
                    pieces: '1',
                    packType: 'Box',
                    length: '10.00 M',
                    width: '22.00 M',
                    weightMeasure: {
                        unitLabel: 'KG',
                        unit: 'kg',
                        qty: '200.00',
                    },
                    volumeMeasure: {
                        unitLabel: 'M3',
                        unit: 'm3',
                        qty: '321',
                    },
                    description: 'Fresh Apples',
                    marksAndNumbers: 'ABC1234567',
                    linePrice: '$100.00',
                    tariffNumber: '0808.10',
                    hsCode: '841810',
                    height: '10.00 M',
                    id: 2,
                    unitDimension: 'm',
                    priceCurrency: '',
                },
                {
                    pieces: '1',
                    packType: 'Box',
                    length: '10.00 M',
                    width: '22.00 M',
                    weightMeasure: {
                        unitLabel: 'KG',
                        unit: 'kg',
                        qty: '200.00',
                    },
                    volumeMeasure: {
                        unitLabel: 'M3',
                        unit: 'm3',
                        qty: '321',
                    },
                    description: 'Fresh Apples',
                    marksAndNumbers: 'ABC1234567',
                    linePrice: '$100.00',
                    tariffNumber: '0808.10',
                    hsCode: '841810',
                    height: '10.00 M',
                    id: 3,
                    unitDimension: 'm',
                    priceCurrency: '',
                },
                {
                    pieces: '1',
                    packType: 'Box',
                    length: '10.00 M',
                    width: '22.00 M',
                    weightMeasure: {
                        unitLabel: 'KG',
                        unit: 'kg',
                        qty: '200.00',
                    },
                    volumeMeasure: {
                        unitLabel: 'M3',
                        unit: 'm3',
                        qty: '321',
                    },
                    description: 'Fresh Apples',
                    marksAndNumbers: 'ABC1234567',
                    linePrice: '$100.00',
                    tariffNumber: '0808.10',
                    hsCode: '841810',
                    height: '10.00 M',
                    id: 4,
                    unitDimension: 'm',
                    priceCurrency: '',
                },
                {
                    pieces: '1',
                    packType: 'Box',
                    length: '10.00 M',
                    width: '22.00 M',
                    weightMeasure: {
                        unitLabel: 'KG',
                        unit: 'kg',
                        qty: '200.00',
                    },
                    volumeMeasure: {
                        unitLabel: 'M3',
                        unit: 'm3',
                        qty: '321',
                    },
                    description: 'Fresh Apples',
                    marksAndNumbers: 'ABC1234567',
                    linePrice: '$100.00',
                    tariffNumber: '0808.10',
                    hsCode: '841810',
                    height: '10.00 M',
                    id: 5,
                    unitDimension: 'm',
                    priceCurrency: 'AUD',
                },
            ],
            containers: [
                {
                    containerNo: 'ABCD12345678',
                    type: '40 HC',
                    count: '1',
                    sealNo: 'SL2658981',
                    id: 1,
                },
                {
                    containerNo: 'ABCD12345678',
                    type: '40 HC',
                    count: '1',
                    sealNo: 'SL2658981',
                    id: 2,
                },
                {
                    containerNo: 'ABCD12345678',
                    type: '40 HC',
                    count: '1',
                    sealNo: 'SL2658981',
                    id: 3,
                },
                {
                    containerNo: 'ABCD12345678',
                    type: '40 HC',
                    count: '1',
                    sealNo: 'SL2658981',
                    id: 4,
                },
            ],
        },
        {
            transportMode: 'Sea',
            status: 'to be submitted',
            bookingNo: 'BKG005',
            consignor: 'Company I',
            consignee: 'Company J',
            goodsDescription: 'Machinery',
            origin: 'SHA',
            destination: 'LAX',
            packs: '12',
            totalVolume: '9.6',
            containerMode: '',
            detailsData: {
                bookingNo: 'BKG005',
                containerMode: 'ftl',
                transportMode: 'Sea Freight',
                serviceLevel: {
                    code: 'std',
                    description: 'Standard',
                },
                outerPacks: '20',
                outerPacksUnit: {
                    code: 'plt',
                    description: 'Pallet',
                },
                weightMeasure: {
                    qty: '100',
                    unit: 'kg',
                    unitLabel: 'KG',
                },
                volumeMeasure: {
                    unitLabel: 'M3',
                    unit: 'm3',
                    qty: '321',
                },
                woodenPackage: 'Fumigated',
                dangerousGoods: 'With MSDS',
                originPort: 'Melbourne',
                cargoReadyDate: '12 Jun 2024',
                destinationPort: 'Bangkok',
                deliveryRequiredBy: '20 Jun 2024',
                incoTerm: 'Ex Works',
                goodsValue: '154.00',
                goodsCurrency: 'THB',
                additionalTerms: 'Customer will issue agreement',
                insuranceValue: '100.00',
                insuranceCurrency: 'THB',
                chargesApply: 'Show Collect Charges',
                marksAndNumbers:
                    'Each package is clearly marked with a unique identifier, consisting of a combination of letters and numbers, for easy tracking and identification. Labels include the following marks and numbers: ABC1234567, XYZ8901234, LMN5678901, and QRS2345678. These markings ensure accurate handling and efficient processing throughout the shipping process.',
                goodsDescription:
                    'This shipment includes a variety of high-quality items. Fresh apples, picked at peak ripeness, ensure crisp and juicy fruit ready for consumption. Stylish leather jackets, crafted from premium leather, offer both durability and a fashionable look for all seasons. Soft and comfortable cotton T-shirts, available in various sizes and colors, provide everyday wear versatility. Beautifully crafted wooden furniture, including tables and chairs, features intricate designs and sturdy construction, perfect for home or office use. Each item is meticulously packed to ensure safe transit and delivery, guaranteeing customer satisfaction and product integrity upon arrival.',
                shipperPickupAddress: {
                    companyName: 'OneByOne Logistical Melbourne',
                    address1: '567 Collins Street',
                    address2: '',
                    city: 'Melborne',
                    state: 'VIC',
                    postCode: '3000',
                    country: {
                        code: 'AU',
                        name: 'Australia',
                    },
                    contactName: 'Raven Hudson',
                    phoneNumber: '+61 2 1234 5678',
                    mmobileNumber: '+61 4 1234 5678',
                    emailAddress: 'raven.hudson@gmail.com',
                },
                shipper: {
                    companyName: 'OneByOne Logistical Melbourne',
                    address1: '567 Collins Street',
                    address2: '',
                    city: 'Melborne',
                    state: 'VIC',
                    postCode: '3000',
                    country: {
                        code: 'AU',
                        name: 'Australia',
                    },
                    contactName: 'Raven Hudson',
                    phoneNumber: '+61 2 1234 5678',
                    mmobileNumber: '+61 4 1234 5678',
                    emailAddress: 'raven.hudson@gmail.com',
                },
                consigneeDeliveryAddress: {
                    companyName: 'OneByOne Logistical Bangkok',
                    address1: '123 Sukhumvit Road',
                    address2: '',
                    city: 'Bangkok',
                    state: 'Khlong Toei',
                    postCode: '10110',
                    country: {
                        code: 'TH',
                        name: 'Thailand',
                    },
                    contactName: 'Jason Lorde',
                    phoneNumber: '+66 2 123 4567',
                    mmobileNumber: '+66 8 1234 5678',
                    emailAddress: 'jason.lorde@gmail.com',
                },
                consignee: {
                    companyName: 'OneByOne Logistical Bangkok',
                    address1: '123 Sukhumvit Road',
                    address2: '',
                    city: 'Bangkok',
                    state: 'Khlong Toei',
                    postCode: '10110',
                    country: {
                        code: 'TH',
                        name: 'Thailand',
                    },
                    contactName: 'Jason Lorde',
                    phoneNumber: '+66 2 123 4567',
                    mmobileNumber: '+66 8 1234 5678',
                    emailAddress: 'jason.lorde@gmail.com',
                },
            },
            packLines: [
                {
                    pieces: '1',
                    packType: 'Box',
                    length: '10.00',
                    height: '10.00',
                    width: '22.00',
                    weightMeasure: {
                        unitLabel: 'KG',
                        unit: 'kg',
                        qty: '200.00',
                    },
                    volumeMeasure: {
                        unitLabel: 'M3',
                        unit: 'm3',
                        qty: '321',
                    },
                    description: 'Fresh Apples',
                    marksAndNumbers: 'ABC1234567',
                    linePrice: '$100.00',
                    tariffNumber: '0808.10',
                    hsCode: '841810',
                    id: 1,
                    unitDimension: 'm',
                    priceCurrency: '',
                },
                {
                    pieces: '1',
                    packType: 'Box',
                    length: '10.00 M',
                    width: '22.00 M',
                    weightMeasure: {
                        unitLabel: 'KG',
                        unit: 'kg',
                        qty: '200.00',
                    },
                    volumeMeasure: {
                        unitLabel: 'M3',
                        unit: 'm3',
                        qty: '321',
                    },
                    description: 'Fresh Apples',
                    marksAndNumbers: 'ABC1234567',
                    linePrice: '$100.00',
                    tariffNumber: '0808.10',
                    hsCode: '841810',
                    height: '10.00 M',
                    id: 2,
                    unitDimension: 'm',
                    priceCurrency: '',
                },
                {
                    pieces: '1',
                    packType: 'Box',
                    length: '10.00 M',
                    width: '22.00 M',
                    weightMeasure: {
                        unitLabel: 'KG',
                        unit: 'kg',
                        qty: '200.00',
                    },
                    volumeMeasure: {
                        unitLabel: 'M3',
                        unit: 'm3',
                        qty: '321',
                    },
                    description: 'Fresh Apples',
                    marksAndNumbers: 'ABC1234567',
                    linePrice: '$100.00',
                    tariffNumber: '0808.10',
                    hsCode: '841810',
                    height: '10.00 M',
                    id: 3,
                    unitDimension: 'm',
                    priceCurrency: '',
                },
                {
                    pieces: '1',
                    packType: 'Box',
                    length: '10.00 M',
                    width: '22.00 M',
                    weightMeasure: {
                        unitLabel: 'KG',
                        unit: 'kg',
                        qty: '200.00',
                    },
                    volumeMeasure: {
                        unitLabel: 'M3',
                        unit: 'm3',
                        qty: '321',
                    },
                    description: 'Fresh Apples',
                    marksAndNumbers: 'ABC1234567',
                    linePrice: '$100.00',
                    tariffNumber: '0808.10',
                    hsCode: '841810',
                    height: '10.00 M',
                    id: 4,
                    unitDimension: 'm',
                    priceCurrency: '',
                },
                {
                    pieces: '1',
                    packType: 'Box',
                    length: '10.00 M',
                    width: '22.00 M',
                    weightMeasure: {
                        qty: '100',
                        unit: 'kg',
                        unitLabel: 'KG',
                    },
                    volumeMeasure: {
                        unitLabel: 'M3',
                        unit: 'm3',
                        qty: '321',
                    },
                    description: 'Fresh Apples',
                    marksAndNumbers: 'ABC1234567',
                    linePrice: '$100.00',
                    tariffNumber: '0808.10',
                    hsCode: '841810',
                    height: '10.00 M',
                    id: 5,
                    unitDimension: 'm',
                    priceCurrency: 'AUD',
                },
            ],
            containers: [],
        },
        {
            transportMode: 'Road',
            status: 'submitted',
            bookingNo: 'BKG006',
            consignor: 'Company K',
            consignee: 'Company L',
            goodsDescription: 'Toys',
            origin: 'ATL',
            destination: 'MIA',
            packs: '8',
            totalVolume: '4.0',
            containerMode: '',
            detailsData: {
                bookingNo: 'BKG006',
                containerMode: 'ftl',
                transportMode: 'Road Freight',
                serviceLevel: {
                    code: 'std',
                    description: 'Standard',
                },
                outerPacks: '20',
                outerPacksUnit: {
                    code: 'plt',
                    description: 'Pallet',
                },
                weightMeasure: {
                    qty: '100',
                    unit: 'kg',
                    unitLabel: 'KG',
                },
                volumeMeasure: {
                    unitLabel: 'M3',
                    unit: 'm3',
                    qty: '321',
                },
                woodenPackage: 'Fumigated',
                dangerousGoods: 'With MSDS',
                originPort: 'Melbourne',
                cargoReadyDate: '12 Jun 2024',
                destinationPort: 'Bangkok',
                deliveryRequiredBy: '20 Jun 2024',
                incoTerm: 'Ex Works',
                goodsValue: '154.00',
                goodsCurrency: 'THB',
                additionalTerms: 'Customer will issue agreement',
                insuranceValue: '100.00',
                insuranceCurrency: 'THB',
                chargesApply: 'Show Collect Charges',
                marksAndNumbers:
                    'Each package is clearly marked with a unique identifier, consisting of a combination of letters and numbers, for easy tracking and identification. Labels include the following marks and numbers: ABC1234567, XYZ8901234, LMN5678901, and QRS2345678. These markings ensure accurate handling and efficient processing throughout the shipping process.',
                goodsDescription:
                    'This shipment includes a variety of high-quality items. Fresh apples, picked at peak ripeness, ensure crisp and juicy fruit ready for consumption. Stylish leather jackets, crafted from premium leather, offer both durability and a fashionable look for all seasons. Soft and comfortable cotton T-shirts, available in various sizes and colors, provide everyday wear versatility. Beautifully crafted wooden furniture, including tables and chairs, features intricate designs and sturdy construction, perfect for home or office use. Each item is meticulously packed to ensure safe transit and delivery, guaranteeing customer satisfaction and product integrity upon arrival.',
                shipperPickupAddress: {
                    companyName: 'OneByOne Logistical Melbourne',
                    address1: '567 Collins Street',
                    address2: '',
                    city: 'Melborne',
                    state: 'VIC',
                    postCode: '3000',
                    country: {
                        code: 'AU',
                        name: 'Australia',
                    },
                    contactName: 'Raven Hudson',
                    phoneNumber: '+61 2 1234 5678',
                    mmobileNumber: '+61 4 1234 5678',
                    emailAddress: 'raven.hudson@gmail.com',
                },
                shipper: {
                    companyName: 'OneByOne Logistical Melbourne',
                    address1: '567 Collins Street',
                    address2: '',
                    city: 'Melborne',
                    state: 'VIC',
                    postCode: '3000',
                    country: {
                        code: 'AU',
                        name: 'Australia',
                    },
                    contactName: 'Raven Hudson',
                    phoneNumber: '+61 2 1234 5678',
                    mmobileNumber: '+61 4 1234 5678',
                    emailAddress: 'raven.hudson@gmail.com',
                },
                consigneeDeliveryAddress: {
                    companyName: 'OneByOne Logistical Bangkok',
                    address1: '123 Sukhumvit Road',
                    address2: '',
                    city: 'Bangkok',
                    state: 'Khlong Toei',
                    postCode: '10110',
                    country: {
                        code: 'TH',
                        name: 'Thailand',
                    },
                    contactName: 'Jason Lorde',
                    phoneNumber: '+66 2 123 4567',
                    mmobileNumber: '+66 8 1234 5678',
                    emailAddress: 'jason.lorde@gmail.com',
                },
                consignee: {
                    companyName: 'OneByOne Logistical Bangkok',
                    address1: '123 Sukhumvit Road',
                    address2: '',
                    city: 'Bangkok',
                    state: 'Khlong Toei',
                    postCode: '10110',
                    country: {
                        code: 'TH',
                        name: 'Thailand',
                    },
                    contactName: 'Jason Lorde',
                    phoneNumber: '+66 2 123 4567',
                    mmobileNumber: '+66 8 1234 5678',
                    emailAddress: 'jason.lorde@gmail.com',
                },
            },
            packLines: [
                {
                    pieces: '1',
                    packType: 'Box',
                    length: '10.00',
                    height: '10.00',
                    width: '22.00',
                    weightMeasure: {
                        qty: '100',
                        unit: 'kg',
                        unitLabel: 'KG',
                    },
                    volumeMeasure: {
                        unitLabel: 'M3',
                        unit: 'm3',
                        qty: '321',
                    },
                    description: 'Fresh Apples',
                    marksAndNumbers: 'ABC1234567',
                    linePrice: '$100.00',
                    tariffNumber: '0808.10',
                    hsCode: '841810',
                    id: 1,
                    unitDimension: 'm',
                    priceCurrency: '',
                },
                {
                    pieces: '1',
                    packType: 'Box',
                    length: '10.00 M',
                    width: '22.00 M',
                    weightMeasure: {
                        qty: '100',
                        unit: 'kg',
                        unitLabel: 'KG',
                    },
                    volumeMeasure: {
                        unitLabel: 'M3',
                        unit: 'm3',
                        qty: '321',
                    },
                    description: 'Fresh Apples',
                    marksAndNumbers: 'ABC1234567',
                    linePrice: '$100.00',
                    tariffNumber: '0808.10',
                    hsCode: '841810',
                    height: '10.00 M',
                    id: 2,
                    unitDimension: 'm',
                    priceCurrency: '',
                },
                {
                    pieces: '1',
                    packType: 'Box',
                    length: '10.00 M',
                    width: '22.00 M',
                    weightMeasure: {
                        unitLabel: 'KG',
                        unit: 'kg',
                        qty: '200.00',
                    },
                    volumeMeasure: {
                        unitLabel: 'M3',
                        unit: 'm3',
                        qty: '321',
                    },
                    description: 'Fresh Apples',
                    marksAndNumbers: 'ABC1234567',
                    linePrice: '$100.00',
                    tariffNumber: '0808.10',
                    hsCode: '841810',
                    height: '10.00 M',
                    id: 3,
                    unitDimension: 'm',
                    priceCurrency: '',
                },
                {
                    pieces: '1',
                    packType: 'Box',
                    length: '10.00 M',
                    width: '22.00 M',
                    weightMeasure: {
                        unitLabel: 'KG',
                        unit: 'kg',
                        qty: '200.00',
                    },
                    volumeMeasure: {
                        unitLabel: 'M3',
                        unit: 'm3',
                        qty: '321',
                    },
                    description: 'Fresh Apples',
                    marksAndNumbers: 'ABC1234567',
                    linePrice: '$100.00',
                    tariffNumber: '0808.10',
                    hsCode: '841810',
                    height: '10.00 M',
                    id: 4,
                    unitDimension: 'm',
                    priceCurrency: '',
                },
                {
                    pieces: '1',
                    packType: 'Box',
                    length: '10.00 M',
                    width: '22.00 M',
                    weightMeasure: {
                        unitLabel: 'KG',
                        unit: 'kg',
                        qty: '200.00',
                    },
                    volumeMeasure: {
                        unitLabel: 'M3',
                        unit: 'm3',
                        qty: '321',
                    },
                    description: 'Fresh Apples',
                    marksAndNumbers: 'ABC1234567',
                    linePrice: '$100.00',
                    tariffNumber: '0808.10',
                    hsCode: '841810',
                    height: '10.00 M',
                    id: 5,
                    unitDimension: 'm',
                    priceCurrency: 'AUD',
                },
            ],
            containers: [],
        },
        {
            transportMode: 'Air',
            status: 'to be submitted',
            bookingNo: 'BKG007',
            consignor: 'Company M',
            consignee: 'Company N',
            goodsDescription: 'Pharmaceuticals',
            origin: 'DXB',
            destination: 'LHR',
            packs: '6',
            totalVolume: '2.7',
            containerMode: '',
            detailsData: {
                bookingNo: 'BKG007',
                containerMode: 'lcl',
                transportMode: 'Air Freight',
                serviceLevel: {
                    code: 'std',
                    description: 'Standard',
                },
                outerPacks: '20',
                outerPacksUnit: {
                    code: 'plt',
                    description: 'Pallet',
                },
                weightMeasure: {
                    qty: '100',
                    unit: 'kg',
                    unitLabel: 'KG',
                },
                volumeMeasure: {
                    unitLabel: 'M3',
                    unit: 'm3',
                    qty: '321',
                },
                woodenPackage: 'Fumigated',
                dangerousGoods: 'With MSDS',
                originPort: 'Melbourne',
                cargoReadyDate: '12 Jun 2024',
                destinationPort: 'Bangkok',
                deliveryRequiredBy: '20 Jun 2024',
                incoTerm: 'Ex Works',
                goodsValue: '154.00',
                goodsCurrency: 'THB',
                additionalTerms: 'Customer will issue agreement',
                insuranceValue: '100.00',
                insuranceCurrency: 'THB',
                chargesApply: 'Show Collect Charges',
                marksAndNumbers:
                    'Each package is clearly marked with a unique identifier, consisting of a combination of letters and numbers, for easy tracking and identification. Labels include the following marks and numbers: ABC1234567, XYZ8901234, LMN5678901, and QRS2345678. These markings ensure accurate handling and efficient processing throughout the shipping process.',
                goodsDescription:
                    'This shipment includes a variety of high-quality items. Fresh apples, picked at peak ripeness, ensure crisp and juicy fruit ready for consumption. Stylish leather jackets, crafted from premium leather, offer both durability and a fashionable look for all seasons. Soft and comfortable cotton T-shirts, available in various sizes and colors, provide everyday wear versatility. Beautifully crafted wooden furniture, including tables and chairs, features intricate designs and sturdy construction, perfect for home or office use. Each item is meticulously packed to ensure safe transit and delivery, guaranteeing customer satisfaction and product integrity upon arrival.',
                shipperPickupAddress: {
                    companyName: 'OneByOne Logistical Melbourne',
                    address1: '567 Collins Street',
                    address2: '',
                    city: 'Melborne',
                    state: 'VIC',
                    postCode: '3000',
                    country: {
                        code: 'AU',
                        name: 'Australia',
                    },
                    contactName: 'Raven Hudson',
                    phoneNumber: '+61 2 1234 5678',
                    mmobileNumber: '+61 4 1234 5678',
                    emailAddress: 'raven.hudson@gmail.com',
                },
                shipper: {
                    companyName: 'OneByOne Logistical Melbourne',
                    address1: '567 Collins Street',
                    address2: '',
                    city: 'Melborne',
                    state: 'VIC',
                    postCode: '3000',
                    country: {
                        code: 'AU',
                        name: 'Australia',
                    },
                    contactName: 'Raven Hudson',
                    phoneNumber: '+61 2 1234 5678',
                    mmobileNumber: '+61 4 1234 5678',
                    emailAddress: 'raven.hudson@gmail.com',
                },
                consigneeDeliveryAddress: {
                    companyName: 'OneByOne Logistical Bangkok',
                    address1: '123 Sukhumvit Road',
                    address2: '',
                    city: 'Bangkok',
                    state: 'Khlong Toei',
                    postCode: '10110',
                    country: {
                        code: 'TH',
                        name: 'Thailand',
                    },
                    contactName: 'Jason Lorde',
                    phoneNumber: '+66 2 123 4567',
                    mmobileNumber: '+66 8 1234 5678',
                    emailAddress: 'jason.lorde@gmail.com',
                },
                consignee: {
                    companyName: 'OneByOne Logistical Bangkok',
                    address1: '123 Sukhumvit Road',
                    address2: '',
                    city: 'Bangkok',
                    state: 'Khlong Toei',
                    postCode: '10110',
                    country: {
                        code: 'TH',
                        name: 'Thailand',
                    },
                    contactName: 'Jason Lorde',
                    phoneNumber: '+66 2 123 4567',
                    mmobileNumber: '+66 8 1234 5678',
                    emailAddress: 'jason.lorde@gmail.com',
                },
            },
            packLines: [
                {
                    pieces: '1',
                    packType: 'Box',
                    length: '10.00',
                    height: '10.00',
                    width: '22.00',
                    weightMeasure: {
                        unitLabel: 'KG',
                        unit: 'kg',
                        qty: '200.00',
                    },
                    volumeMeasure: {
                        unitLabel: 'M3',
                        unit: 'm3',
                        qty: '321',
                    },
                    description: 'Fresh Apples',
                    marksAndNumbers: 'ABC1234567',
                    linePrice: '$100.00',
                    tariffNumber: '0808.10',
                    hsCode: '841810',
                    id: 1,
                    unitDimension: 'm',
                    priceCurrency: '',
                },
                {
                    pieces: '1',
                    packType: 'Box',
                    length: '10.00 M',
                    width: '22.00 M',
                    weightMeasure: {
                        unitLabel: 'KG',
                        unit: 'kg',
                        qty: '200.00',
                    },
                    volumeMeasure: {
                        unitLabel: 'M3',
                        unit: 'm3',
                        qty: '321',
                    },
                    description: 'Fresh Apples',
                    marksAndNumbers: 'ABC1234567',
                    linePrice: '$100.00',
                    tariffNumber: '0808.10',
                    hsCode: '841810',
                    height: '10.00 M',
                    id: 2,
                    unitDimension: 'm',
                    priceCurrency: '',
                },
                {
                    pieces: '1',
                    packType: 'Box',
                    length: '10.00 M',
                    width: '22.00 M',
                    weightMeasure: {
                        unitLabel: 'KG',
                        unit: 'kg',
                        qty: '200.00',
                    },
                    volumeMeasure: {
                        unitLabel: 'M3',
                        unit: 'm3',
                        qty: '321',
                    },
                    description: 'Fresh Apples',
                    marksAndNumbers: 'ABC1234567',
                    linePrice: '$100.00',
                    tariffNumber: '0808.10',
                    hsCode: '841810',
                    height: '10.00 M',
                    id: 3,
                    unitDimension: 'm',
                    priceCurrency: '',
                },
                {
                    pieces: '1',
                    packType: 'Box',
                    length: '10.00 M',
                    width: '22.00 M',
                    weightMeasure: {
                        unitLabel: 'KG',
                        unit: 'kg',
                        qty: '200.00',
                    },
                    volumeMeasure: {
                        unitLabel: 'M3',
                        unit: 'm3',
                        qty: '321',
                    },
                    description: 'Fresh Apples',
                    marksAndNumbers: 'ABC1234567',
                    linePrice: '$100.00',
                    tariffNumber: '0808.10',
                    hsCode: '841810',
                    height: '10.00 M',
                    id: 4,
                    unitDimension: 'm',
                    priceCurrency: '',
                },
                {
                    pieces: '1',
                    packType: 'Box',
                    length: '10.00 M',
                    width: '22.00 M',
                    weightMeasure: {
                        unitLabel: 'KG',
                        unit: 'kg',
                        qty: '200.00',
                    },
                    volumeMeasure: {
                        unitLabel: 'M3',
                        unit: 'm3',
                        qty: '321',
                    },
                    description: 'Fresh Apples',
                    marksAndNumbers: 'ABC1234567',
                    linePrice: '$100.00',
                    tariffNumber: '0808.10',
                    hsCode: '841810',
                    height: '10.00 M',
                    id: 5,
                    unitDimension: 'm',
                    priceCurrency: 'AUD',
                },
            ],
            containers: [],
        },
        {
            transportMode: 'Sea',
            status: 'submitted',
            bookingNo: 'BKG008',
            consignor: 'Company O',
            consignee: 'Company P',
            goodsDescription: 'Automotive Parts',
            origin: 'RTM',
            destination: 'NYC',
            packs: '18',
            totalVolume: '11.5',
            containerMode: '',
            detailsData: {
                bookingNo: 'BKG008',
                containerMode: 'lcl',
                transportMode: 'Sea Freight',
                serviceLevel: {
                    code: 'std',
                    description: 'Standard',
                },
                outerPacks: '20',
                outerPacksUnit: {
                    code: 'plt',
                    description: 'Pallet',
                },
                weightMeasure: {
                    qty: '100',
                    unit: 'kg',
                    unitLabel: 'KG',
                },
                volumeMeasure: {
                    unitLabel: 'M3',
                    unit: 'm3',
                    qty: '321',
                },
                woodenPackage: 'Fumigated',
                dangerousGoods: 'With MSDS',
                originPort: 'Melbourne',
                cargoReadyDate: '12 Jun 2024',
                destinationPort: 'Bangkok',
                deliveryRequiredBy: '20 Jun 2024',
                incoTerm: 'Ex Works',
                goodsValue: '154.00',
                goodsCurrency: 'THB',
                additionalTerms: 'Customer will issue agreement',
                insuranceValue: '100.00',
                insuranceCurrency: 'THB',
                chargesApply: 'Show Collect Charges',
                marksAndNumbers:
                    'Each package is clearly marked with a unique identifier, consisting of a combination of letters and numbers, for easy tracking and identification. Labels include the following marks and numbers: ABC1234567, XYZ8901234, LMN5678901, and QRS2345678. These markings ensure accurate handling and efficient processing throughout the shipping process.',
                goodsDescription:
                    'This shipment includes a variety of high-quality items. Fresh apples, picked at peak ripeness, ensure crisp and juicy fruit ready for consumption. Stylish leather jackets, crafted from premium leather, offer both durability and a fashionable look for all seasons. Soft and comfortable cotton T-shirts, available in various sizes and colors, provide everyday wear versatility. Beautifully crafted wooden furniture, including tables and chairs, features intricate designs and sturdy construction, perfect for home or office use. Each item is meticulously packed to ensure safe transit and delivery, guaranteeing customer satisfaction and product integrity upon arrival.',
                shipperPickupAddress: {
                    companyName: 'OneByOne Logistical Melbourne',
                    address1: '567 Collins Street',
                    address2: '',
                    city: 'Melborne',
                    state: 'VIC',
                    postCode: '3000',
                    country: {
                        code: 'AU',
                        name: 'Australia',
                    },
                    contactName: 'Raven Hudson',
                    phoneNumber: '+61 2 1234 5678',
                    mmobileNumber: '+61 4 1234 5678',
                    emailAddress: 'raven.hudson@gmail.com',
                },
                shipper: {
                    companyName: 'OneByOne Logistical Melbourne',
                    address1: '567 Collins Street',
                    address2: '',
                    city: 'Melborne',
                    state: 'VIC',
                    postCode: '3000',
                    country: {
                        code: 'AU',
                        name: 'Australia',
                    },
                    contactName: 'Raven Hudson',
                    phoneNumber: '+61 2 1234 5678',
                    mmobileNumber: '+61 4 1234 5678',
                    emailAddress: 'raven.hudson@gmail.com',
                },
                consigneeDeliveryAddress: {
                    companyName: 'OneByOne Logistical Bangkok',
                    address1: '123 Sukhumvit Road',
                    address2: '',
                    city: 'Bangkok',
                    state: 'Khlong Toei',
                    postCode: '10110',
                    country: {
                        code: 'TH',
                        name: 'Thailand',
                    },
                    contactName: 'Jason Lorde',
                    phoneNumber: '+66 2 123 4567',
                    mmobileNumber: '+66 8 1234 5678',
                    emailAddress: 'jason.lorde@gmail.com',
                },
                consignee: {
                    companyName: 'OneByOne Logistical Bangkok',
                    address1: '123 Sukhumvit Road',
                    address2: '',
                    city: 'Bangkok',
                    state: 'Khlong Toei',
                    postCode: '10110',
                    country: {
                        code: 'TH',
                        name: 'Thailand',
                    },
                    contactName: 'Jason Lorde',
                    phoneNumber: '+66 2 123 4567',
                    mmobileNumber: '+66 8 1234 5678',
                    emailAddress: 'jason.lorde@gmail.com',
                },
            },
            packLines: [
                {
                    pieces: '1',
                    packType: 'Box',
                    length: '10.00',
                    height: '10.00',
                    width: '22.00',
                    weightMeasure: {
                        unitLabel: 'KG',
                        unit: 'kg',
                        qty: '200.00',
                    },
                    volumeMeasure: {
                        unitLabel: 'M3',
                        unit: 'm3',
                        qty: '321',
                    },
                    description: 'Fresh Apples',
                    marksAndNumbers: 'ABC1234567',
                    linePrice: '$100.00',
                    tariffNumber: '0808.10',
                    hsCode: '841810',
                    id: 1,
                    unitDimension: 'm',
                    priceCurrency: '',
                },
                {
                    pieces: '1',
                    packType: 'Box',
                    length: '10.00 M',
                    width: '22.00 M',
                    weightMeasure: {
                        unitLabel: 'KG',
                        unit: 'kg',
                        qty: '200.00',
                    },
                    volumeMeasure: {
                        unitLabel: 'M3',
                        unit: 'm3',
                        qty: '321',
                    },
                    description: 'Fresh Apples',
                    marksAndNumbers: 'ABC1234567',
                    linePrice: '$100.00',
                    tariffNumber: '0808.10',
                    hsCode: '841810',
                    height: '10.00 M',
                    id: 2,
                    unitDimension: 'm',
                    priceCurrency: '',
                },
                {
                    pieces: '1',
                    packType: 'Box',
                    length: '10.00 M',
                    width: '22.00 M',
                    weightMeasure: {
                        unitLabel: 'KG',
                        unit: 'kg',
                        qty: '200.00',
                    },
                    volumeMeasure: {
                        unitLabel: 'M3',
                        unit: 'm3',
                        qty: '321',
                    },
                    description: 'Fresh Apples',
                    marksAndNumbers: 'ABC1234567',
                    linePrice: '$100.00',
                    tariffNumber: '0808.10',
                    hsCode: '841810',
                    height: '10.00 M',
                    id: 3,
                    unitDimension: 'm',
                    priceCurrency: '',
                },
                {
                    pieces: '1',
                    packType: 'Box',
                    length: '10.00 M',
                    width: '22.00 M',
                    weightMeasure: {
                        unitLabel: 'KG',
                        unit: 'kg',
                        qty: '200.00',
                    },
                    volumeMeasure: {
                        unitLabel: 'M3',
                        unit: 'm3',
                        qty: '321',
                    },
                    description: 'Fresh Apples',
                    marksAndNumbers: 'ABC1234567',
                    linePrice: '$100.00',
                    tariffNumber: '0808.10',
                    hsCode: '841810',
                    height: '10.00 M',
                    id: 4,
                    unitDimension: 'm',
                    priceCurrency: '',
                },
                {
                    pieces: '1',
                    packType: 'Box',
                    length: '10.00 M',
                    width: '22.00 M',
                    weightMeasure: {
                        unitLabel: 'KG',
                        unit: 'kg',
                        qty: '200.00',
                    },
                    volumeMeasure: {
                        unitLabel: 'M3',
                        unit: 'm3',
                        qty: '321',
                    },
                    description: 'Fresh Apples',
                    marksAndNumbers: 'ABC1234567',
                    linePrice: '$100.00',
                    tariffNumber: '0808.10',
                    hsCode: '841810',
                    height: '10.00 M',
                    id: 5,
                    unitDimension: 'm',
                    priceCurrency: 'AUD',
                },
            ],
            containers: [],
        },
        {
            transportMode: 'Road',
            status: 'submitted',
            bookingNo: 'BKG009',
            consignor: 'Company Q',
            consignee: 'Company R',
            goodsDescription: 'Cosmetics',
            origin: 'LHR',
            destination: 'CDG',
            packs: '9',
            totalVolume: '3.8',
            containerMode: '',
            detailsData: {
                bookingNo: 'BKG009',
                containerMode: 'lcl',
                transportMode: 'Road Freight',
                serviceLevel: {
                    code: 'std',
                    description: 'Standard',
                },
                outerPacks: '20',
                outerPacksUnit: {
                    code: 'plt',
                    description: 'Pallet',
                },
                weightMeasure: {
                    qty: '100',
                    unit: 'kg',
                    unitLabel: 'KG',
                },
                volumeMeasure: {
                    unitLabel: 'M3',
                    unit: 'm3',
                    qty: '321',
                },
                woodenPackage: 'Fumigated',
                dangerousGoods: 'With MSDS',
                originPort: 'Melbourne',
                cargoReadyDate: '12 Jun 2024',
                destinationPort: 'Bangkok',
                deliveryRequiredBy: '20 Jun 2024',
                incoTerm: 'Ex Works',
                goodsValue: '154.00',
                goodsCurrency: 'THB',
                additionalTerms: 'Customer will issue agreement',
                insuranceValue: '100.00',
                insuranceCurrency: 'THB',
                chargesApply: 'Show Collect Charges',
                marksAndNumbers:
                    'Each package is clearly marked with a unique identifier, consisting of a combination of letters and numbers, for easy tracking and identification. Labels include the following marks and numbers: ABC1234567, XYZ8901234, LMN5678901, and QRS2345678. These markings ensure accurate handling and efficient processing throughout the shipping process.',
                goodsDescription:
                    'This shipment includes a variety of high-quality items. Fresh apples, picked at peak ripeness, ensure crisp and juicy fruit ready for consumption. Stylish leather jackets, crafted from premium leather, offer both durability and a fashionable look for all seasons. Soft and comfortable cotton T-shirts, available in various sizes and colors, provide everyday wear versatility. Beautifully crafted wooden furniture, including tables and chairs, features intricate designs and sturdy construction, perfect for home or office use. Each item is meticulously packed to ensure safe transit and delivery, guaranteeing customer satisfaction and product integrity upon arrival.',
                shipperPickupAddress: {
                    companyName: 'OneByOne Logistical Melbourne',
                    address1: '567 Collins Street',
                    address2: '',
                    city: 'Melborne',
                    state: 'VIC',
                    postCode: '3000',
                    country: {
                        code: 'AU',
                        name: 'Australia',
                    },
                    contactName: 'Raven Hudson',
                    phoneNumber: '+61 2 1234 5678',
                    mmobileNumber: '+61 4 1234 5678',
                    emailAddress: 'raven.hudson@gmail.com',
                },
                shipper: {
                    companyName: 'OneByOne Logistical Melbourne',
                    address1: '567 Collins Street',
                    address2: '',
                    city: 'Melborne',
                    state: 'VIC',
                    postCode: '3000',
                    country: {
                        code: 'AU',
                        name: 'Australia',
                    },
                    contactName: 'Raven Hudson',
                    phoneNumber: '+61 2 1234 5678',
                    mmobileNumber: '+61 4 1234 5678',
                    emailAddress: 'raven.hudson@gmail.com',
                },
                consigneeDeliveryAddress: {
                    companyName: 'OneByOne Logistical Bangkok',
                    address1: '123 Sukhumvit Road',
                    address2: '',
                    city: 'Bangkok',
                    state: 'Khlong Toei',
                    postCode: '10110',
                    country: {
                        code: 'TH',
                        name: 'Thailand',
                    },
                    contactName: 'Jason Lorde',
                    phoneNumber: '+66 2 123 4567',
                    mmobileNumber: '+66 8 1234 5678',
                    emailAddress: 'jason.lorde@gmail.com',
                },
                consignee: {
                    companyName: 'OneByOne Logistical Bangkok',
                    address1: '123 Sukhumvit Road',
                    address2: '',
                    city: 'Bangkok',
                    state: 'Khlong Toei',
                    postCode: '10110',
                    country: {
                        code: 'TH',
                        name: 'Thailand',
                    },
                    contactName: 'Jason Lorde',
                    phoneNumber: '+66 2 123 4567',
                    mmobileNumber: '+66 8 1234 5678',
                    emailAddress: 'jason.lorde@gmail.com',
                },
            },
            packLines: [
                {
                    pieces: '1',
                    packType: 'Box',
                    length: '10.00',
                    height: '10.00',
                    width: '22.00',
                    weightMeasure: {
                        unitLabel: 'KG',
                        unit: 'kg',
                        qty: '200.00',
                    },
                    volumeMeasure: {
                        unitLabel: 'M3',
                        unit: 'm3',
                        qty: '321',
                    },
                    description: 'Fresh Apples',
                    marksAndNumbers: 'ABC1234567',
                    linePrice: '$100.00',
                    tariffNumber: '0808.10',
                    hsCode: '841810',
                    id: 1,
                    unitDimension: 'm',
                    priceCurrency: '',
                },
                {
                    pieces: '1',
                    packType: 'Box',
                    length: '10.00 M',
                    width: '22.00 M',
                    weightMeasure: {
                        unitLabel: 'KG',
                        unit: 'kg',
                        qty: '200.00',
                    },
                    volumeMeasure: {
                        unitLabel: 'M3',
                        unit: 'm3',
                        qty: '321',
                    },
                    description: 'Fresh Apples',
                    marksAndNumbers: 'ABC1234567',
                    linePrice: '$100.00',
                    tariffNumber: '0808.10',
                    hsCode: '841810',
                    height: '10.00 M',
                    id: 2,
                    unitDimension: 'm',
                    priceCurrency: '',
                },
                {
                    pieces: '1',
                    packType: 'Box',
                    length: '10.00 M',
                    width: '22.00 M',
                    weightMeasure: {
                        unitLabel: 'KG',
                        unit: 'kg',
                        qty: '200.00',
                    },
                    volumeMeasure: {
                        unitLabel: 'M3',
                        unit: 'm3',
                        qty: '321',
                    },
                    description: 'Fresh Apples',
                    marksAndNumbers: 'ABC1234567',
                    linePrice: '$100.00',
                    tariffNumber: '0808.10',
                    hsCode: '841810',
                    height: '10.00 M',
                    id: 3,
                    unitDimension: 'm',
                    priceCurrency: '',
                },
                {
                    pieces: '1',
                    packType: 'Box',
                    length: '10.00 M',
                    width: '22.00 M',
                    weightMeasure: {
                        unitLabel: 'KG',
                        unit: 'kg',
                        qty: '200.00',
                    },
                    volumeMeasure: {
                        unitLabel: 'M3',
                        unit: 'm3',
                        qty: '321',
                    },
                    description: 'Fresh Apples',
                    marksAndNumbers: 'ABC1234567',
                    linePrice: '$100.00',
                    tariffNumber: '0808.10',
                    hsCode: '841810',
                    height: '10.00 M',
                    id: 4,
                    unitDimension: 'm',
                    priceCurrency: '',
                },
                {
                    pieces: '1',
                    packType: 'Box',
                    length: '10.00 M',
                    width: '22.00 M',
                    weightMeasure: {
                        unitLabel: 'KG',
                        unit: 'kg',
                        qty: '200.00',
                    },
                    volumeMeasure: {
                        unitLabel: 'M3',
                        unit: 'm3',
                        qty: '321',
                    },
                    description: 'Fresh Apples',
                    marksAndNumbers: 'ABC1234567',
                    linePrice: '$100.00',
                    tariffNumber: '0808.10',
                    hsCode: '841810',
                    height: '10.00 M',
                    id: 5,
                    unitDimension: 'm',
                    priceCurrency: 'AUD',
                },
            ],
            containers: [],
        },
        {
            transportMode: 'Air',
            status: 'submitted',
            bookingNo: 'BKG010',
            consignor: 'Company S',
            consignee: 'Company T',
            goodsDescription: 'Chemicals',
            origin: 'ORD',
            destination: 'DFW',
            packs: '11',
            totalVolume: '6.3',
            containerMode: '',
            detailsData: {
                bookingNo: 'BKG010',
                containerMode: 'lcl',
                transportMode: 'Air Freight',
                serviceLevel: {
                    code: 'std',
                    description: 'Standard',
                },
                outerPacks: '20',
                outerPacksUnit: {
                    code: 'plt',
                    description: 'Pallet',
                },
                weightMeasure: {
                    qty: '100',
                    unit: 'kg',
                    unitLabel: 'KG',
                },
                volumeMeasure: {
                    unitLabel: 'M3',
                    unit: 'm3',
                    qty: '321',
                },
                woodenPackage: 'Fumigated',
                dangerousGoods: 'With MSDS',
                originPort: 'Melbourne',
                cargoReadyDate: '12 Jun 2024',
                destinationPort: 'Bangkok',
                deliveryRequiredBy: '20 Jun 2024',
                incoTerm: 'Ex Works',
                goodsValue: '154.00',
                goodsCurrency: 'THB',
                additionalTerms: 'Customer will issue agreement',
                insuranceValue: '100.00',
                insuranceCurrency: 'THB',
                chargesApply: 'Show Collect Charges',
                marksAndNumbers:
                    'Each package is clearly marked with a unique identifier, consisting of a combination of letters and numbers, for easy tracking and identification. Labels include the following marks and numbers: ABC1234567, XYZ8901234, LMN5678901, and QRS2345678. These markings ensure accurate handling and efficient processing throughout the shipping process.',
                goodsDescription:
                    'This shipment includes a variety of high-quality items. Fresh apples, picked at peak ripeness, ensure crisp and juicy fruit ready for consumption. Stylish leather jackets, crafted from premium leather, offer both durability and a fashionable look for all seasons. Soft and comfortable cotton T-shirts, available in various sizes and colors, provide everyday wear versatility. Beautifully crafted wooden furniture, including tables and chairs, features intricate designs and sturdy construction, perfect for home or office use. Each item is meticulously packed to ensure safe transit and delivery, guaranteeing customer satisfaction and product integrity upon arrival.',
                shipperPickupAddress: {
                    companyName: 'OneByOne Logistical Melbourne',
                    address1: '567 Collins Street',
                    address2: '',
                    city: 'Melborne',
                    state: 'VIC',
                    postCode: '3000',
                    country: {
                        code: 'AU',
                        name: 'Australia',
                    },
                    contactName: 'Raven Hudson',
                    phoneNumber: '+61 2 1234 5678',
                    mmobileNumber: '+61 4 1234 5678',
                    emailAddress: 'raven.hudson@gmail.com',
                },
                shipper: {
                    companyName: 'OneByOne Logistical Melbourne',
                    address1: '567 Collins Street',
                    address2: '',
                    city: 'Melborne',
                    state: 'VIC',
                    postCode: '3000',
                    country: {
                        code: 'AU',
                        name: 'Australia',
                    },
                    contactName: 'Raven Hudson',
                    phoneNumber: '+61 2 1234 5678',
                    mmobileNumber: '+61 4 1234 5678',
                    emailAddress: 'raven.hudson@gmail.com',
                },
                consigneeDeliveryAddress: {
                    companyName: 'OneByOne Logistical Bangkok',
                    address1: '123 Sukhumvit Road',
                    address2: '',
                    city: 'Bangkok',
                    state: 'Khlong Toei',
                    postCode: '10110',
                    country: {
                        code: 'TH',
                        name: 'Thailand',
                    },
                    contactName: 'Jason Lorde',
                    phoneNumber: '+66 2 123 4567',
                    mmobileNumber: '+66 8 1234 5678',
                    emailAddress: 'jason.lorde@gmail.com',
                },
                consignee: {
                    companyName: 'OneByOne Logistical Bangkok',
                    address1: '123 Sukhumvit Road',
                    address2: '',
                    city: 'Bangkok',
                    state: 'Khlong Toei',
                    postCode: '10110',
                    country: {
                        code: 'TH',
                        name: 'Thailand',
                    },
                    contactName: 'Jason Lorde',
                    phoneNumber: '+66 2 123 4567',
                    mmobileNumber: '+66 8 1234 5678',
                    emailAddress: 'jason.lorde@gmail.com',
                },
            },
            packLines: [
                {
                    pieces: '1',
                    packType: 'Box',
                    length: '10.00',
                    height: '10.00',
                    width: '22.00',
                    weightMeasure: {
                        unitLabel: 'KG',
                        unit: 'kg',
                        qty: '200.00',
                    },
                    volumeMeasure: {
                        unitLabel: 'M3',
                        unit: 'm3',
                        qty: '321',
                    },
                    description: 'Fresh Apples',
                    marksAndNumbers: 'ABC1234567',
                    linePrice: '$100.00',
                    tariffNumber: '0808.10',
                    hsCode: '841810',
                    id: 1,
                    unitDimension: 'm',
                    priceCurrency: '',
                },
                {
                    pieces: '1',
                    packType: 'Box',
                    length: '10.00 M',
                    width: '22.00 M',
                    weightMeasure: {
                        unitLabel: 'KG',
                        unit: 'kg',
                        qty: '200.00',
                    },
                    volumeMeasure: {
                        unitLabel: 'M3',
                        unit: 'm3',
                        qty: '321',
                    },
                    description: 'Fresh Apples',
                    marksAndNumbers: 'ABC1234567',
                    linePrice: '$100.00',
                    tariffNumber: '0808.10',
                    hsCode: '841810',
                    height: '10.00 M',
                    id: 2,
                    unitDimension: 'm',
                    priceCurrency: '',
                },
                {
                    pieces: '1',
                    packType: 'Box',
                    length: '10.00 M',
                    width: '22.00 M',
                    weightMeasure: {
                        unitLabel: 'KG',
                        unit: 'kg',
                        qty: '200.00',
                    },
                    volumeMeasure: {
                        unitLabel: 'M3',
                        unit: 'm3',
                        qty: '321',
                    },
                    description: 'Fresh Apples',
                    marksAndNumbers: 'ABC1234567',
                    linePrice: '$100.00',
                    tariffNumber: '0808.10',
                    hsCode: '841810',
                    height: '10.00 M',
                    id: 3,
                    unitDimension: 'm',
                    priceCurrency: '',
                },
                {
                    pieces: '1',
                    packType: 'Box',
                    length: '10.00 M',
                    width: '22.00 M',
                    weightMeasure: {
                        unitLabel: 'KG',
                        unit: 'kg',
                        qty: '200.00',
                    },
                    volumeMeasure: {
                        unitLabel: 'M3',
                        unit: 'm3',
                        qty: '321',
                    },
                    description: 'Fresh Apples',
                    marksAndNumbers: 'ABC1234567',
                    linePrice: '$100.00',
                    tariffNumber: '0808.10',
                    hsCode: '841810',
                    height: '10.00 M',
                    id: 4,
                    unitDimension: 'm',
                    priceCurrency: '',
                },
                {
                    pieces: '1',
                    packType: 'Box',
                    length: '10.00 M',
                    width: '22.00 M',
                    weightMeasure: {
                        unitLabel: 'KG',
                        unit: 'kg',
                        qty: '200.00',
                    },
                    volumeMeasure: {
                        unitLabel: 'M3',
                        unit: 'm3',
                        qty: '321',
                    },
                    description: 'Fresh Apples',
                    marksAndNumbers: 'ABC1234567',
                    linePrice: '$100.00',
                    tariffNumber: '0808.10',
                    hsCode: '841810',
                    height: '10.00 M',
                    id: 5,
                    unitDimension: 'm',
                    priceCurrency: 'AUD',
                },
            ],
            containers: [
                {
                    containerNo: 'ABCD12345678',
                    type: '40 HC',
                    count: '1',
                    sealNo: 'SL2658981',
                    id: 1,
                },
                {
                    containerNo: 'ABCD12345678',
                    type: '40 HC',
                    count: '1',
                    sealNo: 'SL2658981',
                    id: 2,
                },
                {
                    containerNo: 'ABCD12345678',
                    type: '40 HC',
                    count: '1',
                    sealNo: 'SL2658981',
                    id: 3,
                },
                {
                    containerNo: 'ABCD12345678',
                    type: '40 HC',
                    count: '1',
                    sealNo: 'SL2658981',
                    id: 4,
                },
            ],
        },
    ]

// segment details
export const relatedPartiesDetailsDummy = {
    shipperPickupAddress: {
        company: 'OneByOne Logistical Melbourne',
        address1: '567 Collins Street',
        address2: '',
        city: 'Melborne',
        state: 'VIC',
        postCode: '3000',
        country: 'AU, Australia',
        contactName: 'Raven Hudson',
        contactPhoneNumber: '+61 2 1234 5678',
        contactMobileNumber: '+61 4 1234 5678',
        contactEmailAddress: 'raven.hudson@gmail.com',
    },
    shipper: {
        company: 'OneByOne Logistical Melbourne',
        address1: '567 Collins Street',
        address2: '',
        city: 'Melborne',
        state: 'VIC',
        postCode: '3000',
        country: 'AU, Australia',
        contactName: 'Raven Hudson',
        contactPhoneNumber: '+61 2 1234 5678',
        contactMobileNumber: '+61 4 1234 5678',
        contactEmailAddress: 'raven.hudson@gmail.com',
    },
    consigneePickupAddress: {
        company: 'OneByOne Logistical Bangkok',
        address1: '123 Sukhumvit Road',
        address2: '',
        city: 'Bangkok',
        state: 'Khlong Toei',
        postCode: '10110',
        country: 'TH, Thailand',
        contactName: 'Jason Lorde',
        contactPhoneNumber: '+66 2 123 4567',
        contactMobileNumber: '+66 2 123 4567',
        contactEmailAddress: 'jason.lorde@gmail.com',
    },
    consignee: {
        company: 'OneByOne Logistical Bangkok',
        address1: '123 Sukhumvit Road',
        address2: '',
        city: 'Bangkok',
        state: 'Khlong Toei',
        postCode: '10110',
        country: 'TH, Thailand',
        contactName: 'Jason Lorde',
        contactPhoneNumber: '+66 2 123 4567',
        contactMobileNumber: '+66 2 123 4567',
        contactEmailAddress: 'jason.lorde@gmail.com',
    },
} as const

export const packLinesDetailsDummy: IShippingInstructionPackLines[] = [
    {
        pieces: '1',
        packType: 'Box',
        length: '10.00 M',
        height: '10.00 M',
        width: '22.00 M',
        totalWeight: '12,548.00 KG',
        totalVolume: '1,448.40 M3',
        description: 'Fresh Apples',
        marksAndNumbers: 'ABC1234567',
        linePrice: '$100.00',
        tariffNumber: '0808.10',
        hSCode: '841810',
    },
    {
        pieces: '1',
        packType: 'Box',
        length: '10.00 M',
        width: '22.00 M',
        totalWeight: '12,548.00 KG',
        totalVolume: '1,448.40 M3',
        description: 'Fresh Apples',
        marksAndNumbers: 'ABC1234567',
        linePrice: '$100.00',
        tariffNumber: '0808.10',
        hSCode: '841810',
        height: '10.00 M',
    },
    {
        pieces: '1',
        packType: 'Box',
        length: '10.00 M',
        width: '22.00 M',
        totalWeight: '12,548.00 KG',
        totalVolume: '1,448.40 M3',
        description: 'Fresh Apples',
        marksAndNumbers: 'ABC1234567',
        linePrice: '$100.00',
        tariffNumber: '0808.10',
        hSCode: '841810',
        height: '10.00 M',
    },
    {
        pieces: '1',
        packType: 'Box',
        length: '10.00 M',
        width: '22.00 M',
        totalWeight: '12,548.00 KG',
        totalVolume: '1,448.40 M3',
        description: 'Fresh Apples',
        marksAndNumbers: 'ABC1234567',
        linePrice: '$100.00',
        tariffNumber: '0808.10',
        hSCode: '841810',
        height: '10.00 M',
    },
    {
        pieces: '1',
        packType: 'Box',
        length: '10.00 M',
        width: '22.00 M',
        totalWeight: '12,548.00 KG',
        totalVolume: '1,448.40 M3',
        description: 'Fresh Apples',
        marksAndNumbers: 'ABC1234567',
        linePrice: '$100.00',
        tariffNumber: '0808.10',
        hSCode: '841810',
        height: '10.00 M',
    },
]

export const containerDetail: IShippingInstructionContainer[] = [
    {
        containerNo: 'ABCD12345678',
        type: '40 HC',
        count: '1',
        sealNo: 'SL2658981',
    },
    {
        containerNo: 'ABCD12345678',
        type: '40 HC',
        count: '1',
        sealNo: 'SL2658981',
    },
    {
        containerNo: 'ABCD12345678',
        type: '40 HC',
        count: '1',
        sealNo: 'SL2658981',
    },
    {
        containerNo: 'ABCD12345678',
        type: '40 HC',
        count: '1',
        sealNo: 'SL2658981',
    },
]

// segment new
export const relatedPartiesAddressShipperDummy: IRelatedPartiesFormDummy[] = [
    {
        companyName: 'OneByOne Logistical Melbourne A',
        address1: '123 Main Street',
        address2: 'Suite 100',
        city: 'Melborne A',
        state: 'VIC A',
        postCode: '10001',
        country: {
            code: 'au',
            name: 'Australia',
        },
        contacts: [
            {
                contactName: 'John Doe',
                phoneNumber: '+1 212-555-1234',
                mobileNumber: '+1 917-555-5678',
                emailAddress: 'johndoe@example.com',
            },
            {
                contactName: 'Raven Hudson',
                phoneNumber: '+1 212-555-1234',
                mobileNumber: '+1 917-555-5678',
                emailAddress: 'raven.hudson@gmail.com',
            },
        ],
    },
    {
        companyName: 'OneByOne Logistical Melbourne B',
        address1: '456 Elm Street',
        address2: 'Apt 2B',
        city: 'Melborne B',
        state: 'CA',
        postCode: '90001',
        country: {
            code: 'au',
            name: 'Australia',
        },
        contacts: [
            {
                contactName: 'Jane Smith',
                phoneNumber: '+1 310-555-7890',
                mobileNumber: '+1 323-555-4567',
                emailAddress: 'janesmith@example.com',
            },
            {
                contactName: 'Jane Smith 2',
                phoneNumber: '+1 310-555-7890',
                mobileNumber: '+1 323-555-4567',
                emailAddress: 'janesmith2@example.com',
            },
        ],
    },
    {
        companyName: 'OneByOne Logistical Melbourne',
        address1: '789 Oak Street',
        address2: '',
        city: 'Melborne',
        state: 'IL',
        postCode: '60601',
        country: {
            code: 'au',
            name: 'Australia',
        },
        contacts: [
            {
                contactName: 'Michael Johnson',
                phoneNumber: '+1 312-555-2345',
                mobileNumber: '+1 773-555-6789',
                emailAddress: 'michaelj@example.com',
            },
            {
                contactName: 'Michael Johnson 2',
                phoneNumber: '+1 312-555-2345',
                mobileNumber: '+1 773-555-6789',
                emailAddress: 'michaelj2@example.com',
            },
        ],
    },
]

export const relatedPartiesAddressConsigneeDummy: IRelatedPartiesFormDummy[] = [
    {
        companyName: 'OneByOne Logistical Bangkok',
        address1: '789 Oak Street',
        address2: '',
        city: 'Chicago',
        state: 'IL',
        postCode: '60601',
        country: {
            code: 'th',
            name: 'Thailand',
        },
        contacts: [
            {
                contactName: 'Michael Johnson',
                phoneNumber: '+1 312-555-2345',
                mobileNumber: '+1 773-555-6789',
                emailAddress: 'michaelj@example.com',
            },
            {
                contactName: 'Jason Lorde',
                phoneNumber: '+66 2 123 4567',
                mobileNumber: '+1 773-555-6789',
                emailAddress: 'jason.lorde@gmail.com',
            },
        ],
    },
    {
        companyName: 'OneByOne Logistical Bangkok A',
        address1: '101 Pine Street',
        address2: 'Floor 3',
        city: 'San Francisco',
        state: 'CA',
        postCode: '94101',
        country: {
            code: 'th',
            name: 'Thailand',
        },
        contacts: [
            {
                contactName: 'Emily Davis',
                phoneNumber: '+1 415-555-3456',
                mobileNumber: '+1 415-555-6789',
                emailAddress: 'emilyd@example.com',
            },
            {
                contactName: 'Emily Davis 2',
                phoneNumber: '+1 415-555-3456',
                mobileNumber: '+1 415-555-6789',
                emailAddress: 'emilyd2@example.com',
            },
        ],
    },
    {
        companyName: 'OneByOne Logistical Bangkok B',
        address1: '222 Maple Avenue',
        address2: 'Suite 200',
        city: 'Seattle',
        state: 'WA',
        postCode: '98101',
        country: {
            code: 'th',
            name: 'Thailand',
        },
        contacts: [
            {
                contactName: 'Chris Wilson',
                phoneNumber: '+1 206-555-1234',
                mobileNumber: '+1 206-555-5678',
                emailAddress: 'chrisw@example.com',
            },
            {
                contactName: 'Chris Wilson 2',
                phoneNumber: '+1 206-555-1234',
                mobileNumber: '+1 206-555-5678',
                emailAddress: 'chrisw2@example.com',
            },
        ],
    },
]

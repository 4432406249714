import Button from '@components/button/button.component'
import { useTranslation } from 'react-i18next'
import useNewVendorBooking from './new-vendor-booking.service'
import Tab from '@components/tab/tab.component'
import { FormikProvider } from 'formik'
import Spinner from '@components/spinner/spinner.component'
import Details from './contents/details.component'
import RelatedParties from './contents/related-parties.component'
import PackLines from './contents/pack-lines.component'
import ConfirmationCreateBookingModal from './components/confirmation-create-booking-modal.component'
import FormPackLineModal from './components/form-pack-line-modal.component'
import Container from './contents/container.component'
import FormContainerModal from './components/form-container-modal.component'

const NewVendorBooking = () => {
    const { t } = useTranslation()

    const service = useNewVendorBooking()

    const loadContentForm = () => {
        const contents: Record<string, JSX.Element> = {
            details: <Details service={service} />,
            relatedParties: <RelatedParties service={service} />,
            packLines: <PackLines service={service} />,
            container: <Container service={service} />,
        }
        return contents[service.tabFormBooking.value]
    }

    const selectFormNewVendorBookingComponent = (
        <div className="p-3 h-[calc(100vh-10.7rem)] overflow-auto flex flex-col gap-3 justify-center">
            <div className="font-bold text-size-L mt-80 sm:mt-24 md:mt-0">
                Book by Container Mode
            </div>
            <span className="-mt-5">
                Select one of the container mode to get started
            </span>
            <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-3">
                <div
                    className="relative cursor-pointer border rounded leading-tight themes-border-brand-v4 flex flex-col justify-between items-start p-4 themes-text-brand-v4 themes-bg-brand-v1 min-h-[100px] md:min-h-[200px]"
                    onClick={() => {
                        service.goToFormCreate('fcl')
                    }}
                >
                    <div className="text-size-L font-bold">
                        {t('bookings.full_container_load')}
                        <p className="text-size-S font-normal">
                            SEA / ROAD / RAIL
                        </p>
                    </div>
                    <i className="ri-archive-2-line text-[51px] themes-text-brand-v3 absolute bottom-4 right-4"></i>
                </div>
                <div
                    className="relative cursor-pointer border rounded leading-tight themes-border-brand-v4 flex flex-col justify-between items-start p-4 themes-text-brand-v4 themes-bg-brand-v1 min-h-[100px] md:min-h-[200px]"
                    onClick={() => service.goToFormCreate('lcl')}
                >
                    <div className="text-size-L font-bold">
                        Less Container Load
                        <p className="text-size-S font-normal">
                            SEA / ROAD / RAIL
                        </p>
                    </div>
                    <i className="ri-archive-drawer-line text-[51px] themes-text-brand-v3 absolute bottom-4 right-4"></i>
                </div>
                <div
                    className="relative cursor-pointer border rounded leading-tight themes-border-brand-v4 flex flex-col justify-between items-start p-4 themes-text-brand-v4 themes-bg-brand-v1 min-h-[100px] md:min-h-[200px]"
                    onClick={() => service.goToFormCreate('lse')}
                >
                    <div className="text-size-L font-bold">
                        {t('bookings.loose')}
                        <p className="text-size-S font-normal">AIR Only</p>
                    </div>
                    <i className="ri-stack-overflow-line text-[51px] themes-text-brand-v3 absolute bottom-4 right-4"></i>
                </div>
                <div
                    className="relative cursor-pointer border rounded leading-tight themes-border-brand-v4 flex flex-col justify-between items-start p-4 themes-text-brand-v4 themes-bg-brand-v1 min-h-[100px] md:min-h-[200px]"
                    onClick={() => service.goToFormCreate('ftl')}
                >
                    <div className="text-size-L font-bold">
                        {t('bookings.full_truck_load')}
                        <p className="text-size-S font-normal">Road Only</p>
                    </div>
                    <i className="ri-truck-line text-[51px] themes-text-brand-v3 absolute bottom-4 right-4"></i>
                </div>
                <div
                    className="relative cursor-pointer border rounded leading-tight themes-border-brand-v4 flex flex-col justify-between items-start p-4 themes-text-brand-v4 themes-bg-brand-v1 min-h-[100px] md:min-h-[200px]"
                    onClick={() => service.goToFormCreate('cou')}
                >
                    <div className="text-size-L font-bold">
                        No Container
                        <p className="text-size-S font-normal">Courier Only</p>
                    </div>
                    <i className="ri-e-bike-2-line text-[51px] themes-text-brand-v3 absolute bottom-4 right-4"></i>
                </div>
            </div>
        </div>
    )

    const formNewVendorBookingComponent = (
        <>
            <Tab
                containerClassName="px-3"
                items={service.tabFormBookingItems.filter(
                    (item) =>
                        service.formType === 'fcl' ||
                        item.value !== 'container',
                )}
                tabFilter={service.tabFormBooking}
                onChange={(item) => {
                    service.setTabFormBooking(item)
                }}
            />
            <div className="border-b"></div>
            <FormikProvider value={service.newVendorBookingFormik}>
                <div className="h-[calc(100vh-13.8rem)]">
                    {service.loading ? (
                        <div
                            className={`h-full flex justify-center items-center`}
                        >
                            <Spinner label="Please Wait..." />
                        </div>
                    ) : (
                        loadContentForm()
                    )}
                </div>
            </FormikProvider>
        </>
    )

    return (
        <div className="container-global content-full-height flex">
            <div className="flex flex-grow flex-col w-[calc(100vw-20rem)] sm:w-[calc(100vw-4rem)]">
                {!service.isFormPage
                    ? selectFormNewVendorBookingComponent
                    : formNewVendorBookingComponent}
                <div className="border-b"></div>
                <div className="p-3 flex items center justify-between">
                    {!service.isFormPage ? (
                        <Button
                            onClick={() => {
                                service.navigate(-1)
                            }}
                            label={'BACK'}
                            useUpperCase
                            className="w-btnRegularWidth"
                            variant="brand-v2-inverse"
                        />
                    ) : (
                        <>
                            <Button
                                onClick={() => {
                                    service.setConfirmationModalProperty({
                                        title: 'Discard Changes',
                                        message:
                                            'Everything that you’ve added in the page will not be recorded',
                                        btnProceed: {
                                            onClick: () =>
                                                service.navigate(
                                                    '/vendor-bookings',
                                                ),
                                            variant: 'brand-v2-inverse',
                                        },
                                    })
                                    service.confirmationModal.openModalHandling()
                                }}
                                label="discard changes"
                                useUpperCase
                                variant="brand-v2-inverse"
                                className="w-btnRegularWidth"
                            />
                            <Button
                                onClick={() => {
                                    service.saveBooking()
                                }}
                                label="PLACE BOOKING"
                                className="w-btnRegularWidth"
                                variant="brand-v2-inverse"
                                isDisabled={service.loading}
                            />
                        </>
                    )}
                </div>
            </div>

            {/* modals */}
            <>
                <ConfirmationCreateBookingModal service={service} />
                <FormPackLineModal service={service} />
                <FormContainerModal service={service} />
            </>
        </div>
    )
}

export default NewVendorBooking

import CardsDashboard from '@components/card-dashboard/card-dashboard.component'
import InfoCardGroup from '@components/InfoCardGroup/Info-card-group.component'
import { BarChartStacked, DoughnutChart } from '@components/rechart-component'
import ItemListInfo from '@components/item-list-info/item-list-info.component'
import {
    itemListMonthlyCost,
    itemListShipmentsCosts,
} from '../performances.static'
import {
    IAdditionalValuePerfomance,
    IUsePerformances,
} from '../performances.interface'
import Pagination from '@components/pagination/pagination.component'
import { numberToCurrency } from 'common/common.service'
import EmpityResultIfb from 'common/components/empityResult/empityResultIfb.component'

function Costs({
    performancesService,
}: {
    performancesService: IUsePerformances
}) {
    return (
        <>
            <CardsDashboard className="!p-0 flex flex-col row-span-2 col-span-12 xl:col-span-4 h-[550px] xl:h-full">
                <div className="border-b p-2 h-1/2 flex flex-col">
                    <div className="font-bold text-size-S 2xl:text-size-M">
                        Total Cost by Modes
                    </div>
                    <InfoCardGroup
                        items={
                            performancesService.totalCostByModes
                                .infoCardGroupItems
                        }
                        titleClass="!text-size-S"
                        descClass="!text-size-XS"
                    />
                    <DoughnutChart<IAdditionalValuePerfomance>
                        isLoading={
                            performancesService.loadingCost.totalCostByModes
                        }
                        data={performancesService.totalCostByModes.chartData}
                        renderTextLegendAndTooltip={({ additionalValue }) =>
                            `${additionalValue?.currency} ${additionalValue?.value}`
                        }
                        customEmpityResultComponent={<EmpityResultIfb />}
                    />
                </div>
                <div className="p-2 h-1/2">
                    <div className="font-bold flex justify-between items-center text-size-S 2xl:text-size-M">
                        Port Charges
                    </div>
                    <InfoCardGroup
                        items={
                            performancesService.portCharges.infoCardGroupItems
                        }
                        titleClass="!text-size-S"
                        descClass="!text-size-XS"
                    />
                    <DoughnutChart<IAdditionalValuePerfomance>
                        data={performancesService.portCharges.chartData}
                        renderTextLegendAndTooltip={({ additionalValue }) =>
                            `${additionalValue?.currency} ${additionalValue?.value}`
                        }
                        isLoading={performancesService.loadingCost.portCharges}
                        customEmpityResultComponent={<EmpityResultIfb />}
                    />
                </div>
            </CardsDashboard>
            <CardsDashboard className="!p-0 flex gap-3  w-full col-span-12 row-span-2 xl:col-span-8 xl:row-span-1 xl:h-full  lg:flex-row lg:h-[492px] md:flex-col md:h-[500px] sm:flex-col sm:h-[500px]">
                <div className="flex w-full flex-col h-full">
                    <div className="flex justify-between items-center border-b flex-wrap p-2">
                        <div className="font-bold flex-grow text-size-S 2xl:text-size-M">
                            Monthly Total Cost
                        </div>

                        <div
                            className="flex justify-end gap-1 flex-grow max-w-[764px]
                            flex-wrap 
                        "
                        >
                            <ItemListInfo
                                itemListInfo={itemListMonthlyCost}
                                containerClassNames="themes-bg-white
                                min-w-[100px]
                                mobile:flex-wrap
                                "
                                itemClassNames="flex-grow mobile:!w-[182px]"
                            />
                        </div>
                    </div>
                    <div className="flex-grow p-2">
                        <BarChartStacked
                            data={performancesService.monthlyTotalCost}
                            isLoading={
                                performancesService.loadingCost.monthlyTotalCost
                            }
                            renderTooltipValue={(data) => {
                                let loadContent = ''
                                const dataValue = data?.value
                                    ? `PLN ${numberToCurrency(data.value)}`
                                    : 'PLN 0.00'
                                switch (data?.name) {
                                    case 'f1':
                                        loadContent = `Sea Freight: ${dataValue}`
                                        break
                                    case 'f2':
                                        loadContent = `Air Freight: ${dataValue}`
                                        break
                                    case 'f3':
                                        loadContent = `Road Freight: ${dataValue}`
                                        break
                                    case 'f4':
                                        loadContent = `Rail Freight: ${dataValue}`
                                        break
                                    case 'f5':
                                        loadContent = `Courier Freight: ${dataValue}`
                                        break
                                }
                                return loadContent
                            }}
                            disableErrorIllustration
                            customEmpityResultComponent={<EmpityResultIfb />}
                        />
                    </div>
                </div>
            </CardsDashboard>
            <CardsDashboard className="!p-0 flex w-full col-span-12 row-span-2 lg:h-[492px] xl:col-span-8 xl:row-span-1 xl:h-full lg:flex-row md:flex-col md:h-[500px] sm:flex-col sm:h-[722px]">
                <div className="flex w-full flex-col h-full">
                    <div className="flex justify-between items-center border-b flex-wrap p-2">
                        <div className="font-bold flex-grow text-size-S 2xl:text-size-M">
                            Total Cost by Ports
                        </div>

                        <div
                            className="flex justify-end gap-1 flex-grow max-w-[645px]
                            mobile:flex-wrap 
                        "
                        >
                            <ItemListInfo
                                itemListInfo={itemListShipmentsCosts}
                                containerClassNames="themes-bg-white
                                min-w-[100px]
                                mobile:flex-grow mobile:flex-wrap
                                "
                            />
                            <Pagination
                                containerClassName="!p-0 flex"
                                valueClass={{
                                    valueNumber: '!text-size-XS',
                                    valueString: '!text-size-XS',
                                }}
                                className="!h-[27px]"
                                btnClassName="!h-[27px] flex items-center justify-center cursor-pointer"
                                meta={performancesService.totalCostByPorts.meta}
                                previousHandling={(page) =>
                                    performancesService.setPageNumberTotalCostByPorts(
                                        page,
                                    )
                                }
                                nextHandling={(page) =>
                                    performancesService.setPageNumberTotalCostByPorts(
                                        page,
                                    )
                                }
                            />
                        </div>
                    </div>
                    <div className="flex-grow p-2">
                        <BarChartStacked
                            data={
                                performancesService.totalCostByPorts.chartData
                            }
                            isLoading={
                                performancesService.loadingCost.totalCostByPorts
                            }
                            renderTooltipValue={(data) => {
                                let loadContent = ''
                                const dataValue = data?.value
                                    ? `PLN ${numberToCurrency(data.value)}`
                                    : 'PLN 0.00'
                                switch (data?.name) {
                                    case 'f1':
                                        loadContent = `Loading Costs:  ${dataValue}`
                                        break
                                    case 'f2':
                                        loadContent = `Main Freight Costs:  ${dataValue}`
                                        break
                                    case 'f3':
                                        loadContent = `Discharge Costs:  ${dataValue}`
                                        break
                                }
                                return loadContent
                            }}
                            customEmpityResultComponent={<EmpityResultIfb />}
                            disableErrorIllustration
                        />
                    </div>
                </div>
            </CardsDashboard>
        </>
    )
}

export default Costs

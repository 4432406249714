import Spinner from '@components/spinner/spinner.component'
import TabMaster from '@components/tab-master/tab-master.component'
import Table from '@components/table/table.component'
import GenerateReportModal from 'common/components/generate-report-modal.component'
import { IFormikBookingFilter } from 'form-validation/booking.validation'
import { IBookings } from 'pages/bookings/bookings.interface'
import BookingFilter from 'pages/bookings/components/booking-filter.component'
import { useTranslation } from 'react-i18next'
import useShippingInstructions from './shipping-instructions.service'
import { generateReportHeaders } from 'pages/bookings/bookings.static'
import ConfigureColumnsModal from '@components/configure-columns-modal/configure-columns-modal.component'
import { IShippingInstructionsSummary } from './shipping-instructions.interface'
import { shippingInstructionsConfigureColumns } from './shipping-instructions.static'

const ShippingInstructions = () => {
    const { t } = useTranslation()
    const {
        generateReportModalService,
        loading,
        headers,
        navigate,
        tabItems,
        setTabFilter,
        handleSearch,
        shippingInstructionsSummary: vendorBookingSummary,
        setPageData,
        formikFilter,
        updateFormFilter,
        loadGenerateReportData,
        loadingGenerateReport,
        env,
        companyData,
        getCompanyOptions,
        ConfigureColumnsModalService,
        bookingConfigureColumns,
        setHeaders,
    } = useShippingInstructions()

    return (
        <div className="container-global content-full-height flex">
            <div className="flex flex-grow flex-col w-[calc(100vw-20rem)] sm:w-[calc(100vw-4rem)] pt-0">
                {loadingGenerateReport ? (
                    <div
                        className={`flex items-center justify-center h-full text-size-XS-testing`}
                    >
                        <Spinner label="Please Wait..." />
                    </div>
                ) : (
                    <>
                        <TabMaster
                            items={tabItems || []}
                            tabFilter={vendorBookingSummary.statusSelected}
                            onChange={(item) => {
                                setTabFilter(item)
                            }}
                            //  search ----------------------------------------------------------------
                            useSearch={true}
                            placeHolderSearch={'Search Booking Number'}
                            onSearchSubmit={handleSearch}
                            containerSearchClassName={'flex-1'}
                            //  button for overlay or something ----------------------------------------
                            // hide filter for new feature
                            // iconButton1={{
                            //     onClick: () =>
                            //         filterOverlayService.toggleOverlayHandling(),
                            //     icon: 'ri-filter-2-line',
                            //     filterOverlayComponent: (
                            //         <BookingFilterOverlay
                            //             filterOverlayService={
                            //                 filterOverlayService
                            //             }
                            //             formikFilter={formikFilter}
                            //             updateFormFilter={updateFormFilter}
                            //         />
                            //     ),
                            // }}
                            // iconButton2={{
                            //     onClick: () =>
                            //         ConfigureColumnsModalService.openModalHandling(),
                            //     icon: 'ri-settings-2-line',
                            // }}
                        />
                        <Table<IShippingInstructionsSummary>
                            headers={headers}
                            data={vendorBookingSummary.data}
                            loading={loading}
                            disableErrorIllustration
                            nextHandling={(page) => {
                                setPageData(page)
                            }}
                            previousHandling={(page) => {
                                setPageData(page)
                            }}
                            meta={vendorBookingSummary.meta}
                            moduleTitle={'Shipping Instructions'}
                            onRowClick={(data) => {
                                const id = data.bookingNo
                                navigate('/shipping-instruction-details/' + id)
                            }}
                            enableExport={env !== 'production' ? true : false}
                            exportModalService={generateReportModalService}
                            variantButtonExport="brand-v2-inverse"
                            dataTestId="SHIPPING_INSTRUCTIONS_TABLE"
                        />
                    </>
                )}
            </div>
            <>
                <ConfigureColumnsModal<IShippingInstructionsSummary>
                    modalService={ConfigureColumnsModalService}
                    columns={shippingInstructionsConfigureColumns}
                    setHeaders={setHeaders}
                />
                <GenerateReportModal<IBookings, IFormikBookingFilter>
                    modalService={generateReportModalService}
                    formik={formikFilter}
                    filterFormComponent={
                        <BookingFilter
                            companyData={companyData}
                            getCompanyOptions={getCompanyOptions}
                        />
                    }
                    module={'Shipping Instructions'}
                    loadGenerateReportData={loadGenerateReportData}
                    headers={generateReportHeaders}
                    updateFormFilter={updateFormFilter}
                />
            </>
        </div>
    )
}

export default ShippingInstructions

import SimpleTable from '@components/simple-table/simple-table.component'
import { metaDummy2 } from 'common/common.static'
import { IVendorBookingPackLines } from '../vendor-booking-details.interface'
import { packLineHeaders } from '../vendor-booking-details.static'
import { packLinesDetailsDummy } from 'pages/vendor-bookings/vendor-bookings.dummy'

const PackLines = () => {
    return (
        <div className="h-full max-h-[calc(100vh-283px)]">
            <SimpleTable<IVendorBookingPackLines>
                headers={packLineHeaders}
                data={packLinesDetailsDummy}
                usePagination
                nextHandling={(page) => {
                    console.log('hit Next API -> ' + page)
                }}
                previousHandling={(page) => {
                    console.log('hit Prev API -> ' + page)
                }}
                meta={metaDummy2(packLinesDetailsDummy.length)}
                isBorder={false}
                disableErrorIllustration
            />
        </div>
    )
}

export default PackLines

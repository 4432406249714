import { ISTColumn } from '@components/simple-table/simple-table.interface'
import { customRounding } from 'common/common.service'
import {
    INewVendorBookingContainerValidation,
    INewVendorBookingPackLineValidation,
} from 'form-validation/new-vendor-booking'

// pack lines
export const packLineHeadersForm: ISTColumn<INewVendorBookingPackLineValidation>[] =
    [
        {
            label: 'Pieces',
            accessor: 'pieces',
        },
        {
            label: 'Packs Type',
            accessor: 'packType',
        },
        {
            label: 'Length',
            accessor: 'length',
            customBuild: (data, rowData) => {
                return (
                    <>
                        {customRounding({
                            value: rowData.length,
                            useDecimalPoint: true,
                        })}{' '}
                        {rowData?.unitDimension}
                    </>
                )
            },
        },
        {
            label: 'Height',
            accessor: 'height',
            customBuild: (data, rowData) => {
                return (
                    <>
                        {customRounding({
                            value: rowData.height,
                            useDecimalPoint: true,
                        })}{' '}
                        {rowData?.unitDimension}
                    </>
                )
            },
        },
        {
            label: 'Width',
            accessor: 'width',
            customBuild: (data, rowData) => {
                return (
                    <>
                        {customRounding({
                            value: rowData.width,
                            useDecimalPoint: true,
                        })}{' '}
                        {rowData?.unitDimension}
                    </>
                )
            },
        },
        {
            label: 'Total Weight',
            accessor: 'weightMeasure',
            customBuild: (data, rowData) => {
                if (rowData?.specialField) {
                    const { qty, unit } = rowData.weightMeasure
                    return (
                        <>
                            {customRounding({
                                value: qty,
                                useDecimalPoint: true,
                            })}{' '}
                            {unit}
                        </>
                    )
                } else {
                    return <></>
                }
            },
        },

        {
            label: 'Total Volume',
            accessor: 'volumeMeasure',
            customBuild: (data, rowData) => {
                if (rowData?.specialField) {
                    const { qty, unit } = rowData.volumeMeasure
                    return (
                        <>
                            {customRounding({
                                value: qty,
                                useDecimalPoint: true,
                            })}{' '}
                            {unit}
                        </>
                    )
                } else {
                    return <></>
                }
            },
        },
        {
            label: 'Description',
            accessor: 'description',
        },
        {
            label: 'Marks and Numbers',
            accessor: 'marksAndNumbers',
        },
        {
            label: 'Line Price',
            accessor: 'linePrice',
            customBuild: (data) => {
                return (
                    <>
                        {customRounding({
                            value: data as string,
                            useDecimalPoint: true,
                        })}
                    </>
                )
            },
        },
        {
            label: 'Tariff Number',
            accessor: 'tariffNumber',
            customBuild: (data) => {
                return (
                    <>
                        {customRounding({
                            value: data as string,
                            useDecimalPoint: true,
                        })}
                    </>
                )
            },
        },
        {
            label: 'HS CODE',
            accessor: 'hsCode',
        },
    ]

// containers
export const containersHeaders: ISTColumn<INewVendorBookingContainerValidation>[] =
    [
        {
            label: 'Container No.',
            accessor: 'containerNo',
        },
        {
            label: 'Type',
            accessor: 'containerType',
        },
        {
            label: 'Count',
            accessor: 'count',
        },
        {
            label: 'Seal Number',
            accessor: 'SealNumber',
        },
    ]

import { ISTColumn } from '@components/simple-table/simple-table.interface'
import { ITabItem } from '@components/tab/tab.interface'
import {
    IVendorBookingContainer,
    IVendorBookingPackLines,
} from './vendor-booking-details.interface'

export const tabItemsVendorBookingDetails: ITabItem[] = [
    {
        label: 'Details',
        labelTranslationCode: 'tab_details.details',
        key: 'status',
        value: 'details',
        className: 'brand',
    },
    {
        label: 'Related Parties',
        key: 'status',
        value: 'relatedParties',
        className: 'brand',
    },
    {
        label: 'Pack Lines',
        labelTranslationCode: 'tab_details.pack_lines',
        key: 'status',
        value: 'packLines',
        className: 'brand',
    },
    {
        label: 'Container',
        key: 'status',
        value: 'container',
        className: 'brand',
    },
]

export const propertyRelatedPartiesComponent = [
    {
        title: 'Shipper Pickup Address',
        entity: 'shipperPickupAddress',
    },
    {
        title: 'Shipper',
        entity: 'shipper',
    },
    {
        title: 'Consignee Delivery Address',
        entity: 'consigneePickupAddress',
    },
    {
        title: 'Consignee',
        entity: 'consignee',
    },
] as const

export const relatedPartiesComponentHeaders = [
    {
        label: 'Company Name',
        accessor: 'company',
    },
    {
        label: 'Address 1',
        accessor: 'address1',
    },
    {
        label: 'Address 2',
        accessor: 'address2',
    },
    {
        label: 'City',
        accessor: 'city',
    },
    {
        label: 'State',
        accessor: 'state',
    },
    {
        label: 'Postcode',
        accessor: 'postCode',
    },
    {
        label: 'Country',
        accessor: 'country',
    },
    {
        label: 'Contact Name',
        accessor: 'contactName',
    },
    {
        label: 'Phone Number',
        accessor: 'contactPhoneNumber',
    },
    {
        label: 'Mobile Number',
        accessor: 'contactMobileNumber',
    },
    {
        label: 'Email Address',
        accessor: 'contactEmailAddress',
    },
] as const

export const packLineHeaders: ISTColumn<IVendorBookingPackLines>[] = [
    {
        label: 'Pieces',
        accessor: 'pieces',
    },
    {
        label: 'Pack Type',
        accessor: 'packType',
    },
    {
        label: 'Length',
        accessor: 'length',
    },
    {
        label: 'Height',
        accessor: 'height',
    },
    {
        label: 'Width',
        accessor: 'width',
    },
    {
        label: 'Total Weight',
        accessor: 'totalWeight',
    },
    {
        label: 'Total Volume',
        accessor: 'totalVolume',
    },
    {
        label: 'Description',
        accessor: 'description',
    },
    {
        label: 'Marks and Numbers',
        accessor: 'marksAndNumbers',
    },
    {
        label: 'Line Price',
        accessor: 'linePrice',
    },
    {
        label: 'Tariff Number',
        accessor: 'tariffNumber',
    },
    {
        label: 'HS Code',
        accessor: 'hSCode',
    },
]

export const containerHeaders: ISTColumn<IVendorBookingContainer>[] = [
    {
        accessor: 'containerNo',
        label: 'Container No.',
    },
    {
        accessor: 'type',
        label: 'Type',
    },
    {
        accessor: 'count',
        label: 'Count',
    },
    {
        accessor: 'sealNo',
        label: 'Seal No.',
    },
]

import { IUseShippingInstructionDetails } from '../shipping-instruction-details.interface'

const Details = ({ service }: { service: IUseShippingInstructionDetails }) => {
    const loadDataComponent = ({
        label,
        value,
    }: {
        label: string
        value?: string | number
    }) => (
        <div className="leading-none">
            <div className="themes-text-gray-v5">{label}</div>
            <div className="themes-text-gray-v9">{value || '-'}</div>
        </div>
    )
    const detailsData = service.shippingInstructionDetails.detailsData

    return (
        <div className="p-3 h-full">
            <div className="grid grid-cols-4 sm:grid-cols-2 mobile:!grid-cols-1 gap-3 h-full">
                {/* shipments details */}
                <div className="flex flex-col gap-1">
                    <div className="text-size-L themes-text-gray-v9 mb-1">
                        Shipment Details
                    </div>
                    {loadDataComponent({
                        label: 'Booking No',
                        value: service.shippingInstructionDetails.bookingNo,
                    })}
                    {detailsData.containerMode !== 'courier' &&
                        loadDataComponent({
                            label: 'Container Mode',
                            value: detailsData.containerMode.toUpperCase(),
                        })}
                    {loadDataComponent({
                        label: 'Transport Mode',
                        value: detailsData.transportMode,
                    })}
                    {loadDataComponent({
                        label: 'Service Level',
                        value: `${detailsData.serviceLevel.code}, ${detailsData.serviceLevel.description}`,
                    })}
                    {loadDataComponent({
                        label: 'Outer Packs',
                        value: `${detailsData.outerPacks} ${detailsData.outerPacksUnit}`,
                    })}
                    {loadDataComponent({
                        label: 'Weight Measure',
                        value: `${detailsData.weightMeasure.qty} ${detailsData.weightMeasure.unitLabel}`,
                    })}
                    {loadDataComponent({
                        label: 'Volume Measure',
                        value: `${detailsData.volumeMeasure.qty} ${detailsData.volumeMeasure.unitLabel}`,
                    })}
                    {loadDataComponent({
                        label: 'Wooden Package',
                        value: detailsData.woodenPackage,
                    })}
                    {loadDataComponent({
                        label: 'Dangerous Goods',
                        value: detailsData.dangerousGoods,
                    })}
                </div>

                {/* Origin & destination */}
                <div className="flex flex-col gap-3 border-l pl-3 mobile:pl-0 mobile:border-none">
                    <div className="flex flex-col gap-1">
                        <div className="text-size-L themes-text-gray-v9 mb-1">
                            Origin
                        </div>
                        {loadDataComponent({
                            label: 'Origin Port',
                            value: detailsData.originPort,
                        })}
                        {loadDataComponent({
                            label: 'Cargo Ready Date',
                            value: detailsData.cargoReadyDate,
                        })}
                    </div>
                    <div className="flex flex-col gap-1">
                        <div className="text-size-L themes-text-gray-v9 my-1">
                            Destination
                        </div>
                        {loadDataComponent({
                            label: 'Destination Port',
                            value: detailsData.destinationPort,
                        })}
                        {loadDataComponent({
                            label: 'Delivery Required By',
                            value: detailsData.deliveryRequiredBy,
                        })}
                    </div>
                </div>

                {/* charges */}
                <div className="flex flex-col gap-3 border-l pl-3 sm:pl-0 sm:border-none">
                    <div className="flex flex-col gap-1">
                        <div className="text-size-L themes-text-gray-v9 mb-1">
                            Charges
                        </div>
                        {loadDataComponent({
                            label: 'INCO Term',
                            value: detailsData.incoTerm,
                        })}

                        {loadDataComponent({
                            label: 'Goods Value',
                            value: detailsData.goodsValue,
                        })}
                        {loadDataComponent({
                            label: 'Additional Terms',
                            value: detailsData.additionalTerms,
                        })}
                        {loadDataComponent({
                            label: 'Insurance Value',
                            value: `${detailsData.insuranceValue} ${detailsData.insuranceCurrency}`,
                        })}
                        {loadDataComponent({
                            label: 'Charges Apply',
                            value: detailsData.chargesApply,
                        })}
                    </div>
                </div>

                {/* notes */}
                <div className="flex flex-col gap-3 border-l pl-3 mobile:pl-0 mobile:border-none">
                    <div className="flex flex-col gap-1">
                        <div className="text-size-L themes-text-gray-v9 mb-1">
                            Notes
                        </div>
                        {loadDataComponent({
                            label: 'Marks and Numbers',
                            value: detailsData.marksAndNumbers,
                        })}
                        {loadDataComponent({
                            label: 'Goods Description',
                            value: detailsData.goodsDescription,
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Details

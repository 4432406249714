import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ITabItem } from '@components/tab/tab.interface'
import { RootState } from 'store'

import {
    metaDataDropdown,
    IBookingsDropdownRespown,
    IBookingsDetailsResponse,
    IBookingsFilter,
    IBookinsStatusTotal,
    IFieldDropdownMetaData,
    IFieldDropdownCountry,
    IFieldDropdownCurrency,
    IFieldDropdownCompany,
    IFieldDropdownContactName,
    IFieldDropdownContactNameItem,
} from '../bookings/bookings.interface'
import { IDropdownItem } from '@components/dropdown/dropdown.interface'
import { IMeta, IResponseData } from 'common/common.interface'
import { formatDate } from 'common/common.service'
import { Draft } from 'immer'
import { IContactDropdownItem } from '../bookings/create-booking/create-booking.interface'
import { ICointainerTypeData } from 'repository/interface/loglinesMetaData.interface'
import { IVendorBookingsSummary } from './vendors-bookings.interface'
import { vendorBookingsSummaryDummy } from './vendor-bookings.dummy'
import { IRelatedPartiesFormDummy } from './new-vendor-booking/new-vendor-booking.interface'

export interface IVendorBookinsSlice {
    vendorBookingsSummary: {
        data: IVendorBookingsSummary[]
        meta: IMeta
        filter: IBookingsFilter
        statusSelected: ITabItem
        statusTotal: IBookinsStatusTotal
        search: string | null
    }
    bookingsDetail: IBookingsDetailsResponse
    dropdownCreate: IBookingsDropdownRespown
    idMode: string
    contact: IContactDropdownItem
}

export const initialState: IVendorBookinsSlice = {
    vendorBookingsSummary: {
        data: vendorBookingsSummaryDummy,
        meta: {
            current_page: 0,
            last_page: 0,
            per_page: 50,
            total_page: 0,
            total_Items: 0,
            from: 0,
            to: 0,
            index_end: 0,
            index_start: 0,
        },
        search: '',
        filter: {
            status: '',
            inProgressStatus: '',
            pageNumber: 1,
            pageSize: 50,
        },
        statusTotal: {
            all: 0,
            draft: 0,
            submitted: 0,
            others: 0,
        },
        statusSelected: {
            value: 'all',
            key: 'status',
            label: 'All bookings',
            totalData: 0,
        },
    },
    bookingsDetail: {
        detailsConsignorPickupAddress: {
            address1: '',
            address2: '',
            addressType: '',
            city: '',
            company: '',
            contactEmailAddress: '',
            contactMobileNumber: '',
            contactName: '',
            contactPhoneNumber: '',
            countryCode: '',
            countryName: '',
            postCode: '',
            state: {
                description: '',
                value: '',
            },
        },
        detailsConsigneeDeliveryAddress: {
            address1: '',
            address2: '',
            addressType: '',
            city: '',
            company: '',
            contactEmailAddress: '',
            contactMobileNumber: '',
            contactName: '',
            contactPhoneNumber: '',
            countryCode: '',
            countryName: '',
            postCode: '',
            state: {
                description: '',
                value: '',
            },
        },
        detailsDocumentaryPickupAddress: {
            address1: '',
            address2: '',
            addressType: '',
            city: '',
            company: '',
            contactEmailAddress: '',
            contactMobileNumber: '',
            contactName: '',
            contactPhoneNumber: '',
            countryCode: '',
            countryName: '',
            postCode: '',
            state: {
                description: '',
                value: '',
            },
        },
        detailsDocumentaryDeliveryAddress: {
            address1: '',
            address2: '',
            addressType: '',
            city: '',
            company: '',
            contactEmailAddress: '',
            contactMobileNumber: '',
            contactName: '',
            contactPhoneNumber: '',
            countryCode: '',
            countryName: '',
            postCode: '',
            state: {
                description: '',
                value: '',
            },
        },
        detailsOrigin: {
            originPort: '',
            estimatedPickup: '',
            pickupRequiredBy: '',
            pickupEquipmentNeeded: '',
            portTransportAdvised: '',
            actualPickup: '',
        },
        detailsDestination: {
            destinationPort: '',
            estimatedDelivery: '',
            deliveryRequiredBy: '',
            deliveryEquipmentNeeded: '',
            deliveryPortTransportAdvised: '',
            actualDelivery: '',
        },
        shipmentDetails: [
            {
                houseBill: '',
                shippersRef: '',
                transportMode: '',
                containerMode: '',
                goodsDescription: '',
                marksAndNumbers: '',
                serviceLevelCode: '',
                serviceLevelDescription: '',
                packs: '',
                packageType: '',
                outerPacksUnitCode: '',
                outerPacksUnitDescription: '',
                weightMeasureKG: '',
                volumeMeasureM3: '',
                chargeableWeightVolume: '',
            },
        ],
        detailsCharges: {
            incoTerm: '',
            goodsValue: '',
            goodsValueCurrency: '',
            goodsValueCurrencyDescription: '',
            additionalTerms: '',
            insuranceValue: '',
            insuranceValueCurrencyCode: '',
            insuranceValueCurrencyDescription: '',
            chargeApply: '',
        },
        relatedParties: {
            consignorPickupAddress: {
                address1: '',
                address2: '',
                addressType: '',
                city: '',
                company: '',
                contactEmailAddress: '',
                contactMobileNumber: '',
                contactName: '',
                contactPhoneNumber: '',
                countryCode: '',
                countryName: '',
                postCode: '',
                state: {
                    description: '',
                    value: '',
                },
            },
            consignorDocumentaryAddress: {
                address1: '',
                address2: '',
                addressType: '',
                city: '',
                company: '',
                contactEmailAddress: '',
                contactMobileNumber: '',
                contactName: '',
                contactPhoneNumber: '',
                countryCode: '',
                countryName: '',
                postCode: '',
                state: {
                    description: '',
                    value: '',
                },
            },
            consigneePickupAddress: {
                address1: '',
                address2: '',
                addressType: '',
                city: '',
                company: '',
                contactEmailAddress: '',
                contactMobileNumber: '',
                contactName: '',
                contactPhoneNumber: '',
                countryCode: '',
                countryName: '',
                postCode: '',
                state: {
                    description: '',
                    value: '',
                },
            },
            consigneeDocumentaryAddress: {
                address1: '',
                address2: '',
                addressType: '',
                city: '',
                company: '',
                contactEmailAddress: '',
                contactMobileNumber: '',
                contactName: '',
                contactPhoneNumber: '',
                countryCode: '',
                countryName: '',
                postCode: '',
                state: {
                    description: '',
                    value: '',
                },
            },
        },
        detailsContainers: [
            {
                containerCount: '',
                containerTypeCode: '',
                containerTypeDescription: '',
                containerNumber: '',
                flagsChiller: '',
                flagsContactHasWebContainerViewRight: '',
                flagEmptyContainer: '',
                flagFCLStorageArrivedUnderbound: '',
                flagsFreezer: '',
                flagsHasConsolidation: '',
                flagsHasUSBasedLoadPort: '',
                flagsHeldForFCLTransitStaging: '',
                flagsIsArrivingAtCTOByRail: '',
                flagsIsCFSRegistered: '',
                flagsIsDamaged: '',
                flagsIsSealOK: '',
                flagsIsShipperOwned: '',
                flagsOverrideFCLAvailableStorage: '',
                flagsOverride: '',
                flagsPickupByRail: '',
                flagsTempControlled: '',
                datesActualFullDelivery: '',
                datesActualFullPickup: '',
                datesArrivalDeliveryRequiredBy: '',
                datesCFSAvailable: '',
                datesCFSStorageStart: '',
                datesContainerYardEmptyReturnGateIn: '',
                datesCTOAvailable: '',
                datesCTOStorageStart: '',
                datesDepartureSlotDate: '',
                datesEmptyReady: '',
                datesEmptyReleasedFromCY: '',
                datesEmptyRequiredBy: '',
                datesEmptyReturnBy: '',
                datesEstFullPickup: '',
                datesEstimatedFullDelivery: '',
                datesETD: '',
                datesFCLLoaded: '',
                datesFCLStorageUnderbondCleared: '',
                datesFCLUnloadFromVessel: '',
                datesFCLWharfGateIn: '',
                datesPackDate: '',
                datesPortTransportAdvisedBooked: '',
                datesPortTransportBooked: '',
                datesSlotDate: '',
                datesSystemCreateTimeUTC: '',
                datesSystemLastEditTimeUTC: '',
                datesUnpackDate: '',
                datesVGMVerifiedDate: '',
                datesWharfGateOut: '',
                measurementDunnageMeasure: '',
                measurementGrossWeightMeasure: '',
                measurementHeight: '',
                measurementLength: '',
                measurementTareWeightMeasure: '',
                measurementTemperatureSetPointMeasure: '',
                measurementVolCapacityMeasure: '',
                measurementWgtCapacityMeasure: '',
                measurementWidth: '',
                numericAirVentSetting: '',
                numericDeliverySequence: '',
                numericGatewaySell: '',
                numericGoodsValue: '',
                numericHumidityPercent: '',
                numericNegotiatedCost: '',
                numericOverhangBack: '',
                numericOverhangRight: '',
                numericSpotRate: '',
                numericTEUCount: '',
                numericTotalPackages: '',
                numericVehicleNumberOfDoors: '',
                numericVehicleYear: '',
                numericVolume: '',
                text2ndSealedBy: '',
                text3rdSealedBy: '',
                textAgentType: '',
                textClient: '',
                textCommodity: '',
                textConsolID: '',
                textConsolMode: '',
                textContainerMode: '',
                textContainerQuality: '',
                textContainerStatus: '',
                textCreatedByStaff: '',
                textCurrency: '',
                textEmptyPickupFromAddress: '',
                textEmptyReturnToAddress: '',
                textGoodsCurrency: '',
                textGrossWeightVerification: '',
                textLastEditedByStaff: '',
                textPackType: '',
                textPortOfArrival: '',
                textPortOfLoading: '',
                textSailing: '',
                textSealedBy: '',
                textShippingLine: '',
                textShippingLineAddress: '',
                textTransportMode: '',
                textVGMStatus: '',
                text2ndSealNumber: '',
                text3rdSealNumber: '',
                textAllocationLevel: '',
                textArrivalCartageRef: '',
                textArrivalPort: '',
                textArrivalSlotReference: '',
                textAutoratingMode: '',
                textBookingReference: '',
                textCarrierName: '',
                textChillerOrFreezer: '',
                textClipOnUnitNumber: '',
                textContType: '',
                textContainerImportRelease: '',
                textContainerJobNo: '',
                textContainerNumberWithFallback: '',
                textContainerStorageClass: '',
                textDeliveryMode: '',
                textDepartureCartageRef: '',
                textDepartureSlotReference: '',
                textDockReceiptNumber: '',
                textEmptyReleaseNum: '',
                textEmptyReturnReference: '',
                textExportCustomsRef: '',
                textFlowUnit: '',
                textFreightChargesRateClass: '',
                textHarmonizedCode: '',
                textImportDepotCustomsReference: '',
                textLoadPort: '',
                textMasterBill: '',
                textMasterBillNumber: '',
                textMovementType: '',
                textRating: '',
                textSealNumber: '',
                textSpecialInstructionsNoteText: '',
                textSpotRateAutoratingMode: '',
                textStorLocation: '',
                textStowagePosition: '',
                textTempRecNumber: '',
                textTrainWagonNumber: '',
                textUnpackGang: '',
                textUnpackShed: '',
                textVehicleColor: '',
                textVehicleMake: '',
                textVehicleModel: '',
                textVehicleTransmission: '',
                textVerifiedByAddressCompanyName: '',
                textVerifiedByAddressContactName: '',
                textVerifiedByAddressEmail: '',
                textVerifiedByAddressPhone: '',
                textVesselJourneyName: '',
                textVolumeUnit: '',
                textVoyageFlight: '',
            },
        ],
        detailsPacklines: [
            {
                packs: '',
                weightMeasureKG: '',
                goodsDescription: '',
                lengthMeasure: '',
                widthMeasure: '',
                heightMeasure: '',
                volumeMeasureM3: '',
                packType: '',
                marksAndNumbers: '',
                linePrice: '',
                tariffNumber: '',
            },
        ],
    },

    dropdownCreate: {
        pickupEquipmentNeeded: [],
        deliveryEquipmentNeeded: [],
        transportMode: [],
        serviceLevel: [],
        packsUnit: [],
        chargesApply: [],
        weightMeasure: [],
        volumeMeasure: [],
        lengthWidthHeightMeasure: [],
        incOterm: [],
        country: [],
        currency: [],
        insuranceCurrency: [],
        origins: [],
        destinations: [],
        shippercompanies1: [],
        shippercompanies2: [],
        consgineecompanies1: [],
        consgineecompanies2: [],
        containerModes: [],
    },
    idMode: '',
    contact: {
        shipperAddress1: [],
        shipperAddress2: [],
        consigneeAddress1: [],
        consigneeAddress2: [],
    },
}

const vendorBookinsSlice = createSlice({
    name: 'VendorBookingsIFB',
    initialState,
    reducers: {
        setVendorBookingSummaryData(
            state,
            action: PayloadAction<{
                data: IVendorBookingsSummary[]
                statusTotal: IBookinsStatusTotal
                meta: IMeta
            }>,
        ) {
            const { data, statusTotal, meta } = action.payload

            state.vendorBookingsSummary.data = data
            state.vendorBookingsSummary.statusTotal = statusTotal
            state.vendorBookingsSummary.meta = meta
        },
        setBookingsDataDetail(
            state,
            action: PayloadAction<IResponseData<IBookingsDetailsResponse>>,
        ) {
            const { data, isSuccess, message, meta, links } = action.payload

            // format date
            const format = 'DD MMM YYYY'
            data.detailsOrigin.estimatedPickup = data.detailsOrigin
                .estimatedPickup
                ? formatDate(data.detailsOrigin.estimatedPickup, format)
                : ''

            data.detailsOrigin.pickupRequiredBy = data.detailsOrigin
                .pickupRequiredBy
                ? formatDate(data.detailsOrigin.pickupRequiredBy, format)
                : ''
            data.detailsDestination.estimatedDelivery = data.detailsDestination
                .estimatedDelivery
                ? formatDate(data.detailsDestination.estimatedDelivery, format)
                : ''
            data.detailsDestination.deliveryRequiredBy = data.detailsDestination
                .deliveryRequiredBy
                ? formatDate(data.detailsDestination.deliveryRequiredBy, format)
                : ''

            const d = {
                bookingsDetail: data,
                isSuccess,
                message,
                meta,
                links,
            }

            return {
                ...state,
                ...d,
            }
        },
        setSelectedStatus(state, action: PayloadAction<ITabItem>) {
            state.vendorBookingsSummary.statusSelected = action.payload
        },
        setFilter(state, action: PayloadAction<IBookingsFilter>) {
            state.vendorBookingsSummary.filter = action.payload
        },
        setMode(
            state: Draft<IVendorBookinsSlice>,
            action: PayloadAction<string>,
        ) {
            const idMode = action.payload
            return {
                ...state,
                idMode,
            }
        },
        setPageNumber(state, action: PayloadAction<number>) {
            const pageNumber = action.payload
            const filter = { ...state.vendorBookingsSummary.filter, pageNumber }
            return {
                ...state,
                filter,
            }
        },
        setBookingsDropdownContact(
            state,
            action: PayloadAction<{
                goal: string
                contact: IFieldDropdownContactName
            }>,
        ) {
            const convertToDropdownItemsContact = (
                dropdown: IFieldDropdownContactNameItem[],
            ): IDropdownItem<IFieldDropdownContactNameItem>[] => {
                return dropdown.map((item) => ({
                    label: item.contactName,
                    value: item.contactName,
                    additionalData: item,
                }))
            }

            const { goal, contact } = action.payload
            const existingData = state.contact || {
                shipperAddress1: [],
                shipperAddress2: [],
                consigneeAddress1: [],
                consigneeAddress2: [],
            }

            const updatedData = { ...existingData }
            if (goal.startsWith('shipperAddress[0]')) {
                updatedData.shipperAddress1 = convertToDropdownItemsContact(
                    contact.contact,
                )
            } else if (goal.startsWith('shipperAddress[1]')) {
                updatedData.shipperAddress2 = convertToDropdownItemsContact(
                    contact.contact,
                )
            } else if (goal.startsWith('consigneeAddress[0]')) {
                updatedData.consigneeAddress1 = convertToDropdownItemsContact(
                    contact.contact,
                )
            } else if (goal.startsWith('consigneeAddress[1]')) {
                updatedData.consigneeAddress2 = convertToDropdownItemsContact(
                    contact.contact,
                )
            }

            return {
                ...state,
                contact: updatedData,
            }
        },
        setBookingsDropdown(state, action: PayloadAction<metaDataDropdown>) {
            const convertToDropdownItems = (
                dropdown: IFieldDropdownMetaData[],
            ): IDropdownItem[] => {
                return dropdown.map((data) => ({
                    label: data.item + ' - ' + data.value,
                    value: data.item,
                }))
            }

            const convertToDropdownItemsUnit = (
                dropdown: IFieldDropdownMetaData[],
            ): IDropdownItem[] => {
                return dropdown.map((data) => ({
                    label: data.item,
                    value: data.item,
                    customLabelOption: data.item + ' - ' + data.value,
                }))
            }

            const convertToDropdownItemsCurrency = (
                dropdown: IFieldDropdownCurrency[],
            ): IDropdownItem[] => {
                return dropdown.map((data) => ({
                    label: data.code + ' - ' + data.title,
                    value: data.code,
                }))
            }
            const convertToDropdownItemsCountry = (
                dropdown: IFieldDropdownCountry[],
            ): IDropdownItem[] => {
                return dropdown.map((data) => ({
                    label: data.country + ' - ' + data.name,
                    value: data.country,
                }))
            }

            const convertToDropdownItemsCity = (
                dropdown: IFieldDropdownCountry[],
            ): IDropdownItem[] => {
                return dropdown.map((item) => ({
                    label: item.location + ' - ' + item.name,
                    value: item.location,
                }))
            }

            const convertToDropdownItemsCompany = (
                dropdown: IFieldDropdownCompany[],
            ): IDropdownItem<IFieldDropdownCompany>[] => {
                return dropdown.map((item) => ({
                    label: item.company,
                    value: item.company,
                    additionalData: item,
                }))
            }

            const item = action.payload.dropdownData
            const itemCurrency = action.payload.currency
            const itemCountry = action.payload.countries
            const itemOrigin = action.payload.origins
            const itemDestinations = action.payload.destinations
            const itemConsignee = action.payload.consignee
            const itemConsignor = action.payload.consignor

            const transformedData = {
                pickupEquipmentNeeded: convertToDropdownItems(
                    item.pickupEquipmentNeeded,
                ),
                deliveryEquipmentNeeded: convertToDropdownItems(
                    item.deliveryEquipmentNeeded,
                ),
                transportMode: convertToDropdownItems(item.transportMode),
                serviceLevel: convertToDropdownItems(item.serviceLevel),
                packsUnit: convertToDropdownItems(item.packsUnit),
                chargesApply: convertToDropdownItems(item.chargesApply),
                weightMeasure: convertToDropdownItemsUnit(item.weightMeasure),
                volumeMeasure: convertToDropdownItemsUnit(item.volumeMeasure),
                lengthWidthHeightMeasure: convertToDropdownItems(
                    item.lengthWidthHeightMeasure,
                ),
                incOterm: convertToDropdownItems(item.incOterm),
                country: convertToDropdownItemsCountry(itemCountry),
                currency: convertToDropdownItemsCurrency(itemCurrency),
                insuranceCurrency: convertToDropdownItemsCurrency(itemCurrency),
                origins: convertToDropdownItemsCity(itemOrigin),
                destinations: convertToDropdownItemsCity(itemDestinations),
                shippercompanies1: convertToDropdownItemsCompany(itemConsignor),
                shippercompanies2: convertToDropdownItemsCompany(itemConsignor),
                consgineecompanies1:
                    convertToDropdownItemsCompany(itemConsignee),
                consgineecompanies2:
                    convertToDropdownItemsCompany(itemConsignee),
                containerModes: state.dropdownCreate.containerModes,
            }

            const d = {
                dropdownCreate: transformedData,
            }

            return {
                ...state,
                ...d,
            }
        },
        setDropdownContactItems(
            state,
            action: PayloadAction<IContactDropdownItem>,
        ) {
            state.contact = action.payload
        },
        setLoadOptionsCreateBooking<T = undefined>(
            state: IVendorBookinsSlice,
            action: PayloadAction<{
                options: IDropdownItem<T>[]
                loadType: string
            }>,
        ) {
            const { options, loadType } = action.payload
            switch (loadType) {
                case 'origin':
                    state.dropdownCreate.origins =
                        options as IDropdownItem<undefined>[]
                    break
                case 'destination':
                    state.dropdownCreate.destinations =
                        options as IDropdownItem<undefined>[]
                    break
                case 'shippercompanies':
                    state.dropdownCreate.shippercompanies1 =
                        options as IDropdownItem<IFieldDropdownCompany>[]
                    break
                case 'shippercompanies2':
                    state.dropdownCreate.shippercompanies2 =
                        options as IDropdownItem<IFieldDropdownCompany>[]
                    break
                case 'consgineecompanies1':
                    state.dropdownCreate.consgineecompanies1 =
                        options as IDropdownItem<IFieldDropdownCompany>[]
                    break
                case 'consgineecompanies2':
                    state.dropdownCreate.consgineecompanies2 =
                        options as IDropdownItem<IFieldDropdownCompany>[]
                    break
                case 'currency':
                    state.dropdownCreate.currency =
                        options as IDropdownItem<undefined>[]
                    break
                case 'insuranceCurrency':
                    state.dropdownCreate.insuranceCurrency =
                        options as IDropdownItem<undefined>[]
                    break
            }
        },
        setContainerModeDropdown(
            state: IVendorBookinsSlice,
            action: PayloadAction<IDropdownItem<ICointainerTypeData>[]>,
        ) {
            state.dropdownCreate.containerModes = action.payload
        },
    },
})

// these all the variables used for selector
export const bookingsDataDropdownSelector = (state: RootState) =>
    state.vendorBookingsIFB.dropdownCreate || {}
export const bookingsDataDropdownContactSelector = (state: RootState) =>
    state.vendorBookingsIFB.contact || {}
export const idModeDropdownSelector = (state: RootState) =>
    state.vendorBookingsIFB.idMode || ''
export const vendorBookingsSummarySelector = (state: RootState) =>
    state.vendorBookingsIFB.vendorBookingsSummary || {}

export const bookingsDetailsSelector = (state: RootState) =>
    state.vendorBookingsIFB.bookingsDetail || {}

// all actions
export const {
    setVendorBookingSummaryData,
    setSelectedStatus,
    setPageNumber,
    setFilter,
    setBookingsDropdown,
    setBookingsDropdownContact,
    setBookingsDataDetail,
    setMode,
    setDropdownContactItems,
    setLoadOptionsCreateBooking,
    setContainerModeDropdown,
    // setShipmentDetailsData,
} = vendorBookinsSlice.actions

// Reducer
export default vendorBookinsSlice.reducer

import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    fetchBookingStatus,
    fetchContainerModeStatus,
    fetchNewsData,
    fetchScfiData,
    fetchShipmenteStatus,
    fetchShipmenteStatus2,
    fetchShipmentInvoice,
    fetchShipmentsData,
    fetchtDropdownDashboard,
} from 'repository/dashboard.repository'
import {
    barContainerSelector,
    barInvoiceSelector,
    dropdownTransModeSelector,
    filterShipmentDataSelector,
    openInvoicesSelector,
    itemInvoiceSelector,
    itemInvoiceUnPaidSelector,
    itemSelector,
    newsSelector,
    setBarInvoice,
    setBookingStatus,
    setContainermodeStatus,
    setDashboardDropdown,
    setNews,
    setPageNumber,
    setSelectedStatus,
    setShipmentStatus,
    setShipmentStatus2,
    setTrackingShipmentLocation,
    statusContainerModeSelector,
    tabStatusFilterSelector,
    trackingShipmentLocationeSelector,
    setOpenInvoices,
} from './dashboard.slice'
import { useFormik } from 'formik'
import {
    IDashboardFilterValidation,
    useDashboardFilterValidation,
} from 'form-validation/dashboard.validation'
import {
    IBookingStatusRespon,
    IInfoCardProps,
    IShipmentStatusRespon2,
} from './dashboard.interface'
import { metaDataDropdown } from '../bookings/bookings.interface'
import { ITabItem } from '@components/tab/tab.interface'
import moment from 'moment'
import { ILineChartItem } from '@components/rechart-component/rechart-interface'
import { Toast } from '@components/toast/toast.component'
import { ifbEndpoints } from 'common/endpoints/ifb.endpoints'
import {
    IROpenInvoiceData,
    IRTotalShipmentsByInvoicePaidData,
} from 'repository/interface/dashboard.interface'
import { InfoCardGroupItems } from '@components/InfoCardGroup/Info-card-group.interface'
import { userDataSelector } from 'pages/login/login.slice'
import {
    bookingMilestones,
    shipmentMilestones as InitialShipmentMilestones,
} from './dashboard.static'
import { customRounding } from 'common/common.service'
import EmptyResult from '@components/empty-result/empty-result.component'

const useDashboard = () => {
    const dispatch = useDispatch()

    //  selectors
    const dataItem = useSelector(itemSelector)
    const filterShipmentData = useSelector(filterShipmentDataSelector)
    const openInvoices = useSelector(openInvoicesSelector)
    const barInvoiceData = useSelector(barInvoiceSelector)
    const barContainerData = useSelector(barContainerSelector)
    const itemInvoiceData = useSelector(itemInvoiceSelector)
    const itemInvoiceDataUnPaid = useSelector(itemInvoiceUnPaidSelector)
    const statusContainerMode = useSelector(statusContainerModeSelector)
    const news = useSelector(newsSelector)
    const transModeData = useSelector(dropdownTransModeSelector)
    const trackingShipmentLocatione = useSelector(
        trackingShipmentLocationeSelector,
    )
    const tabFilter = useSelector(tabStatusFilterSelector)
    const user = useSelector(userDataSelector)

    // states
    const [isLoading, setIsLoading] = useState({
        shipmentStatus: true,
        shipmentStatus2: true,
        bookingStatus: true,
        containerModeStatus: true,
        shipmentData: true,
        totalShipmentsByInvoicePaid: true,
        openInvoices: true,
    })
    const [isLoadingNews, setIsLoadingNews] = useState(false)
    const [dataItems, setDataItems] = useState<IInfoCardProps[]>([])
    const { pageNumber, pageSize, status, search } = filterShipmentData
    const [isInitialRender, setIsInitialRender] = useState(true) // state for handle first render

    const { dashboardFilterValidation, dashboardFilterInitialValue } =
        useDashboardFilterValidation()

    const formikDashboard = useFormik<IDashboardFilterValidation>({
        validationSchema: dashboardFilterValidation,
        initialValues: dashboardFilterInitialValue,
        onSubmit: async () => {},
    })

    const isBarContainerDataEmpty = barContainerData?.every(
        (data) => !data.number,
    )
    const isInvoicePaidAmountEmpty =
        itemInvoiceData.every((item) => !item.value) ||
        barInvoiceData.every((data) => !data.number)

    const isOpenInvoicesEmpty =
        itemInvoiceDataUnPaid.every((item) => !Number(item.value)) &&
        openInvoices.every((invoice) => !invoice.value)

    const errorHandlingComponent = (
        isError: boolean,
        fallbackComponent: React.ReactNode,
    ) =>
        isError ? (
            <EmptyResult disableIllustration message="No Data Found" />
        ) : (
            fallbackComponent
        )

    const filterMilestonesObject = <T extends Object>(
        milestonesResponseData: T,
        milestonesStateData: T,
    ): T => {
        const initialDataObjKeys = Object.keys(milestonesStateData)
        const filterMilestonesData = Object.entries(milestonesResponseData)
            // Sort by the biggest number
            .sort((entryA, entryB) => Number(entryB[1]) - Number(entryA[1]))

            // limit maximum 4 entries, filter by specific Keys and only get if value of entries more than 0
            .filter(
                (
                    [bookingStatusKey, bookingStatusValue],
                    bookingStatusEntryIdx,
                ) =>
                    initialDataObjKeys.includes(bookingStatusKey) &&
                    bookingStatusValue &&
                    bookingStatusEntryIdx < 4,
            )

        const milestones = Object.fromEntries(
            [...filterMilestonesData, ...Object.entries(milestonesStateData)]
                // limit maximum 4 entries
                .filter(
                    (_, bookingStatusEntryIdx) => bookingStatusEntryIdx < 4,
                ),
        ) as T

        return milestones
    }

    const fetchData = async () => {
        try {
            const shipmentStatusPromise = fetchShipmenteStatus(
                formikDashboard.values,
            )
                .then((shipmentStatus) => {
                    dispatch(setShipmentStatus(shipmentStatus.data))
                    setIsLoading((prevLoading) => ({
                        ...prevLoading,
                        shipmentStatus: false,
                    }))
                })
                .catch((error) => {
                    console.error('Error fetching Shipment Status data:', error)
                    setIsLoading((prevLoading) => ({
                        ...prevLoading,
                        shipmentStatus: false,
                    }))
                })

            const shipmentStatusPromise2 = fetchShipmenteStatus2(
                formikDashboard.values,
            )
                .then((shipmentStatus2) => {
                    if (shipmentStatus2.isSuccess) {
                        const shipmentMilestones =
                            filterMilestonesObject<IShipmentStatusRespon2>(
                                shipmentStatus2.data,
                                {
                                    ...InitialShipmentMilestones,
                                    ...dataItem.shipment2,
                                },
                            )
                        dispatch(setShipmentStatus2(shipmentMilestones))
                    }
                    setIsLoading((prevLoading) => ({
                        ...prevLoading,
                        shipmentStatus2: false,
                    }))
                })
                .catch((error) => {
                    console.error('Error fetching Shipment Status data:', error)
                    setIsLoading((prevLoading) => ({
                        ...prevLoading,
                        shipmentStatus: false,
                    }))
                })

            const bookingStatusPromise = fetchBookingStatus(
                formikDashboard.values,
            )
                .then((bookingStatus) => {
                    if (bookingStatus.isSuccess) {
                        const filterBookingMilestoneData =
                            filterMilestonesObject<IBookingStatusRespon>(
                                bookingStatus.data,
                                {
                                    ...bookingMilestones,
                                    ...dataItem.bookings,
                                },
                            )
                        dispatch(setBookingStatus(filterBookingMilestoneData))
                    }

                    setIsLoading((prevLoading) => ({
                        ...prevLoading,
                        bookingStatus: false,
                    }))
                })
                .catch((error) => {
                    console.error('Error fetching Shipment Status data:', error)
                    setIsLoading((prevLoading) => ({
                        ...prevLoading,
                        bookingStatus: false,
                    }))
                })

            const containerModeStatusPromise = fetchContainerModeStatus(
                formikDashboard.values,
            )
                .then((containerModeStatus) => {
                    dispatch(setContainermodeStatus(containerModeStatus.data))
                    setIsLoading((prevLoading) => ({
                        ...prevLoading,
                        containerModeStatus: false,
                    }))
                })
                .catch((error) => {
                    console.error('Error fetching Shipment Status data:', error)
                    setIsLoading((prevLoading) => ({
                        ...prevLoading,
                        containerModeStatus: false,
                    }))
                })

            const totalShipmentsByInvoicePaid =
                loadTotalShipmentsByInvoicePaid()

            const dataShipmentTable = loadDataShipment()

            const openInvoices = fetchShipmentInvoice<IROpenInvoiceData>(
                formikDashboard.values,
                ifbEndpoints.dashboard_OpenInvoices,
            )
                .then(({ data }) => {
                    dispatch(
                        setOpenInvoices({
                            data,
                            currency: user.userDefaultData.currency.code,
                        }),
                    )
                })
                .catch((error) => {
                    console.error('Error fetching data:', error)
                })
                .finally(() => {
                    setIsLoading((prevLoading) => ({
                        ...prevLoading,
                        openInvoices: false,
                    }))
                })

            await Promise.allSettled([
                shipmentStatusPromise,
                shipmentStatusPromise2,
                bookingStatusPromise,
                containerModeStatusPromise,
                totalShipmentsByInvoicePaid,
                dataShipmentTable,
                openInvoices,
            ])
        } catch (error) {
            console.error('Error fetching data:', error)
        }
    }

    const fetchDataOnlyOnce = async () => {
        try {
            const [dataNews, dataDropdown] = await Promise.all([
                fetchNewsData(),
                fetchtDropdownDashboard(),
            ])
            dispatch(setNews(dataNews))
            dispatch(setDashboardDropdown(dataDropdown as metaDataDropdown))
        } catch (error) {
            console.error('Error fetching data:', error)
        } finally {
            setIsLoadingNews(false)
        }
    }

    // Get Data Function
    const loadDataShipment = async () => {
        let tmpFilter = filterShipmentData
        if (tabFilter.value) {
            const inProgressStat = tabFilter.value
            tmpFilter = {
                ...filterShipmentData,
                status,
                inProgressStatus: inProgressStat,
            }
        } else {
            tmpFilter = { ...filterShipmentData, status }
        }
        const filterData = {
            ...tmpFilter,
            ...formikDashboard.values,
        }
        try {
            setIsLoading((prevLoading) => ({
                ...prevLoading,
                shipmentData: true,
            }))
            const actionResult = await fetchShipmentsData(filterData)
            if (actionResult.isSuccess)
                dispatch(setTrackingShipmentLocation(actionResult))
        } catch (e) {
            console.error('Error fetching data:', e)
        } finally {
            setIsLoading((prevLoading) => ({
                ...prevLoading,
                shipmentData: false,
            }))
        }
    }

    const setPageData = (pageNumber: number) => {
        dispatch(setPageNumber(pageNumber))
    }

    const setTabFilter = async (data: ITabItem) => {
        dispatch(setSelectedStatus(data))
        dispatch(setPageNumber(1))
    }

    const loadTotalShipmentsByInvoicePaid = async () => {
        const start = moment(formikDashboard.values.StartMonth, 'MM/YYYY')
        const end = moment(formikDashboard.values.EndMonth, 'MM/YYYY')

        try {
            const fecthData =
                await fetchShipmentInvoice<IRTotalShipmentsByInvoicePaidData>(
                    formikDashboard.values,
                    ifbEndpoints.dashboard_TotalShipmentsByInvoicePaid,
                    { currencyCode: user.userDefaultData.currency.code },
                )
            // barchart data
            const data: ILineChartItem[] = []
            while (start.isSameOrBefore(end, 'month')) {
                const month = start.format('MMM').toUpperCase()
                const year = start.format('YYYY')

                let invoiceValue = 0

                if (fecthData?.data?.perMonth) {
                    invoiceValue =
                        fecthData?.data?.perMonth?.find(
                            (item) =>
                                item?.month === month &&
                                item?.year?.toString() === year,
                        )?.invoice || 0
                }

                data.push({
                    name: month,
                    number: invoiceValue,
                    secName: year,
                    fill: '#D9DDE1',
                })

                start.add(1, 'month')
            }

            // card info data
            const itemInvoice: InfoCardGroupItems[] = [
                {
                    value: (
                        fecthData?.data?.header?.totalInvoicePaid || 0
                    )?.toString(),
                    label: 'Total Invoice Paid',
                },
                {
                    value: `${user?.userDefaultData.currency?.code || ''} ${customRounding({ value: fecthData?.data?.header?.average || '0.00', useDecimalPoint: true })}`,
                    label: 'Average per month',
                },
                {
                    value: `${user?.userDefaultData.currency?.code || ''} ${customRounding({ value: fecthData?.data?.header?.totalPaidAmount || '0.00', useDecimalPoint: true })}`,
                    label: 'Total paid amount',
                },
            ]

            dispatch(
                setBarInvoice({ chartData: data, cardInfoData: itemInvoice }),
            )
        } catch (error) {
            console.error('Error fetching data avarage days delayed:', error)
        } finally {
            setIsLoading((prevLoading) => ({
                ...prevLoading,
                totalShipmentsByInvoicePaid: false,
            }))
        }
    }

    useEffect(() => {
        setIsLoadingNews(true)
        fetchDataOnlyOnce()
        formikDashboard.submitForm()
    }, [])

    useEffect(() => {
        if (!isInitialRender) loadDataShipment()
    }, [pageNumber, pageSize, status, search, filterShipmentData])

    useEffect(() => {
        const handler = setTimeout(() => {
            if (Object.keys(formikDashboard.errors).length !== 0) {
                Toast({
                    header: 'Improper Filter Selection!',
                    message: formikDashboard.errors.EndMonth || '',
                    type: 'error',
                })
                return
            }
            setIsInitialRender(false)
            setIsLoading({
                shipmentStatus: true,
                shipmentStatus2: true,
                bookingStatus: true,
                containerModeStatus: true,
                shipmentData: false,
                totalShipmentsByInvoicePaid: true,
                openInvoices: true,
            })
            fetchData()
        }, 1000)
        return () => clearTimeout(handler)
    }, [formikDashboard.values, formikDashboard.errors])

    useEffect(() => {
        if (dataItem) {
            const formatingData = [
                {
                    icon: 'ri-price-tag-2-line',
                    total: dataItem.quote.total,
                    title: 'Quotes',
                    devOnly: true,
                    items: [
                        { label: 'Approved', value: dataItem.quote.approved },
                        { label: 'Pending', value: dataItem.quote.pending },
                        { label: 'Delayed', value: dataItem.quote.delayed },
                        {
                            label: 'New Quotes',
                            value: dataItem.quote.newQuotes,
                        },
                    ],
                },
                {
                    icon: 'ri-book-marked-line',
                    total: Object.values(dataItem.bookings).reduce(
                        (total, num) => total + num,
                    ),
                    title: 'Bookings',
                    items: Object.entries(dataItem.bookings).map(
                        ([label, value]) => ({ label, value }),
                    ),
                },
                {
                    icon: 'ri-ship-line',
                    total: Object.values(dataItem.shipment2).reduce(
                        (total, num) => total + num,
                    ),
                    title: 'Shipments',
                    items: Object.entries(dataItem.shipment2).map(
                        ([label, value]) => ({ label, value }),
                    ),
                },
                {
                    icon: 'ri-box-3-line',
                    total: dataItem.warehouse.total,
                    title: 'Warehouse',
                    devOnly: true,
                    items: [
                        {
                            label: 'In stock',
                            value: dataItem.warehouse.inStock,
                        },
                        {
                            label: 'Low stock',
                            value: dataItem.warehouse.lowStock,
                        },
                        {
                            label: 'Out of Stock',
                            value: dataItem.warehouse.outOfStock,
                        },
                        {
                            label: 'New Orders',
                            value: dataItem.warehouse.newOrders,
                        },
                    ],
                },
                {
                    icon: 'ri-leaf-line',
                    total: dataItem.co2Emissions.total,
                    title: 'CO2 Emissions',
                    devOnly: true,
                    items: [
                        { label: 'SEA', value: dataItem.co2Emissions.sea },
                        { label: 'AIR', value: dataItem.co2Emissions.air },
                        { label: 'ROAD', value: dataItem.co2Emissions.road },
                        { label: 'RAIL', value: dataItem.co2Emissions.rail },
                    ],
                },
            ]
            setDataItems(formatingData)
        }
    }, [dataItem])

    return {
        news,
        isLoadingNews,
        transModeData,
        barContainerData,
        isBarContainerDataEmpty,
        isInvoicePaidAmountEmpty,
        isOpenInvoicesEmpty,
        errorHandlingComponent,
        barInvoiceData,
        openInvoices,
        fetchNewsData,
        fetchScfiData,
        isLoading,
        formikDashboard,
        itemInvoiceData,
        itemInvoiceDataUnPaid,
        dataItems,
        statusContainerMode,
        trackingShipmentLocatione,
        setPageData,
        tabFilter,
        setTabFilter,
    }
}

export default useDashboard

import {
    IVendorBookingContainer,
    IVendorBookingPackLines,
} from './vendor-booking-details/vendor-booking-details.interface'
import { IVendorBookingsSummary } from './vendors-bookings.interface'
import { IRelatedPartiesFormDummy } from './new-vendor-booking/new-vendor-booking.interface'

export const vendorBookingsSummaryDummy: IVendorBookingsSummary[] = [
    {
        transportMode: 'Air',
        status: 'draft',
        bookingNo: 'BKG001',
        consignor: 'Company A',
        consignee: 'Company B',
        goodsDescription: 'Electronics',
        origin: 'JFK',
        destination: 'LHR',
        packs: '10',
        totalVolume: '5.2',
    },
    {
        transportMode: 'Sea',
        status: 'submitted',
        bookingNo: 'BKG002',
        consignor: 'Company C',
        consignee: 'Company D',
        goodsDescription: 'Furniture',
        origin: 'HKG',
        destination: 'SIN',
        packs: '20',
        totalVolume: '15.7',
    },
    {
        transportMode: 'Road',
        status: 'others',
        bookingNo: 'BKG003',
        consignor: 'Company E',
        consignee: 'Company F',
        goodsDescription: 'Clothing',
        origin: 'LAX',
        destination: 'ORD',
        packs: '15',
        totalVolume: '8.4',
    },
    {
        transportMode: 'Air',
        status: 'others',
        bookingNo: 'BKG004',
        consignor: 'Company G',
        consignee: 'Company H',
        goodsDescription: 'Medical Supplies',
        origin: 'CDG',
        destination: 'FRA',
        packs: '5',
        totalVolume: '3.1',
    },
    {
        transportMode: 'Sea',
        status: 'others',
        bookingNo: 'BKG005',
        consignor: 'Company I',
        consignee: 'Company J',
        goodsDescription: 'Machinery',
        origin: 'SHA',
        destination: 'LAX',
        packs: '12',
        totalVolume: '9.6',
    },
    {
        transportMode: 'Road',
        status: 'submitted',
        bookingNo: 'BKG006',
        consignor: 'Company K',
        consignee: 'Company L',
        goodsDescription: 'Toys',
        origin: 'ATL',
        destination: 'MIA',
        packs: '8',
        totalVolume: '4.0',
    },
    {
        transportMode: 'Air',
        status: 'draft',
        bookingNo: 'BKG007',
        consignor: 'Company M',
        consignee: 'Company N',
        goodsDescription: 'Pharmaceuticals',
        origin: 'DXB',
        destination: 'LHR',
        packs: '6',
        totalVolume: '2.7',
    },
    {
        transportMode: 'Sea',
        status: 'submitted',
        bookingNo: 'BKG008',
        consignor: 'Company O',
        consignee: 'Company P',
        goodsDescription: 'Automotive Parts',
        origin: 'RTM',
        destination: 'NYC',
        packs: '18',
        totalVolume: '11.5',
    },
    {
        transportMode: 'Road',
        status: 'submitted',
        bookingNo: 'BKG009',
        consignor: 'Company Q',
        consignee: 'Company R',
        goodsDescription: 'Cosmetics',
        origin: 'LHR',
        destination: 'CDG',
        packs: '9',
        totalVolume: '3.8',
    },
    {
        transportMode: 'Air',
        status: 'submitted',
        bookingNo: 'BKG010',
        consignor: 'Company S',
        consignee: 'Company T',
        goodsDescription: 'Chemicals',
        origin: 'ORD',
        destination: 'DFW',
        packs: '11',
        totalVolume: '6.3',
    },
]

// segment details
export const relatedPartiesDetailsDummy = {
    shipperPickupAddress: {
        company: 'OneByOne Logistical Melbourne',
        address1: '567 Collins Street',
        address2: '',
        city: 'Melborne',
        state: 'VIC',
        postCode: '3000',
        country: 'AU, Australia',
        contactName: 'Raven Hudson',
        contactPhoneNumber: '+61 2 1234 5678',
        contactMobileNumber: '+61 4 1234 5678',
        contactEmailAddress: 'raven.hudson@gmail.com',
    },
    shipper: {
        company: 'OneByOne Logistical Melbourne',
        address1: '567 Collins Street',
        address2: '',
        city: 'Melborne',
        state: 'VIC',
        postCode: '3000',
        country: 'AU, Australia',
        contactName: 'Raven Hudson',
        contactPhoneNumber: '+61 2 1234 5678',
        contactMobileNumber: '+61 4 1234 5678',
        contactEmailAddress: 'raven.hudson@gmail.com',
    },
    consigneePickupAddress: {
        company: 'OneByOne Logistical Bangkok',
        address1: '123 Sukhumvit Road',
        address2: '',
        city: 'Bangkok',
        state: 'Khlong Toei',
        postCode: '10110',
        country: 'TH, Thailand',
        contactName: 'Jason Lorde',
        contactPhoneNumber: '+66 2 123 4567',
        contactMobileNumber: '+66 2 123 4567',
        contactEmailAddress: 'jason.lorde@gmail.com',
    },
    consignee: {
        company: 'OneByOne Logistical Bangkok',
        address1: '123 Sukhumvit Road',
        address2: '',
        city: 'Bangkok',
        state: 'Khlong Toei',
        postCode: '10110',
        country: 'TH, Thailand',
        contactName: 'Jason Lorde',
        contactPhoneNumber: '+66 2 123 4567',
        contactMobileNumber: '+66 2 123 4567',
        contactEmailAddress: 'jason.lorde@gmail.com',
    },
} as const

export const packLinesDetailsDummy: IVendorBookingPackLines[] = [
    {
        pieces: '1',
        packType: 'Box',
        length: '10.00 M',
        height: '10.00 M',
        width: '22.00 M',
        totalWeight: '12,548.00 KG',
        totalVolume: '1,448.40 M3',
        description: 'Fresh Apples',
        marksAndNumbers: 'ABC1234567',
        linePrice: '$100.00',
        tariffNumber: '0808.10',
        hSCode: '841810',
    },
    {
        pieces: '1',
        packType: 'Box',
        length: '10.00 M',
        width: '22.00 M',
        totalWeight: '12,548.00 KG',
        totalVolume: '1,448.40 M3',
        description: 'Fresh Apples',
        marksAndNumbers: 'ABC1234567',
        linePrice: '$100.00',
        tariffNumber: '0808.10',
        hSCode: '841810',
        height: '10.00 M',
    },
    {
        pieces: '1',
        packType: 'Box',
        length: '10.00 M',
        width: '22.00 M',
        totalWeight: '12,548.00 KG',
        totalVolume: '1,448.40 M3',
        description: 'Fresh Apples',
        marksAndNumbers: 'ABC1234567',
        linePrice: '$100.00',
        tariffNumber: '0808.10',
        hSCode: '841810',
        height: '10.00 M',
    },
    {
        pieces: '1',
        packType: 'Box',
        length: '10.00 M',
        width: '22.00 M',
        totalWeight: '12,548.00 KG',
        totalVolume: '1,448.40 M3',
        description: 'Fresh Apples',
        marksAndNumbers: 'ABC1234567',
        linePrice: '$100.00',
        tariffNumber: '0808.10',
        hSCode: '841810',
        height: '10.00 M',
    },
    {
        pieces: '1',
        packType: 'Box',
        length: '10.00 M',
        width: '22.00 M',
        totalWeight: '12,548.00 KG',
        totalVolume: '1,448.40 M3',
        description: 'Fresh Apples',
        marksAndNumbers: 'ABC1234567',
        linePrice: '$100.00',
        tariffNumber: '0808.10',
        hSCode: '841810',
        height: '10.00 M',
    },
]

export const containerDetail: IVendorBookingContainer[] = [
    {
        containerNo: 'ABCD12345678',
        type: '40 HC',
        count: '1',
        sealNo: 'SL2658981',
    },
    {
        containerNo: 'ABCD12345678',
        type: '40 HC',
        count: '1',
        sealNo: 'SL2658981',
    },
    {
        containerNo: 'ABCD12345678',
        type: '40 HC',
        count: '1',
        sealNo: 'SL2658981',
    },
    {
        containerNo: 'ABCD12345678',
        type: '40 HC',
        count: '1',
        sealNo: 'SL2658981',
    },
]

// segment new
export const relatedPartiesAddressShipperDummy: IRelatedPartiesFormDummy[] = [
    {
        companyName: 'Company A',
        address1: '123 Main Street',
        address2: 'Suite 100',
        city: 'New York',
        state: 'NY',
        postCode: '10001',
        country: 'USA',
        contacts: [
            {
                contactName: 'John Doe',
                phoneNumber: '+1 212-555-1234',
                mobileNumber: '+1 917-555-5678',
                emailAddress: 'johndoe@example.com',
            },
            {
                contactName: 'John Doe 2',
                phoneNumber: '+1 212-555-1234',
                mobileNumber: '+1 917-555-5678',
                emailAddress: 'johndoe2@example.com',
            },
        ],
    },
    {
        companyName: 'Company B',
        address1: '456 Elm Street',
        address2: 'Apt 2B',
        city: 'Los Angeles',
        state: 'CA',
        postCode: '90001',
        country: 'USA',
        contacts: [
            {
                contactName: 'Jane Smith',
                phoneNumber: '+1 310-555-7890',
                mobileNumber: '+1 323-555-4567',
                emailAddress: 'janesmith@example.com',
            },
            {
                contactName: 'Jane Smith 2',
                phoneNumber: '+1 310-555-7890',
                mobileNumber: '+1 323-555-4567',
                emailAddress: 'janesmith2@example.com',
            },
        ],
    },
    {
        companyName: 'Company C',
        address1: '789 Oak Street',
        address2: '',
        city: 'Chicago',
        state: 'IL',
        postCode: '60601',
        country: 'USA',
        contacts: [
            {
                contactName: 'Michael Johnson',
                phoneNumber: '+1 312-555-2345',
                mobileNumber: '+1 773-555-6789',
                emailAddress: 'michaelj@example.com',
            },
            {
                contactName: 'Michael Johnson 2',
                phoneNumber: '+1 312-555-2345',
                mobileNumber: '+1 773-555-6789',
                emailAddress: 'michaelj2@example.com',
            },
        ],
    },
]

export const relatedPartiesAddressConsigneeDummy: IRelatedPartiesFormDummy[] = [
    {
        companyName: 'Company C',
        address1: '789 Oak Street',
        address2: '',
        city: 'Chicago',
        state: 'IL',
        postCode: '60601',
        country: 'USA',
        contacts: [
            {
                contactName: 'Michael Johnson',
                phoneNumber: '+1 312-555-2345',
                mobileNumber: '+1 773-555-6789',
                emailAddress: 'michaelj@example.com',
            },
            {
                contactName: 'Michael Johnson 2',
                phoneNumber: '+1 312-555-2345',
                mobileNumber: '+1 773-555-6789',
                emailAddress: 'michaelj2@example.com',
            },
        ],
    },
    {
        companyName: 'Company D',
        address1: '101 Pine Street',
        address2: 'Floor 3',
        city: 'San Francisco',
        state: 'CA',
        postCode: '94101',
        country: 'USA',
        contacts: [
            {
                contactName: 'Emily Davis',
                phoneNumber: '+1 415-555-3456',
                mobileNumber: '+1 415-555-6789',
                emailAddress: 'emilyd@example.com',
            },
            {
                contactName: 'Emily Davis 2',
                phoneNumber: '+1 415-555-3456',
                mobileNumber: '+1 415-555-6789',
                emailAddress: 'emilyd2@example.com',
            },
        ],
    },
    {
        companyName: 'Company E',
        address1: '222 Maple Avenue',
        address2: 'Suite 200',
        city: 'Seattle',
        state: 'WA',
        postCode: '98101',
        country: 'USA',
        contacts: [
            {
                contactName: 'Chris Wilson',
                phoneNumber: '+1 206-555-1234',
                mobileNumber: '+1 206-555-5678',
                emailAddress: 'chrisw@example.com',
            },
            {
                contactName: 'Chris Wilson 2',
                phoneNumber: '+1 206-555-1234',
                mobileNumber: '+1 206-555-5678',
                emailAddress: 'chrisw2@example.com',
            },
        ],
    },
]

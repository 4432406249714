var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import Tab from '@components/tab/tab.component';
import { FormikProvider } from 'formik';
import useTabMaster from './tab-master.service';
import Tooltip from '@components/tooltip/tooltip.component';
import FormInput from '@components/form-input/form-input.component';
import Button from '@components/button/button.component';
import { useEffect, useState } from 'react';
var TabMaster = function (_a) {
    var _b, _c, _d, _e;
    var useSearch = _a.useSearch, placeHolderSearch = _a.placeHolderSearch, items = _a.items, tabFilter = _a.tabFilter, iconButton1 = _a.iconButton1, iconButton2 = _a.iconButton2, iconButton3 = _a.iconButton3, actionButton1 = _a.actionButton1, actionButton2 = _a.actionButton2, containerSearchClassName = _a.containerSearchClassName, initialSearch = _a.initialSearch, onChange = _a.onChange, onSearchSubmit = _a.onSearchSubmit, additionalButton = _a.additionalButton, tabClassname = _a.tabClassname, containerClassname = _a.containerClassname, showTotalData = _a.showTotalData, _f = _a.minSearchChar, minSearchChar = _f === void 0 ? 3 : _f, props = __rest(_a, ["useSearch", "placeHolderSearch", "items", "tabFilter", "iconButton1", "iconButton2", "iconButton3", "actionButton1", "actionButton2", "containerSearchClassName", "initialSearch", "onChange", "onSearchSubmit", "additionalButton", "tabClassname", "containerClassname", "showTotalData", "minSearchChar"]);
    var formik = useTabMaster({
        handleSearch: onSearchSubmit,
        initialSearch: initialSearch,
        minSearchChar: minSearchChar,
    }).formik;
    var _g = useState(false), isWrapped = _g[0], setIsWrapped = _g[1];
    var isButtonExists = !!(iconButton1 || iconButton2 || iconButton3);
    var isRightSectionVisible = isButtonExists || useSearch;
    var flexWrap = useSearch ? '' : '';
    var widthSideFlex = useSearch ? 'sm:w-full' : ' ';
    var dataTestIdComponent = props.dataTestId
        ? "".concat(props.dataTestId, "_TAB_MASTER")
        : '';
    var filterChildHeader = items === null || items === void 0 ? void 0 : items.filter(function (value) { return (tabFilter === null || tabFilter === void 0 ? void 0 : tabFilter.value) === (value === null || value === void 0 ? void 0 : value.value); })[0];
    var checkWrapping = function () {
        var childA = document.getElementById('tabmaster-side-A');
        var childB = document.getElementById('tabmaster-side-B');
        if (childA && childB) {
            var childATop = childA.getBoundingClientRect().top;
            var childBTop = childB.getBoundingClientRect().top;
            var isWrappedCondition = childBTop < childATop;
            setIsWrapped(isWrappedCondition);
        }
    };
    useEffect(function () {
        var divElement = document.getElementById('loglines-content');
        if (!divElement)
            return;
        var handleWidthChange = function () {
            checkWrapping();
        };
        var observer = new ResizeObserver(function (entries) {
            for (var _i = 0, entries_1 = entries; _i < entries_1.length; _i++) {
                var entry = entries_1[_i];
                if (entry.contentRect.width !== divElement.offsetWidth) {
                    handleWidthChange();
                }
            }
        });
        observer.observe(divElement);
        // Clean up observer on component unmount
        return function () { return observer.disconnect(); };
    }, []);
    useEffect(function () {
        // const handleInitialCheck = () => {
        //     setTimeout(checkWrapping, 1)
        // }
        // Re-check on window resize to detect any wrapping changes
        window.addEventListener('resize', checkWrapping);
        return function () { return window.removeEventListener('resize', checkWrapping); };
    }, []);
    return (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: "css-tab-master-container px-3 gap-1 flex justify-between border-b border-solid themes-border-color ".concat(containerClassname), style: { flexWrap: useSearch ? 'wrap-reverse' : 'nowrap' } }, { children: [_jsx("div", __assign({ id: "tabmaster-side-A", className: "css-tab-master-side-a pt-0 flex items-end overflow-auto" }, { children: _jsx(Tab, { containerClassName: "flex-grow sm:w-full h-full ".concat(tabClassname), items: items !== null && items !== void 0 ? items : [], tabFilter: tabFilter, onChange: function (item) {
                                onChange(item);
                            }, showTotalData: showTotalData, dataTestId: dataTestIdComponent }) })), _jsx(_Fragment, { children: isRightSectionVisible && (_jsxs("div", __assign({ id: "tabmaster-side-B", className: "\n                                ".concat(widthSideFlex, " ").concat(containerSearchClassName || '', " \n                                ").concat(isWrapped ? 'pt-2 md:max-w-none' : 'py-2', "\n                            css-tab-master-side-b sm:min-w-[300px] mobile:min-w-[100px] 2xl:max-w-[600px] xl:max-w-[500px] md:max-w-[500px] flex flex-grow items-center mobile:justify-end \n                            mobile:flex-wrap mobile:flex-wrap-reverse mobile:gap-1") }, { children: [useSearch && (_jsx(FormikProvider, __assign({ value: formik }, { children: _jsx("form", __assign({ className: "w-full flex justify-end  mobile:pt-1", onSubmit: function (e) {
                                            e.preventDefault();
                                            formik.handleSubmit();
                                        } }, { children: _jsx(Tooltip, __assign({ text: "Search term must be at least ".concat(minSearchChar, " characters"), isShow: formik.errors.searchTerm
                                                ? true
                                                : false }, { children: _jsx(FormInput, { autoComplete: "off", hideError: true, placeholder: placeHolderSearch, parentDivClassName: "!mb-0 w-full min-w-[180px]", icon: "ri-search-line", name: "searchTerm", onKeyDown: function (event) {
                                                    if (event.key ===
                                                        'Backspace' &&
                                                        formik.values.searchTerm
                                                            .length === 1) {
                                                        formik.values.searchTerm =
                                                            '';
                                                        formik.handleSubmit();
                                                    }
                                                }, dataTestId: dataTestIdComponent
                                                    ? "".concat(dataTestIdComponent, "_SEARCH")
                                                    : '' }) })) })) }))), iconButton1 && (_jsxs("div", { children: [_jsx(Button, { onClick: function () {
                                                iconButton1.onClick();
                                            }, icon: iconButton1.icon, className: "".concat(iconButton1.className, "  ml-2 w-btnIconWidth !border-transparent"), variant: "default" }), (iconButton1 === null || iconButton1 === void 0 ? void 0 : iconButton1.filterOverlayComponent)
                                            ? iconButton1.filterOverlayComponent
                                            : ''] })), iconButton2 && (_jsxs("div", { children: [_jsx(Button, { onClick: function () {
                                                iconButton2.onClick();
                                            }, icon: iconButton2.icon, className: "".concat(iconButton2.className, " ml-2 w-btnIconWidth !border-transparent"), variant: "default" }), (iconButton2 === null || iconButton2 === void 0 ? void 0 : iconButton2.filterOverlayComponent)
                                            ? iconButton2.filterOverlayComponent
                                            : ''] })), iconButton3 && (_jsxs("div", { children: [_jsx(Button, { onClick: function () {
                                                iconButton3.onClick();
                                            }, icon: iconButton3.icon, className: "".concat(iconButton3.className, " ml-2 w-btnIconWidth !border-transparent"), variant: "default" }), (iconButton3 === null || iconButton3 === void 0 ? void 0 : iconButton3.filterOverlayComponent)
                                            ? iconButton3.filterOverlayComponent
                                            : ''] })), actionButton1 && (_jsxs("div", { children: [_jsx(Button, { onClick: function () {
                                                actionButton1.onClick();
                                            }, label: actionButton1.label, icon: actionButton1.icon, iconSuffix: actionButton1.iconSuffix, className: "".concat(actionButton1.className, " css-tab-master-action-btn-1 ml-2 w-btnRegularWidth !text-size-S"), iconClassName: "".concat(actionButton1.iconClassName), variant: "".concat(actionButton1.variant ? actionButton1.variant : 'brand'), dataTestId: dataTestIdComponent
                                                ? "".concat(dataTestIdComponent, "_").concat((_d = (_c = (_b = actionButton1.label) === null || _b === void 0 ? void 0 : _b.toUpperCase()) === null || _c === void 0 ? void 0 : _c.split(' ')) === null || _d === void 0 ? void 0 : _d.join('_'))
                                                : '' }), (actionButton1 === null || actionButton1 === void 0 ? void 0 : actionButton1.OverlayComponent)
                                            ? actionButton1.OverlayComponent
                                            : ''] })), actionButton2 && (_jsxs("div", { children: [_jsx(Button, { onClick: function () {
                                                actionButton2.onClick();
                                            }, label: actionButton2.label, icon: actionButton2.icon, iconSuffix: actionButton2.iconSuffix, className: "".concat(actionButton2.className, " ml-2 w-btnRegularWidth !text-size-S"), iconClassName: "".concat(actionButton2.iconClassName), variant: "".concat(actionButton2.variant ? actionButton2.variant : 'brand') }), (actionButton2 === null || actionButton2 === void 0 ? void 0 : actionButton2.OverlayComponent)
                                            ? actionButton2.OverlayComponent
                                            : ''] })), additionalButton] }))) })] })), ((_e = filterChildHeader === null || filterChildHeader === void 0 ? void 0 : filterChildHeader.childStatus) === null || _e === void 0 ? void 0 : _e.length) ? (_jsx("div", __assign({ className: "px-3 pt-3 gap-1 flex ".concat(flexWrap, " justify-between border-b border-solid themes-border-color") }, { children: _jsx(Tab, { items: (filterChildHeader === null || filterChildHeader === void 0 ? void 0 : filterChildHeader.childStatus) || [], onChange: function (item) {
                        onChange(__assign(__assign({}, filterChildHeader), { childStatus: [item] }));
                    }, tabFilter: tabFilter === null || tabFilter === void 0 ? void 0 : tabFilter.childStatus[0] }) }))) : (_jsx(_Fragment, {}))] }));
};
export default TabMaster;

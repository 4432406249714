import {
    filterCOInitialValue,
    filterCOValidation,
    IFilterCO,
    IFilterDataCategoryCO,
    IFilterShipmentEventCO,
} from 'form-validation/control-tower.validation'
import { useFormik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import {
    averageMonthlyPerformanceSelector,
    dataOverviewTable1Selector,
    financePieChartSelector,
    historyDataSelector,
    TradeLanesDataSelector,
    setAverageMonthlyPerformance,
    setDataOverviewTable1,
    setHistoryDevelopment,
    setFilterDropdownOptions,
    filterDropdownOptionsSelector,
    setTradeLanesCarrierData,
    setTradeLanesRegionData,
    setHistorySplitRecord,
    setMediumTableData,
    mediumTableSelector,
    setPageNumberMediumTable2Slice,
    setMediumTableMultipleBarsItemsData,
    setMediumTableCardItems,
    setLoadOptionsFilterDropdown,
    financeDataSelector,
    setOpenInvoceData,
    smallTableSelector,
    setSmallTableData,
    setPageNumberSmallTable2Slice,
    setFinaceProfitAndLossTable,
    setFinaceCreditLimitTable,
    setPageNumberFinanceTableSlice,
} from './control-tower.slice'
import { useEffect, useState } from 'react'
import { ITabItem } from '@components/tab/tab.interface'
import {
    initialDataHistoryDevelopment,
    initialDataBarChartSplitRecord,
    tabItemFilterCustomers,
    tabItemFilterFinance,
    tabItemFilterOverview,
    tabItemFilterTradeLanes,
    historySplitRecordSummaryIntialvalue,
    tabItemFilterSales,
    toastInitialValue,
    financeProfitAndLostTableInitialData,
    multipleBarsMediumTableInitialValue,
    summaryMediumTableIntialValue,
    historySplitRecordChartDataIntialValue,
} from './control-tower.static'
import {
    IButtonContentCO,
    IButtonContentItems,
    ICOFilterByMetrics,
    ICreditLimitTable,
    IDataOverviewTable1,
    IFilterDropdownOptions,
    IFinanceData,
    IFinanceProfitAndLossTableData,
    IHistoryDevelopmentData,
    ILoadCharLineMonthByRangeMonthFinanceProps,
    ILoadingFinance,
    ILoadingHistory,
    ILoadingTradeLanes,
    ILoadOptionsFilterDropdownProps,
    IMarkShipmentCustomersAndSales,
    IMediumTableData,
    ISmallTableData,
    IUseControlTower,
} from './control-tower.interface'
import moment from 'moment'
import {
    fetchControlTowerHistory,
    fetchControlTowerData,
    fetchControlTowerFinanceData,
} from 'repository/control-tower.repository'
import { Toast } from '@components/toast/toast.component'
import { IPillItem } from '@components/pills/pills.component'
import { IResponseData } from 'common/common.interface'
import {
    IRSmallTable,
    IRHistoryDevelopment,
    IROverviewSplitRecord,
    IRTradeLanesCarrier,
    IRTradeLanesRegion,
    IRTradeLanesHistorySplit,
    IRSalesTable,
    IROverallSales,
    IRSalesHistoryDevelopment,
    IRSalesHistorySplitRecord,
    IRCustomerOrganisationTable,
    IRCustomerOrgDevelopment,
    IRCustomerOrgSplitRecord,
    IRFinanceOpenInvoices,
    IRFinanceTableProfitAndLoss,
    IRFinanceTableCreditLimit,
    IRelatedKeyFiguresResponse2,
    IRFinanceAvgMonthlyPerformance,
    IRFinanceProfitAndLoss,
    IROverviewTableBranch,
    IRFinanceSplitRecord,
} from 'repository/interface/control-tower.interface'
import {
    IBarchartStacktedDataItem,
    ILineData,
} from '@components/rechart-component/rechart-interface'
import { InfoCardGroupItems } from '@components/InfoCardGroup/Info-card-group.interface'
import {
    createMetaData,
    customRounding,
    debounce,
    debounceVoid,
    getEnv,
} from 'common/common.service'
import { IProgressMultipleBarsData } from '@components/progress-multiple-bars/progress-multiple-bars.interface'
import {
    fetchLoglinesMetaData,
    loadContainerModeDropdown,
    loadTransportModeDropdown,
} from 'repository/loglinesMetaData.repository'
import {
    IRLoglineMetaBranchData,
    IRLoglineMetaContainerModeData,
} from 'repository/interface/loglinesMetaData.interface'
import { IDropdownItem } from '@components/dropdown/dropdown.interface'
import { userDataSelector } from 'pages/login/login.slice'
import { ifbEndpoints } from 'common/endpoints/ifb.endpoints'
import { IToastProps } from '@components/toast/toast.interface'

const useControlTower = (): IUseControlTower => {
    const dispatch = useDispatch()

    // selector common
    const user = useSelector(userDataSelector)
    const historyData = useSelector(historyDataSelector)
    const filterDropdownOptions = useSelector(filterDropdownOptionsSelector)
    const mediumTable = useSelector(mediumTableSelector)
    const smallTable = useSelector(smallTableSelector)

    // selector overview
    const dataOverviewTable1 = useSelector(dataOverviewTable1Selector)

    // selector trade lanes
    const tradeLanesData = useSelector(TradeLanesDataSelector)

    // selector finance
    const financePieChart = useSelector(financePieChartSelector)
    const averageMonthlyPerformance = useSelector(
        averageMonthlyPerformanceSelector,
    )
    const financeData = useSelector(financeDataSelector)

    // state common
    const [buttonContent, setButtonContent] =
        useState<IButtonContentItems>('overview')
    const [loadingHistory, setLoadingHistory] = useState<ILoadingHistory>({
        development: true,
        splitRecord: true,
    })
    const [isInitialRender, setIsInitialRender] = useState<boolean>(true)
    const [loadingMediumTable, setLoadingMediumTable] = useState<boolean>(true)
    const [loadingSmallTable, setLoadingSmallTable] = useState<boolean>(true)
    const [rawMediumTable, setRawMediumTable] = useState<IMediumTableData[]>([])
    const [rawSmallTable, setRawSmallTable] = useState<IRSmallTable[]>([])

    // state overview
    const [tabFilterOverview, setTabFilterOverview] = useState<ITabItem>(
        tabItemFilterOverview[0],
    )
    const [loadingRelatedKeyFigures, setLoadingRelatedKeyFigures] =
        useState<boolean>(true)

    // state trade lanes
    const [tabFilterTradeLanes, setTabFilterTradeLanes] = useState<ITabItem>(
        tabItemFilterTradeLanes[0],
    )
    const [loadingTradeLanes, setLoadinTradeLanes] =
        useState<ILoadingTradeLanes>({
            region: true,
            carrier: true,
        })
    const [tabFilterCustomers, setTabFilterCustomers] = useState<ITabItem>(
        tabItemFilterCustomers[0],
    )

    // state sales
    const [tabFilterSales, setTabFilterSales] = useState<ITabItem>(
        tabItemFilterSales[0],
    )

    // state finance
    const [tabFilterFinance, setTabFilterFinance] = useState<ITabItem>(
        tabItemFilterFinance[0],
    )
    const [loadingFinance, setLoadingFinance] = useState<ILoadingFinance>({
        averageMonthlyPerformance: true,
        openInvoice: true,
        table: true,
    })
    const [financeTableRawData, setFinanceTableRawData] = useState<{
        creditLimit: ICreditLimitTable[]
        profitandLoss: IFinanceProfitAndLossTableData[]
    }>({
        creditLimit: [],
        profitandLoss: [],
    })

    // formik
    const formikFilterCO = useFormik<IFilterCO>({
        validationSchema: filterCOValidation,
        initialValues: filterCOInitialValue,
        onSubmit: async () => {},
    })

    // variable
    const env = getEnv()
    const devOnlyCOBtnClassName =
        env !== 'production' ? 'col-span-4' : 'col-span-6'
    const debounceTime: number = 1200
    let tampungMessagetoast: IToastProps = toastInitialValue
    const currencyCode = user.userDefaultData.currency.code
    const countryCode = user.userDefaultData.country.code
    const { DataCategory, ...financeFilter } = formikFilterCO.values

    // function & variable common
    const buttonContentCO: IButtonContentCO[] = [
        {
            onClick: () => onClickButtonContent('overview'),
            label: 'OVERVIEW',
            icon: 'ri-pages-line',
            className: 'col-span-6',
        },
        {
            onClick: () => onClickButtonContent('tradeLanes'),
            label: 'trade lanes',
            icon: 'ri-share-line',
            useUpperCase: true,
            className: 'col-span-6',
        },

        {
            onClick: () => onClickButtonContent('customers'),
            label: 'customers',
            icon: 'ri-user-heart-line',
            useUpperCase: true,
            className: `${devOnlyCOBtnClassName}`,
        },
        {
            onClick: () => onClickButtonContent('sales'),
            label: 'sales',
            icon: 'ri-money-cny-box-line',
            useUpperCase: true,
            className: `${devOnlyCOBtnClassName} !pl-0`,
        },
        {
            onClick: () => onClickButtonContent('finance'),
            label: 'finance',
            icon: 'ri-book-2-line',
            useUpperCase: true,
            className: `${devOnlyCOBtnClassName}`,
            devOnly: true,
        },
    ]
    const onClickButtonContent = (content: IButtonContentItems) => {
        // update filter dateType
        const dateTypeFilter: string =
            content !== 'finance' ? 'eta' : 'accounting period'
        formikFilterCO.setFieldValue('ShipmentDateBy', dateTypeFilter)

        setButtonContent(content)
    }
    const wrapFetchDataContent = async () => {
        // check error filter
        if (Object.keys(formikFilterCO.errors).length !== 0) {
            Toast({
                header: 'Improper Filter Selection!',
                message: formikFilterCO.errors.EndDate || '',
                type: 'error',
            })
            return
        }
        // reset pagination medium table
        if (mediumTable.table.filterPageNumber !== 1) {
            handlingPageNumberMediumTable(1)
        }

        // reset pagination small table
        if (smallTable.filterPageNumber !== 1) {
            handlingPageNumberSmallTable(1)
        }

        switch (buttonContent) {
            case 'overview':
                fechtOverviewData()
                break
            case 'tradeLanes':
                loadTradeLanesData()
                break
            case 'customers':
                loadCustomersData()
                break
            case 'sales':
                loadSalesData()
                break
            case 'finance':
                loadFinanceData()
                break
        }
    }
    const handlePillsDataCategory = (
        data: IPillItem<IFilterDataCategoryCO>,
    ) => {
        const dataCategory: IFilterDataCategoryCO =
            formikFilterCO.values.DataCategory

        // Set semua properti ke false
        Object.keys(dataCategory).forEach((key) => {
            dataCategory[key as keyof IFilterDataCategoryCO] = false
        })

        if (Object.prototype.hasOwnProperty.call(dataCategory, data.value)) {
            dataCategory[data.value] = true
        }

        formikFilterCO.setValues({
            ...formikFilterCO.values,
            DataCategory: dataCategory,
        })
    }
    const handlePillsShipmentEvent = (
        data: IPillItem<IFilterShipmentEventCO>,
    ) => {
        const shipmentEvent = formikFilterCO.values.ShipmentEvent

        if (Object.prototype.hasOwnProperty.call(shipmentEvent, data.value)) {
            shipmentEvent[data.value] = data.isActive || false
        }

        formikFilterCO.setValues({
            ...formikFilterCO.values,
            ShipmentEvent: shipmentEvent,
        })
    }
    const wrapFetchHistoryData = async (name: string) => {
        if (!name) {
            loadEmpityHistorySplitRecord()
            return
        }
        switch (buttonContent) {
            case 'overview':
                loadOverviewHistory(name)
                break
            case 'tradeLanes':
                loadTradeLanesHistory(name)
                break
            case 'customers':
                loadCustomersHistory(name)
                break
            case 'sales':
                loadSalesHistory(name)
                break
            case 'finance':
                loadFinanceHistory(name)
                break
            default:
                loadEmpityHistorySplitRecord()
        }
    }
    const loadEmpityHistorySplitRecord = async () => {
        // history development
        setHistoryDevelopmentData(null)

        // history split record
        const barChartSplitRecord: IBarchartStacktedDataItem[] = []
        dispatch(
            setHistorySplitRecord({
                chartData: barChartSplitRecord,
                infoCardGroupItems: historySplitRecordSummaryIntialvalue,
            }),
        )
        setLoadingHistory({
            splitRecord: false,
            development: false,
        })
    }
    const handleLoadHistoryData = (name: string) => {
        handleLoadingHistory()
        const handleDebounce = debounceVoid(() => {
            wrapFetchHistoryData(name)
        }, debounceTime)

        handleDebounce()
    }
    const handlingLoadingContent = () => {
        // load content history
        handleLoadingHistory()

        // load content now
        switch (buttonContent) {
            case 'overview':
                setLoadingRelatedKeyFigures(true)
                setLoadingSmallTable(true)
                break
            case 'tradeLanes':
                setLoadingSmallTable(true)
                setLoadinTradeLanes({
                    region: true,
                    carrier: true,
                })
                break
            case 'customers':
            case 'sales':
                setLoadingMediumTable(true)
                break
            case 'finance':
                setLoadingFinance({
                    averageMonthlyPerformance: true,
                    openInvoice: true,
                    table: true,
                })
                break
        }
    }
    const handleLoadingHistory = (value: boolean = true) => {
        setLoadingHistory({
            development: value,
            splitRecord: value,
        })
    }
    const getActiveFieldFilterDataCategory = () => {
        const activeFilter = Object.entries(
            formikFilterCO.values.DataCategory,
        ).find(([key, value]) => value === true)

        if (!activeFilter) {
            throw new Error('No active filter found')
        }

        const [key] = activeFilter

        // Map key filter ke nama field di items
        const fieldMapping: Record<
            string,
            {
                key: ICOFilterByMetrics
                label: string
                infoCardSplitRecordKey: string
            }
        > = {
            Shipments: {
                key: 'shipment',
                label: 'Shipments',
                infoCardSplitRecordKey: 'avgCurrentShipment',
            },
            Revenue: {
                key: 'revenue',
                label: 'Revenue',
                infoCardSplitRecordKey: 'avgCurrentRevenue',
            },
            GrossProfit: {
                key: 'gp',
                label: 'Gross Profit',
                infoCardSplitRecordKey: 'avgCurrentGp',
            },
            Volume: {
                key: 'volume',
                label: 'Volume',
                infoCardSplitRecordKey: 'avgCurrentVolume',
            },
        }

        const fieldName = fieldMapping[key]

        if (!fieldName) {
            throw new Error(`Field mapping not found for key: ${key}`)
        }

        return fieldName
    }
    const loadFilterDropdownOption = async () => {
        try {
            let filterOptions: IFilterDropdownOptions = {
                transportMode: [],
                containerMode: [],
                company: [{ label: 'All Company', value: 'all' }],
            }

            const company = fetchLoglinesMetaData<IRLoglineMetaBranchData[]>(
                ifbEndpoints.loglines_meta_data_Branch,
            )
                .then((res) => {
                    filterOptions.company = res?.data?.map((item, index) => ({
                        label: index === 0 ? 'All Company' : item.company,
                        value: index === 0 ? 'all' : item.company,
                    }))
                })
                .catch((error) => {
                    console.error('Error fetching data:', error)
                })

            await Promise.allSettled([company])

            filterOptions.transportMode = loadTransportModeDropdown(
                formikFilterCO.values.ContainerMode,
            )
            filterOptions.containerMode = loadContainerModeDropdown(
                formikFilterCO.values.TransportMode,
            )

            dispatch(setFilterDropdownOptions(filterOptions))
        } catch (error) {
            console.error(error)
        }
    }
    const loadContainerModeDropdownOption = () => {
        if (!formikFilterCO.values.TransportMode) {
            const containerModeOptions = loadContainerModeDropdown('all')
            formikFilterCO.setFieldValue('ContainerMode', 'all')

            setFilterDropdownOptions({
                ...filterDropdownOptions,
                containerMode: containerModeOptions,
            })
        } else {
            const containerModeOptions = loadContainerModeDropdown(
                formikFilterCO?.values?.TransportMode,
            )

            // check option dropdown container mode
            const isExists = containerModeOptions.some(
                (item) => item.value === formikFilterCO.values.ContainerMode,
            )
            if (!isExists)
                formikFilterCO.setFieldValue(
                    'ContainerMode',
                    containerModeOptions[0]?.value,
                )

            dispatch(
                setFilterDropdownOptions({
                    ...filterDropdownOptions,
                    containerMode: containerModeOptions,
                }),
            )
        }
    }
    const loadTransportModeDropdownOption = () => {
        if (!formikFilterCO.values.ContainerMode) {
            const transportModeOptions = loadTransportModeDropdown('all')
            formikFilterCO.setFieldValue('TransportMode', 'all')
            setFilterDropdownOptions({
                ...filterDropdownOptions,
                transportMode: transportModeOptions,
            })
        } else {
            const transportModeOptions = loadTransportModeDropdown(
                formikFilterCO?.values?.ContainerMode,
            )

            // check option dropdown transport mode
            const isExists = transportModeOptions.some(
                (item) => item.value === formikFilterCO.values.TransportMode,
            )
            if (!isExists)
                formikFilterCO.setFieldValue(
                    'TransportMode',
                    transportModeOptions[0]?.value,
                )

            dispatch(
                setFilterDropdownOptions({
                    ...filterDropdownOptions,
                    transportMode: transportModeOptions,
                }),
            )
        }
    }
    const setHistoryDevelopmentData = (
        data: IHistoryDevelopmentData | null,
    ) => {
        const start = moment(formikFilterCO.values.StartDate, 'MM/YYYY')
        const end = moment(formikFilterCO.values.EndDate, 'MM/YYYY')

        const rangeMonth: string[] = []
        const line1 = []
        const line2 = []
        let index = 1

        const fieldName = getActiveFieldFilterDataCategory()
        try {
            while (start.isSameOrBefore(end, 'month')) {
                const month = start.format('MM')
                const year = start.format('YYYY')
                let currentValue = undefined
                let previousValue = undefined

                if (data !== null) {
                    const findDataByMonthYear = data?.graphData?.find(
                        (item) =>
                            item?.month?.padStart(2, '0') === month &&
                            item?.year === year,
                    )
                    currentValue =
                        findDataByMonthYear && findDataByMonthYear?.current
                            ? findDataByMonthYear?.current[
                                  fieldName?.key as keyof typeof findDataByMonthYear.current
                              ]
                            : undefined
                    previousValue =
                        findDataByMonthYear && findDataByMonthYear?.previous
                            ? findDataByMonthYear?.previous[
                                  fieldName?.key as keyof typeof findDataByMonthYear.previous
                              ]
                            : undefined
                }

                rangeMonth.push(start.format('MMM'))
                line1.push({
                    x: index,
                    secName: start.format('YYYY'),
                    y: Number(currentValue) || 0,
                    keyId: 'line1',
                    customLabelTooltip: handleFormatValueNumber(
                        currentValue || 0,
                    ),
                })
                line2.push({
                    x: index,
                    secName: start.format('YYYY'),
                    y: Number(previousValue) || 0,
                    keyId: 'line2',
                    customLabelTooltip: handleFormatValueNumber(
                        previousValue || 0,
                    ),
                })

                start.add(1, 'month')
                index++
            }

            const valueInfoCard = data?.averageInfo
                ? data?.averageInfo[
                      fieldName.infoCardSplitRecordKey as keyof typeof data.averageInfo
                  ]
                : 0

            const infoCardItems = [
                {
                    value: handleFormatValueNumber(valueInfoCard),
                    label: `Average ${fieldName.label}`,
                },
            ]

            dispatch(
                setHistoryDevelopment({
                    line1,
                    line2,
                    tickItems: rangeMonth,
                    infoCardItems,
                }),
            )
        } catch (error) {
            console.error(error)
        }
    }
    const loadMediumTableUseRawData = () => {
        setLoadMediumTable(rawMediumTable)
    }
    const loadSmallTableUseRawData = () => {
        setLoadSmallTable(rawSmallTable, getActiveFieldFilterDataCategory().key)
    }
    const handlingPageNumberMediumTable = (pageNumber: number) => {
        if (pageNumber <= 0) return
        dispatch(setPageNumberMediumTable2Slice(pageNumber))
    }
    const loadOptionsFilterDropdown = debounce(
        async ({ containerMode, company }: ILoadOptionsFilterDropdownProps) => {
            try {
                let options: IDropdownItem[] = []
                let loadType

                switch (true) {
                    case !!containerMode: {
                        const fetchData: IResponseData<
                            IRLoglineMetaContainerModeData[]
                        > = await fetchLoglinesMetaData(
                            ifbEndpoints.loglines_meta_data_ContainerMode,
                            {
                                container: containerMode,
                            },
                        )
                        options = fetchData?.data?.map((item) => ({
                            label: `${item.code} - ${item.description}`,
                            value: item.code,
                        }))
                        loadType = 'containerMode'
                        break
                    }
                    case !!company: {
                        const fetchData: IResponseData<
                            IRLoglineMetaBranchData[]
                        > = await fetchLoglinesMetaData(
                            ifbEndpoints.loglines_meta_data_Branch,
                            {
                                branch: company,
                            },
                        )
                        options = fetchData?.data?.map((item) => ({
                            label: item.company,
                            value: item.company,
                        }))
                        loadType = 'company'
                        break
                    }
                    default:
                        break
                }
                if (options.length && loadType) {
                    dispatch(
                        setLoadOptionsFilterDropdown({
                            options: options,
                            loadType,
                        }),
                    )
                }
                return options
            } catch (error) {
                console.error(error)
            }
        },
        500,
    )
    const handleFormatValueNumber = (
        value: number | string,
        useCurrencyCode?: boolean,
    ) => {
        const count = customRounding({
            value,
            useDecimalPoint: formikFilterCO.values.DataCategory.Shipments
                ? false
                : true,
        })

        const useCurrencyValue =
            useCurrencyCode &&
            (formikFilterCO.values.DataCategory.GrossProfit ||
                formikFilterCO.values.DataCategory.Revenue)
                ? `${user?.userDefaultData?.currency?.code} ${count}`
                : count

        return useCurrencyValue
    }
    const handlingPageNumberSmallTable = (pageNumber: number) => {
        if (pageNumber <= 0) return
        dispatch(setPageNumberSmallTable2Slice(pageNumber))
    }
    const setLoadSmallTable = async (
        data: IRSmallTable[],
        filter: ICOFilterByMetrics,
    ) => {
        const { meta, filterPageNumber } = smallTable

        const loadMeta = createMetaData({
            meta: meta,
            lengthData: data.length,
            itemPerPage: 50,
            pageNumber: filterPageNumber,
        })
        const filterData: ISmallTableData[] = data
            ?.map((item) => {
                return {
                    name: item.name,
                    thisPeriod: handleFormatValueNumber(
                        item?.current[filter] || 0,
                        true,
                    ),
                    lastPeriod: handleFormatValueNumber(
                        item?.previous[filter] || 0,
                        true,
                    ),
                    change: {
                        value: handleFormatValueNumber(
                            item?.changes[filter]?.value,
                        ),
                        percentage: handleFormatValueNumber(
                            item.changes[filter]?.percentage,
                        ),
                    },
                    code: item.code,
                }
            })
            .slice(loadMeta.index_start - 1, loadMeta.index_end)

        dispatch(
            setSmallTableData({
                data: filterData,
                meta: { ...meta, ...loadMeta },
            }),
        )

        await wrapFetchHistoryData(filterData[0]?.code ? filterData[0]?.code : filterData[0]?.name)
        setLoadingSmallTable(false)
    }
    const setLoadMediumTable = async (data: IMediumTableData[]) => {
        const { meta, filterPageNumber } = mediumTable.table

        const loadMeta = createMetaData({
            meta: meta,
            lengthData: data.length,
            itemPerPage: 50,
            pageNumber: filterPageNumber,
        })

        const filterData: IMediumTableData[] = data.slice(
            loadMeta.index_start - 1,
            loadMeta.index_end,
        )

        dispatch(
            setMediumTableData({
                data: filterData,
                meta: { ...meta, ...loadMeta },
            }),
        )

        await wrapFetchHistoryData(filterData[0]?.name || '')

        setLoadingMediumTable(false)
    }
    const callToastError = () => {
        if (!tampungMessagetoast.header) return

        Toast(tampungMessagetoast)

        // reset toast
        tampungMessagetoast.header = ''
        tampungMessagetoast.message = ''
    }

    // function overview
    const fechtOverviewData = async () => {
        try {
            const relatedKeyFigures =
                await fetchControlTowerData<IRelatedKeyFiguresResponse2>(
                    formikFilterCO.values,
                    ifbEndpoints.control_tower_RelatedKeyFigure,
                    {
                        currencyCode: user?.userDefaultData?.currency?.code,
                        countryCode: user?.userDefaultData?.country?.code,
                    },
                )
                    .then((res) => {
                        let mappingData: IDataOverviewTable1[] = []
                        if (res.isSuccess) {
                            const calculatePercentage = ({
                                lastPeriod = 0,
                                thisPeriod = 0,
                            }: {
                                lastPeriod: number
                                thisPeriod: number
                            }) => {
                                let value
                                if (
                                    !lastPeriod ||
                                    (!lastPeriod && !thisPeriod)
                                ) {
                                    value = 0
                                } else {
                                    value =
                                        ((thisPeriod - lastPeriod) * 100) /
                                        (thisPeriod + lastPeriod)
                                }

                                return customRounding({
                                    value,
                                    useCheckDecimal: true,
                                })
                            }

                            mappingData = [
                                {
                                    item: 'Revenue',
                                    thisPeriod: `${currencyCode} ${customRounding(
                                        {
                                            value: res.data.current.revenue,
                                            useDecimalPoint: true,
                                        },
                                    )}`,
                                    lastPeriod: `${currencyCode} ${customRounding(
                                        {
                                            value: res.data.previous.revenue,
                                            useDecimalPoint: true,
                                        },
                                    )}`,
                                    change: {
                                        amount: res.data.change.revenue,
                                        labelValue: `${currencyCode} ${customRounding(
                                            {
                                                value: res.data.change.revenue,
                                                useDecimalPoint: true,
                                            },
                                        )} (${calculatePercentage({ thisPeriod: res.data.current.revenue, lastPeriod: res.data.previous.revenue })}%)`,
                                    },
                                },
                                {
                                    item: 'GP',
                                    thisPeriod: `${currencyCode} ${customRounding(
                                        {
                                            value: res.data.current.gp,
                                            useDecimalPoint: true,
                                        },
                                    )}`,
                                    lastPeriod: `${currencyCode} ${customRounding(
                                        {
                                            value: res.data.previous.gp,
                                            useDecimalPoint: true,
                                        },
                                    )}`,
                                    change: {
                                        amount: res.data.change.gp,
                                        labelValue: `${currencyCode} ${customRounding(
                                            {
                                                value: res.data.change.gp,
                                                useDecimalPoint: true,
                                            },
                                        )} (${calculatePercentage({ thisPeriod: res.data.current.gp, lastPeriod: res.data.previous.gp })}%)`,
                                    },
                                },
                                {
                                    item: 'GP Margin',
                                    thisPeriod: `${handleFormatValueNumber(
                                        res.data.current.gpMargin,
                                    )}%`,
                                    lastPeriod: `${handleFormatValueNumber(
                                        res.data.previous.gpMargin,
                                    )}%`,
                                    change: {
                                        amount: res.data.change.gpMargin,
                                        labelValue: `${handleFormatValueNumber(
                                            res.data.change.gpMargin,
                                        )}%`,
                                    },
                                },
                                {
                                    item: 'No. of Shipments',
                                    thisPeriod: `${customRounding({
                                        value: res.data.current.shipment,
                                    })}`,
                                    lastPeriod: `${customRounding({
                                        value: res.data.previous.shipment,
                                    })}`,
                                    change: {
                                        amount: res.data.change.shipment,
                                        labelValue: `${customRounding({
                                            value: res.data.change.shipment,
                                        })} (${calculatePercentage({ thisPeriod: res.data.current.shipment, lastPeriod: res.data.previous.shipment })}%)`,
                                    },
                                },
                                {
                                    item: 'Volume Sea FCL',
                                    thisPeriod: `${customRounding({
                                        value: res.data.current.volumeSeaFCL,
                                        useDecimalPoint: true,
                                    })} TEU`,
                                    lastPeriod: `${customRounding({
                                        value: res.data.previous.volumeSeaFCL,
                                        useDecimalPoint: true,
                                    })} TEU`,
                                    change: {
                                        amount: res.data.change.volumeSeaFCL,
                                        labelValue: `${customRounding({
                                            value: res.data.change.volumeSeaFCL,
                                            useDecimalPoint: true,
                                        })} TEU (${calculatePercentage({ thisPeriod: res.data.current.volumeSeaFCL, lastPeriod: res.data.previous.volumeSeaFCL })}%)`,
                                    },
                                },
                                {
                                    item: 'Volume Sea LCL',
                                    thisPeriod: `${customRounding({
                                        value: res.data.current.volumeSeaLCL,
                                        useDecimalPoint: true,
                                    })} M3`,
                                    lastPeriod: `${customRounding({
                                        value: res.data.previous.volumeSeaLCL,
                                        useDecimalPoint: true,
                                    })} M3`,
                                    change: {
                                        amount: res.data.change.volumeSeaLCL,
                                        labelValue: `${customRounding({
                                            value: res.data.change.volumeSeaLCL,
                                            useDecimalPoint: true,
                                        })} M3 (${calculatePercentage({ thisPeriod: res.data.current.volumeSeaLCL, lastPeriod: res.data.previous.volumeSeaLCL })}%)`,
                                    },
                                },
                                {
                                    item: 'Volume Rail FCL',
                                    thisPeriod: `${customRounding({
                                        value: res.data.current.volumeRaiFCL,
                                        useDecimalPoint: true,
                                    })} TEU`,
                                    lastPeriod: `${customRounding({
                                        value: res.data.previous.volumeRaiFCL,
                                        useDecimalPoint: true,
                                    })} TEU`,
                                    change: {
                                        amount: res.data.change.volumeRaiFCL,
                                        labelValue: `${customRounding({
                                            value: res.data.change.volumeRaiFCL,
                                            useDecimalPoint: true,
                                        })} TEU (${calculatePercentage({ thisPeriod: res.data.current.volumeRaiFCL, lastPeriod: res.data.previous.volumeRaiFCL })}%)`,
                                    },
                                },
                                {
                                    item: 'Volume Rail LCL',
                                    thisPeriod: `${customRounding({
                                        value: res.data.current.volumeRaiLCL,
                                        useDecimalPoint: true,
                                    })} M3`,
                                    lastPeriod: `${customRounding({
                                        value: res.data.previous.volumeRaiLCL,
                                        useDecimalPoint: true,
                                    })} M3`,
                                    change: {
                                        amount: res.data.change.volumeRaiLCL,
                                        labelValue: `${customRounding({
                                            value: res.data.change.volumeRaiLCL,
                                            useDecimalPoint: true,
                                        })} M3 (${calculatePercentage({ thisPeriod: res.data.current.volumeRaiLCL, lastPeriod: res.data.previous.volumeRaiLCL })}%)`,
                                    },
                                },
                                {
                                    item: 'Volume Road',
                                    thisPeriod: `${customRounding({
                                        value: res.data.current.volumeRoad,
                                        useDecimalPoint: true,
                                    })} M3`,
                                    lastPeriod: `${customRounding({
                                        value: res.data.previous.volumeRoad,
                                        useDecimalPoint: true,
                                    })} M3`,
                                    change: {
                                        amount: res.data.change.volumeRoad,
                                        labelValue: `${customRounding({
                                            value: res.data.change.volumeRoad,
                                            useDecimalPoint: true,
                                        })} M3 (${calculatePercentage({ thisPeriod: res.data.current.volumeRoad, lastPeriod: res.data.previous.volumeRoad })}%)`,
                                    },
                                },
                                {
                                    item: 'Weight Air',
                                    thisPeriod: `${customRounding({
                                        value: res.data.current.weightAir,
                                        useDecimalPoint: true,
                                    })} KGs`,
                                    lastPeriod: `${customRounding({
                                        value: res.data.previous.weightAir,
                                        useDecimalPoint: true,
                                    })} KGs`,
                                    change: {
                                        amount: res.data.change.weightAir,
                                        labelValue: `${customRounding({
                                            value: res.data.change.weightAir,
                                            useDecimalPoint: true,
                                        })} KGs (${calculatePercentage({ thisPeriod: res.data.current.weightAir, lastPeriod: res.data.previous.weightAir })}%)`,
                                    },
                                },
                            ]
                        } else {
                            tampungMessagetoast.message = res.message
                            tampungMessagetoast.header = 'Related Key Figures'
                        }
                        dispatch(setDataOverviewTable1(mappingData))
                    })
                    .catch((error) => {
                        console.error(
                            'Error fetching data related key figures:',
                            error,
                        )
                        tampungMessagetoast.message = error.message
                        tampungMessagetoast.header = 'Related Key Figures'
                    })
                    .finally(() => {
                        setLoadingRelatedKeyFigures(false)
                    })

            const tableOverview2 = loadOverviewTable2()
            await Promise.allSettled([relatedKeyFigures, tableOverview2])
        } catch (error) {
            console.error('Error fetching data:', error)
        } finally {
            callToastError()
        }
    }
    const loadOverviewTable2 = async () => {
        try {
            const loadData = await fetchControlTowerData<any[]>(
                formikFilterCO.values,
                ifbEndpoints.control_tower_Overview + tabFilterOverview.value,
                { countryCode, currency: currencyCode },
            )
            const fieldName = getActiveFieldFilterDataCategory()

            let tableData: IRSmallTable[] = []
            if (!loadData.isSuccess) {
                tampungMessagetoast.message = loadData.message
                tampungMessagetoast.header =
                    'Table Tab ' + tabFilterOverview.value
            } else {
                if (tabFilterOverview.value === 'Branch') {
                    const branchData =
                        loadData.data as unknown as IROverviewTableBranch[]

                    tableData = branchData?.map((item) => ({
                        name: item.branch.name,
                        code:item.branch.code,
                        current: item.current,
                        previous: item.previous,
                        changes: {
                            shipment: {
                                value: item.changes.shipment.value,
                                percentage: item.changes.shipment.percent,
                            },
                            revenue: {
                                value: item.changes.revenue.value,
                                percentage: item.changes.revenue.percent,
                            },
                            volume: {
                                value: item.changes.volume.value,
                                percentage: item.changes.volume.percent,
                            },
                            gp: {
                                value: item.changes.gp.value,
                                percentage: item.changes.gp.percent,
                            },
                        },
                    }))
                } else {
                    tableData = loadData.data as IRSmallTable[]
                }
            }

            setLoadSmallTable(tableData, fieldName.key)
            setRawSmallTable(tableData)

            if (!tableData?.length) {
                handleLoadingHistory(false)
            }
        } catch (error) {
            console.error('Error fetching data:', error)
            tampungMessagetoast.message = (error as Error).message
            tampungMessagetoast.header = 'Table Tab ' + +tabFilterOverview.value
            setLoadingSmallTable(false)
        }
    }
    const loadOverviewHistory = async (name: string) => {
        let developmentParams = {
            endpoint: '',
            customField: {},
        }
        let splitRecordParams = {
            endpoint: '',
            customField: {},
        }

        try {
            switch (tabFilterOverview.value) {
                case 'Customer':
                    developmentParams = {
                        customField: {
                            companyName: name,
                            currency: currencyCode,
                        },
                        endpoint:
                            ifbEndpoints.control_tower_CustomerHistoryDevelopmnent,
                    }
                    splitRecordParams = {
                        customField: { custName: name, currency: currencyCode },
                        endpoint:
                            ifbEndpoints.control_tower_CustomerHistorySplitRecord,
                    }
                    break
                case 'Branch':
                    developmentParams = {
                        customField: {
                            branchName: name,
                            currency: currencyCode,
                        },
                        endpoint:
                            ifbEndpoints.control_tower_BranchHistoryDevelopmnent,
                    }
                    splitRecordParams = {
                        customField: {
                            branchName: name,
                            currency: currencyCode,
                        },
                        endpoint:
                            ifbEndpoints.control_tower_BranchHistorySplitRecord,
                    }
                    break
                case 'Agent':
                    developmentParams = {
                        customField: {
                            agentName: name,
                            currency: currencyCode,
                        },
                        endpoint:
                            ifbEndpoints.control_tower_AgentHistoryDevelopmnent,
                    }
                    splitRecordParams = {
                        customField: {
                            agentName: name,
                            currency: currencyCode,
                        },
                        endpoint:
                            ifbEndpoints.control_tower_AgentHistorySplitRecord,
                    }
                    break
            }

            const historyDevelopment =
                fetchControlTowerData<IRHistoryDevelopment>(
                    formikFilterCO.values,
                    developmentParams.endpoint,
                    {
                        ...developmentParams.customField,
                        countryCode: user.userDefaultData?.country.code || '',
                    },
                )
                    .then(({ data, isSuccess, message }) => {
                        let flattenedData = initialDataHistoryDevelopment

                        if (!isSuccess) {
                            tampungMessagetoast.message = message
                            tampungMessagetoast.header = 'History Development'
                        } else {
                            flattenedData.graphData = data?.current?.items
                                .length
                                ? data.current.items.map((item) => {
                                      const [year, month] =
                                          item.label.split('-')
                                      const findPreviousDataByLabel =
                                          data?.previous?.items?.find(
                                              (previous) =>
                                                  previous.label === item.label,
                                          )
                                      let formatMonth = ''
                                      if (month) {
                                          formatMonth =
                                              parseFloat(month) < 10
                                                  ? `0${month}`
                                                  : month
                                      }
                                      return {
                                          year: year,
                                          month: formatMonth,
                                          current: {
                                              shipment: item.shipment || 0,
                                              revenue: item.revenue || 0,
                                              volume: item.volume || 0,
                                              gp: item.gp || 0,
                                          },
                                          previous: {
                                              shipment:
                                                  findPreviousDataByLabel?.shipment ||
                                                  0,
                                              revenue:
                                                  findPreviousDataByLabel?.revenue ||
                                                  0,
                                              volume:
                                                  findPreviousDataByLabel?.volume ||
                                                  0,
                                              gp:
                                                  findPreviousDataByLabel?.gp ||
                                                  0,
                                          },
                                      }
                                  })
                                : initialDataHistoryDevelopment.graphData

                            flattenedData.averageInfo = {
                                avgCurrentRevenue: data?.avgRevenue || 0,
                                avgCurrentGp: data?.avgGp || 0,
                                avgCurrentShipment: data?.avgShipment || 0,
                                avgCurrentVolume: data?.avgVolume || 0,
                            }
                        }

                        setHistoryDevelopmentData(flattenedData)
                    })
                    .catch((error) => {
                        console.error('Error fetching data:', error)
                        tampungMessagetoast.message = error.message
                        tampungMessagetoast.header = 'History Development'
                    })
                    .finally(() => {
                        setLoadingHistory((prevState) => ({
                            ...prevState,
                            development: false,
                        }))
                    })

            const historySplitRecord =
                fetchControlTowerData<IROverviewSplitRecord>(
                    formikFilterCO.values,
                    splitRecordParams.endpoint,
                    {
                        ...splitRecordParams.customField,
                        countryCode: user.userDefaultData?.country.code || '',
                    },
                )
                    .then(({ data, message, isSuccess }) => {
                        const fieldName = getActiveFieldFilterDataCategory()

                        let chartData: IBarchartStacktedDataItem[] =
                            historySplitRecordChartDataIntialValue
                        let summaryData: InfoCardGroupItems[] =
                            historySplitRecordSummaryIntialvalue

                        if (!isSuccess) {
                            tampungMessagetoast.message = message
                            tampungMessagetoast.header = 'History Development'
                        } else {
                            const fieldMap = {
                                'This Period': 'Current',
                                'Last Period': 'Previous',
                            }

                            chartData = !data?.datas.length
                                ? []
                                : historySplitRecordChartDataIntialValue?.map(
                                      (item) => {
                                          const getFieldMapData =
                                              fieldMap[
                                                  item.name as keyof typeof fieldMap
                                              ]
                                          const findDataByPeriod =
                                              data?.datas?.find(
                                                  (fetchItem) =>
                                                      fetchItem?.period?.toLocaleLowerCase() ===
                                                      getFieldMapData?.toLocaleLowerCase(),
                                              )
                                          const nominationData =
                                              findDataByPeriod?.items?.find(
                                                  (subItem) =>
                                                      subItem?.category?.toLocaleLowerCase() ===
                                                      'nominator',
                                              )
                                          const localControlData =
                                              findDataByPeriod?.items?.find(
                                                  (subItem) =>
                                                      subItem?.category?.toLocaleLowerCase() ===
                                                      'local control',
                                              )
                                          return {
                                              ...item,
                                              f1: nominationData
                                                  ? Number(
                                                        nominationData[
                                                            fieldName.key as keyof typeof nominationData
                                                        ],
                                                    )
                                                  : 0,
                                              f2: localControlData
                                                  ? Number(
                                                        localControlData[
                                                            fieldName.key as keyof typeof localControlData
                                                        ],
                                                    )
                                                  : 0,
                                          }
                                      },
                                  )

                            summaryData =
                                historySplitRecordSummaryIntialvalue.map(
                                    (item) => ({
                                        ...item,
                                        value: handleFormatValueNumber(
                                            Number(item.value),
                                        ),
                                    }),
                                )
                        }
                        dispatch(
                            setHistorySplitRecord({
                                chartData,
                                infoCardGroupItems: summaryData,
                            }),
                        )
                    })
                    .catch((error) => {
                        console.error('Error fetching data:', error)
                        tampungMessagetoast.message = error.message
                        tampungMessagetoast.header = 'History Development'
                    })
                    .finally(() => {
                        setLoadingHistory((prevState) => ({
                            ...prevState,
                            splitRecord: false,
                        }))
                    })

            await Promise.allSettled([historyDevelopment, historySplitRecord])
        } catch (error) {
            console.error(error)
        } finally {
            setLoadingHistory({
                splitRecord: false,
                development: false,
            })
            callToastError()
        }
    }

    // function trade lanes
    const loadTradeLanesData = async () => {
        try {
            const tradeLanesRegion = fetchControlTowerData<IRTradeLanesRegion>(
                formikFilterCO.values,
                ifbEndpoints.control_tower_TrandeLanesRegion,
                {
                    countryCode: user?.userDefaultData?.country?.code,
                    currencyCode,
                },
            )
                .then((res) => {
                    let regionData = null
                    if (res.isSuccess) {
                        regionData = res.data
                    } else {
                        tampungMessagetoast.message = res.message
                        tampungMessagetoast.header = 'Region'
                    }
                    dispatch(
                        setTradeLanesRegionData({
                            data: regionData,
                            filter: formikFilterCO.values.DataCategory,
                        }),
                    )
                })
                .catch((error) => {
                    console.error('Error fetching data:', error)
                    tampungMessagetoast.message = error.message
                    tampungMessagetoast.header = 'Region'
                })
                .finally(() => {
                    setLoadinTradeLanes((prevLoading) => ({
                        ...prevLoading,
                        region: false,
                    }))
                })

            const tradeLanesCarrier =
                fetchControlTowerData<IRTradeLanesCarrier>(
                    formikFilterCO.values,
                    ifbEndpoints.control_tower_TrandeLanesCarrier,
                    {
                        countryCode: user?.userDefaultData?.country?.code,
                        currencyCode,
                    },
                )
                    .then((res) => {
                        let carrierData = null
                        if (res.isSuccess) {
                            carrierData = res.data
                        } else {
                            tampungMessagetoast.message = res.message
                            tampungMessagetoast.header = 'Carrier'
                        }

                        dispatch(
                            setTradeLanesCarrierData({
                                data: carrierData,
                                filter: formikFilterCO.values.DataCategory,
                            }),
                        )
                    })
                    .catch((error) => {
                        console.error('Error fetching data:', error)
                        tampungMessagetoast.message = error.message
                        tampungMessagetoast.header = 'Carrier'
                    })
                    .finally(() => {
                        setLoadinTradeLanes((prevLoading) => ({
                            ...prevLoading,
                            carrier: false,
                        }))
                    })

            const tabletradeLanes = loadTradeLanesTable()

            await Promise.allSettled([
                tradeLanesRegion,
                tradeLanesCarrier,
                tabletradeLanes,
            ])
        } catch (error) {
            console.error('Error fetching data:', error)
        } finally {
            callToastError()
        }
    }
    const loadTradeLanesHistory = async (name: string) => {
        let developmentParams: {
            endpoint: string
            field: {}
        } | null = {
            endpoint: '',
            field: {},
        }
        let splitRecordParams = {
            endpoint: '',
            field: {},
        }

        try {
            switch (tabFilterTradeLanes.value) {
                case 'Countries':
                    if (name) {
                        developmentParams = {
                            endpoint:
                                ifbEndpoints.control_tower_TrandeLanesCountriesDevelopment,
                            field: {
                                countryName: name,
                            },
                        }
                        splitRecordParams = {
                            endpoint:
                                ifbEndpoints.control_tower_TradeLanesCountrySplitRecord,
                            field: { countryName: name },
                        }
                    }

                    break
                case 'Ports':
                    if (name) {
                        developmentParams = {
                            endpoint:
                                ifbEndpoints.control_tower_TrandeLanesPortDevelopment,
                            field: {
                                portName: name,
                            },
                        }
                        splitRecordParams = {
                            endpoint:
                                ifbEndpoints.control_tower_TradeLanesPortSplitRecord,
                            field: { portName: name },
                        }
                    }

                    break
                case 'Lanes':
                    developmentParams = {
                        endpoint:
                            ifbEndpoints.control_tower_TradeLaneLanesDevelopment,
                        field: { laneName: name },
                    }
                    splitRecordParams = {
                        endpoint:
                            ifbEndpoints.control_tower_TradeLaneLanesSplitRecord,
                        field: { regionName: name },
                    }
                    break
            }

            const historyDevelopment =
                developmentParams !== null
                    ? fetchControlTowerData<any>(
                          formikFilterCO.values,
                          developmentParams.endpoint,
                          {
                              ...developmentParams.field,
                              countryCode:
                                  user?.userDefaultData?.country?.code || '',
                              currencyCode,
                          },
                      )
                          .then((res) => {
                              let flattenedChartData = null
                              if (!res.isSuccess) {
                                  tampungMessagetoast.message = res.message
                                  tampungMessagetoast.header =
                                      'History Development'
                              } else {
                                  flattenedChartData = {
                                      averageInfo: res?.data.itemId,
                                      graphData: res?.data?.data,
                                  }
                              }
                              setHistoryDevelopmentData(flattenedChartData)
                          })
                          .catch((error) => {
                              console.error('Error fetching data:', error)
                              tampungMessagetoast.message = error.message
                              tampungMessagetoast.header = 'History Development'
                          })
                          .finally(() => {
                              setLoadingHistory((prevState) => ({
                                  ...prevState,
                                  development: false,
                              }))
                          })
                    : null

            const historySplitRecord =
                loadTradeLanesHistorySplitRecord(splitRecordParams)

            await Promise.allSettled([historyDevelopment, historySplitRecord])
        } catch (error) {
            console.error(error)
        } finally {
            setLoadingHistory({
                splitRecord: false,
                development: false,
            })
            callToastError()
        }
    }
    const loadTradeLanesTable = async () => {
        try {
            let endpoint: string = ''
            switch (tabFilterTradeLanes.value) {
                case 'Countries':
                    endpoint = ifbEndpoints.control_tower_TrandeLanesCountry
                    break
                case 'Ports':
                    endpoint = ifbEndpoints.control_tower_TrandeLanesPort
                    break
                case 'Lanes':
                    endpoint = ifbEndpoints.control_tower_TrandeLanesLane
            }

            const fetchData = await fetchControlTowerData<IRSmallTable[]>(
                formikFilterCO.values,
                endpoint,
                {
                    countryCode: user?.userDefaultData?.country?.code,
                    currencyCode,
                },
            )

            const fieldName = getActiveFieldFilterDataCategory()
            let tableData: IRSmallTable[] = []

            if (!fetchData?.isSuccess) {
                tampungMessagetoast.message = fetchData.message
                tampungMessagetoast.header = 'Table Trade Lanes'
            } else {
                tableData = fetchData?.data
            }

            setLoadSmallTable(tableData, fieldName.key)

            setRawSmallTable(tableData)
        } catch (error) {
            console.error(error)
            tampungMessagetoast.message = (error as Error).message
            tampungMessagetoast.header = 'Table Trade Lanes'
            setLoadingSmallTable(false)
        }
    }
    const loadTradeLanesHistorySplitRecord = async (params: {
        endpoint: string
        field: {}
    }) => {
        try {
            const fetchData =
                await fetchControlTowerData<IRTradeLanesHistorySplit>(
                    formikFilterCO.values,
                    params.endpoint,
                    {
                        ...params.field,
                        countryCode: user?.userDefaultData?.country?.code || '',
                        currencyCode,
                    },
                )

            const fieldName = getActiveFielTradeLanesdFilterDataCategory()
            let chartData: IBarchartStacktedDataItem[] =
                historySplitRecordChartDataIntialValue
            let summaryData: InfoCardGroupItems[] =
                historySplitRecordSummaryIntialvalue

            if (!fetchData.isSuccess) {
                tampungMessagetoast.message = fetchData.message
                tampungMessagetoast.header = 'History Split Record'
            } else {
                type IRTradeLanesHistorySplitKeys = keyof Pick<
                    IRTradeLanesHistorySplit,
                    'currentPeriod' | 'lastPeriod'
                >

                const fieldMap: Record<string, IRTradeLanesHistorySplitKeys> = {
                    'This Period': 'currentPeriod',
                    'Last Period': 'lastPeriod',
                }
                const summaryDataInitialValue = {
                    nomination: { currentPeriod: 0, lastPeriod: 0 },
                    localControl: { currentPeriod: 0, lastPeriod: 0 },
                }

                chartData = !fetchData?.data
                    ? []
                    : initialDataBarChartSplitRecord?.map((item) => {
                          const getFieldMapData = fieldMap[item.name]
                          const findDataByPeriod =
                              fetchData?.data[getFieldMapData]
                          const nominationData =
                              findDataByPeriod.nomination[
                                  fieldName.key as keyof typeof findDataByPeriod.nomination
                              ] || 0
                          const localControlData =
                              findDataByPeriod.localAgent[
                                  fieldName.key as keyof typeof findDataByPeriod.nomination
                              ] || 0

                          summaryDataInitialValue.nomination[getFieldMapData] =
                              nominationData
                          summaryDataInitialValue.localControl[
                              getFieldMapData
                          ] = localControlData

                          return {
                              ...item,
                              f1: nominationData,
                              f2: localControlData,
                          }
                      })

                const nominationValue = handleFormatValueNumber(
                    summaryDataInitialValue.nomination.currentPeriod +
                        summaryDataInitialValue.nomination.lastPeriod,
                )
                const localControlValue = handleFormatValueNumber(
                    summaryDataInitialValue.localControl.currentPeriod +
                        summaryDataInitialValue.localControl.lastPeriod,
                )

                summaryData = [
                    {
                        value: nominationValue.toString(),
                        label: 'Nomination',
                    },
                    {
                        value: localControlValue.toString(),
                        label: 'Local Control',
                    },
                ]
            }

            dispatch(
                setHistorySplitRecord({
                    chartData,
                    infoCardGroupItems: summaryData,
                }),
            )
        } catch (error) {
            console.error(error)
            tampungMessagetoast.message = (error as Error).message
            tampungMessagetoast.header = 'History Split Record'
        } finally {
            setLoadingHistory((prevState) => ({
                ...prevState,
                splitRecord: false,
            }))
        }
    }
    const getActiveFielTradeLanesdFilterDataCategory = () => {
        const activeFilter = Object.entries(
            formikFilterCO.values.DataCategory,
        ).find(([key, value]) => value === true)

        if (!activeFilter) {
            throw new Error('No active filter found')
        }

        const [key] = activeFilter

        // Map key filter ke nama field di items
        const fieldMapping: Record<
            string,
            {
                key: string
                label: string
                infoCardSplitRecordKey: string
            }
        > = {
            Shipments: {
                key: 'totalShipment',
                label: 'Shipments',
                infoCardSplitRecordKey: 'avgCurrentShipment',
            },
            Revenue: {
                key: 'totalRevenue',
                label: 'Revenue',
                infoCardSplitRecordKey: 'avgCurrentRevenue',
            },
            GrossProfit: {
                key: 'totalGP',
                label: 'Gross Profit',
                infoCardSplitRecordKey: 'avgCurrentGp',
            },
            Volume: {
                key: 'totalVolume',
                label: 'Volume',
                infoCardSplitRecordKey: 'avgCurrentVolume',
            },
        }

        const fieldName = fieldMapping[key]

        if (!fieldName) {
            throw new Error(`Field mapping not found for key: ${key}`)
        }

        return fieldName
    }

    // function customers
    const loadCustomersData = async () => {
        let flattenedMultipleBarsData: IProgressMultipleBarsData<IMarkShipmentCustomersAndSales> =
            multipleBarsMediumTableInitialValue
        let flattenedSummaryData: InfoCardGroupItems[] =
            summaryMediumTableIntialValue
        let flattenedTableData: IMediumTableData[] = []

        try {
            const fieldName = getActiveFieldFilterDataCategory()

            const fetchData =
                await fetchControlTowerData<IRCustomerOrganisationTable>(
                    formikFilterCO.values,
                    ifbEndpoints.control_tower_CustomerOrganisation,
                    {
                        currency: currencyCode,
                        countryCode,
                    },
                )

            const { charge, listOrganization } = fetchData.data

            // intial flattened data

            if (!fetchData.isSuccess) {
                tampungMessagetoast.message = fetchData.message
                tampungMessagetoast.header = 'Customer'
            } else {
                // flattened percentage data by fieldName.key
                const percentageData = listOrganization?.map((item) => {
                    return {
                        shipment: Number(
                            customRounding({
                                value: item?.shipmentPercentage || 0,
                                useCheckDecimal: true,
                            }),
                        ),
                        revenue: Number(
                            customRounding({
                                value: item.revenuePercentage,
                                useCheckDecimal: true,
                            }),
                        ),
                        volume: Number(
                            customRounding({
                                value: item.volumePercentage,
                                useCheckDecimal: true,
                            }),
                        ),
                        gp: Number(
                            customRounding({
                                value: item.gpPercentage,
                                useCheckDecimal: true,
                            }),
                        ),
                    }
                })

                if (percentageData.length)
                    flattenedMultipleBarsData = {
                        total: percentageData?.reduce(
                            (total, item) => total + item[fieldName.key] || 0,
                            0,
                        ),
                        items: {
                            value: {
                                blue: percentageData[0]
                                    ? percentageData[0][fieldName.key]
                                    : 0,
                                aqua: percentageData[1]
                                    ? percentageData[1][fieldName.key]
                                    : 0,
                                purple: percentageData[2]
                                    ? percentageData[2][fieldName.key]
                                    : 0,
                                orange: percentageData[3]
                                    ? percentageData[3][fieldName.key]
                                    : 0,
                                yellow: percentageData[4]
                                    ? percentageData[4][fieldName.key]
                                    : 0,
                                red: percentageData[5]
                                    ? percentageData[5][fieldName.key]
                                    : 0,
                                gray: percentageData[6]
                                    ? percentageData
                                          .slice(6)
                                          .reduce(
                                              (sum, item) =>
                                                  sum +
                                                  (item[fieldName.key] || 0),
                                              0,
                                          )
                                    : 0,
                            },
                        },
                    }

                flattenedSummaryData = [
                    {
                        value: customRounding({
                            value:
                                fetchData?.data?.shipment?.current
                                    ?.totalShipment || 0,
                        }),
                        label: 'Overall Shipments',
                    },
                    {
                        value: `${currencyCode} ${customRounding({ value: charge.current.totalRev, useDecimalPoint: true })}`,
                        label: 'Overall Revenue',
                    },
                    {
                        value: `${currencyCode} ${customRounding({ value: charge.current.totalGp, useDecimalPoint: true })}`,
                        label: 'Overall Gross Profit',
                    },
                    {
                        value: `${customRounding({ value: charge?.current?.totalVolume, useDecimalPoint: true })} ${charge?.volumeUnit || 'M3'}`, // need be add overal volume value
                        label: 'Overall Volume',
                    },
                ]
                flattenedTableData = listOrganization?.map((item, idx) => {
                    return {
                        index: idx,
                        name: item?.name || '',
                        thisPeriod: handleFormatValueNumber(
                            item?.current&& item?.current[fieldName.key] ? item?.current[fieldName.key] : 0,
                            true,
                        ),
                        lastPeriod: handleFormatValueNumber(
                            item?.previous && item?.previous[fieldName.key] ? item?.previous[fieldName.key] : 0,
                            true,
                        ),
                        development: {
                            value: handleFormatValueNumber(
                                item?.changes && item?.changes[fieldName.key]?.value ? item?.changes[fieldName.key]?.value : 0,
                            ),
                            percentage: customRounding({
                                value:
                                item?.changes && item?.changes[fieldName.key]?.percentage ? item?.changes[fieldName.key]?.percentage :
                                    0,
                                useCheckDecimal: true,
                            }),
                        },
                        rev: `${
                            item.revenuePercentage
                                ? customRounding({
                                      value: item.revenuePercentage,
                                      useCheckDecimal: true,
                                  })
                                : '0'
                        }%`,
                        gp: `${
                            item.gpPercentage
                                ? customRounding({
                                      value: item.gpPercentage,
                                      useCheckDecimal: true,
                                  })
                                : '0'
                        }%`,
                        vol: `${
                            item.volumePercentage
                                ? customRounding({
                                      value: item.volumePercentage,
                                      useCheckDecimal: true,
                                  })
                                : '0'
                        }%`,
                        gpPerShipment: `${currencyCode} ${
                            item.avgPerShipment
                                ? customRounding({
                                      value: item.avgPerShipment,
                                      useDecimalPoint: true,
                                  })
                                : '0.00'
                        }`,
                    }
                })
            }

            dispatch(
                setMediumTableMultipleBarsItemsData(flattenedMultipleBarsData),
            )
            dispatch(setMediumTableCardItems(flattenedSummaryData))
            setRawMediumTable(flattenedTableData)
            setLoadMediumTable(flattenedTableData)
        } catch (error) {
            console.error(error)
            setLoadingMediumTable(false)
            setLoadingHistory({
                splitRecord: false,
                development: false,
            })
            tampungMessagetoast.message = (error as Error).message
            tampungMessagetoast.header = 'Customer'
        } finally {
            callToastError()
        }
    }
    const loadCustomersHistory = async (name: string) => {
        try {
            const historyDevelopment =
                fetchControlTowerData<IRCustomerOrgDevelopment>(
                    formikFilterCO.values,
                    ifbEndpoints.control_tower_CustomerOrganisationHistoryDevelopment,
                    {
                        orgName: name,
                        countryCode: user?.userDefaultData?.country.code,
                        currency: user.userDefaultData.currency.code,
                    },
                )
                    .then(({ data, isSuccess, message }) => {
                        let flattenedHistoryDevelopmentData = null

                        if (!isSuccess) {
                            tampungMessagetoast.message = message
                            tampungMessagetoast.header = 'History Development'
                        } else {
                            const flattenedAvarageData = {
                                avgCurrentRevenue: data.avgCurrentRevenue,
                                avgCurrentGp: data.avgCurrentGp,
                                avgCurrentShipment: data.avgCurrentShipment,
                                avgCurrentVolume: data.avgCurrentVolume,
                            }

                            const defaultValue = {
                                revenue: 0,
                                volume: 0,
                                gp: 0,
                                shipment: 0,
                            }

                            // flattened data with merge data current and previ
                            const mergeDataByLabel = () => {
                                const map: Record<string, any> = {}
                                const seperateMonthAndYear = (
                                    item: string = '',
                                ) => {
                                    const [year, month] = item.split('-')
                                    return {
                                        month,
                                        year,
                                    }
                                }

                                // Masukkan data1 ke dalam map
                                data?.currents?.forEach((item) => {
                                    const monthYear = seperateMonthAndYear(
                                        item.label,
                                    )
                                    map[item.label] = {
                                        month: monthYear?.month,
                                        year: monthYear?.year,
                                        current: {
                                            revenue: item.revenue,
                                            volume: item.volume,
                                            gp: item.gp,
                                            shipment: item?.shipment,
                                        },
                                    }
                                    delete map[item.label].current.label // Hapus label agar sesuai output
                                })

                                // Masukkan data2 ke dalam map, gabungkan jika label sudah ada
                                data?.previous?.forEach((item) => {
                                    const monthYear = seperateMonthAndYear(
                                        item.label,
                                    )
                                    if (map[item.label]) {
                                        map[item.label].previous = {
                                            revenue: item.revenue,
                                            volume: item.volume,
                                            gp: item.gp,
                                            shipment: item.shipment,
                                        }
                                        delete map[item.label].previous.label // Hapus label agar sesuai output
                                    } else {
                                        map[item.label] = {
                                            month: monthYear?.month,
                                            year: monthYear?.year,
                                            previous: {
                                                revenue: item.revenue,
                                                volume: item.volume,
                                                gp: item.gp,
                                                shipment: item.shipment,
                                            },
                                        }
                                        delete map[item.label].previous.label
                                    }
                                })

                                Object.values(map).forEach((entry) => {
                                    if (!entry.current)
                                        entry.current = { ...defaultValue }
                                    if (!entry.previous)
                                        entry.previous = { ...defaultValue }
                                })

                                return Object.values(map)
                            }

                            flattenedHistoryDevelopmentData = {
                                averageInfo: flattenedAvarageData,
                                graphData: mergeDataByLabel(),
                            }
                        }

                        setHistoryDevelopmentData(
                            flattenedHistoryDevelopmentData,
                        )
                    })
                    .catch((error) => {
                        console.error('Error fetching data:', error)
                        tampungMessagetoast.message = error.message
                        tampungMessagetoast.header = 'History Development'
                    })
                    .finally(() => {
                        setLoadingHistory((prevState) => ({
                            ...prevState,
                            development: false,
                        }))
                    })

            const historySplitRecord =
                fetchControlTowerData<IRCustomerOrgSplitRecord>(
                    formikFilterCO.values,
                    ifbEndpoints.control_tower_CustomerOrganisationHistorySplitRecord,
                    {
                        orgName: name,
                        countryCode: user.userDefaultData.country.code,
                        currency: user.userDefaultData.currency.code,
                    },
                )
                    .then(({ data, isSuccess, message }) => {
                        const fieldName = getActiveFieldFilterDataCategory()

                        let chartData: IBarchartStacktedDataItem[] =
                            historySplitRecordChartDataIntialValue
                        let summaryData: InfoCardGroupItems[] =
                            historySplitRecordSummaryIntialvalue

                        if (!isSuccess) {
                            tampungMessagetoast.message = message
                            tampungMessagetoast.header = 'History Development'
                        } else {
                            chartData = [
                                {
                                    name: 'This Period',
                                    f1:
                                        Number(
                                            data?.current?.nominator[
                                                fieldName.key as ICOFilterByMetrics
                                            ],
                                        ) || 0,
                                    f2:
                                        Number(
                                            data?.current?.local[
                                                fieldName.key as ICOFilterByMetrics
                                            ] as number,
                                        ) || 0,
                                    amt: 1000,
                                },
                                {
                                    name: 'Last Period',
                                    f1:
                                        Number(
                                            data?.previous?.nominator[
                                                fieldName.key as ICOFilterByMetrics
                                            ] as number,
                                        ) || 0,
                                    f2:
                                        Number(
                                            data?.previous?.local[
                                                fieldName.key as ICOFilterByMetrics
                                            ] as number,
                                        ) || 0,
                                    amt: 1000,
                                },
                            ]

                            summaryData = [
                                {
                                    value: handleFormatValueNumber(
                                        data?.current?.nominator[
                                            fieldName.key as ICOFilterByMetrics
                                        ] || 0,
                                    ),
                                    label: 'Nomination',
                                },
                                {
                                    value: handleFormatValueNumber(
                                        data?.current?.local[
                                            fieldName.key as ICOFilterByMetrics
                                        ] || 0,
                                    ),
                                    label: 'Local Control',
                                },
                            ]
                        }

                        dispatch(
                            setHistorySplitRecord({
                                chartData,
                                infoCardGroupItems: summaryData,
                            }),
                        )
                    })
                    .catch((error) => {
                        console.error('Error fetching data:', error)
                        tampungMessagetoast.message = error.message
                        tampungMessagetoast.header = 'History Split Record'
                    })
                    .finally(() => {
                        setLoadingHistory((prevState) => ({
                            ...prevState,
                            splitRecord: false,
                        }))
                    })

            await Promise.allSettled([historyDevelopment, historySplitRecord])
        } catch (error) {
            console.error(error)
        } finally {
            setLoadingHistory({
                splitRecord: false,
                development: false,
            })
            callToastError()
        }
    }

    // funtion sales
    const loadSalesData = async () => {
        try {
            const overallSales = fetchControlTowerData<IROverallSales>(
                formikFilterCO.values,
                getEnpointSalesByTabFilter()?.endpoint?.overallSales || '',
                {
                    currencyCode,
                    countryCode,
                },
            )
                .then(({ data, isSuccess, message }) => {
                    // intial flattened data
                    let flattenedMultipleBarsData: IProgressMultipleBarsData<IMarkShipmentCustomersAndSales> =
                        multipleBarsMediumTableInitialValue
                    let flattenedSummaryData: InfoCardGroupItems[] =
                        summaryMediumTableIntialValue

                    if (!isSuccess) {
                        tampungMessagetoast.message = message
                        tampungMessagetoast.header = 'Overal Sales'
                    } else {
                        flattenedMultipleBarsData = {
                            total: data?.queryResults.reduce(
                                (total, item) => total + item.percentage,
                                0,
                            ),
                            items: {
                                value: {
                                    blue:
                                        data?.queryResults[0]?.percentage || 0,
                                    aqua:
                                        data?.queryResults[1]?.percentage || 0,
                                    purple:
                                        data?.queryResults[2]?.percentage || 0,
                                    orange:
                                        data?.queryResults[3]?.percentage || 0,
                                    yellow:
                                        data?.queryResults[4]?.percentage || 0,
                                    red: data?.queryResults[5]?.percentage || 0,
                                    gray: data?.queryResults
                                        .slice(6)
                                        .reduce(
                                            (sum, item) =>
                                                sum + (item?.percentage || 0),
                                            0,
                                        ),
                                },
                            },
                        }
                        flattenedSummaryData = [
                            {
                                value: customRounding({
                                    value: data.overallShipment,
                                }),
                                label: 'Overall Shipments',
                            },
                            {
                                value: `${currencyCode} ${customRounding({ value: data.overallRevenue, useDecimalPoint: true })}`,
                                label: 'Overall Revenue',
                            },
                            {
                                value: `${currencyCode} ${customRounding({ value: data.overallGP, useDecimalPoint: true })}`,
                                label: 'Overall Gross Profit',
                            },
                            {
                                value: `${customRounding({ value: data.overallVolume, useDecimalPoint: true })} M3`, // need be add overal unit volume value
                                label: 'Overall Volume',
                            },
                        ]
                    }

                    dispatch(
                        setMediumTableMultipleBarsItemsData(
                            flattenedMultipleBarsData,
                        ),
                    )
                    dispatch(setMediumTableCardItems(flattenedSummaryData))
                })
                .catch((error) => {
                    console.error('Error fetching data:', error)
                    tampungMessagetoast.message = error.message
                    tampungMessagetoast.header = 'Overal Sales'
                })

            const tableSales = loadSalesTable()

            await Promise.allSettled([overallSales, tableSales])
        } catch (error) {
            console.error('Error fetching data:', error)
        } finally {
            callToastError()
        }
    }
    const loadSalesTable = async () => {
        try {
            const endpoint =
                getEnpointSalesByTabFilter()?.endpoint?.tableSales || ''
            const fieldName = getActiveFieldFilterDataCategory()

            const fetchData = await fetchControlTowerData<IRSalesTable[]>(
                formikFilterCO.values,
                endpoint,
                {
                    currencyCode,
                    countryCode,
                },
            )

            let flattenedData: IMediumTableData[] = []

            if (!fetchData.isSuccess) {
                tampungMessagetoast.message = fetchData.message
                tampungMessagetoast.header = 'Overal Sales'
            } else {
                flattenedData = fetchData?.data?.map((item, idx) => {
                    return {
                        index: idx,
                        name: item?.name || '',
                        thisPeriod: handleFormatValueNumber(
                            item.current[fieldName.key],
                            true,
                        ),
                        lastPeriod: handleFormatValueNumber(
                            item.previous[fieldName.key],
                            true,
                        ),
                        development: {
                            value: handleFormatValueNumber(
                                item.changes[fieldName.key].value,
                            ),
                            percentage: customRounding({
                                value: item.changes[fieldName.key].percentage,
                                useCheckDecimal: true,
                            }),
                        },
                        rev: `${
                            item.revPercentage
                                ? customRounding({
                                      value: item.revPercentage,
                                      useCheckDecimal: true,
                                  })
                                : '0'
                        }%`,
                        gp: `${
                            item.gpPercentage
                                ? customRounding({
                                      value: item.gpPercentage,
                                      useCheckDecimal: true,
                                  })
                                : '0'
                        }%`,
                        vol: `${
                            item.volPercentage
                                ? customRounding({
                                      value: item.volPercentage,
                                      useCheckDecimal: true,
                                  })
                                : '0'
                        }%`,
                        gpPerShipment: `${user.userDefaultData.currency.code} ${
                            item.avgGpPerShipment
                                ? customRounding({
                                      value: item.volPercentage,
                                      useDecimalPoint: true,
                                  })
                                : '0.00'
                        }`,
                    }
                })
            }

            setLoadMediumTable(flattenedData)
            setRawMediumTable(flattenedData)
        } catch (error) {
            console.error(error)
            setLoadingMediumTable(false)
            handleLoadingHistory(false)
            tampungMessagetoast.message = (error as Error).message
            tampungMessagetoast.header = 'Sales Table'
        }
    }
    const getEnpointSalesByTabFilter = (name?: string) => {
        let endpoint = {
            tableSales: '',
            overallSales: '',
            salesHistoryDevelopment: '',
            salesHistorySplitRecord: '',
        }
        let paramsCustomField = {}
        switch (tabFilterSales.value) {
            case 'Organisations':
                endpoint = {
                    tableSales: ifbEndpoints.control_tower_SalesOrganisation,
                    overallSales:
                        ifbEndpoints.control_tower_SalesOverallOrganisation,
                    salesHistoryDevelopment:
                        ifbEndpoints.control_tower_SalesOrganisationDevelopment,
                    salesHistorySplitRecord:
                        ifbEndpoints.control_tower_SalesOrganisationSplit,
                }
                paramsCustomField = { salesOrgName: name || '' }
                break
            case 'Contact':
                endpoint = {
                    tableSales: ifbEndpoints.control_tower_SalesContact,
                    overallSales:
                        ifbEndpoints.control_tower_OverallSalesContact,
                    salesHistoryDevelopment:
                        ifbEndpoints.control_tower_SalesContactHistoryDevelopment,
                    salesHistorySplitRecord:
                        ifbEndpoints.control_tower_SalesContactHistorySplitRecord,
                }
                paramsCustomField = { salesStaffName: name || '' }
                break
        }
        return {
            endpoint,
            paramsCustomField: {
                ...paramsCustomField,
                currencyCode: user.userDefaultData?.currency.code || '',
                countryCode: user.userDefaultData?.country.code || '',
            },
        }
    }
    const loadSalesHistory = async (name: string) => {
        const x = getEnpointSalesByTabFilter(name)

        try {
            const historyDevelopment =
                fetchControlTowerHistory<IRSalesHistoryDevelopment>({
                    filter: formikFilterCO.values,
                    endpoint: x?.endpoint?.salesHistoryDevelopment || '',
                    field: x?.paramsCustomField || {},
                })
                    .then(({ data, isSuccess, message }) => {
                        let flattenedChartData = null

                        if (!isSuccess) {
                            tampungMessagetoast.message = message
                            tampungMessagetoast.header = 'History Development'
                        } else {
                            flattenedChartData = {
                                averageInfo: data?.itemId,
                                graphData: data?.data,
                            }
                        }
                        setHistoryDevelopmentData(flattenedChartData)
                    })
                    .catch((error) => {
                        console.error('Error fetching data:', error)
                        tampungMessagetoast.message = (error as Error).message
                        tampungMessagetoast.header = 'History Development'
                    })
                    .finally(() => {
                        setLoadingHistory((prevState) => ({
                            ...prevState,
                            development: false,
                        }))
                    })

            const historySplitRecord =
                fetchControlTowerHistory<IRSalesHistorySplitRecord>({
                    filter: formikFilterCO.values,
                    endpoint: x?.endpoint?.salesHistorySplitRecord || '',
                    field: x?.paramsCustomField || {},
                })
                    .then(({ data, isSuccess, message }) => {
                        let chartData: IBarchartStacktedDataItem[] =
                            historySplitRecordChartDataIntialValue
                        let summaryData: InfoCardGroupItems[] =
                            historySplitRecordSummaryIntialvalue

                        if (!isSuccess) {
                            tampungMessagetoast.message = message
                            tampungMessagetoast.header = 'History Split Record'
                        } else {
                            const fieldName =
                                getActiveFielSalesdFilterDataCategory()
                            type IRTradeLanesHistorySplitKeys = keyof Pick<
                                IRTradeLanesHistorySplit,
                                'currentPeriod' | 'lastPeriod'
                            >

                            const fieldMap: Record<
                                string,
                                IRTradeLanesHistorySplitKeys
                            > = {
                                'This Period': 'currentPeriod',
                                'Last Period': 'lastPeriod',
                            }
                            const summaryDataInitialValue = {
                                nomination: { currentPeriod: 0, lastPeriod: 0 },
                                localControl: {
                                    currentPeriod: 0,
                                    lastPeriod: 0,
                                },
                            }

                            chartData = initialDataBarChartSplitRecord?.map(
                                (item) => {
                                    const getFieldMapData = fieldMap[item.name]
                                    const findDataByPeriod =
                                        data?.[getFieldMapData]
                                    const nominationData =
                                        findDataByPeriod.nomination[
                                            fieldName.key as keyof typeof findDataByPeriod.nomination
                                        ] || 0
                                    const localControlData =
                                        findDataByPeriod.localAgent[
                                            fieldName.key as keyof typeof findDataByPeriod.localAgent
                                        ] || 0

                                    summaryDataInitialValue.nomination[
                                        getFieldMapData
                                    ] = nominationData
                                    summaryDataInitialValue.localControl[
                                        getFieldMapData
                                    ] = localControlData

                                    return {
                                        ...item,
                                        f1: nominationData,
                                        f2: localControlData,
                                    }
                                },
                            )

                            summaryData = [
                                {
                                    value: handleFormatValueNumber(
                                        data?.summary?.nomination[
                                            fieldName.key as keyof typeof data.difference.nomination
                                        ],
                                    ),
                                    label: 'Nomination',
                                },
                                {
                                    value: handleFormatValueNumber(
                                        data?.summary?.localAgent[
                                            fieldName.key as keyof typeof data.difference.nomination
                                        ],
                                    ),
                                    label: 'Local Control',
                                },
                            ]
                        }

                        dispatch(
                            setHistorySplitRecord({
                                chartData,
                                infoCardGroupItems: summaryData,
                            }),
                        )
                    })
                    .catch((error) => {
                        console.error('Error fetching data:', error)
                        tampungMessagetoast.message = (error as Error).message
                        tampungMessagetoast.header = 'History Split Record'
                    })
                    .finally(() => {
                        setLoadingHistory((prevState) => ({
                            ...prevState,
                            splitRecord: false,
                        }))
                    })

            await Promise.allSettled([historyDevelopment, historySplitRecord])
        } catch (error) {
            console.error(error)
        } finally {
            setLoadingHistory({
                splitRecord: false,
                development: false,
            })
            callToastError()
        }
    }
    const getActiveFielSalesdFilterDataCategory = () => {
        const activeFilter = Object.entries(
            formikFilterCO.values.DataCategory,
        ).find(([key, value]) => value === true)

        if (!activeFilter) {
            throw new Error('No active filter found')
        }

        const [key] = activeFilter

        // Map key filter ke nama field di items
        const fieldMapping: Record<
            string,
            {
                key: string
                label: string
                infoCardSplitRecordKey: string
            }
        > = {
            Shipments: {
                key: 'totalShipment',
                label: 'Shipments',
                infoCardSplitRecordKey: 'avgCurrentShipment',
            },
            Revenue: {
                key: 'totalRevenue',
                label: 'Revenue',
                infoCardSplitRecordKey: 'avgCurrentRevenue',
            },
            GrossProfit: {
                key: 'totalGP',
                label: 'Gross Profit',
                infoCardSplitRecordKey: 'avgCurrentGp',
            },
            Volume: {
                key: 'totalVolume',
                label: 'Volume',
                infoCardSplitRecordKey: 'avgCurrentVolume',
            },
        }

        const fieldName = fieldMapping[key]

        if (!fieldName) {
            throw new Error(`Field mapping not found for key: ${key}`)
        }

        return fieldName
    }

    // function finance
    const loadFinanceData = async () => {
        try {
            const openInvoice =
                fetchControlTowerFinanceData<IRFinanceOpenInvoices>(
                    financeFilter,
                    ifbEndpoints.control_tower_FinanceOpenInvoice,
                    {
                        date: moment().format('YYYY-MM-DD'), // today
                        countryCode: user?.userDefaultData?.country.code,
                        currency: user.userDefaultData.currency.code,
                    },
                )
                    .then(({ data, isSuccess, message }) => {
                        let openInvoiceData = null
                        if (!isSuccess) {
                            tampungMessagetoast.message = message
                            tampungMessagetoast.header = 'Open Invoice'
                            return
                        } else {
                            const flatteneOpenInvoicesData: IFinanceData['openInvoices'] =
                                {
                                    infoCardItems: [
                                        {
                                            value: handleFormatValueNumberFinance(
                                                data?.shipmentOverall || 0,
                                                false,
                                            ),
                                            label: 'Total open invoices',
                                        },
                                        {
                                            value: handleFormatValueNumberFinance(
                                                data?.amountOverall || 0,
                                                false,
                                            ),
                                            label: 'Total open amount',
                                        },
                                    ],
                                    pieChart:
                                        financeData?.openInvoices?.pieChart?.map(
                                            (item) => {
                                                const findValueByKey =
                                                    data?.items[
                                                        item.key as keyof typeof data.items
                                                    ]?.amount
                                                return {
                                                    ...item,
                                                    value: parseFloat(
                                                        handleFormatValueNumberFinance(
                                                            findValueByKey || 0,
                                                            false,
                                                        ),
                                                    ),
                                                }
                                            },
                                        ),
                                }
                            openInvoiceData = flatteneOpenInvoicesData
                        }
                        dispatch(setOpenInvoceData(openInvoiceData))
                    })
                    .catch((error) => {
                        console.error('Error fetching data:', error)
                        tampungMessagetoast.message = error.message
                        tampungMessagetoast.header = 'Open Invoice'
                    })
                    .finally(() => {
                        setLoadingFinance((prevState) => ({
                            ...prevState,
                            openInvoice: false,
                        }))
                    })

            const averageMonthlyPerformanceData =
                loadAverageMonthlyPerformanceData()

            const tableFinance =
                tabFilterFinance.value === 'P&L'
                    ? loadFinanceTableProfitAndLoss()
                    : loadFinanceTableCreditLimit()

            await Promise.allSettled([
                openInvoice,
                averageMonthlyPerformanceData,
                tableFinance,
            ])
        } catch (error) {
            console.error(error)
        } finally {
            callToastError()
        }
    }
    const handleFormatValueNumberFinance = (
        value: number | string,
        useCurrencyCode: boolean = true,
    ) => {
        const count = customRounding({
            value,
            useDecimalPoint: true,
        })

        const useCurrencyValue = useCurrencyCode
            ? `${user?.userDefaultData?.currency?.code} ${count}`
            : count

        return useCurrencyValue
    }
    const loadFinanceTableProfitAndLoss = async () => {
        try {
            const loadData =
                await fetchControlTowerFinanceData<IRFinanceTableProfitAndLoss>(
                    financeFilter,
                    ifbEndpoints.control_tower_FinanceProfitAndLoss,
                    {
                        countryCode: user.userDefaultData?.country.code || '',
                        currency: user.userDefaultData?.currency.code || '',
                    },
                )

            const { data } = loadData
            let flattenedDataTable: IFinanceProfitAndLossTableData[] = []

            if (!loadData.isSuccess) {
                tampungMessagetoast.message = loadData.message
                tampungMessagetoast.header = 'Table Tab Profit And Loss'
            } else {
                const subDataGp =
                    financeProfitAndLostTableInitialData[0]?.subData &&
                    financeProfitAndLostTableInitialData[0]?.subData.map(
                        (item) => {
                            const findData =
                                item.key &&
                                data?.gp?.items?.find(
                                    (item2) => item2.current.desc === item.key,
                                )

                            return {
                                ...item,
                                thisPeriod: handleFormatValueNumberFinance(
                                    (findData && findData?.current?.value) || 0,
                                ),
                                lastPeriod: handleFormatValueNumberFinance(
                                    (findData && findData?.previous?.value) ||
                                        0,
                                ),
                                change: {
                                    value: handleFormatValueNumberFinance(
                                        (findData &&
                                            findData?.changes?.value) ||
                                            0,
                                        false,
                                    ),
                                    percentage: handleFormatValueNumberFinance(
                                        (findData &&
                                            findData?.changes?.percent) ||
                                            0,
                                        false,
                                    ),
                                    labelValue: handleFormatValueNumberFinance(
                                        (findData &&
                                            findData?.changes?.value) ||
                                            0,
                                    ),
                                },
                            }
                        },
                    )

                const subDataCosts =
                    financeProfitAndLostTableInitialData[1]?.subData &&
                    financeProfitAndLostTableInitialData[1]?.subData.map(
                        (item) => {
                            const findData =
                                item.key &&
                                data?.cost?.items?.find(
                                    (item2) => item2.current.desc === item.key,
                                )

                            return {
                                ...item,
                                thisPeriod: handleFormatValueNumberFinance(
                                    (findData && findData?.current?.value) || 0,
                                ),
                                lastPeriod: handleFormatValueNumberFinance(
                                    (findData && findData?.previous?.value) ||
                                        0,
                                ),
                                change: {
                                    value: handleFormatValueNumberFinance(
                                        (findData &&
                                            findData?.changes?.value) ||
                                            0,
                                        false,
                                    ),
                                    percentage: handleFormatValueNumberFinance(
                                        (findData &&
                                            findData?.changes?.percent) ||
                                            0,
                                        false,
                                    ),
                                    labelValue: handleFormatValueNumberFinance(
                                        (findData &&
                                            findData?.changes?.value) ||
                                            0,
                                    ),
                                },
                            }
                        },
                    )

                const subDataOpex =
                    financeProfitAndLostTableInitialData[3]?.subData &&
                    financeProfitAndLostTableInitialData[3]?.subData.map(
                        (item) => {
                            const findData =
                                item.key &&
                                data?.opex?.items?.find(
                                    (item2) => item2.current.desc === item.key,
                                )
                            return {
                                ...item,
                                thisPeriod: handleFormatValueNumberFinance(
                                    (findData && findData?.current?.value) || 0,
                                ),
                                lastPeriod: handleFormatValueNumberFinance(
                                    (findData && findData?.previous?.value) ||
                                        0,
                                ),
                                change: {
                                    value: handleFormatValueNumberFinance(
                                        (findData &&
                                            findData?.changes?.value) ||
                                            0,
                                        false,
                                    ),
                                    percentage: handleFormatValueNumberFinance(
                                        (findData &&
                                            findData?.changes?.percent) ||
                                            0,
                                        false,
                                    ),
                                    labelValue: handleFormatValueNumberFinance(
                                        (findData &&
                                            findData?.changes?.value) ||
                                            0,
                                    ),
                                },
                            }
                        },
                    )

                flattenedDataTable = [
                    {
                        name: 'Revenue',
                        thisPeriod: handleFormatValueNumberFinance(
                            data?.revenue?.current?.value || 0,
                        ),
                        lastPeriod: handleFormatValueNumberFinance(
                            data?.revenue?.previous?.value || 0,
                        ),
                        change: {
                            value: handleFormatValueNumberFinance(
                                data?.revenue?.changes?.value || 0,
                                false,
                            ),
                            percentage: handleFormatValueNumberFinance(
                                data?.revenue?.changes?.percent || 0,
                                false,
                            ),
                            labelValue: handleFormatValueNumberFinance(
                                data?.revenue?.changes?.value || 0,
                            ),
                        },
                        subData: subDataGp,
                    },
                    {
                        name: 'Cost',
                        thisPeriod: handleFormatValueNumberFinance(
                            data?.cost?.value?.current?.value || 0,
                        ),
                        lastPeriod: handleFormatValueNumberFinance(
                            data?.cost?.value?.previous?.value || 0,
                        ),
                        change: {
                            value: handleFormatValueNumberFinance(
                                data?.cost?.value?.changes?.value || 0,
                                false,
                            ),
                            percentage: handleFormatValueNumberFinance(
                                data?.cost?.value?.changes?.percent || 0,
                                false,
                            ),
                            labelValue: handleFormatValueNumberFinance(
                                data?.cost?.value?.changes?.value || 0,
                            ),
                        },
                        subData: subDataCosts,
                    },

                    {
                        name: 'GP',
                        thisPeriod: handleFormatValueNumberFinance(
                            data?.gp?.value.current?.value || 0,
                        ),
                        lastPeriod: handleFormatValueNumberFinance(
                            data?.gp?.value?.previous?.value || 0,
                        ),
                        change: {
                            value: handleFormatValueNumberFinance(
                                data?.gp?.value?.changes?.value || 0,
                                false,
                            ),
                            percentage: handleFormatValueNumberFinance(
                                data?.gp?.value?.changes?.percent || 0,
                                false,
                            ),
                            labelValue: handleFormatValueNumberFinance(
                                data?.gp?.value?.changes?.value || 0,
                            ),
                        },
                    },
                    {
                        name: 'OPEX',
                        thisPeriod: handleFormatValueNumberFinance(
                            data?.opex?.value?.current?.value || 0,
                        ),
                        lastPeriod: handleFormatValueNumberFinance(
                            data?.opex?.value?.previous?.value || 0,
                        ),
                        change: {
                            value: handleFormatValueNumberFinance(
                                data?.opex?.value?.changes?.value || 0,
                                false,
                            ),
                            percentage: handleFormatValueNumberFinance(
                                data?.opex?.value?.changes?.percent || 0,
                                false,
                            ),
                            labelValue: handleFormatValueNumberFinance(
                                data?.opex?.value?.changes?.value || 0,
                            ),
                        },
                        subData: subDataOpex,
                    },
                    {
                        name: 'Net Profit',
                        thisPeriod: handleFormatValueNumberFinance(
                            data?.netProfit.current?.value || 0,
                        ),
                        lastPeriod: handleFormatValueNumberFinance(
                            data?.netProfit.previous?.value || 0,
                        ),
                        change: {
                            value: handleFormatValueNumberFinance(
                                data?.netProfit.changes?.value || 0,
                                false,
                            ),
                            percentage: handleFormatValueNumberFinance(
                                data?.netProfit.changes?.percent || 0,
                                false,
                            ),
                            labelValue: handleFormatValueNumberFinance(
                                data?.netProfit.changes?.value || 0,
                            ),
                        },
                    },
                ]
            }

            setLoadFinanceTableProfitAndLoss(flattenedDataTable)
            setFinanceTableRawData((prevState) => ({
                ...prevState,
                profitandLoss: flattenedDataTable,
            }))
        } catch (error) {
            console.error('Error fetching data:', error)
            tampungMessagetoast.message = (error as Error).message
            tampungMessagetoast.header = 'Table Tab Profit And Loss'
            setLoadingFinance((prevState) => ({ ...prevState, table: false }))
            handleLoadingHistory(false)
        }
    }
    const loadFinanceTableCreditLimit = async () => {
        try {
            const loadData =
                await fetchControlTowerFinanceData<IRFinanceTableCreditLimit>(
                    financeFilter,
                    ifbEndpoints.control_tower_FinanceCreditLimit,
                    {
                        countryCode: user.userDefaultData?.country.code || '',
                        currency: user.userDefaultData?.currency.code || '',
                    },
                )

            let flattenedDataTable: ICreditLimitTable[] = []

            if (!loadData.isSuccess) {
                tampungMessagetoast.message = loadData.message
                tampungMessagetoast.header = 'Table Tab Credit Limit'
            } else {
                flattenedDataTable = loadData?.data?.items?.length
                    ? loadData.data.items.map((item) => {
                          return {
                              organization: item?.org,
                              creditLimit: handleFormatValueNumberFinance(
                                  item?.creditLimit || 0,
                              ),
                          }
                      })
                    : []
            }

            setLoadFinanceTableCreditLimit(flattenedDataTable)
            setFinanceTableRawData((prevState) => ({
                ...prevState,
                creditLimit: flattenedDataTable,
            }))
        } catch (error) {
            console.error('Error fetching data:', error)
            tampungMessagetoast.message = (error as Error).message
            tampungMessagetoast.header = 'Table Tab Credit Limit'
        } finally {
            setLoadingFinance((prevState) => ({ ...prevState, table: false }))
        }
    }
    const loadFinanceHistory = async (name: string) => {
        try {
            const historyDevelopment =
                fetchControlTowerFinanceData<IRFinanceProfitAndLoss>(
                    financeFilter,
                    ifbEndpoints.control_tower_FinanceProfitAndLossHistoryDevelopment,
                    {
                        searchKey: name,
                        countryCode: countryCode,
                        currency: currencyCode,
                    },
                )
                    .then(({ data, isSuccess, message }) => {
                        let flattenedChartData: ILoadCharLineMonthByRangeMonthFinanceProps =
                            {
                                line1: [],
                                line2: [],
                            }
                        let summaryData = [
                            {
                                value: '0',
                                label: `Average Shipment`,
                            },
                        ]
                        if (!isSuccess) {
                            tampungMessagetoast.message = message
                            tampungMessagetoast.header = 'History Development'
                        } else {
                            flattenedChartData = {
                                line1:
                                    data?.current?.map((item) => {
                                        const [year, month] =
                                            item.label.split('-')
                                        return {
                                            month,
                                            year,
                                            value: item.value,
                                        }
                                    }) || [],
                                line2:
                                    data?.previous?.map((item) => {
                                        const [year, month] =
                                            item.label.split('-')
                                        return {
                                            month,
                                            year,
                                            value: item.value,
                                        }
                                    }) || [],
                            }

                            summaryData = [
                                {
                                    value: customRounding({ value: data.avg }),
                                    label: `Average Shipment`,
                                },
                            ]
                        }

                        const chartLineData =
                            loadCharLineByRangeMonthFinance(flattenedChartData)

                        dispatch(
                            setHistoryDevelopment({
                                line1: chartLineData.line1,
                                line2: chartLineData.line2,
                                tickItems: chartLineData.tickItems,
                                infoCardItems: summaryData,
                            }),
                        )
                    })
                    .catch((error) => {
                        console.error('Error fetching data:', error)
                        tampungMessagetoast.message = error.message
                        tampungMessagetoast.header = 'History Development'
                    })
                    .finally(() => {
                        setLoadingHistory((prevState) => ({
                            ...prevState,
                            development: false,
                        }))
                    })

            const historySplitRecord =
                fetchControlTowerFinanceData<IRFinanceSplitRecord>(
                    financeFilter,
                    ifbEndpoints.control_tower_FinanceProfitAndLossHistorySplitRecord,
                    {
                        searchKey: name,
                        countryCode: countryCode,
                        currency: currencyCode,
                    },
                )
                    .then(({ data, isSuccess, message }) => {
                        let chartData: IBarchartStacktedDataItem[] =
                            historySplitRecordChartDataIntialValue
                        let summaryData: InfoCardGroupItems[] =
                            historySplitRecordSummaryIntialvalue

                        if (!isSuccess) {
                            tampungMessagetoast.message = message
                            tampungMessagetoast.header = 'History Development'
                        } else {
                            chartData = [
                                {
                                    name: 'This Period',
                                    f1: data?.current?.nominator?.value || 0,
                                    f2: data?.current?.local?.value || 0,
                                    amt: 1000,
                                },
                                {
                                    name: 'Last Period',
                                    f1: data?.previous?.nominator?.value || 0,
                                    f2: data?.previous?.local?.value || 0,
                                    amt: 1000,
                                },
                            ]

                            summaryData = [
                                {
                                    value: handleFormatValueNumber(
                                        data?.current?.nominator?.value || 0,
                                    ),
                                    label: 'Nomination',
                                },
                                {
                                    value: handleFormatValueNumber(
                                        data?.current?.local?.value || 0,
                                    ),
                                    label: 'Local Control',
                                },
                            ]
                        }

                        dispatch(
                            setHistorySplitRecord({
                                chartData,
                                infoCardGroupItems: summaryData,
                            }),
                        )
                    })
                    .catch((error) => {
                        console.error('Error fetching data:', error)
                        tampungMessagetoast.message = error.message
                        tampungMessagetoast.header = 'History Split Record'
                    })
                    .finally(() => {
                        setLoadingHistory((prevState) => ({
                            ...prevState,
                            splitRecord: false,
                        }))
                    })

            await Promise.allSettled([historyDevelopment, historySplitRecord])
        } catch (error) {
            console.error(error)
        } finally {
            setLoadingHistory({
                splitRecord: false,
                development: false,
            })
            callToastError()
        }
    }
    const setLoadFinanceTableProfitAndLoss = async (
        flattenedDataTable: IFinanceProfitAndLossTableData[],
    ) => {
        const { meta, filterPageNumber } = financeData.table

        const loadMeta = createMetaData({
            meta: meta,
            lengthData: flattenedDataTable.length,
            itemPerPage: 50,
            pageNumber: filterPageNumber,
        })

        const sortDataByPagination = flattenedDataTable.slice(
            loadMeta.index_start - 1,
            loadMeta.index_end,
        )

        dispatch(
            setFinaceProfitAndLossTable({
                profitAndLoss: flattenedDataTable.slice(
                    loadMeta.index_start - 1,
                    loadMeta.index_end,
                ),
                meta: { ...meta, ...loadMeta },
            }),
        )
        setLoadingFinance((prevState) => ({ ...prevState, table: false }))
        await wrapFetchHistoryData(sortDataByPagination[0]?.name || '')
    }
    const setLoadFinanceTableCreditLimit = async (
        flattenedDataTable: ICreditLimitTable[],
    ) => {
        const { meta, filterPageNumber } = financeData.table

        const loadMeta = createMetaData({
            meta: meta,
            lengthData: flattenedDataTable.length,
            itemPerPage: 50,
            pageNumber: filterPageNumber,
        })

        dispatch(
            setFinaceCreditLimitTable({
                creditLimit: flattenedDataTable.slice(
                    loadMeta.index_start - 1,
                    loadMeta.index_end,
                ),
                meta: { ...meta, ...loadMeta },
            }),
        )
        setLoadingFinance((prevState) => ({ ...prevState, table: false }))
        await wrapFetchHistoryData('')
    }
    const handlingPageNumberFinanceTable = (pageNumber: number) => {
        if (pageNumber <= 0) return
        dispatch(setPageNumberFinanceTableSlice(pageNumber))
    }
    const loadAverageMonthlyPerformanceData = async () => {
        try {
            const fetchData =
                await fetchControlTowerFinanceData<IRFinanceAvgMonthlyPerformance>(
                    financeFilter,
                    ifbEndpoints.control_tower_AvgMonthlyPerformance,
                    {
                        countryCode: countryCode,
                        currency: currencyCode,
                    },
                )

            let flattenedDataChart: ILoadCharLineMonthByRangeMonthFinanceProps =
                {
                    line1: [],
                    line2: [],
                    line3: [],
                }

            let summaryData = [
                {
                    value: `${currencyCode} 0.00`,
                    label: 'Overall Average Revenue',
                },
            ]

            if (!fetchData.isSuccess) {
                tampungMessagetoast.message = fetchData.message
                tampungMessagetoast.header = 'Avg. Monthly Performance'
            } else {
                flattenedDataChart = {
                    line1:
                        fetchData?.data?.revenue?.map((item) => {
                            const [year, month] = item.label.split('-')
                            return {
                                month,
                                year,
                                value: item.value,
                            }
                        }) || [],
                    line2:
                        fetchData?.data?.opex?.map((item) => {
                            const [year, month] = item.label.split('-')
                            return {
                                month,
                                year,
                                value: item.value,
                            }
                        }) || [],
                    line3:
                        fetchData?.data?.netProfit?.map((item) => {
                            const [year, month] = item.label.split('-')
                            return {
                                month,
                                year,
                                value: item.value,
                            }
                        }) || [],
                }

                summaryData = [
                    {
                        value: `${currencyCode} ${customRounding({ value: fetchData?.data?.averageRevenue, useDecimalPoint: true })}`,
                        label: 'Overall Average Revenue',
                    },
                ]
            }

            const chartLineData =
                loadCharLineByRangeMonthFinance(flattenedDataChart)

            dispatch(
                setAverageMonthlyPerformance({ ...chartLineData, summaryData }),
            )
        } catch (error) {
            console.error('error', error)
            tampungMessagetoast.message = (error as Error).message
            tampungMessagetoast.header = 'Avg. Monthly Performance'
        } finally {
            setLoadingFinance((prevState) => ({
                ...prevState,
                averageMonthlyPerformance: false,
            }))
        }
    }
    const loadCharLineByRangeMonthFinance = ({
        line1,
        line2,
        line3,
    }: ILoadCharLineMonthByRangeMonthFinanceProps) => {
        const start = moment(formikFilterCO.values.StartDate, 'MM/YYYY')
        const end = moment(formikFilterCO.values.EndDate, 'MM/YYYY')

        const rangeMonth: string[] = []
        const _line1: ILineData[] = []
        const _line2: ILineData[] = []
        const _line3: ILineData[] = []
        let index = 1

        while (start.isSameOrBefore(end, 'month')) {
            const month = start.format('MM')
            const year = start.format('YYYY')

            const findValueLine1 = line1.find(
                (item) =>
                    item?.month?.padStart(2, '0') === month &&
                    item?.year === year,
            )?.value
            const findValueLine2 = line2.find(
                (item) =>
                    item?.month?.padStart(2, '0') === month &&
                    item?.year === year,
            )?.value

            let findValueLine3 = ''
            if (line3?.length)
                findValueLine3 =
                    line3?.find(
                        (item) =>
                            item?.month?.padStart(2, '0') === month &&
                            item?.year === year,
                    )?.value || ''

            _line1.push({
                x: index,
                secName: start.format('YYYY'),
                y: Number(findValueLine1) || 0,
                keyId: 'line1',
            })
            _line2.push({
                x: index,
                secName: start.format('YYYY'),
                y: Number(findValueLine2) || 0,
                keyId: 'line2',
            })
            if (line3 !== undefined)
                _line3.push({
                    x: index,
                    secName: start.format('YYYY'),
                    y: findValueLine3 ? Number(findValueLine3) : 0,
                    keyId: 'line3',
                })

            rangeMonth.push(start.format('MMM'))

            start.add(1, 'month')
            index++
        }

        return {
            line1: _line1,
            line2: _line2,
            line3: _line3,
            tickItems: rangeMonth,
        }
    }

    // useEffects
    useEffect(() => {
        formikFilterCO.submitForm()
        loadFilterDropdownOption()
    }, [])
    useEffect(() => {
        handlingLoadingContent()
        const handler = setTimeout(() => {
            if (isInitialRender) setIsInitialRender(false)
            wrapFetchDataContent()
        }, debounceTime)

        return () => clearTimeout(handler)
    }, [formikFilterCO.values, formikFilterCO.errors, buttonContent])
    useEffect(() => {
        if (!isInitialRender) {
            handleLoadingHistory()
            setLoadingMediumTable(true)

            const handler = setTimeout(() => {
                loadMediumTableUseRawData()
            }, debounceTime)

            return () => clearTimeout(handler)
        }
    }, [mediumTable.table.filterPageNumber])
    useEffect(() => {
        if (!isInitialRender) {
            handleLoadingHistory()
            setLoadingSmallTable(true)

            const handler = setTimeout(() => {
                loadSmallTableUseRawData()
            }, debounceTime)

            return () => clearTimeout(handler)
        }
    }, [smallTable.filterPageNumber])
    useEffect(() => {
        if (!isInitialRender) {
            loadContainerModeDropdownOption()
        }
    }, [formikFilterCO.values.TransportMode])
    useEffect(() => {
        if (!isInitialRender) {
            loadTransportModeDropdownOption()
        }
    }, [formikFilterCO.values.ContainerMode])

    // useEffect overview
    useEffect(() => {
        if (!isInitialRender) {
            handleLoadingHistory()
            setLoadingSmallTable(true)
            const handler = setTimeout(() => {
                loadOverviewTable2()
            }, debounceTime)

            return () => clearTimeout(handler)
        }
    }, [tabFilterOverview])

    // useEffect trade lanes
    useEffect(() => {
        if (!isInitialRender) {
            setLoadingSmallTable(true)
            handleLoadingHistory()
            const handler = setTimeout(() => {
                loadTradeLanesTable()
            }, debounceTime)

            return () => clearTimeout(handler)
        }
    }, [tabFilterTradeLanes])

    // useEffect sales
    useEffect(() => {
        if (!isInitialRender) {
            setLoadingMediumTable(true)
            handleLoadingHistory()
            const handler = setTimeout(() => {
                loadSalesData()
            }, debounceTime)

            return () => clearTimeout(handler)
        }
    }, [tabFilterSales])

    // useEffect finance
    useEffect(() => {
        if (!isInitialRender) {
            let x = {
                loading: () => {},
                loadData: () => {},
            }

            //reset pagination
            if (financeData.table.filterPageNumber !== 1)
                handlingPageNumberFinanceTable(1)

            if (tabFilterFinance.value === 'P&L') {
                x = {
                    loading: () => {
                        setLoadingFinance((prevState) => ({
                            ...prevState,
                            table: true,
                        }))
                        handleLoadingHistory()
                    },
                    loadData: () => {
                        loadFinanceTableProfitAndLoss()
                    },
                }
            } else {
                x = {
                    loading: () => {
                        setLoadingFinance((prevState) => ({
                            ...prevState,
                            table: true,
                        }))
                    },
                    loadData: () => {
                        loadFinanceTableCreditLimit()
                    },
                }
            }

            x.loading()

            const handler = setTimeout(() => {
                x.loadData()
            }, debounceTime)

            return () => clearTimeout(handler)
        }
    }, [tabFilterFinance])
    useEffect(() => {
        if (!isInitialRender) {
            let x = {
                loading: () => {},
                loadData: () => {},
            }

            if (tabFilterFinance.value === 'P&L') {
                x = {
                    loading: () => {
                        setLoadingFinance((prevState) => ({
                            ...prevState,
                            table: true,
                        }))
                        handleLoadingHistory()
                    },
                    loadData: () => {
                        setLoadFinanceTableProfitAndLoss(
                            financeTableRawData.profitandLoss,
                        )
                    },
                }
            } else {
                x = {
                    loading: () => {
                        setLoadingFinance((prevState) => ({
                            ...prevState,
                            table: true,
                        }))
                    },
                    loadData: () => {
                        setLoadFinanceTableCreditLimit(
                            financeTableRawData.creditLimit,
                        )
                    },
                }
            }

            x.loading()

            const handler = setTimeout(() => {
                x.loadData()
            }, debounceTime)

            return () => clearTimeout(handler)
        }
    }, [financeData.table.filterPageNumber])

    return {
        // common
        formikFilterCO,
        buttonContentCO,
        buttonContent,
        historyData,
        loadingHistory,
        handlePillsDataCategory,
        handlePillsShipmentEvent,
        handleLoadHistoryData,
        filterDropdownOptions,
        loadingMediumTable,
        mediumTable,
        handlingPageNumberMediumTable,
        loadOptionsFilterDropdown,
        getActiveFieldFilterDataCategory,
        handleFormatValueNumber,
        user,
        smallTable,
        handlingPageNumberSmallTable,
        loadingSmallTable,

        // overview
        dataOverviewTable1,
        tabFilterOverview,
        setTabFilterOverview,
        loadingRelatedKeyFigures,

        // trade lanes
        tradeLanesData,
        tabFilterTradeLanes,
        setTabFilterTradeLanes,
        loadingTradeLanes,

        // customers
        tabFilterCustomers,
        setTabFilterCustomers,

        // sales
        tabFilterSales,
        setTabFilterSales,

        // finance
        loadingFinance,
        tabFilterFinance,
        setTabFilterFinance,
        financePieChart,
        averageMonthlyPerformance,
        financeData,
        handlingPageNumberFinanceTable,
    }
}

export default useControlTower

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
    ConsignorConsigneeResponseData,
    IConsignorConsigneeInitialData,
} from './consignee-consignor.interface'
import { getConsigneeConsignor } from './consignee-consignor.repository'
import { RootState } from 'store'

export const initialStateConsignorConsignee: IConsignorConsigneeInitialData = {
    status: 'idle', //'idle' | 'loading' | 'succeeded' | 'failed'
}

const consignorConsigneeSlice = createSlice({
    name: 'consignorConsigneeData',
    initialState: initialStateConsignorConsignee,
    reducers: {},
    extraReducers(builder) {
        builder
            // User Default Data Call API
            .addCase(getConsigneeConsignor.pending, (state) => {
                state.status = 'loading'
            })
            .addCase(
                getConsigneeConsignor.fulfilled,
                (
                    state,
                    action: PayloadAction<ConsignorConsigneeResponseData>,
                ) => {
                    const { consignee, consignor } = action.payload

                    state.status = 'succeeded'
                    state.data = { consignee, consignor }
                    state.dropdownItems = {
                        consignee: consignee.map((consigneeData) => ({
                            value: consigneeData.company,
                            label: consigneeData.company,
                            additionalData: consigneeData,
                        })),
                        consignor: consignor.map((consignorData) => ({
                            value: consignorData.company,
                            label: consignorData.company,
                            additionalData: consignorData,
                        })),
                    }
                },
            )
            .addCase(
                getConsigneeConsignor.rejected,
                (state, action: PayloadAction<any>) => {
                    state.status = 'failed'
                    state.error = action.payload
                },
            )
    },
})

export const consignorConsigneeSelector = (state: RootState) =>
    state.consignorConsignee || {}

export default consignorConsigneeSlice.reducer

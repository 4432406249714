import AvatarCircle from '@components/avatar-circle/avatar-circle.component'
import { ITableColumn } from '@components/table/table.interface'
import { EPortalAccess, ERoleType, IManageUser } from './manage-users.interface'
import { IConfigureColumns } from '@components/configure-columns-modal/configure-column-modal.interface'
import { formatDateTime } from 'common/common.service'
import { IManageUserLog } from './manage-user-details/manage-users-details.interface'

export const ManageUserHeader: ITableColumn[] = [
    {
        label: 'Image',
        accessor: 'userName',
        showLabel: false,
        exportColumn: false,
        sort: false,
        width: 30, // min width
    },
    {
        label: 'Contact Name',
        accessor: 'userName',
    },
    {
        label: 'Email',
        accessor: 'email',
    },
    {
        label: 'Organisation',
        accessor: 'organizationName',
    },
    {
        label: 'Role',
        accessor: 'role.roleType',
    },
    {
        label: 'Last Access',
        accessor: 'lastLogin',
    },
    {
        label: 'Status',
        accessor: 'isActive',
    },
]

export const ManageConfigureColumns: IConfigureColumns<IManageUser>[] = [
    {
        title: 'Flags',
        columns: [
            {
                statusColumn: 'selectedColumns',
                label: 'Image',
                accessor: '',
                showLabel: false,
                sort: false,
            },
            {
                statusColumn: 'selectedColumns',
                label: 'Contact Name',
                labelTranslation: 'users.contact_name',
                accessor: 'contactName',
            },
            {
                statusColumn: 'selectedColumns',
                labelTranslation: 'users.email',
                label: 'Email',
                accessor: 'email',
            },
            {
                statusColumn: 'selectedColumns',
                labelTranslation: 'users.organisation_name',
                label: 'Organisation Name',
                accessor: 'organizationName',
                customBuild: (data, rowData) => {
                    return <>{rowData?.organization.name}</>
                },
            },
            // {
            //     statusColumn: 'selectedColumns',
            //     labelTranslation: 'users.role',
            //     label: 'Role',
            //     accessor: 'role.roleType',
            //     customBuild: (data) => {
            //         return (
            //             <div>
            //                 {parseInt(data, 10) === 0 ? 'Admin' : 'Regular'}
            //             </div>
            //         )
            //     },
            // },
            {
                statusColumn: 'selectedColumns',
                labelTranslation: 'users.last_access',
                label: 'Last Access',
                accessor: 'lastLogin',
                customBuild: (data) => {
                    return <>{data ? formatDateTime(data) : '-'}</>
                },
            },
            {
                statusColumn: 'selectedColumns',
                labelTranslation: 'users.status',
                label: 'Status',
                accessor: 'isActive',
                customBuild: (data) => {
                    let statusClass: string
                    let label: string
                    if (data) {
                        statusClass =
                            'themes-border-green-v2 themes-bg-green-v1 themes-text-green-v4 '
                        label = 'ACTIVE'
                    } else {
                        statusClass =
                            'themes-border-gray-v4 themes-bg-gray-v2 themes-text-gray-v6 '
                        label = 'SUSPENDED'
                    }

                    return (
                        <div
                            className={`${statusClass} !h-[30px] leading-[12px] border px-2 rounded flex justify-center items-center text-[12px] font-bold min-w-[126px] max-w-[126px]`}
                        >
                            {label}
                        </div>
                    )
                },
            },
        ],
    },
]

export const userActivityHeaders: ITableColumn<IManageUserLog>[] = [
    {
        accessor: 'time',
        label: 'Time',
        labelTranslation: 'users.time',
        sort: true,
        customBuild: (data) => {
            return <div>{formatDateTime(data)}</div>
        },
    },
    {
        accessor: 'action',
        label: 'Action',
        labelTranslation: 'users.action',
        sort: true,
    },
    {
        accessor: 'description',
        label: 'Descriptions',
        labelTranslation: 'users.description',
        sort: true,
    },
]

// intial value
export const currentUserInitialValue: IManageUser = {
    id: '',
    userName: '',
    fullName: '',
    email: '',
    jobTitle: '',
    nativeStaffData: null,
    jobCategory: '',
    organization: {
        code: '',
        name: '',
    },
    country: {
        code: '',
        name: '',
    },
    province: '',
    city: '',
    addressDetails: '',
    mobilePhoneNumber: '',
    homePhoneNumber: '',
    contactName: '',
    phoneNumber: '',
    role: {
        roleType: ERoleType.Regular,
        portalAccess: EPortalAccess.Customer,
        moduleAccessList: [],
    },
    lastLogin: '',
    createdAt: '',
    updatedAt: '',
    deletedAt: null,
    suspendedAt: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    isActive: null,
    resetPassword: null,
    postCode: '',
    address2: '',
}

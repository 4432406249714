import SimpleTable from '@components/simple-table/simple-table.component'
import { metaDummy2 } from 'common/common.static'
import {
    IShippingInstructionContainer,
    IUseShippingInstructionDetails,
} from '../shipping-instruction-details.interface'
import { containerHeaders } from '../shipping-instruction-details.static'

const Container = ({
    service,
}: {
    service: IUseShippingInstructionDetails
}) => {
    return (
        <div className="h-full max-h-[calc(100vh-283px)]">
            <SimpleTable<IShippingInstructionContainer>
                headers={containerHeaders}
                data={service.shippingInstructionDetails.containers}
                usePagination
                nextHandling={(page) => {
                    console.log('hit Next API -> ' + page)
                }}
                previousHandling={(page) => {
                    console.log('hit Prev API -> ' + page)
                }}
                meta={metaDummy2(
                    service.shippingInstructionDetails.containers.length,
                )}
                isBorder={false}
                disableErrorIllustration
            />
        </div>
    )
}

export default Container

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Text, } from 'recharts';
import BarLoading from '../loading/bar-loading.component';
import EmptyResult from '@components/empty-result/empty-result.component';
var BarChartDouble = function (_a) {
    var data = _a.data, errorMsg = _a.errorMsg, _b = _a.isLoading, isLoading = _b === void 0 ? false : _b, renderTooltipValue = _a.renderTooltipValue, disableErrorIllustration = _a.disableErrorIllustration;
    var _c = useState(window.innerWidth), widthSize = _c[0], setWidthSize = _c[1];
    var _d = useState(window.innerHeight), heightSize = _d[0], setHeightSize = _d[1];
    var _e = useState({
        width: widthSize,
        height: 235,
    }), dimension = _e[0], setDimension = _e[1];
    var YAxisCssProperties = useState({
        fontSize: 14, // default px
    })[0];
    var updateDimensions = function () {
        setWidthSize(window.innerWidth);
        setHeightSize(window.innerHeight);
        var newDimension = __assign({}, dimension);
        if (heightSize === 1080)
            newDimension.height = 250;
        else if (heightSize < 1440 && heightSize > 1300) {
            newDimension.height = 440;
        }
        else if (heightSize === 911)
            newDimension.height = 320;
        setDimension(newDimension);
    };
    useEffect(function () {
        window.addEventListener('resize', updateDimensions);
        return function () {
            window.removeEventListener('resize', updateDimensions);
        };
    });
    var RoundedBar = function (props) {
        var x = props.x, y = props.y, width = props.width, height = props.height;
        return (_jsx("g", { children: _jsx("rect", { x: x, y: y, width: width, height: height, fill: props.fill, rx: 2, ry: 2 }) }));
    };
    var CustomizedXAxisTick = function (_a) {
        var x = _a.x, y = _a.y, payload = _a.payload, width = _a.width;
        if (payload === undefined) {
            if (width === undefined)
                width = 0;
            payload = { value: '' };
        }
        var words = payload.value.split(' ');
        var maxWordsPerLine = 3;
        var lines = [];
        for (var i = 0; i < words.length; i += maxWordsPerLine) {
            lines.push(words.slice(i, i + maxWordsPerLine).join(' '));
        }
        var isTextOverlapping = width ? width < lines.join('').length * 15 : 0;
        return (_jsx("g", __assign({ transform: "translate(".concat(x, ",").concat(y, ")") }, { children: isTextOverlapping
                ? null
                : lines.map(function (line, index) { return (_jsx("text", __assign({ x: width ? width / 1000 : 0, y: index * 16, dy: 16, textAnchor: "middle", fill: "#666", fontSize: heightSize < 900 ? 9 : 12 }, { children: line }), index)); }) })));
    };
    var CustomizedLabel = function (props) {
        var x = props.x, y = props.y, width = props.width, height = props.height;
        return (_jsx(Text, { x: x + width / 2, y: y + height / 2, dy: 5, fill: "#fff", textAnchor: "middle" }));
    };
    var calculateLeftMargin = function () {
        var _a;
        // using formula linear line
        // formula intial margin yx = 0.7x−47
        // formula intial interval yx =  0.7x−1
        // formula marginLeft yx = (0.5x-51) + (((0.7x−1) * maxlength) - (0.7x-1))
        // x = fontSize
        var maxValue = Math.max.apply(Math, data.item.map(function (item) { return Math.max(item.uv, item.pv); }));
        var loadCustomYAxisValue = tickYAxisCustomeFormatter(Math.round(maxValue));
        var maxLength = loadCustomYAxisValue.toString().length;
        var x = (_a = YAxisCssProperties.fontSize) !== null && _a !== void 0 ? _a : 0;
        var initialMargin = maxValue < 0.4 ? 20 : maxValue < 4 ? 30 : 37;
        var calculateMargin = 0.5 * x - initialMargin;
        var initialInterval = 0.5 * x - 1;
        var marginLeft = calculateMargin + (initialInterval * maxLength - initialInterval);
        return marginLeft;
    };
    var CustomTooltip = function (_a) {
        var active = _a.active, payload = _a.payload, label = _a.label;
        if (active && payload && payload.length) {
            return (_jsxs("div", __assign({ className: "custom-tooltip p-2 rounded shadow-md themes-bg-gray-v9 opacity-80 themes-text-white" }, { children: [_jsx("p", __assign({ className: "label css-label text-sm font-semibold" }, { children: "".concat(label) })), payload.map(function (entry, index) {
                        if (!entry.value)
                            return _jsx(_Fragment, {});
                        var contentItemvalue = renderTooltipValue
                            ? renderTooltipValue({
                                value: entry === null || entry === void 0 ? void 0 : entry.value,
                                dataKey: entry === null || entry === void 0 ? void 0 : entry.dataKey,
                            })
                            : "item ".concat(index + 1, " : ").concat(entry.value.toLocaleString());
                        return (_jsxs("p", __assign({ className: "css-value-item intro text-xs flex mb-1 items-centes" }, { children: [_jsx("div", { style: { backgroundColor: entry.color }, className: "css-value-item-box w-[20px] h-[20px] rounded-sm mr-3" }), contentItemvalue] }), "item-".concat(index)));
                    })] })));
        }
        return null;
    };
    var tickYAxisCustomeFormatter = function (value) {
        if (value >= 1000000000) {
            return "".concat((value / 1000000000).toFixed(1), "B");
        }
        else if (value >= 1000000) {
            return "".concat((value / 1000000).toFixed(1), "M");
        }
        else if (value >= 1000) {
            return "".concat((value / 1000).toFixed(1), "K");
        }
        return value.toString();
    };
    return (_jsx("div", __assign({ className: "css-bar-chart-double-container w-full h-full" }, { children: isLoading ? (_jsx(BarLoading, {})) : data.item.length <= 0 ? (_jsx("div", __assign({ className: "h-full" }, { children: _jsx(EmptyResult, { className: "w-full", message: errorMsg || 'Data Empty', disableIllustration: disableErrorIllustration }) }))) : (_jsx(ResponsiveContainer, __assign({ width: "100%", height: "100%" }, { children: _jsxs(BarChart, __assign({ data: data.item, margin: {
                    top: 0,
                    right: 0,
                    left: calculateLeftMargin(),
                    bottom: -30,
                } }, { children: [_jsx(CartesianGrid, { stroke: "#f5f5f5" }), _jsx(XAxis, { dataKey: "name", interval: 0, height: 70, tick: _jsx(CustomizedXAxisTick, {}) }), _jsx(YAxis, { type: 'number', style: YAxisCssProperties, tickFormatter: tickYAxisCustomeFormatter }), _jsx(Tooltip, { content: _jsx(CustomTooltip, {}) }), _jsx(Bar, { dataKey: "pv", fill: data.barColor1, shape: _jsx(RoundedBar, {}), label: _jsx(CustomizedLabel, {}) }), _jsx(Bar, { dataKey: "uv", fill: data.barColor2, shape: _jsx(RoundedBar, {}), label: _jsx(CustomizedLabel, {}) })] })) }))) })));
};
export default BarChartDouble;

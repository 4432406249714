import Button from '@components/button/button.component'
import Modal from '@components/modal/modal.component'
import { IUseEditShippingInstruction } from '../edit-shipping-instructions.interface'

const ConfirmationCreateBookingModal = ({
    service,
}: {
    service: IUseEditShippingInstruction
}) => {
    const borderComponent = <div className="border-b"></div>
    return (
        <Modal
            isModalOpen={service.confirmationModal.isModalOpen}
            className="!w-1/4  px-0"
        >
            <div className="flex flex-col gap-3">
                <div className="text-size-M font-bold px-3">
                    {service.confirmationModalProperty.title}
                </div>
                {borderComponent}
                <div className="px-3 flex flex-col gap-2 text-size-S">
                    <div>Are you sure to proceed with this action?</div>
                    <div className="font-bold">
                        {service.confirmationModalProperty.message}
                    </div>
                </div>
                {borderComponent}
                <div className="flex justify-between px-3">
                    <Button
                        onClick={() =>
                            service.confirmationModal.closeModalHandling()
                        }
                        label="cancel"
                        useUpperCase
                        variant="brand-v2-inverse"
                    />
                    <Button
                        onClick={() => {
                            service.confirmationModalProperty.btnProceed.onClick()
                            service.confirmationModal.closeModalHandling()
                        }}
                        label="proceed"
                        useUpperCase
                        variant={
                            service.confirmationModalProperty.btnProceed.variant
                        }
                    />
                </div>
            </div>
        </Modal>
    )
}

export default ConfirmationCreateBookingModal

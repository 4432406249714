/* eslint-disable no-unused-vars */
const Details = () => {
    const loadDataComponent = ({
        label,
        value,
    }: {
        label: string
        value?: string | number
    }) => (
        <div className="leading-none">
            <div className="themes-text-gray-v5">{label}</div>
            <div className="themes-text-gray-v9">{value || '-'}</div>
        </div>
    )
    const containerMode = 'Full Container Load'

    return (
        <div className="p-3 h-full">
            <div className="grid grid-cols-4 sm:grid-cols-2 mobile:!grid-cols-1 gap-3 h-full">
                {/* shipments details */}
                <div className="flex flex-col gap-1">
                    <div className="text-size-L themes-text-gray-v9 mb-1">
                        Shipment Details
                    </div>
                    {loadDataComponent({
                        label: 'Booking No',
                        value: 'BKG0021',
                    })}
                    {loadDataComponent({
                        label: 'Container Mode',
                        value: 'FCL, Full Container Load',
                    })}
                    {loadDataComponent({
                        label: 'Transport Mode',
                        value: 'Air Freight',
                    })}
                    {containerMode !== 'Full Container Load' && (
                        <>
                            {loadDataComponent({
                                label: 'Service Level',
                                value: 'STD, Standard',
                            })}
                            {loadDataComponent({
                                label: 'Outer Packs',
                                value: `20 PLT`,
                            })}
                            {loadDataComponent({
                                label: 'Weight Measure',
                                value: '250.00 KG',
                            })}
                            {loadDataComponent({
                                label: 'Volume Measure',
                                value: '120.00 M3',
                            })}
                        </>
                    )}
                    {loadDataComponent({
                        label: 'Volume Measure',
                        value: '120.00 M3',
                    })}
                </div>

                {/* Origin & destination */}
                <div className="flex flex-col gap-3 border-l pl-3 mobile:pl-0 mobile:border-none">
                    <div className="flex flex-col gap-1">
                        <div className="text-size-L themes-text-gray-v9 mb-1">
                            Origin
                        </div>
                        {loadDataComponent({
                            label: 'Origin Port',
                            value: 'Melbourne',
                        })}
                        {loadDataComponent({
                            label: 'Cargo Ready Date',
                            value: '12 Jun 2024',
                        })}
                    </div>
                    <div className="flex flex-col gap-1">
                        <div className="text-size-L themes-text-gray-v9 my-1">
                            Destination
                        </div>
                        {loadDataComponent({
                            label: 'Destination Port',
                            value: 'Bangkok',
                        })}
                        {loadDataComponent({
                            label: 'Delivery Required By',
                            value: '20 Jun 2024',
                        })}
                    </div>
                </div>

                {/* charges */}
                <div className="flex flex-col gap-3 border-l pl-3 sm:pl-0 sm:border-none">
                    <div className="flex flex-col gap-1">
                        <div className="text-size-L themes-text-gray-v9 mb-1">
                            Charges
                        </div>
                        {loadDataComponent({
                            label: 'INCO Term',
                            value: 'Ex Works',
                        })}

                        {loadDataComponent({
                            label: 'Goods Value',
                            value: `154.00 THB`,
                        })}
                        {loadDataComponent({
                            label: 'Additional Terms',
                            value: 'Customer will issue agreement',
                        })}
                        {loadDataComponent({
                            label: 'Insurance Value',
                            value: `100.00 THB`,
                        })}
                        {loadDataComponent({
                            label: 'Charges Apply',
                            value: 'Show Collect Charges',
                        })}
                    </div>
                </div>

                {/* notes */}
                <div className="flex flex-col gap-3 border-l pl-3 mobile:pl-0 mobile:border-none">
                    <div className="flex flex-col gap-1">
                        <div className="text-size-L themes-text-gray-v9 mb-1">
                            Notes
                        </div>
                        {loadDataComponent({
                            label: 'Marks and Numbers',
                            value: 'Each package is clearly marked with a unique identifier, consisting of a combination of letters and numbers, for easy tracking and identification. Labels include the following marks and numbers: ABC1234567, XYZ8901234, LMN5678901, and QRS2345678. These markings ensure accurate handling and efficient processing throughout the shipping process.',
                        })}
                        {loadDataComponent({
                            label: 'Goods Description',
                            value: 'This shipment includes a variety of high-quality items. Fresh apples, picked at peak ripeness, ensure crisp and juicy fruit ready for consumption. Stylish leather jackets, crafted from premium leather, offer both durability and a fashionable look for all seasons. Soft and comfortable cotton T-shirts, available in various sizes and colors, provide everyday wear versatility. Beautifully crafted wooden furniture, including tables and chairs, features intricate designs and sturdy construction, perfect for home or office use. Each item is meticulously packed to ensure safe transit and delivery, guaranteeing customer satisfaction and product integrity upon arrival.',
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Details

/* eslint-disable no-unused-vars */
import { useModal } from '@components//modal/modal.service'
import useOverlay from '@components/overlay/overlay.service'
import { ITabItem } from '@components/tab/tab.interface'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { bookingConfigureColumns } from '../bookings/bookings.static'
import {
    IBookings,
    IBookingsFilter,
    IBookinsStatusTotal,
    IFilterGenerateReport,
} from '../bookings/bookings.interface'
import { ITableColumn } from '@components/table/table.interface'
import { getBookingsData } from 'repository/booking.repository'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'store'
import {
    setFilter,
    setPageNumber,
    setSelectedStatus,
    setShippingInstructionsSummaryData,
    shippingInstructionsSummarySelector,
} from './shipping-instructions.slice'
import { useFormik } from 'formik'
import { initialFilter } from 'common/common.static'
import {
    bookingFilterInitialValue,
    IFormikBookingFilter,
} from 'form-validation/booking.validation'
import { getEnv } from 'common/common.service'
import {
    CompanyAddressType,
    ConsignorConsigneeResponseData,
    IConsignorConsigneeData,
} from 'repository/consignee-consignor/consignee-consignor.interface'
import { IDropdownItem } from '@components/dropdown/dropdown.interface'
import { getConsigneeConsignor } from 'repository/consignee-consignor/consignee-consignor.repository'
import { consignorConsigneeSelector } from 'repository/consignee-consignor/consignee-consignor.slice'
import {
    IShippingInstructionsSummary,
    IShippingInstructionStatusTotal,
} from './shipping-instructions.interface'
import { shippingInstructionsSummaryDummy } from './shipping-instructions.dummy'
import { IMeta } from 'common/common.interface'

const useShippingInstructions = () => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    // overlay
    const filterOverlayService = useOverlay()
    const newBookingOverlayService = useOverlay()

    // modal
    const generateReportModalService = useModal()
    const ConfigureColumnsModalService = useModal()

    // state
    const [loading, setLoading] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string | undefined>()
    const [tabItems, setTabItems] = useState<ITabItem[]>()
    const [headers, setHeaders] = useState<
        ITableColumn<IShippingInstructionsSummary>[]
    >([])
    const [isInitialized, setIsInitialized] = useState<boolean>(false)
    const [loadingGenerateReport, setLoadingGenerateReport] =
        useState<boolean>(false)
    const [filterGenerateReport, setFilterGenerateReport] =
        useState<IFilterGenerateReport>({
            filter: {},
            isGenerateReport: false,
        })

    // selectors
    const shippingInstructionsSummary = useSelector(
        shippingInstructionsSummarySelector,
    )
    const companyData = useSelector(consignorConsigneeSelector)

    // variable
    const env = getEnv()

    // formiks
    const formikFilter = useFormik<IFormikBookingFilter>({
        initialValues: bookingFilterInitialValue,
        onSubmit: (values) => {
            const tmpFilter: IBookingsFilter = {
                ...shippingInstructionsSummary.filter,
                consignee: values.consignee,
                origin: values.origin,
                destination: values.destination,
                // consignor: values.consignor,
            }
            if (filterGenerateReport.isGenerateReport) {
                setFilterGenerateReport({
                    isGenerateReport: false,
                    filter: tmpFilter,
                })
                updateFormFilter()
            } else {
                dispatch(setFilter(tmpFilter))
            }
        },
    })

    const getCompanyOptions = (
        addressType: CompanyAddressType,
        company?: string,
    ) =>
        dispatch(getConsigneeConsignor({ company })).then(
            (res): IDropdownItem<IConsignorConsigneeData>[] => {
                const consigneeConsignor =
                    res.payload as ConsignorConsigneeResponseData

                return consigneeConsignor?.[addressType].map(
                    (data): IDropdownItem<IConsignorConsigneeData> => ({
                        value: data.company,
                        label: data.company,
                        additionalData: data,
                    }),
                )
            },
        )

    // Get Data Function
    const loadData = async () => {
        try {
            setLoading(true)
            let data = shippingInstructionsSummaryDummy
            let statusTotal: IShippingInstructionStatusTotal = {
                all: data.length,
                submitted: data.filter((i) => i.status === 'submitted').length,
                toBeSubmitted: data.filter(
                    (i) => i.status === 'to be submitted',
                ).length,
            }

            const search = shippingInstructionsSummary.filter.search
            if (search) {
                data = data.filter((i) =>
                    i.bookingNo.toLowerCase().includes(search.toLowerCase()),
                )
                statusTotal = {
                    all: data.length,
                    submitted: data.filter((i) => i.status === 'submitted')
                        .length,
                    toBeSubmitted: data.filter(
                        (i) => i.status === 'to be submitted',
                    ).length,
                }
            }

            // sort by status
            if (shippingInstructionsSummary.statusSelected.value !== 'all') {
                data = data.filter(
                    (i) =>
                        i.status.toLowerCase() ===
                        shippingInstructionsSummary.statusSelected.value.toLowerCase(),
                )
            }

            const meta: IMeta = {
                ...shippingInstructionsSummary.meta,
                current_page: data.length ? 1 : 0,
                total_Items: data.length,
                index_start: data.length ? 1 : 0,
                index_end: data.length,
            }

            dispatch(
                setShippingInstructionsSummaryData({ data, statusTotal, meta }),
            )
            setLoading(false)
        } catch (e) {
            setLoading(false)
            const errorMessage = typeof e !== 'string' ? 'Something wrong' : e
            setErrorMessage(errorMessage)
        }
    }

    // generate tab Items
    const generateTabItems = () => {
        const tabItems: ITabItem[] = [
            {
                label: 'All Bookings',
                labelTranslationCode: 'status.all_bookings',
                totalData: shippingInstructionsSummary.statusTotal.all,
                key: 'status',
                value: 'all',
                className: 'brand-v2',
            },
            {
                label: 'Submitted',
                totalData: shippingInstructionsSummary.statusTotal.submitted,
                key: 'status',
                value: 'submitted',
                className: 'brand-v2',
            },
            {
                label: 'To Be Submitted',
                totalData:
                    shippingInstructionsSummary.statusTotal.toBeSubmitted,
                key: 'status',
                value: 'to be submitted',
                className: 'brand-v2',
            },
        ]
        setTabItems(tabItems)
    }

    const setPageData = (pageNumber: number) => {
        dispatch(setPageNumber(pageNumber))
    }

    const handleSearch = (values: string) => {
        if (values.length >= 3) {
            dispatch(
                setFilter({
                    ...shippingInstructionsSummary.filter,
                    search: values,
                    pageNumber: 1,
                }),
            )
        } else if (values.length <= 0)
            dispatch(
                setFilter({
                    ...shippingInstructionsSummary.filter,
                    search: '',
                    pageNumber: 1,
                }),
            )
    }

    const setTabFilter = async (data: ITabItem) => {
        dispatch(setSelectedStatus(data))
        dispatch(setPageNumber(1))
    }

    const loadGenerateReportData = async (): Promise<IBookings[]> => {
        let generateData: IBookings[] = []
        setLoadingGenerateReport(true)
        try {
            const actionResult = await getBookingsData({
                ...filterGenerateReport,
                status: 'all',
                search: '',
                pageNumber: 1,
                pageSize: shippingInstructionsSummary.meta?.total_Items || 50,
            })
            generateData = actionResult.data
        } catch (e) {
            console.error(e)
        }
        setLoadingGenerateReport(false)
        return generateData
    }

    const updateFormFilter = () => {
        const tmpFilter: IFormikBookingFilter = {
            consignee: shippingInstructionsSummary.filter.consignee || [],
            destination: shippingInstructionsSummary.filter.destination || [],
            origin: shippingInstructionsSummary.filter.origin || [],
            consignor: [],
        }
        formikFilter.setValues(tmpFilter)
    }

    useEffect(() => {
        dispatch(setFilter(initialFilter))
        setIsInitialized(true)
    }, [])

    useEffect(() => {
        generateTabItems()
    }, [shippingInstructionsSummary.statusTotal])

    // get data
    useEffect(() => {
        if (isInitialized) loadData()
    }, [
        shippingInstructionsSummary.filter,
        shippingInstructionsSummary.statusSelected,
        isInitialized,
    ])

    return {
        filterOverlayService,
        newBookingOverlayService,
        generateReportModalService,
        tabItems,
        loading,
        errorMessage,
        setPageData,
        setLoading,
        handleSearch,
        setErrorMessage,
        navigate,
        setTabItems,
        setTabFilter,
        shippingInstructionsSummary,
        ConfigureColumnsModalService,
        headers,
        formikFilter,
        updateFormFilter,
        loadingGenerateReport,
        loadGenerateReportData,
        env,
        companyData,
        getCompanyOptions,
        bookingConfigureColumns,
        setHeaders,
    }
}

export default useShippingInstructions

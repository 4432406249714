import {
    IDataOverviewTable1,
    ISmallTableData,
    IDataTradeLanesTable,
    IFinanceProfitAndLossTableData,
    IMarkShipmentCustomersAndSales,
    IHistoryDevelopmentData,
    IMediumTableData,
    ICreditLimitTable,
} from './control-tower.interface'
import { ITabItem } from '@components/tab/tab.interface'
import { IItemListInfo } from '@components/item-list-info/item-list-info.interface'
import { ISPTColumn } from '@components/split-table/split-table.interface'
import { IDropdownItem } from '@components/dropdown/dropdown.interface'
import {
    IProgressMultipleBarKeys,
    IProgressMultipleBarsData,
} from '@components/progress-multiple-bars/progress-multiple-bars.interface'
import { convertToNumber, customRounding } from 'common/common.service'
import { numberWithCommas } from '@services/common.service'
import { IPillItem } from '@components/pills/pills.component'
import {
    IFilterDataCategoryCO,
    IFilterShipmentEventCO,
} from 'form-validation/control-tower.validation'
import { IBarchartStacktedDataItem } from '@components/rechart-component/rechart-interface'
import { InfoCardGroupItems } from '@components/InfoCardGroup/Info-card-group.interface'
import { IToastProps } from '@components/toast/toast.interface'
import { IPieChartData } from '@components/rechart-component/pie/doughnut-chart.component'

// segment common
export const dataCategoryPillItems: IPillItem<IFilterDataCategoryCO>[] = [
    {
        label: 'SHPs',
        value: 'Shipments',
    },
    {
        label: 'REV',
        value: 'Revenue',
    },
    {
        label: 'GP',
        value: 'GrossProfit',
    },
    {
        label: 'VOL',
        value: 'Volume',
    },
]
export const shipmentEventPillItems: IPillItem<IFilterShipmentEventCO>[] = [
    {
        label: 'DOMESTIC',
        value: 'Domestic',
    },
    {
        label: 'IMP',
        value: 'IMP',
    },
    {
        label: 'EXP',
        value: 'EXP',
    },
    {
        label: 'CROSS-TRADE',
        value: 'CrossTrade',
    },
]
export const initialDataHistoryDevelopment: IHistoryDevelopmentData = {
    averageInfo: {
        avgCurrentRevenue: 0,
        avgCurrentGp: 0,
        avgCurrentShipment: 0,
        avgCurrentVolume: 0,
    },
    graphData: [],
}
export const initialDataBarChartSplitRecord: IBarchartStacktedDataItem[] = [
    {
        name: 'This Period',
        f1: 0,
        f2: 0,
        amt: 1000,
    },
    {
        name: 'Last Period',
        f1: 0,
        f2: 0,
        amt: 1000,
    },
]
export const historySplitRecordSummaryIntialvalue: InfoCardGroupItems[] = [
    {
        value: '0',
        label: 'Nomination',
    },
    {
        value: '0',
        label: 'Local Control',
    },
]
export const mediumTableHeaders: ISPTColumn<IMediumTableData>[] = [
    {
        header: [
            {
                accessor: 'index',
                label: 'Mark',
                customBuild: ({ index }) => {
                    const markColor =
                        markColorMediumTable[index < 6 ? index : 6]
                    return (
                        <div
                            className={`w-[20px] h-[20px] rounded`}
                            style={{ backgroundColor: markColor }}
                        ></div>
                    )
                },
            },
            { accessor: 'name', label: 'Name' },
            { accessor: 'thisPeriod', label: 'This Period' },
            { accessor: 'lastPeriod', label: 'Last Period' },
        ],
        width: '35%',
    },
    {
        header: [
            {
                accessor: 'development',
                label: 'Development',
                customBuild: ({ development }) => {
                    let classNames: string = ''
                    let valueConetnt: string = ''
                    const resultValueNumber = convertToNumber(development.value)

                    if (resultValueNumber > 0) {
                        classNames =
                            'themes-border-green-v2 themes-bg-green-v1 themes-text-green-v4'
                        valueConetnt = `+${numberWithCommas(development.value.toString())} (${development.percentage}%)`
                    } else if (resultValueNumber === 0) {
                        classNames =
                            'themes-border-gray-v2 themes-bg-gray-v1 themes-text-gray-v9'
                        valueConetnt = `${numberWithCommas(development.value.toString())} (${development.percentage}%)`
                    } else {
                        classNames =
                            'themes-border-red-v2 themes-bg-red-v1 themes-text-red-v4'
                        valueConetnt = `${numberWithCommas(development.value.toString())} (${development.percentage}%)`
                    }
                    return (
                        <div
                            className={`${classNames}  rounded w-[200px] px-2 border truncate`}
                        >
                            {valueConetnt}
                        </div>
                    )
                },
            },
        ],
        width: '20%',
    },
    {
        header: [
            { accessor: 'rev', label: '% of Rev' },
            { accessor: 'gp', label: '% of GP' },
            { accessor: 'vol', label: '% of VOL' },
            { accessor: 'gpPerShipment', label: 'Avg. GP per Shipment' },
        ],
        width: '45%',
    },
]
export const markColorMediumTable: string[] = [
    '#5280C6',
    '#76C6CC',
    '#9D75AD',
    '#C98167',
    '#E3CD9A',
    '#C9677F',
    '#D9DDE1',
]
export const multipleBarsMediumTableDataIntialValue: IProgressMultipleBarsData<IMarkShipmentCustomersAndSales> =
    {
        total: 0,
        items: {
            value: {
                blue: 0,
                aqua: 0,
                purple: 0,
                orange: 0,
                yellow: 0,
                red: 0,
                gray: 0,
            },
        },
    }
export const mediumTableprogressMultipleBarKey: IProgressMultipleBarKeys<IMarkShipmentCustomersAndSales>[] =
    [
        {
            key: 'blue',
            bgColor: '#5280C6',
            rendervalue: ({ value }) =>
                customRounding({
                    value: value?.blue || 0,
                    useCheckDecimal: true,
                }).toString() + ' %',
        },
        {
            key: 'aqua',
            bgColor: '#76C6CC',
            rendervalue: ({ value }) =>
                customRounding({
                    value: value?.aqua || 0,
                    useCheckDecimal: true,
                }).toString() + '%',
        },
        {
            key: 'purple',
            bgColor: '#9D75AD',
            rendervalue: ({ value }) =>
                customRounding({
                    value: value?.purple || 0,
                    useCheckDecimal: true,
                }).toString() + '%',
        },
        {
            key: 'orange',
            bgColor: '#C98167',
            rendervalue: ({ value }) =>
                customRounding({
                    value: value?.orange || 0,
                    useCheckDecimal: true,
                }).toString() + '%',
        },
        {
            key: 'yellow',
            bgColor: '#E3CD9A',
            rendervalue: ({ value }) =>
                customRounding({
                    value: value?.yellow || 0,
                    useCheckDecimal: true,
                }).toString() + '%',
        },
        {
            key: 'red',
            bgColor: '#C9677F',
            rendervalue: ({ value }) =>
                customRounding({
                    value: value?.red || 0,
                    useCheckDecimal: true,
                }).toString() + '%',
        },
        {
            key: 'gray',
            bgColor: '#D9DDE1',
            rendervalue: ({ value }) =>
                customRounding({
                    value: value?.gray || 0,
                    useCheckDecimal: true,
                }).toString() + '%',
        },
    ]
export const toastInitialValue: IToastProps = {
    header: '',
    type: 'error',
}
export const multipleBarsMediumTableInitialValue: IProgressMultipleBarsData<IMarkShipmentCustomersAndSales> =
    {
        total: 0,
        items: {
            value: {
                blue: 0,
                aqua: 0,
                purple: 0,
                orange: 0,
                yellow: 0,
                red: 0,
                gray: 0,
            },
        },
    }
export const summaryMediumTableIntialValue: InfoCardGroupItems[] = [
    {
        value: '0',
        label: 'Overall Shipments',
    },
    {
        value: `AUD 0.00`,
        label: 'Overall Revenue',
    },
    {
        value: `AUD 0.00`,
        label: 'Overall Gross Profit',
    },
    {
        value: `0.00 M3`,
        label: 'Overall Volume',
    },
]
export const historySplitRecordChartDataIntialValue: IBarchartStacktedDataItem[] =
    [
        {
            name: 'This Period',
            f1: 0,
            f2: 0,
            amt: 10,
        },
        {
            name: 'Last Period',
            f1: 0,
            f2: 0,
            amt: 10,
        },
    ]

// segment overview
export const tabItemFilterOverview: ITabItem[] = [
    {
        label: 'Customer',
        key: 'status',
        value: 'Customer',
        className: 'brand',
    },
    {
        label: 'Branch',
        key: 'status',
        value: 'Branch',
        className: 'brand',
    },
    {
        label: 'Agents',
        key: 'status',
        value: 'Agent',
        className: 'brand',
    },
]

export const overviewHistoryDevelpmentInfo: IItemListInfo[] = [
    {
        label: 'This Period',
        color: 'gray-v5',
    },
    {
        label: 'Last Period',
        color: 'gray-v3',
    },
]

export const overviewHistorySplitRecord: IItemListInfo[] = [
    {
        label: 'Nomination',
        color: 'gray-v5',
    },
    {
        label: 'Local Control',
        color: 'gray-v3',
    },
]

export const tableOverviewHeaders1: ISPTColumn<IDataOverviewTable1>[] = [
    {
        header: [
            { accessor: 'item', label: 'Items' },
            { accessor: 'thisPeriod', label: 'This Period' },
            { accessor: 'lastPeriod', label: 'Last Period' },
        ],
        width: '55%',
    },
    {
        header: [
            {
                accessor: 'change',
                label: 'Change',
                customBuild: ({ change }) => {
                    let classNames: string = ''
                    let isPositifvalue = false

                    if (change.amount > 0) {
                        classNames =
                            'themes-border-green-v2 themes-bg-green-v1 themes-text-green-v4'
                        isPositifvalue = true
                    } else if (change.amount === 0) {
                        classNames =
                            'themes-border-gray-v2 themes-bg-gray-v1 themes-text-gray-v9'
                    } else {
                        classNames =
                            'themes-border-red-v2 themes-bg-red-v1 themes-text-red-v4'
                    }
                    return (
                        <div
                            className={`${classNames}  rounded w-[200px] px-2 border truncate`}
                        >
                            {isPositifvalue && '+'}
                            {change.labelValue}
                        </div>
                    )
                },
            },
        ],
        width: '45%',
    },
]

export const tableOverviewHeaders2: ISPTColumn<ISmallTableData>[] = [
    {
        header: [
            { accessor: 'name', label: 'Name' },
            { accessor: 'thisPeriod', label: 'This Period' },
            { accessor: 'lastPeriod', label: 'Last Period' },
        ],
        width: '55%',
    },
    {
        header: [
            {
                accessor: 'change',
                label: 'Change',
                customBuild: ({ change }) => {
                    let classNames: string = ''
                    let valueConetnt: string = ''
                    const resultValueNumber = convertToNumber(change.value)

                    if (resultValueNumber > 0) {
                        classNames =
                            'themes-border-green-v2 themes-bg-green-v1 themes-text-green-v4'
                        valueConetnt = `+${change.value} (${change.percentage}%)`
                    } else if (resultValueNumber === 0) {
                        classNames =
                            'themes-border-gray-v2 themes-bg-gray-v1 themes-text-gray-v9'
                        valueConetnt = `${change.value} (${change.percentage}%)`
                    } else {
                        classNames =
                            'themes-border-red-v2 themes-bg-red-v1 themes-text-red-v4'
                        valueConetnt = `${change.value} (${change.percentage}%)`
                    }
                    return (
                        <div
                            className={`${classNames}  rounded w-[200px] px-2 border truncate`}
                        >
                            {valueConetnt}
                        </div>
                    )
                },
            },
        ],
        width: '45%',
    },
]

// segment trade lanes
export const tabItemFilterTradeLanes: ITabItem[] = [
    {
        label: 'Countries',
        key: 'status',
        value: 'Countries',
        className: 'brand',
    },
    {
        label: 'Ports',
        key: 'status',
        value: 'Ports',
        className: 'brand',
    },
    {
        label: 'Lanes',
        key: 'status',
        value: 'Lanes',
        className: 'brand',
    },
]
export const tableTradeLanesHeaders: ISPTColumn<IDataTradeLanesTable>[] = [
    {
        header: [
            { accessor: 'company', label: 'Company' },
            { accessor: 'thisPeriod', label: 'This Period' },
            { accessor: 'lastPeriod', label: 'Last Period' },
        ],
        width: '55%',
    },
    {
        header: [
            {
                accessor: 'change',
                label: 'Change',
                customBuild: ({ change }) => {
                    var firstChar = change.slice(0, 1)

                    let classNames: string = ''

                    if (firstChar === '+') {
                        classNames =
                            'themes-border-green-v2 themes-bg-green-v1 themes-text-green-v4'
                    } else if (firstChar === '-') {
                        classNames =
                            'themes-border-red-v2 themes-bg-red-v1 themes-text-red-v4'
                    }

                    return (
                        <div
                            className={`${classNames}  rounded w-[200px] px-2 border`}
                        >
                            {change}
                        </div>
                    )
                },
            },
        ],
        width: '45%',
    },
]
export const regionPieChartData: IPieChartData<{
    formatNumberValue: string
}>[] = [
    {
        name: 'SOUTHEAST Asia',
        value: 0,
        fill: '#5280C6',
        textColor: '#FFFFFF',
    },
    {
        name: 'SUB-SAHARAN',
        value: 0,
        fill: '#76C6CC',
        textColor: '#FFFFFF',
    },
    {
        name: 'MIDDLE EAST',
        value: 0,
        fill: '#9D75AD',
        textColor: '#FFFFFF',
    },
    {
        name: 'LATIN AMERICA',
        value: 0,
        fill: '#E3CD9A',
        textColor: '#FFFFFF',
    },
    {
        name: 'WESTERN EUROPE',
        value: 0,
        fill: '#C98167',
        textColor: '#FFFFFF',
    },
    {
        name: 'NORTHERN AMERICA',
        value: 0,
        fill: '#C9677F',
        textColor: '#FFFFFF',
    },
    {
        name: 'AUSTRALIA',
        value: 0,
        fill: '#84B37D',
        textColor: '#FFFFFF',
    },
    {
        name: 'OTHERS',
        value: 0,
        fill: '#465A6C',
        textColor: '#FFFFFF',
    },
]
export const tradeLanesRegionInitialValue: {
    pieChart: IPieChartData<{ formatNumberValue: string }>[]
    infoCardItems: InfoCardGroupItems[]
} = {
    pieChart: regionPieChartData,
    infoCardItems: [
        {
            value: '0',
            label: 'Overall Shipments',
        },
        {
            value: '0',
            label: 'Overall Region',
        },
    ],
}
export const tradeLanesCarrierInitialValue: {
    pieChart: IPieChartData<{ formatNumberValue: string }>[]
    infoCardItems: InfoCardGroupItems[]
} = {
    pieChart: [
        {
            name: 'MAERSK LINE',
            value: 0,
            fill: '#5280C6',
            textColor: '#FFFFFF',
        },
        {
            name: 'Mediterranean',
            value: 0,
            fill: '#76C6CC',
            textColor: '#FFFFFF',
        },
        {
            name: 'CMA CGM',
            value: 0,
            fill: '#9D75AD',
            textColor: '#FFFFFF',
        },
        {
            name: 'EVERGREEN LINE',
            value: 0,
            fill: '#E3CD9A',
            textColor: '#FFFFFF',
        },
        {
            name: 'HAPAG-LLOYD',
            value: 0,
            fill: '#C98167',
            textColor: '#FFFFFF',
        },
        {
            name: 'COSCO SHIPPING',
            value: 0,
            fill: '#C9677F',
            textColor: '#FFFFFF',
        },
        {
            name: 'UPS',
            value: 0,
            fill: '#84B37D',
            textColor: '#FFFFFF',
        },
        {
            name: 'OTHERS',
            value: 0,
            fill: '#465A6C',
            textColor: '#FFFFFF',
        },
    ],
    infoCardItems: [
        {
            value: '0',
            label: 'Overall Carrier',
        },
    ],
}

// segment customers
export const tabItemFilterCustomers: ITabItem[] = [
    {
        label: 'Organisations',
        key: 'status',
        value: 'Organisations',
        className: 'brand',
    },
]

export const progressMultipleBarKeyControlTower: IProgressMultipleBarKeys<
    IMarkShipmentCustomersAndSales,
    IMarkShipmentCustomersAndSales
>[] = [
    {
        key: 'blue',
        bgColor: '#5280C6',
        rendervalue: ({ additionalValue }) =>
            additionalValue?.blue.toString() + ' %',
    },
    {
        key: 'aqua',
        bgColor: '#76C6CC',
        rendervalue: ({ additionalValue }) =>
            additionalValue?.aqua.toString() + '%',
    },
    {
        key: 'purple',
        bgColor: '#9D75AD',
        rendervalue: ({ additionalValue }) =>
            additionalValue?.purple.toString() + '%',
    },
    {
        key: 'orange',
        bgColor: '#C98167',
        rendervalue: ({ additionalValue }) =>
            additionalValue?.orange.toString() + '%',
    },
    {
        key: 'yellow',
        bgColor: '#E3CD9A',
        rendervalue: ({ additionalValue }) =>
            additionalValue?.yellow.toString() + '%',
    },
    {
        key: 'red',
        bgColor: '#C9677F',
        rendervalue: ({ additionalValue }) =>
            additionalValue?.red.toString() + '%',
    },
    {
        key: 'gray',
        bgColor: '#D9DDE1',
        rendervalue: ({ additionalValue }) =>
            additionalValue?.gray.toString() + '%',
    },
]

// segment sales
export const tabItemFilterSales: ITabItem[] = [
    {
        label: 'Sales Person',
        key: 'status',
        value: 'Contact',
        className: 'brand',
    },
    {
        label: 'Organisations',
        key: 'status',
        value: 'Organisations',
        className: 'brand',
    },
]

// segment finance
export const tabItemFilterFinance: ITabItem[] = [
    {
        label: 'P&L',
        key: 'status',
        value: 'P&L',
        className: 'brand',
    },
    {
        label: 'Credit Limit',
        key: 'status',
        value: 'Credit Limit',
        className: 'brand',
    },
]
export const tableFinanceHeaders: ISPTColumn<IFinanceProfitAndLossTableData>[] =
    [
        {
            header: [
                { accessor: 'name', label: 'Name' },
                { accessor: 'thisPeriod', label: 'This Period' },
                { accessor: 'lastPeriod', label: 'Last Period' },
            ],
            width: '55%',
        },
        {
            header: [
                {
                    accessor: 'change',
                    label: 'Change',
                    customBuild: ({ change }) => {
                        let classNames: string = ''
                        let valueConetnt: string = ''
                        const resultValueNumber = convertToNumber(change.value)

                        if (resultValueNumber > 0) {
                            classNames =
                                'themes-border-green-v2 themes-bg-green-v1 themes-text-green-v4'
                            valueConetnt = `+${change.labelValue} (${change.percentage}%)`
                        } else if (resultValueNumber < 0) {
                            classNames =
                                'themes-border-red-v2 themes-bg-red-v1 themes-text-red-v4'
                            valueConetnt = `${change.labelValue} (${change.percentage}%)`
                        } else {
                            classNames =
                                'themes-border-gray-v2 themes-bg-gray-v1 themes-text-gray-v9'
                            valueConetnt = `${change.labelValue} (${change.percentage}%)`
                        }
                        return (
                            <div
                                className={`${classNames}  rounded w-[200px] px-2 border truncate`}
                            >
                                {valueConetnt}
                            </div>
                        )
                    },
                },
            ],
            width: '45%',
        },
    ]
export const tableFinanceCreditLimitHeaders: ISPTColumn<ICreditLimitTable>[] = [
    {
        header: [
            { accessor: 'organization', label: 'Organisation' },
            { accessor: 'creditLimit', label: 'Credit Limit' },
        ],
        width: '100%',
    },
]
export const itemListShipmentsFinance: IItemListInfo[] = [
    {
        label: 'Avg. GP',
        color: 'extra-blue-v4',
    },
    {
        label: 'Avg. OPEX',
        color: 'extra-red-v4',
    },
    {
        label: 'Avg. NP',
        color: 'extra-blue-v2',
    },
]

export const financeProfitAndLostTableInitialData: IFinanceProfitAndLossTableData[] =
    [
        {
            name: 'Revenue',
            thisPeriod: 'AUD 0.00',
            lastPeriod: 'AUD 0.00',
            change: {
                value: '0.00',
                labelValue: 'AUD 0.00',
                percentage: '0.00',
            },
            subData: [
                {
                    name: 'Freight',
                    thisPeriod: 'AUD 0.00',
                    lastPeriod: 'AUD 0.00',
                    change: {
                        value: '0.00',
                        labelValue: 'AUD 0.00',
                        percentage: '0.00',
                    },
                    key: 'Freight Gp',
                },
                {
                    name: 'Port and Terminal',
                    thisPeriod: 'AUD 0.00',
                    lastPeriod: 'AUD 0.00',
                    change: {
                        value: '0.00',
                        labelValue: 'AUD 0.00',
                        percentage: '0.00',
                    },
                    key: 'Port And Terminal Gp',
                },

                {
                    name: 'Handling',
                    thisPeriod: 'AUD 0.00',
                    lastPeriod: 'AUD 0.00',
                    change: {
                        value: '0.00',
                        labelValue: 'AUD 0.00',
                        percentage: '0.00',
                    },
                    key: 'Handling Gp',
                },
                {
                    name: 'Other',
                    thisPeriod: 'AUD 0.00',
                    lastPeriod: 'AUD 0.00',
                    change: {
                        value: '0.00',
                        labelValue: 'AUD 0.00',
                        percentage: '0.00',
                    },
                    key: 'Other Gp',
                },
            ],
        },
        {
            name: 'Cost',
            thisPeriod: 'AUD 0.00',
            lastPeriod: 'AUD 0.00',
            change: {
                value: '0.00',
                labelValue: 'AUD 0.00',
                percentage: '0.00',
            },
            key: 'cost',
            subData: [
                {
                    name: 'Freight',
                    thisPeriod: 'AUD 0.00',
                    lastPeriod: 'AUD 0.00',
                    change: {
                        value: '0.00',
                        labelValue: 'AUD 0.00',
                        percentage: '0.00',
                    },
                    key: 'Freight Gp',
                },
                {
                    name: 'Port and Terminal',
                    thisPeriod: 'AUD 0.00',
                    lastPeriod: 'AUD 0.00',
                    change: {
                        value: '0.00',
                        labelValue: 'AUD 0.00',
                        percentage: '0.00',
                    },
                    key: 'Port and Terminal Gp',
                },

                {
                    name: 'Handling',
                    thisPeriod: 'AUD 0.00',
                    lastPeriod: 'AUD 0.00',
                    change: {
                        value: '0.00',
                        labelValue: 'AUD 0.00',
                        percentage: '0.00',
                    },
                    key: 'Handling Gp',
                },
                {
                    name: 'Other',
                    thisPeriod: 'AUD 0.00',
                    lastPeriod: 'AUD 0.00',
                    change: {
                        value: '0.00',
                        labelValue: 'AUD 0.00',
                        percentage: '0.00',
                    },
                    key: 'Other Gp',
                },
            ],
        },

        {
            name: 'GP',
            thisPeriod: 'AUD 0.00',
            lastPeriod: 'AUD 0.00',
            change: {
                value: '0.00',
                labelValue: 'AUD 0.00',
                percentage: '0.00',
            },
            key: 'gp',
        },
        {
            name: 'OPEX',
            thisPeriod: 'AUD 0.00',
            lastPeriod: 'AUD 0.00',
            change: {
                value: '0.00',
                labelValue: 'AUD 0.00',
                percentage: '0.00',
            },
            subData: [
                {
                    name: 'Human Resources',
                    thisPeriod: 'AUD 0.00',
                    lastPeriod: 'AUD 0.00',
                    change: {
                        value: '0.00',
                        labelValue: 'AUD 0.00',
                        percentage: '0.00',
                    },
                },
                {
                    name: 'Marketing',
                    thisPeriod: 'AUD 0.00',
                    lastPeriod: 'AUD 0.00',
                    change: {
                        value: '0.00',
                        labelValue: 'AUD 0.00',
                        percentage: '0.00',
                    },
                },
                {
                    name: 'Travel & Entertainment',
                    thisPeriod: 'AUD 0.00',
                    lastPeriod: 'AUD 0.00',
                    change: {
                        value: '0.00',
                        labelValue: 'AUD 0.00',
                        percentage: '0.00',
                    },
                },
                {
                    name: 'IT & Telecom',
                    thisPeriod: 'AUD 0.00',
                    lastPeriod: 'AUD 0.00',
                    change: {
                        value: '0.00',
                        labelValue: 'AUD 0.00',
                        percentage: '0.00',
                    },
                },
                {
                    name: 'Rent & Utility',
                    thisPeriod: 'AUD 0.00',
                    lastPeriod: 'AUD 0.00',
                    change: {
                        value: '0.00',
                        labelValue: 'AUD 0.00',
                        percentage: '0.00',
                    },
                },
                {
                    name: 'Other OPEX',
                    thisPeriod: 'AUD 0.00',
                    lastPeriod: 'AUD 0.00',
                    change: {
                        value: '0.00',
                        labelValue: 'AUD 0.00',
                        percentage: '0.00',
                    },
                },
            ],
            key: 'opex',
        },
        {
            name: 'Net Profit',
            thisPeriod: 'AUD 0.00',
            lastPeriod: 'AUD 0.00',
            change: {
                value: '0.00',
                labelValue: 'AUD 0.00',
                percentage: '0.00',
            },
            key: 'netProfit',
        },
    ]
export const financeOpenInvoiceInitialValue: {
    infoCardItems: InfoCardGroupItems[]
    pieChart: IPieChartData[]
} = {
    infoCardItems: [
        {
            value: '0',
            label: 'Total open invoices',
        },
        {
            value: '0',
            label: 'Total open amount',
        },
    ],
    pieChart: [
        {
            name: 'Within payment term',
            value: 0,
            fill: '#C0C7CD',
            textColor: '#FFFFFF',
            key: 'Within Term',
        },
        {
            name: '1 week overdue',
            value: 0,
            fill: '#5B6C7C',
            textColor: '#FFFFFF',
            key: '1 week',
        },
        {
            name: '2 weeks overdue',
            value: 0,
            fill: '#FFE3A4',
            textColor: '#FFFFFF',
            key: '2 week',
        },
        {
            name: '1 month overdue',
            value: 0,
            fill: '#E3CD9A',
            textColor: '#FFFFFF',
            key: '1 month',
        },
        {
            name: '3 months overdue',
            value: 0,
            fill: '#F691A9',
            textColor: '#FFFFFF',
            key: '3 month',
        },
        {
            name: '6 months overdue',
            value: 0,
            fill: '#C9677F',
            textColor: '#FFFFFF',
            key: '6 month',
        },
    ],
}

import SimpleTable from '@components/simple-table/simple-table.component'
import { shipmentPackLineHeaders } from '../../shipments.static'
import { IShipmentPackLine } from 'repository/interface/shipment.interface'
import { IUseShipmentDetails } from '../shipment-details.interface'

const PackLines = ({ useService }: { useService: IUseShipmentDetails }) => {
    return (
        <div className="h-full max-h-[calc(100vh-283px)]">
            <SimpleTable<IShipmentPackLine>
                headers={shipmentPackLineHeaders}
                data={useService.shipmentDetails.packLine.data}
                usePagination
                nextHandling={(page) => {
                    useService.handlingPageNumberPackLine(page)
                }}
                previousHandling={(page) => {
                    useService.handlingPageNumberPackLine(page)
                }}
                meta={useService.shipmentDetails.packLine.meta}
                isBorder={false}
                disableErrorIllustration
            />
        </div>
    )
}

export default PackLines

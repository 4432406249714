import Modal from '@components/modal/modal.component'
import { FormikProvider } from 'formik'
import Button from '@components/button/button.component'
import FormInput from '@components/form-input/form-input.component'
import FormDropdown from '@components/form-dropdown/form-dropdown.component'
import { IUseEditShippingInstruction } from '../edit-shipping-instructions.interface'

const FormContainerModal = ({
    service,
}: {
    service: IUseEditShippingInstruction
}) => {
    const borderComponent = <div className="border-b"></div>
    const formTitle =
        service.containerParams === 'add' ? 'Add Container' : 'Edit Container'
    return (
        <Modal
            isModalOpen={service.formContainerModal.isModalOpen}
            className="!w-[25%] px-0 flex flex-col gap-3"
        >
            <>
                <div className="px-3 themes-text-gray-v9 text-size-M">
                    {formTitle}
                </div>
                {borderComponent}
                <div className="px-3 flex flex-col gap-3 mobile:h-[calc(100vh-22rem)] mobile:overflow-auto">
                    <FormikProvider
                        value={service.editShippingInstructionContainerFormik}
                    >
                        <FormInput
                            label={'CONTAINER NO'}
                            name={`containerNo`}
                            placeholder={'Enter here'}
                            parentDivClassName="!mb-0"
                            useArrow={false}
                        />
                        <FormDropdown
                            isSearchable
                            isClearable
                            label={'CONTAINER TYPE'}
                            name={`type`}
                            placeholder={'Select here'}
                            options={[
                                {
                                    value: '20 GP',
                                    label: '20 GP',
                                },
                            ]}
                            parentDivClassName="!mb-0"
                            useUppercaseLabel
                        />
                        <FormInput
                            label={'Count'}
                            name={`count`}
                            placeholder={'Enter here'}
                            parentDivClassName="!mb-0"
                            type={'number'}
                            useUppercaseLabel
                            useArrow={false}
                            maxLength={36}
                        />
                        <FormInput
                            label={'Seal Number'}
                            name={`sealNo`}
                            placeholder={'Enter here'}
                            parentDivClassName="!mb-0"
                            useUppercaseLabel
                        />
                    </FormikProvider>
                </div>
                {borderComponent}
                <div className="flex  px-3 justify-between">
                    <Button
                        onClick={() => {
                            service.formContainerModal.closeModalHandling()
                        }}
                        label="CANCEL"
                        variant="brand-v2-inverse"
                    />
                    <Button
                        onClick={() => {
                            service.editShippingInstructionContainerFormik.submitForm()
                        }}
                        label="PROCEED"
                        variant="brand-v2-inverse"
                    />
                </div>
            </>
        </Modal>
    )
}

export default FormContainerModal

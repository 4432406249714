export interface IEmpityResultIfb {
    message?: string
    classNames?: string
}

const EmpityResultIfb = ({ ...props }: IEmpityResultIfb) => {
    const message = props.message ? props.message : 'No Data Found'
    return (
        <div className="h-full w-full flex justify-center items-center">
            {message}
        </div>
    )
}

export default EmpityResultIfb

import { IDropdownItem } from '@components/dropdown/dropdown.interface'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import {
    CompanyAddressType,
    ConsignorConsigneeResponseData,
    IConsignorConsigneeData,
} from 'repository/consignee-consignor/consignee-consignor.interface'
import { getConsigneeConsignor } from 'repository/consignee-consignor/consignee-consignor.repository'
import { consignorConsigneeSelector } from 'repository/consignee-consignor/consignee-consignor.slice'
import { useAppDispatch } from 'store'

const useShipmentFilter = () => {
    const dispatch = useAppDispatch()
    const companyData = useSelector(consignorConsigneeSelector)
    const getCompanyOptions = (
        addressType: CompanyAddressType,
        company?: string,
    ) =>
        dispatch(getConsigneeConsignor({ company })).then(
            (res): IDropdownItem<IConsignorConsigneeData>[] => {
                const consigneeConsignor =
                    res.payload as ConsignorConsigneeResponseData

                return consigneeConsignor?.[addressType].map(
                    (data): IDropdownItem<IConsignorConsigneeData> => ({
                        value: data.company,
                        label: data.company,
                        additionalData: data,
                    }),
                )
            },
        )

    return {
        companyData,
        getCompanyOptions,
    }
}

export default useShipmentFilter

import { relatedPartiesDetailsDummy } from 'pages/vendor-bookings/vendor-bookings.dummy'
import {
    propertyRelatedPartiesComponent,
    relatedPartiesComponentHeaders,
} from '../shipping-instruction-details.static'
import { IUseShippingInstructionDetails } from '../shipping-instruction-details.interface'

const RelatedParties = ({
    service,
}: {
    service: IUseShippingInstructionDetails
}) => {
    return (
        <div className="p-3 h-full">
            <div className="grid grid-cols-4 gap-3 sm:grid-cols-2 mobile:!grid-cols-1 h-full">
                {propertyRelatedPartiesComponent.map((item, idx) => (
                    <div
                        className={`flex flex-col gap-1 ${idx > 0 ? 'border-l sm:!border-none pl-3 sm:!pl-0' : ''}`}
                    >
                        <div className="text-size-L themes-text-gray-v9 mb-1">
                            {item.title}
                        </div>
                        {relatedPartiesComponentHeaders.map((item2) => (
                            <div className="leading-none">
                                <div className="themes-text-gray-v5">
                                    {item2.label}
                                </div>
                                <div className="themes-text-gray-v9">
                                    {relatedPartiesDetailsDummy[item.entity][
                                        item2.accessor
                                    ] || '-'}
                                </div>
                            </div>
                        ))}
                    </div>
                ))}
            </div>
        </div>
    )
}

export default RelatedParties

import FormDropdown from '@components/form-dropdown/form-dropdown.component'
import FormInput from '@components/form-input/form-input.component'
import { useTranslation } from 'react-i18next'
import FormMeasurementInput from '@components/form-measurement-input/form-measurement-input.component'
import FormTextarea from '@components/form-textarea/form-textarea.component'
import { IUseEditShippingInstruction } from '../edit-shipping-instructions.interface'
import FormDatePicker from '@components/form-date-picker/form-date-picker.component'

const Details = ({ service }: { service: IUseEditShippingInstruction }) => {
    const { t } = useTranslation()

    const containerMode =
        service.editShippingInstructionFormik.values.containerMode

    return (
        <div className="overflow-auto h-inherit">
            <div className={`grid grid-cols-4 gap-3 p-3`}>
                {/* Shipment Details */}
                <div className="flex flex-col gap-3">
                    <div className="themes-text-gray-v9">Shipment Details</div>
                    <FormInput
                        label={'Booking No.'}
                        name={`bookingNo`}
                        placeholder="Enter here"
                        disabled
                        parentDivClassName="!mb-0 "
                        useUppercaseLabel
                    />
                    {containerMode !== 'courier' && (
                        <FormInput
                            label={'Container Mode'}
                            name={`containerMode`}
                            placeholder="Enter here"
                            disabled
                            parentDivClassName="!mb-0 "
                            useUppercaseLabel
                        />
                    )}

                    <FormDropdown
                        isSearchable
                        label={'Transport Mode'}
                        name={`transportMode`}
                        placeholder="Select here"
                        parentDivClassName="!mb-0"
                        options={service.loadTransportModeByContainerDropdown()}
                        isClearable
                        useUppercaseLabel
                        defaultValue={
                            service.loadTransportModeByContainerDropdown()
                                ?.length === 1
                                ? service.loadTransportModeByContainerDropdown()
                                : undefined
                        }
                        disabled={true}
                    />
                    {/* hide for new feature */}
                    <FormDropdown
                        placeholder="Select here"
                        isSearchable
                        label={t('bookings.service_level')}
                        name={`serviceLevel.code`}
                        parentDivClassName="!mb-0"
                        isClearable
                        useUppercaseLabel
                        options={[
                            {
                                value: 'std',
                                label: 'STD - Standard',
                            },
                        ]}
                    />
                    {containerMode !== 'fcl' && (
                        <>
                            <FormInput
                                label="Outer Packs"
                                name={`outerPacks`}
                                placeholder="Enter here"
                                type="number"
                                parentDivClassName="!mb-0"
                                useUppercaseLabel
                                maxLength={36}
                                useArrow={false}
                            />
                            <FormDropdown
                                isSearchable
                                label={t('bookings.outer_pack_unit')}
                                name={`outerPacksUnit.code`}
                                placeholder="Select here"
                                parentDivClassName="!mb-0"
                                options={[
                                    {
                                        value: 'plt',
                                        label: 'PLT - Pallet',
                                    },
                                ]}
                                isClearable
                                useUppercaseLabel
                            />
                            <FormMeasurementInput
                                options={[]}
                                placeholder="Enter here"
                                name={'weightMeasure'}
                                label={t('bookings.weight_measure')}
                                parentDivClassName=""
                                useUppercaseLabel
                                maxLength={36}
                                useDecimalFormat
                            />
                            <FormMeasurementInput
                                options={[
                                    {
                                        label: 'M3',
                                        value: 'm3',
                                    },
                                ]}
                                placeholder="Enter here"
                                name={'volumeMeasure'}
                                label={t('bookings.volume_measure')}
                                parentDivClassName=""
                                useUppercaseLabel
                                maxLength={36}
                                useDecimalFormat
                            />
                            <FormDropdown
                                options={[
                                    {
                                        label: 'Fumigated',
                                        value: 'Fumigated',
                                    },
                                ]}
                                placeholder="Enter here"
                                name={'woodenPackage'}
                                label={'WOODEN PACKAGE'}
                                parentDivClassName="!mb-0"
                                disabled
                            />
                            <FormInput
                                placeholder="Enter here"
                                name={'dangerousGoods'}
                                label={'dangerous Goods'}
                                parentDivClassName="!mb-0"
                                disabled
                                useUppercaseLabel
                            />
                        </>
                    )}
                </div>

                {/* orgin & destination */}
                <div className="flex flex-col gap-3 w-full">
                    <div>
                        <div className="themes-text-gray-v9">
                            {t('bookings.origin')}
                        </div>
                        <div className="flex flex-col gap-3 pt-3">
                            <FormDropdown
                                isSearchable={true}
                                isClearable={true}
                                label={t('bookings.origin_port')}
                                name={`originPort`}
                                placeholder="Select here"
                                parentDivClassName="!mb-0"
                                options={[
                                    {
                                        label: 'Melbourne',
                                        value: 'Melbourne',
                                    },
                                ]}
                                useUppercaseLabel
                            />
                            <div className="relative">
                                <FormDatePicker
                                    name={'cargoReadyDate'}
                                    isRange={false}
                                    label="CARGO READY DATE"
                                    placeholder="Select here"
                                    useUppercaseLabel
                                    disable
                                />
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="themes-text-gray-v9">
                            {t('bookings.destination')}
                        </div>
                        <div className="flex flex-col gap-3 mt-3">
                            <FormDropdown
                                isSearchable
                                placeholder="Select here"
                                label={t('bookings.destination_port')}
                                name={`destinationPort`}
                                parentDivClassName="!mb-0"
                                options={[
                                    {
                                        label: 'Bangkok',
                                        value: 'Bangkok',
                                    },
                                ]}
                                isClearable
                                useUppercaseLabel
                            />
                            <div className="relative">
                                <FormDatePicker
                                    name={'deliveryRequiredBy'}
                                    isRange={false}
                                    label="Delivery Required By"
                                    useUppercaseLabel
                                    placeholder="Select here"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                {/* charges */}
                <div className="flex flex-col gap-3">
                    <div className="themes-text-gray-v9">Charges</div>
                    <FormDropdown
                        placeholder="Select here"
                        isSearchable
                        label={t('bookings.incoterm')}
                        name={`incoTerm`}
                        parentDivClassName="!mb-0"
                        isClearable
                        useUppercaseLabel
                        options={[
                            {
                                label: 'Ex Works',
                                value: 'Ex Works',
                            },
                        ]}
                    />
                    <FormInput
                        label={t('bookings.goods_value')}
                        name={`goodsValue`}
                        placeholder="Enter here"
                        parentDivClassName="!mb-0"
                        useDecimalFormat
                        useArrow={false}
                        useUppercaseLabel
                        maxLength={36}
                    />
                    <FormDropdown
                        placeholder="Select here"
                        isSearchable
                        label={t('bookings.goods_value_currency')}
                        name={`goodsCurrency`}
                        parentDivClassName="!mb-0"
                        isClearable
                        useUppercaseLabel
                        options={[
                            {
                                label: 'THB',
                                value: 'THB',
                            },
                        ]}
                    />
                    <FormInput
                        label={t('bookings.additional_terms')}
                        name={`additionalTerms`}
                        placeholder="Enter here"
                        parentDivClassName="!mb-0 "
                        useUppercaseLabel
                        maxLength={36}
                    />
                    <FormInput
                        label={t('bookings.insurance_value')}
                        name={`insuranceValue`}
                        placeholder="Enter here"
                        parentDivClassName="!mb-0"
                        useDecimalFormat
                        useArrow={false}
                        useUppercaseLabel
                        maxLength={36}
                    />
                    <FormDropdown
                        placeholder="Select here"
                        isSearchable
                        label={t('bookings.insurance_currency')}
                        name={`insuranceCurrency`}
                        parentDivClassName="!mb-0"
                        isClearable
                        useUppercaseLabel
                        options={[
                            {
                                label: 'THB',
                                value: 'THB',
                            },
                        ]}
                    />
                    <FormDropdown
                        placeholder="Select here"
                        isSearchable
                        label={'Charges Apply'}
                        name={`chargesApply`}
                        parentDivClassName="!mb-0"
                        isClearable
                        useUppercaseLabel
                        options={[
                            {
                                label: 'Show Collect Charges',
                                value: 'Show Collect Charges',
                            },
                        ]}
                    />
                </div>

                {/* notes */}
                <div className="flex flex-col gap-3">
                    <div className="themes-text-gray-v9">Notes</div>
                    <FormTextarea
                        name={'goodsDescription'}
                        label={t('bookings.goods_description')}
                        parentDivClassName="h-[140px]"
                        placeholder={t('action.enter', {
                            value: t('bookings.goods_description'),
                        })}
                        useUppercaseLabel
                        maxLength={1002} // 1002 max lenght === 1000 chracter
                    />
                    <FormTextarea
                        name={'marksAndNumbers'}
                        label={t('bookings.marks_and_numbers')}
                        parentDivClassName="h-[220px]"
                        placeholder={t('action.enter', {
                            value: t('bookings.marks_and_numbers'),
                        })}
                        useUppercaseLabel
                        maxLength={1002} // 1002 max lenght === 1000 chracter
                    />
                </div>
            </div>
        </div>
    )
}

export default Details

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ITabItem } from '@components/tab/tab.interface'
import { RootState } from 'store'

import { IBookingsFilter } from '../bookings/bookings.interface'
import { IMeta } from 'common/common.interface'
import {
    IShippingInstructionsSummary,
    IShippingInstructionStatusTotal,
} from './shipping-instructions.interface'
import { shippingInstructionsSummaryDummy } from './shipping-instructions.dummy'
import { IRealatedPartiesDropdownItem } from './edit-shipping-instructions/edit-shipping-instructions.interface'

export interface ISHippingInstructionsSlice {
    shippingInstructionsSummary: {
        data: IShippingInstructionsSummary[]
        meta: IMeta
        filter: IBookingsFilter
        statusSelected: ITabItem
        statusTotal: IShippingInstructionStatusTotal
        search: string | null
    }
    shippingInstructionDetails: IShippingInstructionsSummary
    editShippingInstruction: {
        relatedPartiesDropdown: IRealatedPartiesDropdownItem
    }
}

export const initialState: ISHippingInstructionsSlice = {
    shippingInstructionsSummary: {
        data: shippingInstructionsSummaryDummy,
        meta: {
            current_page: 0,
            last_page: 0,
            per_page: 50,
            total_page: 0,
            total_Items: 0,
            from: 0,
            to: 0,
            index_end: 0,
            index_start: 0,
        },
        search: '',
        filter: {
            status: '',
            inProgressStatus: '',
            pageNumber: 1,
            pageSize: 50,
        },
        statusTotal: {
            all: 0,
            submitted: 0,
            toBeSubmitted: 0,
        },
        statusSelected: {
            value: 'all',
            key: 'status',
            label: 'All bookings',
            totalData: 0,
        },
    },
    shippingInstructionDetails: {
        transportMode: '',
        status: '',
        bookingNo: '',
        consignor: '',
        consignee: '',
        goodsDescription: '',
        origin: '',
        destination: '',
        packs: '',
        totalVolume: '',
        containerMode: '',
        detailsData: {
            containerMode: '',
            transportMode: '',
            serviceLevel: {
                code: '',
                description: '',
            },
            outerPacks: '',
            outerPacksUnit: {
                code: '',
                description: '',
            },
            weightMeasure: {},
            volumeMeasure: {},
            woodenPackage: '',
            dangerousGoods: '',
            originPort: '',
            cargoReadyDate: '',
            destinationPort: '',
            deliveryRequiredBy: '',
            incoTerm: '',
            goodsValue: '',
            goodsCurrency: '',
            additionalTerms: '',
            insuranceValue: '',
            insuranceCurrency: '',
            chargesApply: '',
            marksAndNumbers: '',
            goodsDescription: '',
            shipperPickupAddress: {
                companyName: '',
                address1: '',
                address2: '',
                city: '',
                state: '',
                postCode: '',
                country: {
                    code: '',
                    name: '',
                },
                contactName: '',
                phoneNumber: '',
                mmobileNumber: '',
                emailAddress: '',
            },
            shipper: {
                companyName: '',
                address1: '',
                address2: '',
                city: '',
                state: '',
                postCode: '',
                country: {
                    code: '',
                    name: '',
                },
                contactName: '',
                phoneNumber: '',
                mmobileNumber: '',
                emailAddress: '',
            },
            consigneeDeliveryAddress: {
                companyName: '',
                address1: '',
                address2: '',
                city: '',
                state: '',
                postCode: '',
                country: {
                    code: '',
                    name: '',
                },
                contactName: '',
                phoneNumber: '',
                mmobileNumber: '',
                emailAddress: '',
            },
            consignee: {
                companyName: '',
                address1: '',
                address2: '',
                city: '',
                state: '',
                postCode: '',
                country: {
                    code: '',
                    name: '',
                },
                contactName: '',
                phoneNumber: '',
                mmobileNumber: '',
                emailAddress: '',
            },
            bookingNo: '',
        },
        packLines: [],
        containers: [],
    },
    editShippingInstruction: {
        relatedPartiesDropdown: {
            company: {
                shipperPickupAddress: [],
                shipper: [],
                consigneeDeliveryAddress: [],
                consignee: [],
            },
            contact: {
                shipperPickupAddress: [],
                shipper: [],
                consigneeDeliveryAddress: [],
                consignee: [],
            },
        },
    },
}

const shippingInstructionsSlice = createSlice({
    name: 'ShippingInstructionsIFB',
    initialState,
    reducers: {
        setShippingInstructionsSummaryData(
            state,
            action: PayloadAction<{
                data: IShippingInstructionsSummary[]
                statusTotal: IShippingInstructionStatusTotal
                meta: IMeta
            }>,
        ) {
            const { data, statusTotal, meta } = action.payload

            state.shippingInstructionsSummary.data = data
            state.shippingInstructionsSummary.statusTotal = statusTotal
            state.shippingInstructionsSummary.meta = meta
        },
        setShippingInstructionDetails(
            state,
            action: PayloadAction<IShippingInstructionsSummary>,
        ) {
            state.shippingInstructionDetails = action.payload
        },
        setFilter(state, action: PayloadAction<IBookingsFilter>) {
            state.shippingInstructionsSummary.filter = action.payload
        },
        setPageNumber(state, action: PayloadAction<number>) {
            const pageNumber = action.payload
            const filter = {
                ...state.shippingInstructionsSummary.filter,
                pageNumber,
            }
            return {
                ...state,
                filter,
            }
        },
        setSelectedStatus(state, action: PayloadAction<ITabItem>) {
            state.shippingInstructionsSummary.statusSelected = action.payload
        },

        // segment edit
        setCompanyDropdownS(
            state,
            action: PayloadAction<IRealatedPartiesDropdownItem['company']>,
        ) {
            state.editShippingInstruction.relatedPartiesDropdown.company =
                action.payload
        },
        setContactDropdownS(
            state,
            action: PayloadAction<IRealatedPartiesDropdownItem['contact']>,
        ) {
            state.editShippingInstruction.relatedPartiesDropdown.contact =
                action.payload
        },
    },
})

// these all the variables used for selector
export const shippingInstructionsSummarySelector = (state: RootState) =>
    state.shippingInstructionsIFB.shippingInstructionsSummary || {}
export const shippingInstructionDetailsSelector = (state: RootState) =>
    state.shippingInstructionsIFB.shippingInstructionDetails || {}

// variable edit
export const dropdownRelatedPartiesSelector = (state: RootState) =>
    state.shippingInstructionsIFB.editShippingInstruction
        .relatedPartiesDropdown || {}

// all actions
export const {
    setShippingInstructionsSummaryData,
    setShippingInstructionDetails,
    setFilter,
    setPageNumber,
    setSelectedStatus,
    setContactDropdownS,
    setCompanyDropdownS,
} = shippingInstructionsSlice.actions

// Reducer
export default shippingInstructionsSlice.reducer

import {
    ICreditLimitTable,
    IFinanceProfitAndLossTableData,
    IUseControlTower,
} from '../control-tower.interface'
import SplitTable from '@components/split-table/split-table.component'
import {
    itemListShipmentsFinance,
    tabItemFilterFinance,
    tableFinanceCreditLimitHeaders,
    tableFinanceHeaders,
} from '../control-tower.static'
import Tab from '@components/tab/tab.component'
import Pagination from '@components/pagination/pagination.component'
import ItemListInfo from '@components/item-list-info/item-list-info.component'
import InfoCardGroup from '@components/InfoCardGroup/Info-card-group.component'
import {
    DoughnutChart,
    JointLineScatterChart,
} from '@components/rechart-component'
import ColorPieChart from '../components/color-pie-chart.component'
import { Fragment } from 'react/jsx-runtime'
import EmpityResultIfb from 'common/components/empityResult/empityResultIfb.component'

const Finance = ({ service }: { service: IUseControlTower }) => {
    return (
        <>
            <div
                className="col-span-4 border-r flex flex-col"
                style={{ height: 'inherit' }}
            >
                <div className="p-2 h-1/2 flex flex-col">
                    <div className="flex justify-between">
                        <div className="font-bold text-size-S">
                            Avg. Monthly Performance
                        </div>
                        <ItemListInfo itemListInfo={itemListShipmentsFinance} />
                    </div>
                    <InfoCardGroup
                        items={
                            service.financeData.avgMonthlyPerfomance
                                .infoCardItems
                        }
                        titleClass="!text-size-XS"
                        descClass="!text-[9px]"
                    />
                    <div className="flex-grow">
                        <JointLineScatterChart
                            data={
                                service.financeData.avgMonthlyPerfomance
                                    .chartData
                            }
                            tickItems={
                                service.financeData.avgMonthlyPerfomance
                                    .tickItems
                            }
                            isLoading={
                                service.loadingFinance.averageMonthlyPerformance
                            }
                            customEmpityResultComponent={<EmpityResultIfb />}
                        />
                    </div>
                </div>
                <div className="border-b"></div>
                <div className="px-2 h-1/2 flex-grow">
                    <div className="font-bold text-size-S">Open Invoices</div>
                    <InfoCardGroup
                        items={service.financeData.openInvoices.infoCardItems}
                        titleClass="!text-size-XS"
                        descClass="!text-[9px]"
                    />
                    <DoughnutChart
                        data={service.financeData.openInvoices.pieChart}
                        colorChart={
                            <ColorPieChart
                                items={
                                    service.financeData.openInvoices.pieChart
                                }
                                content={service.buttonContent}
                                currencyCode={
                                    service.user.userDefaultData.currency.code
                                }
                            />
                        }
                        isLoading={service.loadingFinance.openInvoice}
                        customEmpityResultComponent={<EmpityResultIfb />}
                    />
                </div>
            </div>
            <div className="col-span-4 border-r" style={{ height: 'inherit' }}>
                <div className="flex justify-between items-center px-2 h-[40px]">
                    <Tab
                        items={tabItemFilterFinance}
                        tabFilter={service.tabFilterFinance}
                        onChange={(item) => {
                            service.setTabFilterFinance(item)
                        }}
                        containerClassName="!overflow-y-hidden !text-size-XS !p-0 !border-none"
                        containerItemClass="!min-h-[40px] h-[40px]"
                    />
                    <Pagination
                        containerClassName="!p-0 flex items-center h-full"
                        className="!h-[27px]"
                        btnClassName="!h-[27px] flex items-center justify-center cursor-pointer"
                        meta={service.financeData.table.meta}
                        previousHandling={
                            service.handlingPageNumberFinanceTable
                        }
                        nextHandling={service.handlingPageNumberFinanceTable}
                    />
                </div>

                <div className="h-[calc(100vh-17rem)] border-t">
                    {service.tabFilterFinance.value === 'P&L' ? (
                        <Fragment key={'table-profit'}>
                            <SplitTable<IFinanceProfitAndLossTableData, true>
                                columns={tableFinanceHeaders}
                                data={service.financeData.table.profitandLoss}
                                thClass="text-size-XS"
                                tdClass="text-size-XS"
                                onRowClick={(data) => {
                                    service.handleLoadHistoryData(
                                        data.key ? data.key : data.name,
                                    )
                                }}
                                useSubData
                                loading={service.loadingFinance.table}
                                customEmpityResultComponent={
                                    <EmpityResultIfb />
                                }
                            />
                        </Fragment>
                    ) : (
                        <Fragment key={'table-credit-limit'}>
                            <SplitTable<ICreditLimitTable, true>
                                columns={tableFinanceCreditLimitHeaders}
                                data={service.financeData.table.creditLimit}
                                thClass="text-size-XS"
                                tdClass="text-size-XS"
                                loading={service.loadingFinance.table}
                                customEmpityResultComponent={
                                    <EmpityResultIfb />
                                }
                            />
                        </Fragment>
                    )}
                </div>
            </div>
        </>
    )
}

export default Finance

import { createAsyncThunk } from '@reduxjs/toolkit'
import {
    ConsignorConsigneeResponseData,
    IConsignorConsigneeParam,
} from './consignee-consignor.interface'
import { IResponseData } from 'common/common.interface'
import { ifbEndpoints } from 'common/endpoints/ifb.endpoints'
import { fetch } from 'common/common.service'
import { Toast } from '@components/toast/toast.component'

export const getConsigneeConsignor = createAsyncThunk<
    ConsignorConsigneeResponseData,
    IConsignorConsigneeParam
>('consignee-consignor/get', async (params, thunkApi) => {
    try {
        const { company } = params
        const response = await fetch<
            IResponseData<ConsignorConsigneeResponseData>,
            string
        >({
            endpoint: `${ifbEndpoints.loglines_meta_data_Consignee_Consignor}`,
            params: {
                company,
            },
        })

        if (!response.isSuccess) {
            Toast({
                header: 'Get Consignee/Consignor data',
                message:
                    response?.message ||
                    `Failed to fetch Consignee/Consignor data.`,
                type: 'error',
            })
        }

        return response.data
    } catch (error) {
        if (error instanceof Error) {
            Toast({
                header: 'Get Consignee/Consignor data',
                message: `${error?.message}`,
                type: 'error',
            })
        } else {
            Toast({
                header: 'Get Consignee/Consignor data',
                message: `Unknown error occurred while fetching Get Consignee/Consignor data."`,
                type: 'error',
            })
        }
        return thunkApi.rejectWithValue(error)
    }
})

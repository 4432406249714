var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { useEffect, useState } from 'react';
import { dateToUnixInt, isDate } from '@services/common.service';
export function useTable(_a) {
    var data = _a.data, headers = _a.headers, checkboxVisible = _a.checkboxVisible, checkboxDataHandling = _a.checkboxDataHandling, tabFilterItem = _a.tabFilterItem, resetCheckedInitialValue = _a.resetCheckedInitialValue;
    // initial
    var _b = useState(''), sortBy = _b[0], setSortBy = _b[1];
    var _c = useState('asc'), sortOrder = _c[0], setSortOrder = _c[1];
    var _d = useState([]), generatedData = _d[0], setGeneratedData = _d[1];
    var _e = useState([]), checkedData = _e[0], setCheckedData = _e[1];
    var _f = useState(false), isCheckedAll = _f[0], setIsCheckedAll = _f[1];
    // useEffect ---------------------------------------------------------------
    useEffect(function () {
        if (!tabFilterItem) {
            return;
        }
        setGeneratedData(generateData(generatedData));
    }, []);
    useEffect(function () {
        // setIsCheckedAll(false)
        setGeneratedData(generateData(data));
    }, [data]);
    useEffect(function () {
        setCheckedData(resetCheckedInitialValue !== null && resetCheckedInitialValue !== void 0 ? resetCheckedInitialValue : []);
    }, [resetCheckedInitialValue]);
    // End useEffect -----------------------------------------------------------
    // additional function -----------------------------------------------------
    function generateData(dt) {
        if ((typeof dt === 'object' && !Array.isArray(dt)) || !dt) {
            return [];
        }
        var dt_ = dt;
        if (tabFilterItem) {
            if (tabFilterItem.value === 'all') {
                dt_ = data.filter(function (d) {
                    return d;
                });
            }
            else {
                dt_ = data.filter(function (d) {
                    if (d[tabFilterItem.key] === tabFilterItem.value) {
                        return d;
                    }
                });
            }
        }
        if (checkboxVisible) {
            setIsCheckedAll(false);
            dt_ = dt_.map(function (dt__) {
                var newObj = Object.assign({ selected: false }, dt__);
                return newObj;
            });
        }
        return dt_;
    }
    function getDataByAccessor(data, accessor) {
        if (accessor.includes('.')) {
            var accessorArray = accessor.split('.');
            for (var _i = 0, accessorArray_1 = accessorArray; _i < accessorArray_1.length; _i++) {
                var key = accessorArray_1[_i];
                data = data === null || data === void 0 ? void 0 : data[key];
            }
            return data;
        }
        else {
            return data[accessor];
        }
    }
    function setSortColumn(accessor, isAscending) {
        var _a;
        var sortOrder = !isAscending ? 'asc' : 'desc';
        setSortOrder(sortOrder);
        setSortBy(accessor);
        var formatDate = (_a = headers.find(function (header) { return header.accessor === accessor; })) === null || _a === void 0 ? void 0 : _a.formatDate;
        // Check If Comparison Data Value is Date or not
        var isDateComparisonData = function (a, b) { return isDate(a, formatDate) || isDate(b, formatDate); };
        // Convert date into Unix Timestamp (milliseconds) Number
        var dateToIntSortData = function (date) {
            return dateToUnixInt(date, formatDate) ||
                // Logic to handle if value of date is NaN then Return Highest Number if Sort is Asc else if Sort is Desc then return 0
                (sortOrder === 'asc' ? maxTotalDateInt + 1 : 0);
        };
        // Highest Number of Date Unix Timestamp (milliseconds) Number List
        var maxTotalDateInt = __spreadArray([], generatedData, true).map(function (data) { return dateToUnixInt(data[accessor], formatDate) || 0; })
            .reduce(function (total, num) { return total + num; });
        // Sorting Data
        var sortedData = __spreadArray([], generatedData, true).sort(function (a, b) {
            // Get Comparison Data by Accessor
            var dataComparisonA = getDataByAccessor(a, accessor);
            var dataComparisonB = getDataByAccessor(b, accessor);
            // Check if Value is Date then Sort by Date
            if (sortOrder === 'asc' &&
                isDateComparisonData(dataComparisonA, dataComparisonB)) {
                return (dateToIntSortData(dataComparisonA) -
                    dateToIntSortData(dataComparisonB));
            }
            else if (sortOrder === 'desc' &&
                isDateComparisonData(dataComparisonA, dataComparisonB)) {
                return (dateToIntSortData(dataComparisonB) -
                    dateToIntSortData(dataComparisonA));
            }
            // Sorting by String Alphabet
            else if (sortOrder === 'asc') {
                return dataComparisonA > dataComparisonB ? 1 : -1;
            }
            else if (sortOrder === 'desc') {
                return dataComparisonA < dataComparisonB ? 1 : -1;
            }
            // Sorting by String Alphabet by Default
            return dataComparisonA > dataComparisonB ? 1 : -1;
        });
        setGeneratedData(sortedData);
    }
    function checkboxAllHandling(isChecked) {
        var checkedData_ = [];
        var generatedData_ = [];
        if (isChecked) {
            generatedData_ = generatedData.map(function (dt) {
                return __assign(__assign({}, dt), { selected: true });
            });
            checkedData_ = generatedData.map(function (dt) {
                // eslint-disable-next-line no-unused-vars
                var selected = dt.selected, values = __rest(dt, ["selected"]);
                return values;
            });
        }
        else {
            generatedData_ = generatedData.map(function (dt) {
                return __assign(__assign({}, dt), { selected: false });
            });
        }
        setIsCheckedAll(isChecked);
        setCheckedData(checkedData_);
        setGeneratedData(generatedData_);
        checkboxDataHandling && checkboxDataHandling(checkedData_);
    }
    function checkboxSingleHandling(isChecked, data, index) {
        var checkedData_ = __spreadArray([], checkedData, true);
        var generatedData_ = __spreadArray([], generatedData, true);
        if (isChecked) {
            generatedData_[index] = __assign(__assign({}, data), { selected: true });
            setGeneratedData(generatedData_);
            var values = __rest(data, []);
            checkedData_.push(values);
        }
        else {
            generatedData_[index] = __assign(__assign({}, data), { selected: false });
            setGeneratedData(generatedData_);
            var idx = checkedData_.findIndex(function (d) { return (d === null || d === void 0 ? void 0 : d.id) === (data === null || data === void 0 ? void 0 : data.id); });
            checkedData_.splice(idx, 1);
        }
        setCheckedData(checkedData_);
        checkboxDataHandling && checkboxDataHandling(checkedData_);
    }
    // end function -------------------------------------------------
    // return function or variable
    var generatedHeaders = headers;
    return {
        generatedData: generatedData,
        generatedHeaders: generatedHeaders,
        sortBy: sortBy,
        sortOrder: sortOrder,
        setSortColumn: setSortColumn,
        checkboxSingleHandling: checkboxSingleHandling,
        checkboxAllHandling: checkboxAllHandling,
        checkedData: checkedData,
        isCheckedAll: isCheckedAll,
        setIsCheckedAll: setIsCheckedAll,
    };
}

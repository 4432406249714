import Button from '@components/button/button.component'
import useVendorBookingDetails from './vendor-booking-details.service'
import Spinner from '@components/spinner/spinner.component'
import Tab from '@components/tab/tab.component'
import { t } from 'i18next'
import { tabItemsVendorBookingDetails } from './vendor-booking-details.static'
import Details from './contents/details.component'
import RelatedParties from './contents/related-parties.component'
import PackLines from './contents/pack-lines.component'
import Container from './contents/container.component'

const VendorBookingDetails = () => {
    const service = useVendorBookingDetails()

    const loadContent = () => {
        const contents: Record<string, JSX.Element> = {
            details: <Details />,
            packLines: <PackLines />,
            container: <Container />,
            relatedParties: <RelatedParties />,
        }
        return contents[service.tabFilter.value]
    }
    return (
        <div className="container-global content-full-height flex">
            <div className="flex flex-grow flex-col w-[calc(100vw-20rem)] sm:w-[calc(100vw-4rem)]">
                <div className="flex justify-between items-center px-3">
                    <Tab
                        items={tabItemsVendorBookingDetails}
                        tabFilter={service.tabFilter}
                        onChange={(item) => {
                            service.setTabFilter(item)
                        }}
                    />
                </div>
                <div className="border-b"></div>
                <div className="h-[calc(100vh-13rem)] overflow-auto">
                    {service.loading ? (
                        <div
                            className={`flex items-center justify-center h-full`}
                        >
                            <Spinner label="Please Wait..." />
                        </div>
                    ) : (
                        <div className={` w-full h-full`}>{loadContent()}</div>
                    )}
                </div>
                <div className="border-b"></div>
                <div className="p-3">
                    <Button
                        onClick={() => service.navigate('/vendor-bookings')}
                        label={t('action.back')}
                        variant="brand-v2-inverse"
                        className="w-btnRegularWidth"
                        useUpperCase={true}
                    />
                </div>
            </div>
        </div>
    )
}

export default VendorBookingDetails

import Button from '@components/button/button.component'
import FormInput from '@components/form-input/form-input.component'
import { Form, Formik, FormikProps } from 'formik'
import { useForgotPassword } from '../login.service'
// import {
//     IForgotPassword,
//     useForgotPasswordValidation,
// } from 'form-validation/forgot-password.validation'
// import {
//     IResetPasswordValidation,
//     resetPasswordInitialValue,
//     resetPasswordValidation,
// } from 'client/common/form-validation/reset-password.validation'
import { useTranslation } from 'react-i18next'
import { Toast } from '@components/toast/toast.component'
import {
    IForgotPasswordValidation,
    useForgotPasswordValidation,
} from 'form-validation/forgot-password.validation'
import {
    IResetPasswordValidation,
    resetPasswordInitialValue,
    resetPasswordValidation,
} from 'form-validation/reset-password.validation'

const IFBForgotPasswordForm = ({
    backFunction,
}: {
    backFunction: () => void
}) => {
    const { t } = useTranslation()
    const { forgotPasswordInitialValue, forgotPasswordValidation } =
        useForgotPasswordValidation()
    const {
        loading,
        submitForgotPassword,
        changeContent,
        submitConfirmPassword,
        setEmail,
        location,
    } = useForgotPassword()
    const titleContent = changeContent
        ? 'Create New Password'
        : t('login.forgot_password')

    const passwordResetToken = location.search.startsWith('?')
        ? location.search.substring(1)
        : location.search

    return (
        <div>
            <div className="text-[20px] font-bold mb-3">{titleContent}</div>
            <div className="relative">
                <div
                    className={`transition-opacity duration-300 ${changeContent ? 'opacity-100' : 'opacity-0 absolute'}`}
                >
                    {changeContent && (
                        <Formik
                            validationSchema={resetPasswordValidation}
                            initialValues={resetPasswordInitialValue}
                            onSubmit={(values) => {
                                const data = {
                                    ...values,
                                    passwordResetToken,
                                }
                                submitConfirmPassword(data)
                            }}
                        >
                            {(
                                formikProps: FormikProps<IResetPasswordValidation>,
                            ) => (
                                <Form>
                                    <FormInput
                                        name="newPassword"
                                        label="New Password"
                                        type="password"
                                        placeholder="Enter password here"
                                        className="w-full"
                                        client="IFB"
                                        required={true}
                                        defaultValue={
                                            formikProps.values.newPassword
                                        }
                                        useUppercaseLabel
                                    />

                                    <FormInput
                                        name="passwordConfirmation"
                                        label="New Password Confirmation"
                                        type="password"
                                        placeholder="Enter password here"
                                        className="w-full"
                                        client="IFB"
                                        required={true}
                                        defaultValue={
                                            formikProps.values
                                                .passwordConfirmation
                                        }
                                        useUppercaseLabel
                                    />

                                    <Button
                                        type="submit"
                                        label={t('login.confirm')}
                                        variant="brand"
                                        className="w-[100%] mt-[40px] !text-start"
                                        onClick={() => {}}
                                        isLoading={loading}
                                        useUpperCase={true}
                                    />
                                    <div
                                        className="themes-text-brand-v5 cursor-pointer mt-4"
                                        onClick={() => {
                                            backFunction()
                                        }}
                                    >
                                        {t('login.back_to_login')}
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    )}
                </div>
                <div
                    className={`transition-opacity duration-300 ${!changeContent ? 'opacity-100' : 'opacity-0 absolute'}`}
                >
                    {!changeContent && (
                        <Formik
                            validationSchema={forgotPasswordValidation}
                            initialValues={forgotPasswordInitialValue}
                            onSubmit={(values) => {
                                setEmail(values.email)
                                submitForgotPassword(values)
                            }}
                        >
                            {(
                                formikProps: FormikProps<IForgotPasswordValidation>,
                            ) => (
                                <Form>
                                    <FormInput
                                        name="email"
                                        label={t('login.email_address')}
                                        placeholder={t(
                                            'login.email_address_placeholder',
                                        )}
                                        className="w-full"
                                        required={true}
                                        defaultValue={formikProps.values.email}
                                        useUppercaseLabel
                                        data-testid="FORGOT_EMAIL_INPUT"
                                    />

                                    <Button
                                        useUpperCase={true}
                                        type="submit"
                                        label={t('login.confirm')}
                                        variant="brand"
                                        className="w-[100%] mt-[40px] !text-start"
                                        onClick={() => {
                                            if (formikProps.errors.email) {
                                                Toast({
                                                    header: 'Failed',
                                                    message:
                                                        formikProps.errors
                                                            .email,
                                                    type: 'error',
                                                })
                                            }
                                        }}
                                        isLoading={loading}
                                        data-testid="COMFIRM_FORGOT_PASSWORD_BUTTON"
                                    />

                                    <div
                                        className="themes-text-brand-v5 cursor-pointer mt-2"
                                        onClick={() => {
                                            backFunction()
                                        }}
                                        data-testid="FORGOT_BACK_TO_LOGIN_BUTTON"
                                    >
                                        {t('login.back_to_login').toUpperCase()}
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    )}
                </div>
            </div>
        </div>
    )
}
export default IFBForgotPasswordForm

import { IMeasurement } from '@components/measurement-input/measurement-input.interface'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'

export interface IRelatedPartiesAddressValidation {
    companyName: string
    address1: string
    address2: string
    city: string
    state: string
    postCode: string
    country: {
        code: string
        name: string
    }
    contactName: string
    phoneNumber: string
    mmobileNumber: string
    emailAddress: string
}

export interface IEditShippingInstructionValidation {
    // segment details
    containerMode: string
    bookingNo: string
    transportMode: string
    serviceLevel: {
        code: string
        description: string
    }
    outerPacks: string
    outerPacksUnit: {
        code: string
        description: string
    }
    weightMeasure: IMeasurement
    volumeMeasure: IMeasurement
    woodenPackage: string
    dangerousGoods: string
    originPort: string
    cargoReadyDate: string
    destinationPort: string
    deliveryRequiredBy: string
    incoTerm: string
    goodsValue: string
    goodsCurrency: string
    additionalTerms: string
    insuranceValue: string
    insuranceCurrency: string
    chargesApply: string
    marksAndNumbers: string
    goodsDescription: string

    // related parties
    shipperPickupAddress: IRelatedPartiesAddressValidation
    shipper: IRelatedPartiesAddressValidation
    consigneeDeliveryAddress: IRelatedPartiesAddressValidation
    consignee: IRelatedPartiesAddressValidation
}

export interface IEditShippingInstructionPackLineValidation {
    id: number
    pieces: string
    packType: string
    length: string
    height: string
    width: string
    unitDimension: string
    weightMeasure: IMeasurement
    volumeMeasure: IMeasurement
    description: string
    marksAndNumbers: string
    linePrice: string
    priceCurrency: string
    tariffNumber: string
    hsCode: string
}

export interface IEditShippingInstructionContainerValidation {
    id: number
    containerNo: string
    type: string
    count: string
    sealNo: string
}

export const useEditShippingInstructionValidation = () => {
    const { t } = useTranslation()

    const today = new Date()
    const editShippingInstructionValidation = Yup.object().shape({
        shipperPickupAddress: Yup.object().shape({
            companyName: Yup.string().required(
                t('message.required', { value: 'Company Name' }),
            ),
        }),
        consigneeDeliveryAddress: Yup.object().shape({
            companyName: Yup.string().required(
                t('message.required', { value: 'Company Name' }),
            ),
        }),
        deliveryrequiredBy: Yup.date()
            .nullable()
            .transform((value, originalValue) =>
                originalValue === '' ? null : value,
            )
            .min(today, 'Delivery date cannot be in the past'),
        transportMode: Yup.string().required('Transport mode is required'),
        containerMode: Yup.string().required('Container mode is required'),
        originPort: Yup.string().required('Origin port is required'),
        destinationPort: Yup.string().required('Destination port is required'),
    })

    const editShippingInstructionPackLinesValidation = Yup.object().shape({
        pieces: Yup.number()
            .required(t('message.required', { value: 'Pieces' }))
            .min(1),
    })

    const editShippingInstructionContainerValidation = Yup.object().shape({
        count: Yup.number()
            .required(
                t('message.required', {
                    value: 'Container count',
                }),
            )
            .min(1),
        type: Yup.string().required(
            t('message.required', {
                value: 'Container type',
            }),
        ),
    })

    const editShippingInstructionInitialValue: IEditShippingInstructionValidation =
        {
            containerMode: '',
            transportMode: '',
            serviceLevel: {
                code: '',
                description: '',
            },
            outerPacks: '',
            outerPacksUnit: {
                code: '',
                description: '',
            },
            weightMeasure: {
                unitLabel: 'KG',
            },
            volumeMeasure: {
                unitLabel: 'M3',
            },
            woodenPackage: '',
            dangerousGoods: '',
            originPort: '',
            cargoReadyDate: '',
            destinationPort: '',
            deliveryRequiredBy: '',
            incoTerm: '',
            goodsValue: '',
            goodsCurrency: '',
            additionalTerms: '',
            insuranceValue: '',
            insuranceCurrency: '',
            chargesApply: '',
            marksAndNumbers: '',
            goodsDescription: '',
            shipperPickupAddress: {
                companyName: '',
                address1: '',
                address2: '',
                city: '',
                state: '',
                postCode: '',
                country: {
                    code: '',
                    name: '',
                },
                contactName: '',
                phoneNumber: '',
                mmobileNumber: '',
                emailAddress: '',
            },
            shipper: {
                companyName: '',
                address1: '',
                address2: '',
                city: '',
                state: '',
                postCode: '',
                country: {
                    code: '',
                    name: '',
                },
                contactName: '',
                phoneNumber: '',
                mmobileNumber: '',
                emailAddress: '',
            },
            consigneeDeliveryAddress: {
                companyName: '',
                address1: '',
                address2: '',
                city: '',
                state: '',
                postCode: '',
                country: {
                    code: '',
                    name: '',
                },
                contactName: '',
                phoneNumber: '',
                mmobileNumber: '',
                emailAddress: '',
            },
            consignee: {
                companyName: '',
                address1: '',
                address2: '',
                city: '',
                state: '',
                postCode: '',
                country: {
                    code: '',
                    name: '',
                },
                contactName: '',
                phoneNumber: '',
                mmobileNumber: '',
                emailAddress: '',
            },
            bookingNo: '',
        }

    const editShippingInstructionPackLineInitialValue: IEditShippingInstructionPackLineValidation =
        {
            pieces: '',
            packType: '',
            length: '',
            height: '',
            width: '',
            weightMeasure: {
                unitLabel: 'KG',
            },
            volumeMeasure: {
                unitLabel: 'M3',
            },
            description: '',
            marksAndNumbers: '',
            linePrice: '',
            tariffNumber: '',
            hsCode: '',
            id: 1,
            unitDimension: '',
            priceCurrency: '',
        }

    const editShippingInstructionContainerInitialValue: IEditShippingInstructionContainerValidation =
        {
            containerNo: '',
            type: '',
            count: '',
            sealNo: '',
            id: 1,
        }

    return {
        editShippingInstructionValidation,
        editShippingInstructionInitialValue,
        editShippingInstructionPackLinesValidation,
        editShippingInstructionContainerValidation,
        editShippingInstructionContainerInitialValue,
        editShippingInstructionPackLineInitialValue,
    }
}

import { useNavigate } from 'react-router-dom'
import {
    IRealatedPartiesDropdownItem,
    IRelatedPartiesContactFormDummy,
    IRelatedPartiesFormDummy,
    IUseNewVendorBooking,
} from './new-vendor-booking.interface'
import { SetStateAction, useEffect, useState } from 'react'
import { ITabItem } from '@components/tab/tab.interface'
import { formBookingStatusHeader } from 'pages/bookings/create-booking/create-booking.static'
import {
    IConfimationModalProperty,
    IIsFormStillError,
} from 'pages/bookings/create-booking/create-booking.interface'
import { useFormik } from 'formik'
import {
    INewVendorBookingContainerValidation,
    INewVendorBookingPackLineValidation,
    INewVendorBookingValidation,
    IRelatedPartiesAddressValidation,
    useCreateBookingValidation,
} from 'form-validation/new-vendor-booking'
import { Toast } from '@components/toast/toast.component'
import { useModal } from '@components/modal/modal.service'
import {
    relatedPartiesAddressConsigneeDummy,
    relatedPartiesAddressShipperDummy,
} from '../vendor-bookings.dummy'
import { IDropdownItem } from '@components/dropdown/dropdown.interface'

const useNewVendorBooking = (): IUseNewVendorBooking => {
    const navigate = useNavigate()
    const {
        newVendorBookingContainerInitialValue,
        newVendorBookingInitialValue,
        newVendorBookingPackLineInitialValue,
        newVendorBookingPackLinesValidation,
        newVendorBookingValidation,
        newVendorContainerBookingsValidation,
    } = useCreateBookingValidation()

    // common states
    const [isFormPage, setIsFormPage] = useState<boolean>(false)
    const [isFormStillError, setIsFormStillError] = useState<IIsFormStillError>(
        {
            addBooking: null,
            addPackLines: null,
            addContainer: null,
        },
    )
    const [isFirstRender, setIsFirstRender] = useState<boolean>(true)
    const [loading, setLoading] = useState<boolean>(false)
    const [tabFormBookingItems, setTabFormBookingItems] = useState<ITabItem[]>(
        formBookingStatusHeader,
    )
    const [tabFormBooking, setTabFormBooking] = useState<ITabItem>(
        formBookingStatusHeader[0],
    )
    const [formType, setFormType] = useState<string>('')
    const [confirmationModalProperty, setConfirmationModalProperty] =
        useState<IConfimationModalProperty>({
            title: '',
            message: '',
            btnProceed: {
                onClick: () => {},
                variant: 'brand',
            },
        })

    // stata related parties
    const [relatedPartiesDropdown, setRelatedPartiesDropdown] =
        useState<IRealatedPartiesDropdownItem>({
            contact: {
                consigneeDeliveryAddress: [],
                consignee: [],
                shipperPickupAddress: [],
                shipper: [],
            },
            company: {
                shipperPickupAddress: [],
                shipper: [],
                consigneeDeliveryAddress: [],
                consignee: [],
            },
        })

    // state pack lines
    const [packLinesData, setPackLinesData] = useState<
        INewVendorBookingPackLineValidation[]
    >([])
    const [packLineData, setPackLineData] =
        useState<INewVendorBookingPackLineValidation>(
            newVendorBookingPackLineInitialValue,
        )
    const [packLineParams, setPackLineParams] = useState<'update' | 'add'>(
        'update',
    )
    const [packlinesSelected, setPacklinesSelected] = useState<
        INewVendorBookingPackLineValidation[]
    >([])

    // state containers
    const [containersData, setContainersData] = useState<
        INewVendorBookingContainerValidation[]
    >([])
    const [containerData, setContainerData] =
        useState<INewVendorBookingContainerValidation>(
            newVendorBookingContainerInitialValue,
        )
    const [containerParams, setContainerParams] = useState<'update' | 'add'>(
        'update',
    )
    const [containersSelected, setContainersSelected] = useState<
        INewVendorBookingContainerValidation[]
    >([])

    // modal service
    const confirmationModal = useModal()
    const formPackLineModal = useModal()
    const formContainerModal = useModal()

    // formik new vendor booking
    const newVendorBookingFormik = useFormik<INewVendorBookingValidation>({
        validationSchema: newVendorBookingValidation,
        initialValues: newVendorBookingInitialValue,
        enableReinitialize: true,
        onSubmit: async (values) => {
            Toast({
                header: 'New Vendor Booking Succesfully Created!',
                message: `Booking No. BKG001`,
                type: 'success',
            })
            navigate('/booking-details/BKG001')
        },
    })

    const newVendorBookingPackLinesFormik =
        useFormik<INewVendorBookingPackLineValidation>({
            validationSchema: newVendorBookingPackLinesValidation,
            initialValues: packLineData,
            enableReinitialize: true,
            onSubmit: async (values, { resetForm }) => {
                // reset form error to default
                setIsFormStillError((prevState) => ({
                    ...prevState,
                    addPackLines: null,
                }))

                let updateData = []
                let resetValues = null
                let messageToast: string = ''
                let closeModalFormPackline: boolean = false
                switch (packLineParams) {
                    case 'add':
                        updateData = [
                            ...packLinesData,
                            {
                                ...values,
                                id: packLinesData.length + 1,
                            },
                        ]
                        resetValues = newVendorBookingPackLineInitialValue
                        messageToast = 'Pack Line Added Succesfully'
                        closeModalFormPackline = true
                        break
                    case 'update':
                        updateData = packLinesData.map((item) =>
                            item.id === values.id
                                ? { ...item, ...values }
                                : item,
                        )
                        resetValues = values
                        messageToast = 'Pack Line Edited Succesfully'
                        closeModalFormPackline = true

                        break
                    default:
                        updateData = packLinesData
                        break
                }

                setPackLinesData(updateData)
                setPacklinesSelected([])

                Toast({
                    header: messageToast,
                    type: 'success',
                })

                // close modal when add or edit packline
                if (closeModalFormPackline)
                    formPackLineModal.closeModalHandling()

                if (resetValues !== null) {
                    resetForm({
                        values: resetValues as INewVendorBookingPackLineValidation,
                    })
                }
            },
        })

    const newVendorBookingContainerFormik =
        useFormik<INewVendorBookingContainerValidation>({
            validationSchema: newVendorContainerBookingsValidation,
            initialValues: containerData,
            enableReinitialize: true,
            onSubmit: (values, { resetForm }) => {
                // reset form error to default
                setIsFormStillError((prevState) => ({
                    ...prevState,
                    addContainer: null,
                }))

                let updateData = []
                let resetValues = null
                let messageToast = ''
                let closeModalForm: boolean = false
                switch (containerParams) {
                    case 'add':
                        updateData = [
                            ...containersData,
                            { ...values, id: containersData.length + 1 },
                        ]
                        resetValues = newVendorBookingContainerInitialValue
                        messageToast = 'Container Added Succesfully'
                        closeModalForm = true
                        break
                    case 'update':
                        updateData = containersData.map((item) =>
                            item.id === values.id
                                ? { ...item, ...values }
                                : item,
                        )
                        resetValues = values
                        messageToast = 'Container Updated Succesfully'
                        closeModalForm = true
                        break
                    default:
                        updateData = containersData
                        break
                }

                setContainersData(updateData)
                setContainersSelected([])

                // close modal when add or edit cotanienr
                if (closeModalForm) formContainerModal.closeModalHandling()

                Toast({
                    header: messageToast,
                    type: 'success',
                })
                if (resetValues !== null) {
                    resetForm({ values: resetValues })
                }
            },
        })

    // common functions
    const saveBooking = () => {
        setConfirmationModalProperty({
            title: 'New Booking',
            message:
                'Once this booking is created, system will record this booking and notify team for processing.',
            btnProceed: {
                onClick: () =>
                    setTimeout(() => {
                        if (isFormStillError.addBooking)
                            Toast({
                                header: 'Failed Place Booking!',
                                message:
                                    'There is some mandatory fields required to not leave blank',
                                type: 'error',
                            })
                        newVendorBookingFormik.submitForm()
                    }, 500),
                variant: 'brand-v2-inverse',
            },
        })
        confirmationModal.openModalHandling()
    }
    const handleFormRequired = () => {
        if (newVendorBookingFormik?.submitCount === 0) return

        const {
            shipperPickupAddress,
            consigneeDeliveryAddress,
            ...detailsFormErrors
        } = newVendorBookingFormik.errors

        const relatedPartiesFormErrors =
            (shipperPickupAddress &&
                Object.keys(detailsFormErrors).length > 0) ||
            (consigneeDeliveryAddress &&
                Object.keys(consigneeDeliveryAddress).length > 0)

        const detailsError = Object.keys(detailsFormErrors).length !== 0

        // first submit error
        if (
            isFormStillError.addBooking === null &&
            (relatedPartiesFormErrors || detailsError)
        )
            Toast({
                header: 'Failed Place Booking!',
                message:
                    'There is some mandatory fields required to not leave blank',
                type: 'error',
            })

        // remove toast error
        if (!relatedPartiesFormErrors && !detailsError)
            setIsFormStillError((prevState) => ({
                ...prevState,
                addBooking: false,
            }))

        const updatedTabs: ITabItem[] = tabFormBookingItems.map((tab) => {
            if (tab.value === 'relatedParties' && relatedPartiesFormErrors) {
                setIsFormStillError((prevState) => ({
                    ...prevState,
                    addBooking: true,
                }))
                return { ...tab, className: 'red-error' }
            } else if (tab.value === 'details' && detailsError) {
                setIsFormStillError((prevState) => ({
                    ...prevState,
                    addBooking: true,
                }))
                return { ...tab, className: 'red-error' }
            } else {
                return { ...tab, className: 'brand' }
            }
        })
        setTabFormBookingItems(updatedTabs)
    }
    const loadTransportModeByContainerDropdown = () => {
        let options
        switch (formType) {
            case 'lse':
                options = [
                    {
                        label: 'AIR - Air Freight',
                        value: 'AIR',
                    },
                ]
                break
            case 'fcl':
            case 'lcl':
                options = [
                    {
                        label: 'SEA - Sea Freight',
                        value: 'SEA',
                    },
                    {
                        label: 'ROA - Road Freight',
                        value: 'ROA',
                    },
                    {
                        label: 'RAI - Rail Freight',
                        value: 'RAI',
                    },
                ]
                break
            case 'ftl':
                options = [
                    {
                        label: 'ROA - Road Freight',
                        value: 'ROA',
                    },
                ]
                break
            case 'cou':
                options = [
                    {
                        label: 'COU - COURIER',
                        value: 'COU',
                    },
                ]
                break
            default:
                options = [
                    {
                        label: 'AIR - Air Freight',
                        value: 'AIR',
                    },
                ]
                break
        }
        return options
    }
    const goToFormCreate = (containerType: string) => {
        setFormType(containerType)
        newVendorBookingFormik.setFieldValue(
            'containerMode',
            containerType.toUpperCase(),
        )
        setIsFormPage(true)
    }

    // function related parties
    const loadCompanyDropdown = () => {
        const loadShipperDropdown: IDropdownItem<IRelatedPartiesFormDummy>[] =
            relatedPartiesAddressShipperDummy.map((item) => ({
                label: item.companyName,
                value: item.companyName,
                additionalData: item,
            }))
        const loadConsigneeDropdown: IDropdownItem<IRelatedPartiesFormDummy>[] =
            relatedPartiesAddressConsigneeDummy.map((item) => ({
                label: item.companyName,
                value: item.companyName,
                additionalData: item,
            }))

        const updatedCompany: IRealatedPartiesDropdownItem['company'] = {
            shipper: loadShipperDropdown,
            shipperPickupAddress: loadShipperDropdown,
            consignee: loadConsigneeDropdown,
            consigneeDeliveryAddress: loadConsigneeDropdown,
        }

        setRelatedPartiesDropdown({
            ...relatedPartiesDropdown,
            company: updatedCompany,
        })
    }

    const loadContactDropdown = ({
        dropdownData,
        isForShipper,
    }: {
        dropdownData: IRelatedPartiesFormDummy[]
        isForShipper: boolean
    }) => {
        let contact: IRealatedPartiesDropdownItem['contact'] = {
            shipperPickupAddress: [],
            shipper: [],
            consigneeDeliveryAddress: [],
            consignee: [],
        }
        dropdownData.forEach((item) => {
            if (isForShipper) {
                const findItemByCompanyName = dropdownData.find(
                    (item) =>
                        item.companyName ===
                        newVendorBookingFormik.values.shipperPickupAddress
                            .companyName,
                )
                const loadDropdown = findItemByCompanyName?.contacts?.map(
                    (item2) => ({
                        label: item2.contactName,
                        value: item2.contactName,
                        additionalData: item2,
                    }),
                )
                contact.shipperPickupAddress = loadDropdown || []
                contact.shipper = loadDropdown || []
            } else {
                const findItemByCompanyName = dropdownData.find(
                    (item) =>
                        item.companyName ===
                        newVendorBookingFormik.values.consigneeDeliveryAddress
                            .companyName,
                )
                const loadDropdown = findItemByCompanyName?.contacts?.map(
                    (item2) => ({
                        label: item2.contactName,
                        value: item2.contactName,
                        additionalData: item2,
                    }),
                )
                contact.consigneeDeliveryAddress = loadDropdown || []
                contact.consignee = loadDropdown || []
            }
        })

        setRelatedPartiesDropdown({
            ...relatedPartiesDropdown,
            contact,
        })
    }

    const handleClearCompanyForm = ({ key }: { key: string }) => {
        switch (key) {
            case 'shipperPickupAddress':
                newVendorBookingFormik.setFieldValue(
                    key,
                    newVendorBookingInitialValue.shipperPickupAddress,
                )
                newVendorBookingFormik.setFieldValue(
                    'shipper',
                    newVendorBookingInitialValue.shipperPickupAddress,
                )
                break
            case 'consigneeDeliveryAddress':
                newVendorBookingFormik.setFieldValue(
                    key,
                    newVendorBookingInitialValue.shipperPickupAddress,
                )
                newVendorBookingFormik.setFieldValue(
                    'consignee',
                    newVendorBookingInitialValue.shipperPickupAddress,
                )
                break
            case 'consignee':
            case 'shipper':
                newVendorBookingFormik.setFieldValue(
                    key,
                    newVendorBookingInitialValue.shipperPickupAddress,
                )
        }
    }

    const handleCompanySelectDropdown = ({
        key,
        itemDropdown,
    }: {
        key: string
        itemDropdown: IRelatedPartiesFormDummy
    }) => {
        const updatedData = {
            address1: itemDropdown.address1,
            address2: itemDropdown.address2,
            city: itemDropdown.city,
            state: itemDropdown.state,
            postCode: itemDropdown.postCode,
            country: itemDropdown.country,
        }

        switch (key) {
            case 'shipperPickupAddress':
                newVendorBookingFormik.setFieldValue(key, {
                    ...newVendorBookingFormik.values[key],
                    ...updatedData,
                })
                newVendorBookingFormik.setFieldValue('shipper', {
                    ...newVendorBookingFormik.values[key],
                    ...updatedData,
                })
                break
            case 'consigneeDeliveryAddress':
                newVendorBookingFormik.setFieldValue(key, {
                    ...newVendorBookingFormik.values[key],
                    ...updatedData,
                })
                newVendorBookingFormik.setFieldValue('consignee', {
                    ...newVendorBookingFormik.values[key],
                    ...updatedData,
                })
                break
            case 'consignee':
            case 'shipper':
                newVendorBookingFormik.setFieldValue(key, {
                    ...newVendorBookingFormik.values[key],
                    ...updatedData,
                })
        }
    }

    const handleContactSelectDropdown = ({
        key,
        itemDropdown,
    }: {
        key: string
        itemDropdown: IRelatedPartiesContactFormDummy
    }) => {
        const updatedData = {
            phoneNumber: itemDropdown.phoneNumber,
            mobileNumber: itemDropdown.mobileNumber,
            emailAddress: itemDropdown.emailAddress,
        }

        switch (key) {
            case 'shipperPickupAddress':
                newVendorBookingFormik.setFieldValue(key, {
                    ...newVendorBookingFormik.values[key],
                    ...updatedData,
                })
                newVendorBookingFormik.setFieldValue('shipper', {
                    ...newVendorBookingFormik.values[key],
                    ...updatedData,
                })
                break
            case 'consigneeDeliveryAddress':
                newVendorBookingFormik.setFieldValue(key, {
                    ...newVendorBookingFormik.values[key],
                    ...updatedData,
                })
                newVendorBookingFormik.setFieldValue('consignee', {
                    ...newVendorBookingFormik.values[key],
                    ...updatedData,
                })
                break
            case 'consignee':
            case 'shipper':
                newVendorBookingFormik.setFieldValue(key, {
                    ...newVendorBookingFormik.values[key],
                    ...updatedData,
                })
        }
    }

    // common useEffects
    useEffect(() => {
        loadCompanyDropdown()
        setIsFirstRender(false)
    }, [])

    useEffect(() => {
        handleFormRequired()
    }, [newVendorBookingFormik.submitCount, newVendorBookingFormik.errors])

    // related parties useEffects
    useEffect(() => {
        if (!isFirstRender) {
            loadContactDropdown({
                dropdownData: relatedPartiesAddressShipperDummy,
                isForShipper: true,
            })
        }
    }, [newVendorBookingFormik.values.shipperPickupAddress])

    useEffect(() => {
        if (!isFirstRender) {
            loadContactDropdown({
                dropdownData: relatedPartiesAddressConsigneeDummy,
                isForShipper: false,
            })
        }
    }, [newVendorBookingFormik.values.consigneeDeliveryAddress])

    console.log('packLineData', packLineData)

    return {
        navigate,
        isFormPage,
        tabFormBooking,
        tabFormBookingItems,
        formType,
        setTabFormBooking,
        newVendorBookingFormik,
        loading,
        loadTransportModeByContainerDropdown,
        goToFormCreate,
        handleClearCompanyForm,
        relatedPartiesDropdown,
        handleCompanySelectDropdown,
        handleContactSelectDropdown,
        setConfirmationModalProperty,
        confirmationModal,
        confirmationModalProperty,
        saveBooking,

        // pack lines
        packLinesData,
        packLineParams,
        formPackLineModal,
        newVendorBookingPackLinesFormik,
        packlinesSelected,
        setPackLinesData,
        setPacklinesSelected,
        setPackLineParams,
        setPackLineData,
        newVendorBookingPackLineInitialValue,

        // containers
        containersData,
        setContainersData,
        setContainersSelected,
        containersSelected,
        setContainerParams,
        setContainerData,
        newVendorBookingContainerInitialValue,
        formContainerModal,
        containerParams,
        newVendorBookingContainerFormik,
    }
}

export default useNewVendorBooking

import FormDatePicker from '@components/form-date-picker/form-date-picker.component'
import FormDropdown from '@components/form-dropdown/form-dropdown.component'
import useShipmentFilter from './shipment-filter.service'

const ShipmentFilter = () => {
    const { getCompanyOptions, companyData } = useShipmentFilter()
    const { dropdownItems } = companyData

    return (
        <div className={`flex flex-col gap-3 p-4 overflow-auto`}>
            <FormDatePicker
                name={'eta'}
                isRange={true}
                rangeLabel={{
                    start: 'ETA START',
                    end: 'ETA END',
                }}
                useUppercaseLabel
            />
            <FormDatePicker
                name={'etd'}
                isRange={true}
                rangeLabel={{
                    start: 'ETD START',
                    end: 'ETD END',
                }}
                useUppercaseLabel
            />
            <FormDropdown
                isSearchable
                label={'transport mode'}
                name={`transportMode`}
                placeholder="Select Here"
                parentDivClassName="!mb-0"
                useUppercaseLabel
                isMultiSelect
                isClearable
                options={[]}
            />
            <FormDropdown
                isSearchable
                label="consignor"
                name="consignor"
                placeholder="Select Here"
                parentDivClassName="!mb-0"
                useUppercaseLabel
                isMultiSelect
                isClearable
                isAsync
                defaultOptions
                loadOptions={(inputValue: string) =>
                    getCompanyOptions('consignor', inputValue)
                }
                options={dropdownItems?.consignor}
            />
            <FormDropdown
                isSearchable
                label="consignee"
                name="consignee"
                placeholder="Select Here"
                parentDivClassName="!mb-0"
                useUppercaseLabel
                isMultiSelect
                isClearable
                isAsync
                defaultOptions
                loadOptions={(inputValue: string) =>
                    getCompanyOptions('consignee', inputValue)
                }
                options={dropdownItems?.consignee}
            />
            <FormDropdown
                isSearchable
                label={'destination'}
                name={`destination`}
                placeholder="Select Here"
                parentDivClassName="!mb-0"
                useUppercaseLabel
                isMultiSelect
                isClearable
                options={[]}
            />
            <FormDropdown
                isSearchable
                label={'origin'}
                name={`origin`}
                placeholder="Select Here"
                parentDivClassName="!mb-0"
                useUppercaseLabel
                isMultiSelect
                isClearable
                options={[]}
            />
        </div>
    )
}

export default ShipmentFilter

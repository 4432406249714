import Button from '@components/button/button.component'
import { useTranslation } from 'react-i18next'
import useEditShippingInstruction from './edit-shipping-instructions.service'
import Tab from '@components/tab/tab.component'
import { FormikProvider } from 'formik'
import Spinner from '@components/spinner/spinner.component'
import Details from './contents/details.component'
import RelatedParties from './contents/related-parties.component'
import PackLines from './contents/pack-lines.component'
import ConfirmationCreateBookingModal from './components/confirmation-create-booking-modal.component'
import FormPackLineModal from './components/form-pack-line-modal.component'
import Container from './contents/container.component'
import FormContainerModal from './components/form-container-modal.component'

const EditShippingInstruction = () => {
    const { t } = useTranslation()

    const service = useEditShippingInstruction()

    const loadContentForm = () => {
        const contents: Record<string, JSX.Element> = {
            details: <Details service={service} />,
            relatedParties: <RelatedParties service={service} />,
            packLines: <PackLines service={service} />,
            container: <Container service={service} />,
        }
        return contents[service.tabFormBooking.value]
    }

    return (
        <div className="container-global content-full-height flex">
            <div className="flex flex-grow flex-col w-[calc(100vw-20rem)] sm:w-[calc(100vw-4rem)]">
                <Tab
                    containerClassName="px-3"
                    items={service.tabFormBookingItems.filter(
                        (item) =>
                            service.formType === 'fcl' ||
                            item.value !== 'container',
                    )}
                    tabFilter={service.tabFormBooking}
                    onChange={(item) => {
                        service.setTabFormBooking(item)
                    }}
                />
                <div className="border-b"></div>
                <FormikProvider value={service.editShippingInstructionFormik}>
                    <div className="h-[calc(100vh-13.8rem)]">
                        {service.loading ? (
                            <div
                                className={`h-full flex justify-center items-center`}
                            >
                                <Spinner label="Please Wait..." />
                            </div>
                        ) : (
                            loadContentForm()
                        )}
                    </div>
                </FormikProvider>
                <div className="border-b"></div>
                <div className="p-3 flex items center justify-between">
                    <Button
                        onClick={() => {
                            service.setConfirmationModalProperty({
                                title: 'Discard Changes',
                                message:
                                    'Everything that you’ve added in the page will not be recorded',
                                btnProceed: {
                                    onClick: () => service.navigate(-1),
                                    variant: 'brand-v2-inverse',
                                },
                            })
                            service.confirmationModal.openModalHandling()
                        }}
                        label="discard changes"
                        useUpperCase
                        variant="brand-v2-inverse"
                        className="w-btnRegularWidth"
                    />
                    <Button
                        onClick={() => {
                            service.saveShippingInstruction()
                        }}
                        label="PLACE BOOKING"
                        className="w-btnRegularWidth"
                        variant="brand-v2-inverse"
                        isDisabled={service.loading}
                    />
                </div>
            </div>

            {/* modals */}
            <>
                <ConfirmationCreateBookingModal service={service} />
                <FormPackLineModal service={service} />
                <FormContainerModal service={service} />
            </>
        </div>
    )
}

export default EditShippingInstruction

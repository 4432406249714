import { IDropdownItem } from '@components/dropdown/dropdown.interface'
import FormDropdown from '@components/form-dropdown/form-dropdown.component'
import {
    CompanyAddressType,
    IConsignorConsigneeData,
    IConsignorConsigneeInitialData,
} from 'repository/consignee-consignor/consignee-consignor.interface'

const BookingFilter = ({
    companyData,
    getCompanyOptions,
}: {
    companyData: IConsignorConsigneeInitialData
    getCompanyOptions: (
        addressType: CompanyAddressType,
        company?: string,
    ) => Promise<IDropdownItem<IConsignorConsigneeData>[]>
}) => {
    const { dropdownItems } = companyData

    return (
        <div className={`flex flex-col gap-3 p-4 overflow-auto`}>
            <FormDropdown
                isSearchable
                label={'consignor'}
                name={`consignor`}
                placeholder="Select Here"
                parentDivClassName="!mb-0"
                useUppercaseLabel
                isMultiSelect
                isClearable
                isAsync
                defaultOptions
                loadOptions={(inputValue: string) =>
                    getCompanyOptions('consignor', inputValue)
                }
                options={dropdownItems?.consignor}
            />
            <FormDropdown
                isSearchable
                label={'consignee'}
                name={`consignee`}
                placeholder="Select Here"
                parentDivClassName="!mb-0"
                useUppercaseLabel
                isMultiSelect
                isClearable
                isAsync
                defaultOptions
                loadOptions={(inputValue: string) =>
                    getCompanyOptions('consignee', inputValue)
                }
                options={dropdownItems?.consignee}
            />
            <FormDropdown
                isSearchable
                label={'destination'}
                name={`destination`}
                placeholder="Select Here"
                parentDivClassName="!mb-0"
                useUppercaseLabel
                isMultiSelect
                isClearable
                options={[]}
            />
            <FormDropdown
                isSearchable
                label={'origin'}
                name={`origin`}
                placeholder="Select Here"
                parentDivClassName="!mb-0"
                useUppercaseLabel
                isMultiSelect
                isClearable
                options={[]}
            />
        </div>
    )
}

export default BookingFilter

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Pie, PieChart, ResponsiveContainer, Tooltip, } from 'recharts';
import DonutLoading from '../loading/donat.loading.component';
import EmptyResult from '@components/empty-result/empty-result.component';
function DoughnutChart(_a) {
    // const [dimensions, setDimensions] = useState({ width: 0, height: 0 })
    var data = _a.data, _b = _a.isLoading, isLoading = _b === void 0 ? false : _b, colorChart = _a.colorChart, props = __rest(_a, ["data", "isLoading", "colorChart"]);
    // const ref = useRef<HTMLDivElement>(null)
    // useLayoutEffect(() => {
    //     const handleResize = () => {
    //         if (ref.current) {
    //             setDimensions({
    //                 width: ref.current.offsetWidth,
    //                 height: ref.current.offsetHeight,
    //             })
    //         }
    //     }
    //     // Initial dimension set
    //     handleResize()
    //     // Add event listener for window resize
    //     window.addEventListener('resize', handleResize)
    //     return () => {
    //         window.removeEventListener('resize', handleResize)
    //     }
    // }, [isLoading])
    var responsiveContainerClassnames = props.responsiveContainerClassnames
        ? props.responsiveContainerClassnames
        : '';
    var CustomTooltip = function (_a) {
        var _b, _c;
        var active = _a.active, payload = _a.payload, label = _a.label;
        if (active && payload && payload.length) {
            var _d = payload[0], name_1 = _d.name, value = _d.value;
            var customTooltip = '';
            if (props.renderTextLegendAndTooltip) {
                customTooltip = props.renderTextLegendAndTooltip({
                    value: Number(value),
                    additionalValue: (_c = (_b = payload[0]) === null || _b === void 0 ? void 0 : _b.payload) === null || _c === void 0 ? void 0 : _c.additionalValue,
                });
            }
            else {
                customTooltip = (value === null || value === void 0 ? void 0 : value.toLocaleString()) || '';
            }
            return (_jsxs("div", __assign({ className: "custom-tooltip p-2 rounded-md themes-bg-gray-v9 opacity-80 themes-text-white flex items-center max-w-[200px] " }, { children: [_jsx("div", { className: "min-w-[20px] max-w-[20px] w-[20px] h-[20px] bg-white rounded-md mr-2", style: { backgroundColor: payload[0].payload.fill } }), _jsx("p", __assign({ className: "label 2xl:text-size-S text-size-XS" }, { children: "".concat(name_1, ": ").concat(customTooltip) }))] })));
        }
        return null;
    };
    var renderColorfulLegendText = function (item) {
        var totalValue = item.reduce(function (acc, itemData) { return acc + itemData.value; }, 0);
        return (_jsx("div", __assign({ className: "flex flex-grow w-[140px] gap-3 flex-wrap h-fit flex-initial" }, { children: item.map(function (itemData, index) {
                var value = itemData.value, name = itemData.name, fill = itemData.fill, textColor = itemData.textColor;
                var percentage = Number(((value / totalValue) * 100).toFixed(2));
                var formattedValue = '';
                if (props.renderTextLegendAndTooltip) {
                    formattedValue = props.renderTextLegendAndTooltip({
                        value: itemData.value,
                        additionalValue: itemData.additionalValue,
                    });
                }
                else {
                    formattedValue = value.toLocaleString();
                }
                return (_jsx("div", __assign({ className: "flex 2xl:text-size-S text-size-XS leading-none" }, { children: _jsxs("div", __assign({ className: "2xl:w-[103px] xl:w-[61px] w-[140px]" }, { children: [_jsx("div", __assign({ className: "themes-text-gray-v9 pt-1 text-ellipsis overflow-hidden text-nowrap" }, { children: name })), _jsx("div", __assign({ className: "themes-text-gray-v9 2xl:text-size-S text-size-XS font-bold overflow-hidden text-ellipsis text-nowrap " }, { children: "".concat(formattedValue) })), _jsx("div", __assign({ style: { background: "".concat(fill) }, className: "w-full h-4 px-1 bg-red-100 rounded justify-center items-center gap-1 inline-flex " }, { children: _jsxs("div", __assign({ style: { color: "".concat(textColor) }, className: " font-bold leading-[18px]" }, { children: [percentage ? percentage : 0, "%"] })) }))] })) }), index));
            }) })));
    };
    var isEmpityData = data.every(function (data) { return data.value === 0; });
    var empityResultComponent = props.customEmpityResultComponent ? (props.customEmpityResultComponent) : (_jsx(EmptyResult, { className: "w-full", message: 'Data not found' }));
    return isLoading ? (_jsx(DonutLoading, {})) : isEmpityData ? (empityResultComponent) : (_jsxs("div", __assign({ className: "h-fit grid grid-cols-12 gap-3 doughnut-chart-container" }, { children: [_jsx(ResponsiveContainer, __assign({ className: " !col-span-6 lg:!col-span-5 !h-[150px] ".concat(responsiveContainerClassnames) }, { children: _jsxs(PieChart, __assign({ className: "" }, { children: [_jsx(Pie, { data: data, cx: "50%", cy: "50%", innerRadius: "60%", outerRadius: "100%", fill: "#8884d8", paddingAngle: 0, dataKey: "value", startAngle: 90, endAngle: -270 }), _jsx(Tooltip, { content: _jsx(CustomTooltip, {}) })] })) })), colorChart ? colorChart : renderColorfulLegendText(data)] })));
}
export default DoughnutChart;

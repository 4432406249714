import { CallApiStatus } from 'common/common.interface'

export interface IUserCountry {
    code: string
    name: string
}

export interface IUserCurrency {
    code: string
    description: string
}

export interface IManageUser {
    id: string
    userName: string
    fullName: string
    email: string
    jobTitle: string
    nativeStaffData: null
    jobCategory: string
    organization: {
        code: string
        name: string
    }
    country: IUserCountry
    province: string
    city: string
    addressDetails: string
    mobilePhoneNumber: string
    homePhoneNumber: string
    contactName: string
    phoneNumber: string
    role: IRole | null
    lastLogin: string
    createdAt: string
    updatedAt: string
    deletedAt: string | null
    suspendedAt: string | null
    createdBy: string | null
    updatedBy: string | null
    deletedBy: string | null
    isActive: boolean | null
    resetPassword: IResetPassword | null
    postCode: string
    address2: string
}

export interface IAccess {
    canAccessModule: boolean
    canViewDetails: boolean
    canCreate: boolean
    canUpdate: boolean
    canDelete: boolean
}

export interface IModuleAccess {
    moduleTemplateId: string
    access: IAccess
}

export interface IRole {
    roleType: ERoleType
    portalAccess: EPortalAccess
    moduleAccessList: IModuleAccess[]
}

export enum ERoleType {
    Admin,
    Regular,
}

export enum EPortalAccess {
    Customer,
    Agent,
    ControlTower,
}

export interface IResetPassword {
    email: string
    newPassword: string | null
    passwordConfirmation: string | null
    passwordResetToken: string | null
    resetTokenExpires: string | null
    isTokenValid: boolean
}
export interface IAccess {
    canAccessModule: boolean
    canViewDetails: boolean
    canCreate: boolean
    canUpdate: boolean
    canDelete: boolean
}

export interface IModuleAccess {
    moduleTemplateId: string
    access: IAccess
}

export interface IResetPassword {
    email: string
    newPassword: string | null
    passwordConfirmation: string | null
    passwordResetToken: string | null
    resetTokenExpires: string | null
    isTokenValid: boolean
}

export interface IManageUserStatusTotal {
    all: number
    active: number
    suspended: number
}

export interface IManageUserFilter {
    status?: string
    id?: string
    inProgressStatus?: string
    pageSize?: number
    pageNumber?: number
    search?: string
    shipmentId?: string
    SortBy?: string
    transport?: string[]
    origin?: string[]
    destination?: string[]
    consignee?: string[]
    shipper?: string[]
    ETAStart?: string
    ETAEnd?: string
    ETDStart?: string
    ETDEnd?: string
    ATDStart?: string
    ATDEnd?: string
    ATAStart?: string
    ATAEnd?: string
}
export interface IManageUserDetails {
    shipmentID: string
    transport: string
    origin: string
    destination: string
    shipper: string
    consignee: string
    etd: string
    eta: string
    ata: string | null
    atd: string | null
    weight: number
    uw: string
    volume: number
    uv: string
    houseBill: string
    chargeable: number
    goodValue: number
    goodValueCurrency: string
    containerMode: string
    shipmentType: string
    containerType: string | null
    voyageOrFlight: string | null
    wv: string | null
    inners: number
    innersType: string
    insuranceValue: number
    insuranceValueCurrency: string
    description: string
    marksAndNumber: string | null
    incoterm: string
    additionalTerm: string
    supplierInvoice: string | null
    routeCode: string
    spotRate: number
    spotRateCurrency: string
    serviceLevel: string
    entryDetails: string | null
    issueDate: string | null
    expiryDate: string | null
    releaseType: string
    chargeApply: string
    packs: number
    packsType: string
    screeningStatus: string
    shipmentStatus: string
    phase: string | null
    efreightStatus: string | null
    size: number
}

export interface IFilterModuleTemplates {
    portalAcess?: string
    industry?: string
}

export interface IModuleTemplate {
    id: string
    parentId: string
    moduleName: string
    actionList: string[]
    portalAccess: string[]
    industry: string[]
    loadActionList: IActionListModule[]
}

export interface IActionListModule {
    label: string
    value: string
    isActive: boolean
}

export interface ICreateUser {
    fullName: ''
    userName: 'christian'
    email: 'christian.soerensen@ifb.com'
    jobTitle: 'administrator'
    jobCategory: 'administrator'
    organizationName: 'Onebyone'
    country: 'Indonesian'
    province: 'Kepulauan Riau'
    city: 'Batam'
    addressDetails: ''
    mobilePhoneNumber: ''
    homePhoneNumber: ''
    contactName: ''
    phoneNumber: ''
    password: 'christian2024!?'
    moduleList: []
}

export interface IUserDefaultFilter {
    staffId: string
}

export interface IUserDefaultData {
    branch: { code: string; name: string }
    city: string
    state: string
    postcode: string
    country: IUserCountry
    currency: IUserCurrency
}

export interface IGetUserDefaultData {
    data?: IUserDefaultData
    status: CallApiStatus
    error?: string
}

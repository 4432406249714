var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import './month-year-picker.style.css';
import useMonthYearPicker from './month-year-picker.service';
var MonthYearPicker = function (_a) {
    var _b;
    var name = _a.name, label = _a.label, _c = _a.disabled, disabled = _c === void 0 ? false : _c, _d = _a.readonly, readonly = _d === void 0 ? false : _d, _e = _a.required, required = _e === void 0 ? false : _e, _f = _a.isClearable, isClearable = _f === void 0 ? false : _f, useUppercaseLabel = _a.useUppercaseLabel, parentInputClassName = _a.parentInputClassName, className = _a.className, onChange = _a.onChange, value = _a.value, _g = _a.placeholder, placeholder = _g === void 0 ? 'MM/YYYY' : _g, _h = _a.isFormError, isFormError = _h === void 0 ? false : _h, props = __rest(_a, ["name", "label", "disabled", "readonly", "required", "isClearable", "useUppercaseLabel", "parentInputClassName", "className", "onChange", "value", "placeholder", "isFormError"]);
    var _j = useMonthYearPicker({
        value: value,
        readonly: readonly,
        onChange: onChange,
        disabled: disabled,
    }), focus = _j.focus, selectedMonthYear = _j.selectedMonthYear, months = _j.months, pickerRef = _j.pickerRef, toggleDropdown = _j.toggleDropdown, handleClear = _j.handleClear, isOpen = _j.isOpen, handleMonthChange = _j.handleMonthChange, years = _j.years, handleYearChange = _j.handleYearChange;
    var showLabelClass = !label || label === '' ? 'hidden' : '';
    var isRequired = required ? 'required' : '';
    var labelError = isFormError ? 'themes-text-red-v3' : '';
    var labelFocus = focus ? 'themes-text-brand-v5' : labelError;
    var borderError = isFormError
        ? "themes-border-red"
        : 'themes-border-gray-v3';
    var borderFocus = focus ? 'border themes-border-brand-v5' : borderError;
    var displayValue = selectedMonthYear
        ? "".concat((_b = months[parseInt(selectedMonthYear.split('/')[0], 10) - 1]) === null || _b === void 0 ? void 0 : _b.substring(0, 3), " ").concat(selectedMonthYear.split('/')[1])
        : placeholder;
    var dataTestIdComponent = (props === null || props === void 0 ? void 0 : props.dataTestId)
        ? "".concat(props.dataTestId, "_MONTHYEARPICKER")
        : '';
    return (_jsxs("div", __assign({ className: " ".concat(parentInputClassName || '', " month-year-picker-container css-month-year-picker-container ") }, { children: [label && (_jsx("label", __assign({ className: "".concat(showLabelClass, " ") }, { children: _jsx("div", { children: _jsx("p", __assign({ className: "".concat(isRequired, " ").concat(labelFocus, " ").concat(props.labelClassNames || '') }, { children: useUppercaseLabel ? label.toUpperCase() : label })) }) }))), _jsxs("div", __assign({ ref: pickerRef, className: "".concat(className || '', " month-year-picker-input css-month-year-picker-input-container relative border px-3 rounded-[3px] h-full flex flex-col justify-center cursor-pointer ").concat(disabled ? 'bg-gray-200 text-gray-500' : 'bg-white themes-text-gray-v9', " ").concat(borderFocus), onClick: toggleDropdown, "data-testid": dataTestIdComponent }, { children: [_jsx("input", { type: "hidden", name: name, value: selectedMonthYear || '' }), _jsxs("div", __assign({ className: "flex justify-between" }, { children: [_jsx("div", __assign({ className: "".concat(selectedMonthYear ? 'themes-text-gray-v9' : 'themes-text-gray-v4', " flex items-center") }, { children: displayValue })), _jsxs("div", __assign({ className: "flex items-center" }, { children: [isClearable && selectedMonthYear && (_jsx("i", { className: "ri-close-line pr-2 text-[16px] cursor-pointer font-bold themes-text-gray-v4", onClick: function (e) {
                                            e.stopPropagation();
                                            handleClear();
                                        } })), _jsx("i", { className: "ri-calendar-event-line border-l pl-1" })] }))] }))] })), isOpen && (_jsxs("div", __assign({ className: "month-year-picker-month-options css-month-year-picker-month-options" }, { children: [_jsx("div", __assign({ className: "months overflow-y-auto overflow-x-hidden" }, { children: months.map(function (month, index) { return (_jsx("div", __assign({ className: "px-4 py-2 cursor-pointer ".concat(index === parseInt((selectedMonthYear === null || selectedMonthYear === void 0 ? void 0 : selectedMonthYear.split('/')[0]) || '-1', 10) - 1 ? 'bg-brand-v5 themes-text-brand-v5' : 'themes-text-gray-v9'), onClick: function () { return handleMonthChange(index); }, "data-testid": dataTestIdComponent
                                ? "".concat(dataTestIdComponent, "_MONTH_").concat(index + 1)
                                : '' }, { children: month }), index)); }) })), _jsx("div", __assign({ className: "years flex-1 border-l overflow-y-auto overflow-x-hidden" }, { children: years.map(function (year, index) { return (_jsx("div", __assign({ className: "px-4 py-2 cursor-pointer ".concat(year === parseInt((selectedMonthYear === null || selectedMonthYear === void 0 ? void 0 : selectedMonthYear.split('/')[1]) || '-1', 10) ? 'bg-brand-v5 themes-text-brand-v5' : 'themes-text-gray-v9'), onClick: function () { return handleYearChange(year); }, "data-testid": dataTestIdComponent
                                ? "".concat(dataTestIdComponent, "_YEAR_").concat(index + 1)
                                : '' }, { children: year }), index)); }) }))] })))] })));
};
export default MonthYearPicker;

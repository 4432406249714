// import Button from '@components/button/button.component'
// import { metaDummy } from '../../trackings.dummy'
import { packLinesData } from '../trackings-details.static'
import SimpleTable from '@components/simple-table/simple-table.component'
import { useTrackingDetails } from '../trackings-details.service'
import { IPackLinesTracking } from '../../trackings.interface'

const PackLines = () => {
    const {
        allModalPackLines,
        headersPackLines,
        // ConfigureColumnsModalServicePackLines,
    } = useTrackingDetails()

    return (
        <div className="h-[calc(100vh-13.5rem)]">
            <SimpleTable<IPackLinesTracking>
                headers={headersPackLines}
                data={packLinesData}
                disableErrorIllustration
                // usePagination
                // nextHandling={(page) => {
                //     console.log('hit Next API -> ' + page)
                // }}
                // previousHandling={(page) => {
                //     console.log('hit Prev API -> ' + page)
                // }}
                // meta={metaDummy}
                // additionalButtonBottom={
                //     <>
                //         <Button
                //             onClick={() => ConfigureColumnsModalServicePackLines.openModalHandling()}
                //             label="Configure Column"
                //             className="w-[10rem] ml-0"
                //             variant="brand-inverse"
                //         />
                //     </>
                // }
            />
            {allModalPackLines}
        </div>
    )
}

export default PackLines

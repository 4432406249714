var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import Input from '@components/input/input.component';
import { formatDate } from '@services/common.service';
import { useTranslation } from 'react-i18next';
import './date-picker.style.css';
var DatePickerInput = function (_a) {
    var _b;
    var selectedDate = _a.selectedDate, disable = _a.disable, selectedRange = _a.selectedRange, isRange = _a.isRange, label = _a.label, onClear = _a.onClear, setShowDatePicker = _a.setShowDatePicker, _c = _a.iconPosition, iconPosition = _c === void 0 ? 'right' : _c, props = __rest(_a, ["selectedDate", "disable", "selectedRange", "isRange", "label", "onClear", "setShowDatePicker", "iconPosition"]);
    var t = useTranslation().t;
    var setRangeLabelUseUppercase = function (rangeLabel) {
        return props.useUppercaseLabel
            ? {
                start: rangeLabel === null || rangeLabel === void 0 ? void 0 : rangeLabel.start.toUpperCase(),
                end: rangeLabel === null || rangeLabel === void 0 ? void 0 : rangeLabel.end.toUpperCase(),
            }
            : rangeLabel;
    };
    var rangeLabel = isRange && props.rangeLabel
        ? setRangeLabelUseUppercase(props.rangeLabel)
        : setRangeLabelUseUppercase({
            start: t('additional.start_date'),
            end: t('additional.end_date'),
        });
    return (_jsx(_Fragment, { children: isRange ? (_jsxs("div", __assign({ className: "flex rounded-[5px] bg-transparent relative gap-2" }, { children: [_jsxs("div", __assign({ className: "flex w-full relative justify-between" }, { children: [_jsx(Input, { label: rangeLabel === null || rangeLabel === void 0 ? void 0 : rangeLabel.start, onClick: function () {
                                setShowDatePicker(true);
                            }, readonly: true, value: (selectedRange === null || selectedRange === void 0 ? void 0 : selectedRange.from)
                                ? formatDate((_b = selectedRange === null || selectedRange === void 0 ? void 0 : selectedRange.from) === null || _b === void 0 ? void 0 : _b.toString())
                                : '', className: "text-sm placeholder:text-sm !h-[45px] !w-full !text-size-M  ".concat(disable
                                ? 'themes-bg-gray-v5'
                                : 'themes-bg-white'), placeholder: 'DD/MM/YYYY' }), _jsxs("div", __assign({ className: "ml-[-55px] z-[1] flex items-center" }, { children: [(selectedRange === null || selectedRange === void 0 ? void 0 : selectedRange.from) && (_jsx("i", { className: "ri-close-line pr-1 cursor-pointer font-bold", onClick: function () {
                                        props.setSelectedRange &&
                                            props.setSelectedRange(undefined);
                                        setShowDatePicker(false);
                                    } })), _jsx("div", { className: "ri-calendar-event-line border-l px-2 my-2    ".concat(disable ? '' : 'cursor-pointer', " "), onClick: function () { return setShowDatePicker(true); } })] }))] })), _jsxs("div", __assign({ className: "flex w-full relative justify-between " }, { children: [_jsx(Input, { label: rangeLabel.end, onClick: function () {
                                setShowDatePicker(true);
                            }, readonly: true, value: (selectedRange === null || selectedRange === void 0 ? void 0 : selectedRange.to)
                                ? formatDate(selectedRange.to.toString())
                                : '', className: "text-sm placeholder:text-sm !h-[45px] !w-full !text-size-M ".concat(disable
                                ? 'themes-bg-gray-v5'
                                : 'themes-bg-white'), placeholder: 'DD/MM/YYYY' }), _jsxs("div", __assign({ className: "ml-[-55px] z-[1] flex items-center" }, { children: [(selectedRange === null || selectedRange === void 0 ? void 0 : selectedRange.from) && (_jsx("i", { className: "ri-close-line pr-1 cursor-pointer font-bold", onClick: function () {
                                        props.setSelectedRange &&
                                            props.setSelectedRange(undefined);
                                        setShowDatePicker(false);
                                    } })), _jsx("div", { className: "ri-calendar-event-line border-l px-2 my-2    ".concat(disable ? '' : 'cursor-pointer', " "), onClick: function () { return setShowDatePicker(true); } })] }))] }))] }))) : (_jsxs("div", __assign({ className: "flex css-date-input-single-container" }, { children: [iconPosition === 'left' && (_jsx("div", { className: "ri-calendar-event-line border-r px-2 my-2 flex items-center mr-[-36px] z-[1] cursor-pointer", onClick: function () {
                        setShowDatePicker(true);
                    } })), _jsx(Input, { onChange: function () { }, onClick: function () {
                        setShowDatePicker(true);
                    }, value: selectedDate
                        ? formatDate(selectedDate.toString())
                        : '', className: "text-sm placeholder:text-sm ".concat(iconPosition === 'left' && 'pl-10', " caret-transparent ").concat(props.containerInputClassName, " "), label: props.useUppercaseLabel
                        ? label === null || label === void 0 ? void 0 : label.toUpperCase()
                        : label, readonly: true, placeholder: 'DD/MM/YYYY', disabled: disable }), iconPosition === 'right' && (_jsx("div", { className: "ri-calendar-event-line css-right-icon border-l px-2 my-2 flex items-center ml-[-36px] z-[1]  ".concat(disable ? '' : 'cursor-pointer', " "), onClick: function () {
                        if (!disable)
                            setShowDatePicker(true);
                    } }))] }))) }));
};
export default DatePickerInput;

import { IConfigureColumns } from '@components/configure-columns-modal/configure-column-modal.interface'
import { IShippingInstructionsSummary } from './shipping-instructions.interface'
import {
    BookingsStatus,
    BookingsStatusLabelColor,
} from 'pages/bookings/bookings.interface'
import StatusLabel from 'common/components/status-label/status-label.component'
import { bookingStatusLabelColor } from 'pages/bookings/bookings.static'

export const shippingInstructionsConfigureColumns: IConfigureColumns<IShippingInstructionsSummary>[] =
    [
        {
            title: 'Flags',
            columns: [
                {
                    statusColumn: 'selectedColumns',
                    labelTranslation: 'additional.selected_column',
                    label: 'Mode',
                    accessor: 'transportMode',
                    tdClassName: '!pt-0 !pb-0',
                    className: '!h-auto',
                    showLabel: false,
                    sort: false,
                    customBuild: (data) => {
                        let iconTransportMode: string
                        switch (data) {
                            case 'Airplane':
                                iconTransportMode = 'ri-plane-line'
                                break
                            case 'Ship':
                                iconTransportMode = 'ri-ship-line'
                                break
                            case 'Truck':
                                iconTransportMode = 'ri-truck-line'
                                break
                            default:
                                iconTransportMode = 'ri-plane-line'
                        }

                        return (
                            <i
                                className={`${iconTransportMode} text-size-XL`}
                            ></i>
                        )
                    },
                },
                {
                    statusColumn: 'selectedColumns',
                    label: 'Status',
                    accessor: 'status',
                    className: '!h-9',
                    tdClassName: '!pt-0 !pb-0',
                    customBuild: (data) => {
                        const color =
                            data === 'submitted' ? 'success' : 'warning'
                        return <StatusLabel color={color}>{data}</StatusLabel>
                    },
                },
                {
                    statusColumn: 'selectedColumns',
                    label: 'Booking No.',
                    accessor: 'bookingNo',
                },
                {
                    statusColumn: 'selectedColumns',
                    label: 'Consignor',
                    accessor: 'consignor',
                },
                {
                    statusColumn: 'selectedColumns',
                    label: 'Consignee',
                    accessor: 'consignee',
                },
                {
                    statusColumn: 'selectedColumns',
                    label: 'Goods Description',
                    labelTranslation: 'products.goods_description',
                    accessor: 'goodsDescription',
                },
                {
                    statusColumn: 'selectedColumns',
                    label: 'Origin',
                    labelTranslation: 'bookings.origin',
                    accessor: 'origin',
                },
                {
                    statusColumn: 'selectedColumns',
                    label: 'Destination',
                    labelTranslation: 'bookings.destination',
                    accessor: 'destination',
                },
                {
                    statusColumn: 'selectedColumns',
                    label: 'Packs',
                    labelTranslation: 'products.packs',
                    accessor: 'packs',
                },
                {
                    statusColumn: 'selectedColumns',
                    label: 'Total Volume',
                    accessor: 'totalVolume',
                },
            ],
        },
    ]

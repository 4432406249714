import Button from '@components/button/button.component'
import useShippingInstructionDetails from './shipping-instruction-details.service'
import Spinner from '@components/spinner/spinner.component'
import Tab from '@components/tab/tab.component'
import { t } from 'i18next'
import { tabItemsShippingInstructionDetails } from './shipping-instruction-details.static'
import Details from './contents/details.component'
import RelatedParties from './contents/related-parties.component'
import PackLines from './contents/pack-lines.component'
import Container from './contents/container.component'

const ShippingInstructionDetails = () => {
    const service = useShippingInstructionDetails()

    const loadContent = () => {
        const contents: Record<string, JSX.Element> = {
            details: <Details service={service} />,
            packLines: <PackLines service={service} />,
            container: <Container service={service} />,
            relatedParties: <RelatedParties service={service} />,
        }
        return contents[service.tabFilter.value]
    }

    return (
        <div className="container-global content-full-height flex">
            <div className="flex flex-grow flex-col w-[calc(100vw-20rem)] sm:w-[calc(100vw-4rem)]">
                <div className="flex justify-between items-center px-3">
                    <Tab
                        items={tabItemsShippingInstructionDetails.filter(
                            (item) =>
                                service.shippingInstructionDetails.detailsData.containerMode.toLowerCase() ===
                                'fcl'
                                    ? item
                                    : item.value !== 'container',
                        )}
                        tabFilter={service.tabFilter}
                        onChange={(item) => {
                            service.setTabFilter(item)
                        }}
                    />
                </div>
                <div className="border-b"></div>
                <div className="h-[calc(100vh-13rem)] overflow-auto">
                    {service.loading ? (
                        <div
                            className={`flex items-center justify-center h-full`}
                        >
                            <Spinner label="Please Wait..." />
                        </div>
                    ) : (
                        <div className={` w-full h-full`}>{loadContent()}</div>
                    )}
                </div>
                <div className="border-b"></div>
                <div className="p-3 flex justify-between">
                    <Button
                        onClick={() =>
                            service.navigate('/shipping-instructions')
                        }
                        label={t('action.back')}
                        variant="brand-v2-inverse"
                        className="w-btnRegularWidth"
                        useUpperCase={true}
                    />
                    <Button
                        onClick={() =>
                            service.navigate(
                                '/edit-shipping-instruction/' + service.id,
                            )
                        }
                        label={'EDIT BOOKING'}
                        variant="brand-v2-inverse"
                        className="w-btnRegularWidth"
                        useUpperCase={true}
                    />
                </div>
            </div>
        </div>
    )
}

export default ShippingInstructionDetails

import { useEffect, useState } from 'react'
import { IUseShippingInstructionDetails } from './shipping-instruction-details.interface'
import { ITabItem } from '@components/tab/tab.interface'
import { tabItemsShippingInstructionDetails } from './shipping-instruction-details.static'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
    setShippingInstructionDetails,
    shippingInstructionDetailsSelector,
} from '../shipping-instructions.slice'
import { shippingInstructionsSummaryDummy } from '../shipping-instructions.dummy'

const useShippingInstructionDetails = (): IUseShippingInstructionDetails => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { id } = useParams()

    // selectors
    const shippingInstructionDetails = useSelector(
        shippingInstructionDetailsSelector,
    )

    // state
    const [containerMode] = useState<string>('Loose')
    const [tabFilter, setTabFilter] = useState<ITabItem>(
        tabItemsShippingInstructionDetails[0],
    )
    const [loading, setLoading] = useState<boolean>(false)

    const loadData = () => {
        const findData = shippingInstructionsSummaryDummy.find(
            (item) => item.bookingNo === id,
        )
        if (findData) dispatch(setShippingInstructionDetails(findData))
    }

    useEffect(() => {
        if (id) loadData()
    }, [id])

    return {
        containerMode,
        tabFilter,
        setTabFilter,
        navigate,
        loading,
        shippingInstructionDetails,
        id,
    }
}

export default useShippingInstructionDetails

import FormDropdown from '@components/form-dropdown/form-dropdown.component'
import FormInput from '@components/form-input/form-input.component'
import { useTranslation } from 'react-i18next'
import { IDropdownItem } from '@components/dropdown/dropdown.interface'
import {
    IRelatedPartiesContactFormDummy,
    IRelatedPartiesFormDummy,
    IRelatedPartiesPropertyForm,
    IUseEditShippingInstruction,
} from '../edit-shipping-instructions.interface'

const RelatedParties = ({
    service,
}: {
    service: IUseEditShippingInstruction
}) => {
    const { t } = useTranslation()

    const propertyForm: IRelatedPartiesPropertyForm[] = [
        {
            label: 'Shipper Pickup Address',
            key: 'shipperPickupAddress',
            contactDropdown:
                service.dropdownRelatedParties.contact.shipperPickupAddress,
            companyDropdown:
                service.dropdownRelatedParties.company.shipperPickupAddress,
        },
        {
            label: 'Shipper',
            key: 'shipper',
            contactDropdown: service.dropdownRelatedParties.contact.shipper,
            companyDropdown: service.dropdownRelatedParties.company.shipper,
        },
        {
            label: 'Consignee Delivery Address',
            key: 'consigneeDeliveryAddress',
            contactDropdown:
                service.dropdownRelatedParties.contact.consigneeDeliveryAddress,
            companyDropdown:
                service.dropdownRelatedParties.company.consigneeDeliveryAddress,
        },
        {
            label: 'Consignee',
            key: 'consignee',
            contactDropdown: service.dropdownRelatedParties.contact.consignee,
            companyDropdown: service.dropdownRelatedParties.company.consignee,
        },
    ]

    return (
        <div className="h-inherit overflow-auto">
            <div className="p-3 grid grid-cols-4 gap-3">
                {propertyForm.map((item) => {
                    return (
                        <div key={item.key} className="flex flex-col gap-3">
                            <div className="themes-text-gray-v9">
                                {item.label}
                            </div>
                            <div key={`${item.key}.company`}>
                                <FormDropdown<IRelatedPartiesFormDummy>
                                    isSearchable
                                    label={t('bookings.company_name')}
                                    name={`${item.key}.companyName`}
                                    options={item.companyDropdown}
                                    onClear={() =>
                                        service.handleClearCompanyForm({
                                            key: item.key,
                                        })
                                    }
                                    placeholder={t('action.enter', {
                                        value: t('bookings.company_name'),
                                    })}
                                    parentDivClassName="!mb-0 col-span-2"
                                    isClearable={true}
                                    useUppercaseLabel
                                    additionalOnClick={(data) => {
                                        const _data =
                                            data as IDropdownItem<IRelatedPartiesFormDummy>

                                        if (_data.additionalData) {
                                            service.handleCompanySelectDropdown(
                                                {
                                                    itemDropdown:
                                                        _data.additionalData,
                                                    key: item.key,
                                                },
                                            )

                                            service.loadContactDropdownByOnClick(
                                                {
                                                    dropdownData:
                                                        _data.additionalData
                                                            .contacts,
                                                    key: item.key,
                                                },
                                            )
                                        }
                                    }}
                                />
                            </div>
                            <FormInput
                                label={t('bookings.address_1')}
                                name={`${item.key}.address1`}
                                placeholder={t('action.enter', {
                                    value: t('bookings.address_1'),
                                })}
                                parentDivClassName="!mb-0 col-span-2"
                                disabled
                                useUppercaseLabel
                            />
                            <FormInput
                                label={t('bookings.address_2')}
                                name={`${item.key}.address2`}
                                placeholder={t('action.enter', {
                                    value: t('bookings.address_2'),
                                })}
                                parentDivClassName="!mb-0 col-span-2"
                                disabled
                                useUppercaseLabel
                            />
                            <FormInput
                                label={t('bookings.city')}
                                name={`${item.key}.city`}
                                placeholder={t('action.enter', {
                                    value: t('bookings.city'),
                                })}
                                parentDivClassName="!mb-0"
                                disabled
                                useUppercaseLabel
                            />
                            <FormInput
                                label={t('bookings.state')}
                                name={`${item.key}.state`}
                                placeholder={t('action.enter', {
                                    value: t('bookings.state'),
                                })}
                                parentDivClassName="!mb-0"
                                disabled
                                useUppercaseLabel
                            />
                            <FormInput
                                label={t('bookings.postcode')}
                                name={`${item.key}.postCode`}
                                placeholder={t('action.enter', {
                                    value: t('bookings.postcode'),
                                })}
                                parentDivClassName="!mb-0"
                                disabled
                                useUppercaseLabel
                            />

                            {/* temporarily replace with input form country  */}
                            <FormInput
                                label={t('bookings.country')}
                                name={`${item.key}.country.name`}
                                placeholder={t('action.enter', {
                                    value: t('bookings.country'),
                                })}
                                parentDivClassName="!mb-0 "
                                disabled
                                useUppercaseLabel
                            />
                            <div key={`${item.key}.contactName`}>
                                <FormDropdown<IRelatedPartiesContactFormDummy>
                                    isSearchable
                                    label={t('bookings.contact_name')}
                                    name={`${item.key}.contactName`}
                                    placeholder={t('action.enter', {
                                        value: t('bookings.contact_name'),
                                    })}
                                    parentDivClassName="!mb-0 col-span-2"
                                    isClearable
                                    options={item.contactDropdown}
                                    onClear={() =>
                                        service.handleClearCompanyForm({
                                            key: item.key,
                                        })
                                    }
                                    additionalOnClick={(data) => {
                                        const _data =
                                            data as IDropdownItem<IRelatedPartiesContactFormDummy>

                                        if (_data.additionalData)
                                            service.handleContactSelectDropdown(
                                                {
                                                    itemDropdown:
                                                        _data.additionalData,
                                                    key: item.key,
                                                },
                                            )
                                    }}
                                    useUppercaseLabel
                                />
                            </div>
                            <FormInput
                                label={t('bookings.phone_number')}
                                name={`${item.key}.phoneNumber`}
                                placeholder={t('action.enter', {
                                    value: t('bookings.phone_number'),
                                })}
                                parentDivClassName="!mb-0"
                                disabled
                                useUppercaseLabel
                            />
                            <FormInput
                                label={t('bookings.mobile_number')}
                                name={`${item.key}.mobileNumber`}
                                placeholder={t('action.enter', {
                                    value: t('bookings.mobile_number'),
                                })}
                                parentDivClassName="!mb-0"
                                disabled
                                useUppercaseLabel
                            />
                            <FormInput
                                label={t('bookings.email_address')}
                                name={`${item.key}.emailAddress`}
                                placeholder={t('action.enter', {
                                    value: t('bookings.email_address'),
                                })}
                                parentDivClassName="!mb-0 col-span-2"
                                disabled
                                useUppercaseLabel
                            />
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default RelatedParties

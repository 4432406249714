import { useNavigate, useParams } from 'react-router-dom'
import {
    IRealatedPartiesDropdownItem,
    IRelatedPartiesContactFormDummy,
    IRelatedPartiesFormDummy,
    IUseEditShippingInstruction,
} from './edit-shipping-instructions.interface'
import { useEffect, useState } from 'react'
import { ITabItem } from '@components/tab/tab.interface'
import { formBookingStatusHeader } from 'pages/bookings/create-booking/create-booking.static'
import {
    IConfimationModalProperty,
    IIsFormStillError,
} from 'pages/bookings/create-booking/create-booking.interface'
import { useFormik } from 'formik'
import { Toast } from '@components/toast/toast.component'
import { useModal } from '@components/modal/modal.service'
import {
    relatedPartiesAddressConsigneeDummy,
    relatedPartiesAddressShipperDummy,
    shippingInstructionsSummaryDummy,
} from '../shipping-instructions.dummy'
import { IDropdownItem } from '@components/dropdown/dropdown.interface'
import {
    IEditShippingInstructionContainerValidation,
    IEditShippingInstructionPackLineValidation,
    IEditShippingInstructionValidation,
    useEditShippingInstructionValidation,
} from 'form-validation/edit-shipping-instruction'
import { useDispatch, useSelector } from 'react-redux'
import {
    dropdownRelatedPartiesSelector,
    setCompanyDropdownS,
    setContactDropdownS,
    shippingInstructionDetailsSelector,
} from '../shipping-instructions.slice'
import { IShippingInstructionsSummary } from '../shipping-instructions.interface'

const useEditShippingInstruction = (): IUseEditShippingInstruction => {
    const navigate = useNavigate()
    const { id } = useParams()
    const dispatch = useDispatch()
    const {
        editShippingInstructionContainerInitialValue,
        editShippingInstructionInitialValue,
        editShippingInstructionPackLineInitialValue,
        editShippingInstructionPackLinesValidation,
        editShippingInstructionValidation,
        editShippingInstructionContainerValidation,
    } = useEditShippingInstructionValidation()

    // selectors
    const dropdownRelatedParties = useSelector(dropdownRelatedPartiesSelector)

    // common states
    const [isFormPage, setIsFormPage] = useState<boolean>(false)
    const [isFormStillError, setIsFormStillError] = useState<IIsFormStillError>(
        {
            addBooking: null,
            addPackLines: null,
            addContainer: null,
        },
    )
    const [isFirstRender, setIsFirstRender] = useState<boolean>(true)
    const [loading, setLoading] = useState<boolean>(false)
    const [tabFormBookingItems, setTabFormBookingItems] = useState<ITabItem[]>(
        formBookingStatusHeader,
    )
    const [tabFormBooking, setTabFormBooking] = useState<ITabItem>(
        formBookingStatusHeader[0],
    )
    const [formType, setFormType] = useState<string>('')
    const [confirmationModalProperty, setConfirmationModalProperty] =
        useState<IConfimationModalProperty>({
            title: '',
            message: '',
            btnProceed: {
                onClick: () => {},
                variant: 'brand',
            },
        })

    // state pack lines
    const [packLinesData, setPackLinesData] = useState<
        IEditShippingInstructionPackLineValidation[]
    >([])
    const [packLineData, setPackLineData] =
        useState<IEditShippingInstructionPackLineValidation>(
            editShippingInstructionPackLineInitialValue,
        )
    const [packLineParams, setPackLineParams] = useState<'update' | 'add'>(
        'update',
    )
    const [packlinesSelected, setPacklinesSelected] = useState<
        IEditShippingInstructionPackLineValidation[]
    >([])

    // state containers
    const [containersData, setContainersData] = useState<
        IEditShippingInstructionContainerValidation[]
    >([])
    const [containerData, setContainerData] =
        useState<IEditShippingInstructionContainerValidation>(
            editShippingInstructionContainerInitialValue,
        )
    const [containerParams, setContainerParams] = useState<'update' | 'add'>(
        'update',
    )
    const [containersSelected, setContainersSelected] = useState<
        IEditShippingInstructionContainerValidation[]
    >([])

    // modal service
    const confirmationModal = useModal()
    const formPackLineModal = useModal()
    const formContainerModal = useModal()

    // formik new vendor booking
    const editShippingInstructionFormik =
        useFormik<IEditShippingInstructionValidation>({
            validationSchema: editShippingInstructionValidation,
            initialValues: editShippingInstructionInitialValue,
            enableReinitialize: true,
            onSubmit: async (values) => {
                Toast({
                    header: 'Booking Successfully Updated',
                    message: `Booking No. BKG001`,
                    type: 'success',
                })
                navigate('/shipping-instruction-details/' + id)
            },
        })

    const editShippingInstructionPackLinesFormik =
        useFormik<IEditShippingInstructionPackLineValidation>({
            validationSchema: editShippingInstructionPackLinesValidation,
            initialValues: packLineData,
            enableReinitialize: true,
            onSubmit: async (values, { resetForm }) => {
                // reset form error to default
                setIsFormStillError((prevState) => ({
                    ...prevState,
                    addPackLines: null,
                }))

                let updateData = []
                let resetValues = null
                let messageToast: string = ''
                let closeModalFormPackline: boolean = false
                switch (packLineParams) {
                    case 'add':
                        updateData = [
                            ...packLinesData,
                            {
                                ...values,
                                id: packLinesData.length + 1,
                            },
                        ]
                        resetValues =
                            editShippingInstructionPackLineInitialValue
                        messageToast = 'Pack Line Added Succesfully'
                        closeModalFormPackline = true
                        break
                    case 'update':
                        updateData = packLinesData.map((item) =>
                            item.id === values.id
                                ? { ...item, ...values }
                                : item,
                        )
                        resetValues = values
                        messageToast = 'Pack Line Edited Succesfully'
                        closeModalFormPackline = true

                        break
                    default:
                        updateData = packLinesData
                        break
                }

                setPackLinesData(updateData)
                setPacklinesSelected([])

                Toast({
                    header: messageToast,
                    type: 'success',
                })

                // close modal when add or edit packline
                if (closeModalFormPackline)
                    formPackLineModal.closeModalHandling()

                if (resetValues !== null) {
                    resetForm({
                        values: resetValues as IEditShippingInstructionPackLineValidation,
                    })
                }
            },
        })

    const editShippingInstructionContainerFormik =
        useFormik<IEditShippingInstructionContainerValidation>({
            validationSchema: editShippingInstructionContainerValidation,
            initialValues: containerData,
            enableReinitialize: true,
            onSubmit: (values, { resetForm }) => {
                // reset form error to default
                setIsFormStillError((prevState) => ({
                    ...prevState,
                    addContainer: null,
                }))

                let updateData = []
                let resetValues = null
                let messageToast = ''
                let closeModalForm: boolean = false
                switch (containerParams) {
                    case 'add':
                        updateData = [
                            ...containersData,
                            { ...values, id: containersData.length + 1 },
                        ]
                        resetValues =
                            editShippingInstructionContainerInitialValue
                        messageToast = 'Container Added Succesfully'
                        closeModalForm = true
                        break
                    case 'update':
                        updateData = containersData.map((item) =>
                            item.id === values.id
                                ? { ...item, ...values }
                                : item,
                        )
                        resetValues = values
                        messageToast = 'Container Updated Succesfully'
                        closeModalForm = true
                        break
                    default:
                        updateData = containersData
                        break
                }

                setContainersData(updateData)
                setContainersSelected([])

                // close modal when add or edit cotanienr
                if (closeModalForm) formContainerModal.closeModalHandling()

                Toast({
                    header: messageToast,
                    type: 'success',
                })
                if (resetValues !== null) {
                    resetForm({ values: resetValues })
                }
            },
        })

    // common functions
    const saveShippingInstruction = () => {
        setConfirmationModalProperty({
            title: 'Edit Booking',
            message:
                'Editing this booking will update the existing booking record, any changes made system will notify to agents.',
            btnProceed: {
                onClick: () =>
                    setTimeout(() => {
                        if (isFormStillError.addBooking)
                            Toast({
                                header: 'Failed Place Booking!',
                                message:
                                    'There is some mandatory fields required to not leave blank',
                                type: 'error',
                            })
                        editShippingInstructionFormik.submitForm()
                    }, 500),
                variant: 'brand-v2-inverse',
            },
        })
        confirmationModal.openModalHandling()
    }
    const handleFormRequired = () => {
        if (editShippingInstructionFormik?.submitCount === 0) return

        const {
            shipperPickupAddress,
            consigneeDeliveryAddress,
            ...detailsFormErrors
        } = editShippingInstructionFormik.errors

        const relatedPartiesFormErrors =
            (shipperPickupAddress &&
                Object.keys(detailsFormErrors).length > 0) ||
            (consigneeDeliveryAddress &&
                Object.keys(consigneeDeliveryAddress).length > 0)

        const detailsError = Object.keys(detailsFormErrors).length !== 0

        // first submit error
        if (
            isFormStillError.addBooking === null &&
            (relatedPartiesFormErrors || detailsError)
        )
            Toast({
                header: 'Failed Place Booking!',
                message:
                    'There is some mandatory fields required to not leave blank',
                type: 'error',
            })

        // remove toast error
        if (!relatedPartiesFormErrors && !detailsError)
            setIsFormStillError((prevState) => ({
                ...prevState,
                addBooking: false,
            }))

        const updatedTabs: ITabItem[] = tabFormBookingItems.map((tab) => {
            if (tab.value === 'relatedParties' && relatedPartiesFormErrors) {
                setIsFormStillError((prevState) => ({
                    ...prevState,
                    addBooking: true,
                }))
                return { ...tab, className: 'red-error' }
            } else if (tab.value === 'details' && detailsError) {
                setIsFormStillError((prevState) => ({
                    ...prevState,
                    addBooking: true,
                }))
                return { ...tab, className: 'red-error' }
            } else {
                return { ...tab, className: 'brand' }
            }
        })
        setTabFormBookingItems(updatedTabs)
    }
    const loadTransportModeByContainerDropdown = () => {
        let options
        switch (formType) {
            case 'lse':
                options = [
                    {
                        label: 'AIR - Air Freight',
                        value: 'AIR',
                    },
                ]
                break
            case 'fcl':
            case 'lcl':
                options = [
                    {
                        label: 'SEA - Sea Freight',
                        value: 'SEA',
                    },
                    {
                        label: 'ROA - Road Freight',
                        value: 'ROA',
                    },
                    {
                        label: 'RAI - Rail Freight',
                        value: 'RAI',
                    },
                ]
                break
            case 'ftl':
                options = [
                    {
                        label: 'ROA - Road Freight',
                        value: 'ROA',
                    },
                ]
                break
            case 'cou':
                options = [
                    {
                        label: 'COU - COURIER',
                        value: 'COU',
                    },
                ]
                break
            default:
                options = [
                    {
                        label: 'AIR - Air Freight',
                        value: 'AIR',
                    },
                ]
                break
        }
        return options
    }
    const goToFormCreate = (containerType: string) => {
        setFormType(containerType)
        editShippingInstructionFormik.setFieldValue(
            'containerMode',
            containerType.toUpperCase(),
        )
        setIsFormPage(true)
    }
    const loadEditData = () => {
        const findData = shippingInstructionsSummaryDummy.find(
            (item) => item.bookingNo === id,
        )
        if (!findData) return

        editShippingInstructionFormik.setValues(findData.detailsData)
        setPackLinesData(findData.packLines)
        setContainersData(findData.containers)

        // update dropdown in related parties
        loadDropdownContact(findData)
    }

    const loadDropdownContact = (
        shippingInstructionData: IShippingInstructionsSummary,
    ) => {
        // find data contact by company name
        const findDataContact = ({
            contactData,
            companyName = '',
        }: {
            contactData: IRelatedPartiesFormDummy[]
            companyName: string
        }) =>
            contactData.find((item) => item.companyName === companyName)
                ?.contacts

        // flattened drodown contact
        const loadDropdown = (
            dropdownData: IRelatedPartiesContactFormDummy[],
        ): IDropdownItem<IRelatedPartiesContactFormDummy>[] =>
            dropdownData?.map((item) => ({
                label: item.contactName,
                value: item.contactName,
                additionalData: item,
            }))

        const shipperPickupAddressContact = findDataContact({
            contactData: relatedPartiesAddressShipperDummy,
            companyName:
                shippingInstructionData?.detailsData?.shipperPickupAddress
                    .companyName,
        })

        const shipperContact = findDataContact({
            contactData: relatedPartiesAddressShipperDummy,
            companyName:
                shippingInstructionData?.detailsData?.shipper.companyName,
        })

        const consigneeDeliveryAddressContact = findDataContact({
            contactData: relatedPartiesAddressConsigneeDummy,
            companyName:
                shippingInstructionData?.detailsData?.consigneeDeliveryAddress
                    .companyName,
        })

        const consigneeContact = findDataContact({
            contactData: relatedPartiesAddressConsigneeDummy,
            companyName:
                shippingInstructionData?.detailsData?.consignee.companyName,
        })

        const contact = {
            shipperPickupAddress:
                shipperPickupAddressContact &&
                shipperPickupAddressContact.length
                    ? loadDropdown(shipperPickupAddressContact)
                    : [],
            shipper:
                shipperContact && shipperContact.length
                    ? loadDropdown(shipperContact)
                    : [],
            consigneeDeliveryAddress:
                consigneeDeliveryAddressContact &&
                consigneeDeliveryAddressContact.length
                    ? loadDropdown(consigneeDeliveryAddressContact)
                    : [],
            consignee:
                consigneeContact && consigneeContact.length
                    ? loadDropdown(consigneeContact)
                    : [],
        }
        dispatch(setContactDropdownS(contact))
    }

    // function related parties
    const loadCompanyDropdown = () => {
        const loadShipperDropdown: IDropdownItem<IRelatedPartiesFormDummy>[] =
            relatedPartiesAddressShipperDummy.map((item) => ({
                label: item.companyName,
                value: item.companyName,
                additionalData: item,
            }))
        const loadConsigneeDropdown: IDropdownItem<IRelatedPartiesFormDummy>[] =
            relatedPartiesAddressConsigneeDummy.map((item) => ({
                label: item.companyName,
                value: item.companyName,
                additionalData: item,
            }))

        const updatedCompany: IRealatedPartiesDropdownItem['company'] = {
            shipper: loadShipperDropdown,
            shipperPickupAddress: loadShipperDropdown,
            consignee: loadConsigneeDropdown,
            consigneeDeliveryAddress: loadConsigneeDropdown,
        }

        dispatch(setCompanyDropdownS(updatedCompany))
    }

    const loadContactDropdownByOnClick = ({
        dropdownData,
        key,
    }: {
        dropdownData: IRelatedPartiesContactFormDummy[]
        key: string
    }) => {
        let contact: IRealatedPartiesDropdownItem['contact'] = {
            shipperPickupAddress: [],
            shipper: [],
            consigneeDeliveryAddress: [],
            consignee: [],
        }
        const loadDropdown = dropdownData?.map((item) => ({
            label: item.contactName,
            value: item.contactName,
            additionalData: item,
        }))
        switch (key) {
            case 'shipperPickupAddress':
                contact.shipperPickupAddress = loadDropdown || []
                contact.shipper = loadDropdown || []
                break
            case 'shipper':
                contact.shipper = loadDropdown || []
                break

            case 'consigneeDeliveryAddress':
                contact.consigneeDeliveryAddress = loadDropdown || []
                contact.consignee = loadDropdown || []
                break
            case 'consignee':
                contact.consignee = loadDropdown || []
                break
        }

        dispatch(
            setContactDropdownS({
                ...contact,
                ...dropdownRelatedParties.contact,
            }),
        )
    }

    const handleClearCompanyForm = ({ key }: { key: string }) => {
        switch (key) {
            case 'shipperPickupAddress':
                editShippingInstructionFormik.setFieldValue(
                    key,
                    editShippingInstructionInitialValue.shipperPickupAddress,
                )
                editShippingInstructionFormik.setFieldValue(
                    'shipper',
                    editShippingInstructionInitialValue.shipperPickupAddress,
                )
                break
            case 'consigneeDeliveryAddress':
                editShippingInstructionFormik.setFieldValue(
                    key,
                    editShippingInstructionInitialValue.shipperPickupAddress,
                )
                editShippingInstructionFormik.setFieldValue(
                    'consignee',
                    editShippingInstructionInitialValue.shipperPickupAddress,
                )
                break
            case 'consignee':
            case 'shipper':
                editShippingInstructionFormik.setFieldValue(
                    key,
                    editShippingInstructionInitialValue.shipperPickupAddress,
                )
        }
    }

    const handleCompanySelectDropdown = ({
        key,
        itemDropdown,
    }: {
        key: string
        itemDropdown: IRelatedPartiesFormDummy
    }) => {
        const updatedData = {
            address1: itemDropdown.address1,
            address2: itemDropdown.address2,
            city: itemDropdown.city,
            state: itemDropdown.state,
            postCode: itemDropdown.postCode,
            country: itemDropdown.country,
        }

        switch (key) {
            case 'shipperPickupAddress':
                editShippingInstructionFormik.setFieldValue(key, {
                    ...editShippingInstructionFormik.values[key],
                    ...updatedData,
                })
                editShippingInstructionFormik.setFieldValue('shipper', {
                    ...editShippingInstructionFormik.values[key],
                    ...updatedData,
                })
                break
            case 'consigneeDeliveryAddress':
                editShippingInstructionFormik.setFieldValue(key, {
                    ...editShippingInstructionFormik.values[key],
                    ...updatedData,
                })
                editShippingInstructionFormik.setFieldValue('consignee', {
                    ...editShippingInstructionFormik.values[key],
                    ...updatedData,
                })
                break
            case 'consignee':
            case 'shipper':
                editShippingInstructionFormik.setFieldValue(key, {
                    ...editShippingInstructionFormik.values[key],
                    ...updatedData,
                })
        }
    }

    const handleContactSelectDropdown = ({
        key,
        itemDropdown,
    }: {
        key: string
        itemDropdown: IRelatedPartiesContactFormDummy
    }) => {
        const updatedData = {
            phoneNumber: itemDropdown.phoneNumber,
            mobileNumber: itemDropdown.mobileNumber,
            emailAddress: itemDropdown.emailAddress,
        }

        switch (key) {
            case 'shipperPickupAddress':
                editShippingInstructionFormik.setFieldValue(key, {
                    ...editShippingInstructionFormik.values[key],
                    ...updatedData,
                })
                editShippingInstructionFormik.setFieldValue('shipper', {
                    ...editShippingInstructionFormik.values[key],
                    ...updatedData,
                })
                break
            case 'consigneeDeliveryAddress':
                editShippingInstructionFormik.setFieldValue(key, {
                    ...editShippingInstructionFormik.values[key],
                    ...updatedData,
                })
                editShippingInstructionFormik.setFieldValue('consignee', {
                    ...editShippingInstructionFormik.values[key],
                    ...updatedData,
                })
                break
            case 'consignee':
            case 'shipper':
                editShippingInstructionFormik.setFieldValue(key, {
                    ...editShippingInstructionFormik.values[key],
                    ...updatedData,
                })
        }
    }

    // common useEffects
    useEffect(() => {
        loadCompanyDropdown()
        loadEditData()
        setIsFirstRender(false)
    }, [])

    useEffect(() => {
        handleFormRequired()
    }, [
        editShippingInstructionFormik.submitCount,
        editShippingInstructionFormik.errors,
    ])

    return {
        navigate,
        isFormPage,
        tabFormBooking,
        tabFormBookingItems,
        formType,
        setTabFormBooking,
        editShippingInstructionFormik,
        loading,
        loadTransportModeByContainerDropdown,
        goToFormCreate,
        handleClearCompanyForm,
        handleCompanySelectDropdown,
        handleContactSelectDropdown,
        setConfirmationModalProperty,
        confirmationModal,
        confirmationModalProperty,
        saveShippingInstruction,

        // related parties
        loadContactDropdownByOnClick,
        dropdownRelatedParties,

        // pack lines
        packLinesData,
        packLineParams,
        formPackLineModal,
        editShippingInstructionPackLinesFormik,
        packlinesSelected,
        setPackLinesData,
        setPacklinesSelected,
        setPackLineParams,
        setPackLineData,
        editShippingInstructionPackLineInitialValue,

        // containers
        containersData,
        setContainersData,
        setContainersSelected,
        containersSelected,
        setContainerParams,
        setContainerData,
        editShippingInstructionContainerInitialValue,
        formContainerModal,
        containerParams,
        editShippingInstructionContainerFormik,
    }
}

export default useEditShippingInstruction

import Modal from '@components/modal/modal.component'
import { FormikProvider } from 'formik'
import Button from '@components/button/button.component'
import FormInput from '@components/form-input/form-input.component'
import FormDropdown from '@components/form-dropdown/form-dropdown.component'
import FormMeasurementInput from '@components/form-measurement-input/form-measurement-input.component'
import { IUseNewVendorBooking } from '../new-vendor-booking.interface'

const FormPackLineModal = ({ service }: { service: IUseNewVendorBooking }) => {
    const borderComponent = <div className="border-b"></div>
    const formTitle =
        service.packLineParams === 'add' ? 'Add Pack Line' : 'Edit Pack Line'

    return (
        <Modal
            isModalOpen={service.formPackLineModal.isModalOpen}
            className="!w-3/5 px-0 flex flex-col gap-3"
        >
            <>
                <div className="px-3 themes-text-gray-v9 text-size-M">
                    {formTitle}
                </div>
                {borderComponent}
                <div className="grid grid-cols-2 gap-3 px-3 h-full mobile:grid-cols-1 mobile:h-[calc(100vh-22rem)] mobile:overflow-auto">
                    <FormikProvider
                        value={service.newVendorBookingPackLinesFormik}
                    >
                        <FormInput
                            label={'PIECES'}
                            name={`pieces`}
                            placeholder={'Enter here'}
                            parentDivClassName="!mb-0 mobile:mt[3px]"
                            type="number"
                            useUppercaseLabel
                            useArrow={false}
                        />
                        <FormInput
                            label={'DESCRIPTION'}
                            name={`description`}
                            placeholder={'Enter here'}
                            parentDivClassName="!mb-0"
                            useUppercaseLabel
                            maxLength={36}
                        />
                        <FormDropdown
                            label={'PACK TYPE'}
                            name={`packType`}
                            placeholder={'Select here'}
                            options={[
                                {
                                    label: 'PLT, Pallet',
                                    value: 'plt',
                                },
                            ]}
                            parentDivClassName="!mb-0"
                            isClearable
                            isSearchable
                            useUppercaseLabel
                        />
                        <FormInput
                            label={'MARKS AND NUMBERS'}
                            name={`marksAndNumbers`}
                            placeholder={'Enter here'}
                            parentDivClassName="!mb-0"
                            useUppercaseLabel
                            maxLength={36}
                        />
                        <div className="flex gap-3">
                            <FormInput
                                label={'LENGTH'}
                                name={`length`}
                                placeholder={'Enter here'}
                                parentDivClassName="!mb-0"
                                valueSuffix={
                                    service.newVendorBookingPackLinesFormik
                                        .values.unitDimension
                                }
                                useDecimalFormat
                                useUppercaseLabel
                                maxLength={36}
                            />
                            <FormInput
                                label={'WIDTH'}
                                name={`width`}
                                placeholder={'Enter here'}
                                parentDivClassName="!mb-0"
                                valueSuffix={
                                    service.newVendorBookingPackLinesFormik
                                        .values.unitDimension
                                }
                                useDecimalFormat
                                useUppercaseLabel
                                maxLength={36}
                            />
                            <FormInput
                                label={'HEIGHT'}
                                name={`height`}
                                placeholder={'Enter here'}
                                parentDivClassName="!mb-0"
                                valueSuffix={
                                    service.newVendorBookingPackLinesFormik
                                        .values.unitDimension
                                }
                                useDecimalFormat
                                useUppercaseLabel
                                maxLength={36}
                            />
                        </div>
                        <FormInput
                            label={'LINE PRICE'}
                            name={`linePrice`}
                            placeholder={'Enter here'}
                            parentDivClassName="!mb-0"
                            useDecimalFormat
                            useUppercaseLabel
                            maxLength={36}
                        />
                        <FormDropdown
                            label={'UNIT DIMENSION'}
                            name={`unitDimension`}
                            placeholder={'Select here'}
                            options={[
                                {
                                    label: 'M, Meter',
                                    value: 'Meter',
                                },
                            ]}
                            parentDivClassName="!mb-0"
                            isSearchable
                            useUppercaseLabel
                        />
                        <FormDropdown
                            label={'PRICE CURRENCY'}
                            name={`priceCurrency`}
                            placeholder={'Select here'}
                            options={[
                                {
                                    label: 'USD, United State Dollar',
                                    value: 'usd',
                                },
                            ]}
                            parentDivClassName="!mb-0"
                            isClearable
                            isSearchable
                            useUppercaseLabel
                        />
                        <FormMeasurementInput
                            options={[
                                {
                                    label: 'KG, Kilogram',
                                    value: 'kg',
                                },
                            ]}
                            name="weightMeasure"
                            label={'WEIGHT'}
                            useDecimalFormat
                            maxLength={36}
                        />
                        <FormInput
                            label={'TARIFF NUMBER'}
                            name={`tariffNumber`}
                            placeholder={'Enter Here'}
                            parentDivClassName="!mb-0"
                            useDecimalFormat
                            useUppercaseLabel
                            maxLength={36}
                        />
                        <FormMeasurementInput
                            options={[
                                {
                                    label: 'M3, Cubic Meters',
                                    value: 'M3',
                                },
                            ]}
                            name={'volumeMeasure'}
                            label={'VOLUME'}
                            useDecimalFormat
                            maxLength={36}
                        />
                        <FormInput
                            label={'HS CODE'}
                            name={`hsCode`}
                            placeholder={'Enter Here'}
                            parentDivClassName="!mb-0"
                            useUppercaseLabel
                        />
                    </FormikProvider>
                </div>
                {borderComponent}
                <div className="flex  px-3 justify-between">
                    <Button
                        onClick={() => {
                            service.formPackLineModal.closeModalHandling()
                        }}
                        label="CANCEL"
                        variant="brand-v2-inverse"
                    />
                    <Button
                        onClick={() => {
                            console.log('test')

                            service.newVendorBookingPackLinesFormik.submitForm()
                        }}
                        label="PROCEED"
                        variant="brand-v2-inverse"
                    />
                </div>
            </>
        </Modal>
    )
}

export default FormPackLineModal

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import SpinnerTable from '@components/spinner-table/spinner-table.component';
import Pagination from '@components/pagination/pagination.component';
import SortButton from './component/sort-button.component';
import { useTable } from './table.service';
import Checkbox from '@components/checkbox/checkbox.component';
import Button from '@components/button/button.component';
import EmptyResult from '@components/empty-result/empty-result.component';
import RowOptionOverlay from './component/row-option-overlay.component';
import { useTranslation } from 'react-i18next';
import './table.style.css';
function Table(_a) {
    var _b = _a.usePagination, usePagination = _b === void 0 ? true : _b, props = __rest(_a, ["usePagination"]);
    var t = useTranslation().t;
    var _c = useTable({
        data: props.data,
        headers: props.headers,
        checkboxVisible: props.checkboxVisible,
        checkboxDataHandling: props.checkboxDataHandling,
        tabFilterItem: props.tabFilterItem,
        resetCheckedInitialValue: props.resetCheckedInitialValue,
    }), sortBy = _c.sortBy, sortOrder = _c.sortOrder, generatedData = _c.generatedData, generatedHeaders = _c.generatedHeaders, setSortColumn = _c.setSortColumn, checkboxSingleHandling = _c.checkboxSingleHandling, checkboxAllHandling = _c.checkboxAllHandling, isCheckedAll = _c.isCheckedAll;
    var loadingMessage = "Loading ".concat(props.moduleTitle, " Data . . .");
    var notFoundMessage = "No data found";
    var dataTestIdComponent = (props === null || props === void 0 ? void 0 : props.dataTestId)
        ? "".concat(props.dataTestId, "_TABLE")
        : '';
    var lastThreeIndices = generatedData.length > 3
        ? generatedData
            .slice(-3)
            .map(function (_, idx) { return generatedData.length - 3 + idx; })
        : generatedData.map(function (_, idx) { return idx; });
    return (_jsxs(_Fragment, { children: [_jsx("div", __assign({ className: "".concat(props.className, " h-full flex flex-col overflow-auto") }, { children: _jsx("div", __assign({ className: "flex-1 ".concat(props.containerClassname) }, { children: props.loading === true ? (_jsx(SpinnerTable, { message: loadingMessage })) : !props.data.length ? (_jsx(EmptyResult, { disableIllustration: props.disableErrorIllustration, message: notFoundMessage })) : (_jsxs("table", __assign({ className: "table-master css-table-master table-auto", id: "table-master" }, { children: [_jsx("thead", __assign({ className: "thead-master" }, { children: _jsxs("tr", __assign({ className: "tr-master" }, { children: [!props.checkboxVisible ? (_jsx(_Fragment, {})) : (_jsx("th", __assign({ className: "th-master css-th-master sticky-column checkboxEnable w-[10px]" }, { children: _jsx(Checkbox, { onChecked: function (x) {
                                                    checkboxAllHandling(x);
                                                }, useFormik: true, isChecked: isCheckedAll }) }))), generatedHeaders.map(function (data, idx) {
                                            var classColumnActive = sortBy === data.accessor
                                                ? 'sort-active'
                                                : '';
                                            var sortEnable = data.sort === undefined
                                                ? true
                                                : data.sort;
                                            var label = data.labelTranslation
                                                ? t(data.labelTranslation)
                                                : data.label;
                                            var header = data.showLabel === false ? (_jsx(_Fragment, {})) : data.customHeader ? (data.customHeader()) : (label);
                                            var width = data.width
                                                ? data.width + 'px'
                                                : '';
                                            var minWidth = data.minWidth
                                                ? data.minWidth + 'px'
                                                : '';
                                            return (_jsx("th", __assign({ className: "th-master css-th-master", style: {
                                                    width: width,
                                                    minWidth: minWidth,
                                                } }, { children: _jsxs("div", __assign({ className: "".concat(classColumnActive, " \n                            ").concat((data === null || data === void 0 ? void 0 : data.headerClassName) || '', "\n                            ").concat(sortBy === data.accessor ? 'themes-text-gray-v6' : 'none', "\n                          css-th-master-div") }, { children: [header, !sortEnable ? null : (_jsx(SortButton, { columnKey: data.accessor, sortKey: sortBy, sortOrder: sortOrder, onClick: function (accessor) {
                                                                setSortColumn(accessor, sortOrder ===
                                                                    'asc');
                                                            } }, 'sortButton-' +
                                                            idx))] }), data.accessor) }), 'header-' + idx));
                                        }), props.rowOptionComponent && (_jsx("th", { className: "th-expandable" }, 'header-empty'))] })) })), _jsx("tbody", { children: generatedData.map(function (row, rowIdx) {
                                    var selectedClass = row['selected'] === true
                                        ? 'tr-selected'
                                        : '';
                                    var cursor = props.onRowClick
                                        ? 'cursor-pointer'
                                        : '';
                                    var selectedChBg = row['selected'] === false
                                        ? 'themes-bg-white'
                                        : 'themes-bg-brand-v2';
                                    var isLastRow = generatedData.length >= 10 &&
                                        lastThreeIndices.includes(rowIdx);
                                    return (_jsxs("tr", __assign({ className: "".concat(selectedClass, " ").concat(cursor, " tr-master ") }, { children: [!props.checkboxVisible ? (_jsx(_Fragment, {})) : (_jsx("td", __assign({ className: "td-master css-td-master sticky-column ".concat(selectedChBg, " checkboxEnable") }, { children: _jsx(Checkbox, { isChecked: row['selected'], onChecked: function (isChecked) {
                                                        checkboxSingleHandling(isChecked, row, rowIdx);
                                                    } }) }))), props.headers.map(function (col, idx) {
                                                var accessor = col.accessor;
                                                var data = null;
                                                if (accessor.includes('.')) {
                                                    var accessorArray = accessor.split('.');
                                                    data = row;
                                                    for (var _i = 0, accessorArray_1 = accessorArray; _i < accessorArray_1.length; _i++) {
                                                        var key = accessorArray_1[_i];
                                                        data = data === null || data === void 0 ? void 0 : data[key];
                                                    }
                                                }
                                                else {
                                                    data = row[accessor];
                                                }
                                                var width = col.width
                                                    ? col.width + 'px'
                                                    : '';
                                                var minWidth = col.minWidth
                                                    ? col.minWidth + 'px'
                                                    : '';
                                                var height = col.height
                                                    ? col.height + 'px'
                                                    : '';
                                                var cursor = !col.disableRowClick
                                                    ? ''
                                                    : '!cursor-default';
                                                return (_jsx("td", __assign({ className: "td-master css-td-master ".concat(col === null || col === void 0 ? void 0 : col.tdClassName, " ").concat(cursor), onClick: function () {
                                                        var selected = row.selected, values = __rest(row, ["selected"]);
                                                        props.onRowClick &&
                                                            !col.disableRowClick &&
                                                            props.onRowClick(values, rowIdx);
                                                    }, style: {
                                                        height: height,
                                                        width: col.ellipsis
                                                            ? width
                                                            : '',
                                                        minWidth: minWidth,
                                                    } }, { children: _jsx("div", __assign({ className: "".concat((col === null || col === void 0 ? void 0 : col.className) || '', " max-w-[250px] overflow-hidden text-ellipsis flex items-center") }, { children: !col.customBuild
                                                            ? data !== ''
                                                                ? data
                                                                : '-'
                                                            : col.customBuild(data, row, undefined, undefined, undefined, rowIdx) })) }), 'col-' + idx));
                                            }), (props === null || props === void 0 ? void 0 : props.rowOptionComponent) && (_jsx(RowOptionOverlay, __assign({}, ((props === null || props === void 0 ? void 0 : props.rowOptionOverlayProps) ||
                                                {}), { data: row, isLastRow: isLastRow, rowOptionComponent: props.rowOptionComponent, className: props.classNameRowOptionOverlay })))] }), 'row-' + rowIdx));
                                }) })] }))) })) })), usePagination && (_jsxs("div", __assign({ className: "css-table-master-footer border-t themes-border-color flex items-center justify-between" }, { children: [_jsx(Pagination, { meta: props.meta, meta2: props.meta2, meta3: props.meta3, previousHandling: function (page) {
                            (props === null || props === void 0 ? void 0 : props.previousHandling) &&
                                props.previousHandling(page);
                        }, nextHandling: function (page) {
                            (props === null || props === void 0 ? void 0 : props.nextHandling) && props.nextHandling(page);
                        }, dataTestId: dataTestIdComponent }), _jsxs("div", __assign({ className: "m-2 flex gap-2" }, { children: [props.additionalButtonBottom, props.enableExport ? (_jsx(Button, { isLoading: props.exportButtonLoading &&
                                    props.exportButtonLoading, label: props.labelButtonExport ||
                                    t('action.generate_report').toUpperCase(), onClick: function () {
                                    props.exportModalService &&
                                        props.exportModalService.openModalHandling();
                                    props.exportButtonOnClick &&
                                        props.exportButtonOnClick();
                                }, variant: props.variantButtonExport || 'default', "data-testid": dataTestIdComponent &&
                                    "".concat(dataTestIdComponent, "_GENERATE_REPORT") })) : ('')] }))] })))] }));
}
export default Table;

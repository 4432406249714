import { useState } from 'react'
import { IUseVendorBookingDetails } from './vendor-booking-details.interface'
import { ITabItem } from '@components/tab/tab.interface'
import { tabItemsVendorBookingDetails } from './vendor-booking-details.static'
import { useNavigate } from 'react-router-dom'

const useVendorBookingDetails = (): IUseVendorBookingDetails => {
    const navigate = useNavigate()

    // state
    const [containerMode] = useState<string>('Full Container Load')
    const [tabFilter, setTabFilter] = useState<ITabItem>(
        tabItemsVendorBookingDetails[0],
    )
    const [loading, setLoading] = useState<boolean>(false)

    return {
        containerMode,
        tabFilter,
        setTabFilter,
        navigate,
        loading,
    }
}

export default useVendorBookingDetails

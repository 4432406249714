import { IMeasurement } from '@components/measurement-input/measurement-input.interface'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'

export interface IRelatedPartiesAddressValidation {
    companyName: string
    address1: string
    address2: string
    city: string
    state: string
    postCode: string
    country: string
    contactName: string
    phoneNumber: string
    mmobileNumber: string
    emailAddress: string
}

export interface INewVendorBookingValidation {
    // segment details
    containerMode: string
    transportMode: string
    shippersRef: string
    packageTypeDescription: string
    serviceLevel: string
    outerPacks: string
    outerPacksUnit: string
    weightMeasure: IMeasurement
    volumeMeasure: IMeasurement
    woodenPackage: IMeasurement
    dangerousGoods: string
    originPort: string
    cargoReadyDate: string
    destinationPort: string
    deliveryrequiredBy: string
    incoTerm: string
    goodsValue: string
    goodsCurrency: string
    additionalTerms: string
    insuranceValue: string
    insuranceCurrency: string
    chargesApply: string
    marksAndNumbers: string
    goodsDescription: string

    // related parties
    shipperPickupAddress: IRelatedPartiesAddressValidation
    shipper: IRelatedPartiesAddressValidation
    consigneeDeliveryAddress: IRelatedPartiesAddressValidation
    consignee: IRelatedPartiesAddressValidation

    // special Field (not include in payload)
    specialField: {
        id: number
        unitDimension: string
        length: string
        lengthMeasure: IMeasurement
        widthMeasure: IMeasurement
        heightMeasure: IMeasurement
        weightMeasure: IMeasurement
        volumeMeasure: IMeasurement
    }
}

export interface INewVendorBookingPackLineValidation {
    id: number
    pieces: string
    packType: string
    length: string
    height: string
    width: string
    unitDimension: string
    weightMeasure: IMeasurement
    volumeMeasure: IMeasurement
    description: string
    marksAndNumbers: string
    linePrice: string
    priceCurrency: string
    tariffNumber: string
    hsCode: string
}

export interface INewVendorBookingContainerValidation {
    id: number
    containerNo: string
    type: string
    count: string
    sealNo: string
}

export const useCreateBookingValidation = () => {
    const { t } = useTranslation()

    const today = new Date()
    const newVendorBookingValidation = Yup.object().shape({
        shipperPickupAddress: Yup.object().shape({
            companyName: Yup.string().required(
                t('message.required', { value: 'Company Name' }),
            ),
        }),
        consigneeDeliveryAddress: Yup.object().shape({
            companyName: Yup.string().required(
                t('message.required', { value: 'Company Name' }),
            ),
        }),
        deliveryrequiredBy: Yup.date()
            .nullable()
            .transform((value, originalValue) =>
                originalValue === '' ? null : value,
            )
            .min(today, 'Delivery date cannot be in the past'),
        transportMode: Yup.string().required('Transport mode is required'),
        containerMode: Yup.string().required('Container mode is required'),
        originPort: Yup.string().required('Origin port is required'),
        destinationPort: Yup.string().required('Destination port is required'),
    })

    const newVendorBookingPackLinesValidation = Yup.object().shape({
        pieces: Yup.number()
            .required(t('message.required', { value: 'Pieces' }))
            .min(1),
    })

    const newVendorContainerBookingsValidation = Yup.object().shape({
        count: Yup.number()
            .required(
                t('message.required', {
                    value: 'Container count',
                }),
            )
            .min(1),
        type: Yup.string().required(
            t('message.required', {
                value: 'Container type',
            }),
        ),
    })

    const newVendorBookingInitialValue: INewVendorBookingValidation = {
        containerMode: '',
        transportMode: '',
        shippersRef: '',
        packageTypeDescription: '',
        serviceLevel: '',
        outerPacks: '',
        outerPacksUnit: '',
        weightMeasure: {
            unitLabel: 'KG',
        },
        volumeMeasure: {
            unitLabel: 'M3',
        },
        woodenPackage: {
            unitLabel: 'KG',
        },
        dangerousGoods: '',
        originPort: '',
        cargoReadyDate: '',
        destinationPort: '',
        deliveryrequiredBy: '',
        incoTerm: '',
        goodsValue: '',
        goodsCurrency: '',
        additionalTerms: '',
        insuranceValue: '',
        insuranceCurrency: '',
        chargesApply: '',
        marksAndNumbers: '',
        goodsDescription: '',
        shipperPickupAddress: {
            companyName: '',
            address1: '',
            address2: '',
            city: '',
            state: '',
            postCode: '',
            country: '',
            contactName: '',
            phoneNumber: '',
            mmobileNumber: '',
            emailAddress: '',
        },
        shipper: {
            companyName: '',
            address1: '',
            address2: '',
            city: '',
            state: '',
            postCode: '',
            country: '',
            contactName: '',
            phoneNumber: '',
            mmobileNumber: '',
            emailAddress: '',
        },
        consigneeDeliveryAddress: {
            companyName: '',
            address1: '',
            address2: '',
            city: '',
            state: '',
            postCode: '',
            country: '',
            contactName: '',
            phoneNumber: '',
            mmobileNumber: '',
            emailAddress: '',
        },
        consignee: {
            companyName: '',
            address1: '',
            address2: '',
            city: '',
            state: '',
            postCode: '',
            country: '',
            contactName: '',
            phoneNumber: '',
            mmobileNumber: '',
            emailAddress: '',
        },
        specialField: {
            id: 0,
            unitDimension: '',
            length: '',
            lengthMeasure: {},
            widthMeasure: {},
            heightMeasure: {},
            weightMeasure: {},
            volumeMeasure: {},
        },
    }

    const newVendorBookingPackLineInitialValue: INewVendorBookingPackLineValidation =
        {
            pieces: '',
            packType: '',
            length: '',
            height: '',
            width: '',
            weightMeasure: {
                unitLabel: 'KG',
            },
            volumeMeasure: {
                unitLabel: 'M3',
            },
            description: '',
            marksAndNumbers: '',
            linePrice: '',
            tariffNumber: '',
            hsCode: '',
            id: 1,
            unitDimension: '',
            priceCurrency: '',
        }

    const newVendorBookingContainerInitialValue: INewVendorBookingContainerValidation =
        {
            containerNo: '',
            type: '',
            count: '',
            sealNo: '',
            id: 1,
        }

    return {
        newVendorBookingValidation,
        newVendorBookingInitialValue,
        newVendorBookingPackLinesValidation,
        newVendorContainerBookingsValidation,
        newVendorBookingContainerInitialValue,
        newVendorBookingPackLineInitialValue,
    }
}
